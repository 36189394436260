import React, { createContext, useCallback, useContext, useMemo, useState } from 'react'

interface VersionContextType {
    version: number
    incrementVersion: () => void
    setVersionValue: (value: number) => void
}

const initialState: VersionContextType = {
    version: 0,
    incrementVersion: () => {},
    setVersionValue: () => {}
}

const VersionContext = createContext<VersionContextType>(initialState)

export const useVersion = () => {
    return useContext(VersionContext)
}

export const VersionProvider = ({ children }: { children: React.ReactNode }) => {
    const [version, setVersion] = useState<number>(0)

    const incrementVersion = useCallback(() => {
        setVersion((prev) => prev + 1)
    }, [])

    const setVersionValue = useCallback((value: number) => {
        setVersion(value)
    }, [])

    const value = useMemo(
        () => ({ version, incrementVersion, setVersionValue }),
        [version, incrementVersion, setVersionValue]
    )

    return <VersionContext.Provider value={value}>{children}</VersionContext.Provider>
}
