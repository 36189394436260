import { Column } from '@ditkrg/react-core'
import { useTranslation } from 'react-i18next'
import { ViewTableState } from 'src/store/table'
import ViolationStatusLabel from './status-label'
import dateFormat from 'src/utils/date-format'

export const GenerateColumns = ({ tableState }: { tableState: ViewTableState }) => {
    const { t } = useTranslation()
    const columns: Column[] = [
        {
            id: 'index',
            header: '#',
            cell: (_, rowIndex) => tableState.rowsPerPage * tableState.page + rowIndex + 1
        },
        {
            id: 'stationId',
            header: t('view.station.name.headline'),
            cell: (row) => `${t('view.station.name.headline')}: ${row?.station?.number}`
        },
        {
            id: 'status',
            header: t('model.common.status'),
            cell: (row) => <ViolationStatusLabel status={row.status} />
        },
        {
            id: 'createdAt',
            header: t('model.common.createdAt'),
            cell: (row) => dateFormat({ date: row.createdAt, format: 'hh:mm:ss A - MM/DD' })
        }
    ]

    return columns
}
