/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateCircleRequest } from '../models/CreateCircleRequest';
import type { DetailedCircleResponse } from '../models/DetailedCircleResponse';
import type { IdResponse } from '../models/IdResponse';
import type { IncludedResourceName } from '../models/IncludedResourceName';
import type { PagedCircleResponseListResponse } from '../models/PagedCircleResponseListResponse';
import type { UpdateCircleRequest } from '../models/UpdateCircleRequest';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class CirclesService {
    /**
     * @returns PagedCircleResponseListResponse OK
     * @throws ApiError
     */
    public static listCircles({
        page,
        rowsPerPage,
    }: {
        page: number,
        rowsPerPage: number,
    }): CancelablePromise<PagedCircleResponseListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/circles',
            query: {
                'Page': page,
                'RowsPerPage': rowsPerPage,
            },
        });
    }
    /**
     * @returns IdResponse Created
     * @throws ApiError
     */
    public static createCircle({
        requestBody,
    }: {
        requestBody: CreateCircleRequest,
    }): CancelablePromise<IdResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/circles',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @returns IncludedResourceName OK
     * @throws ApiError
     */
    public static listAllCircles(): CancelablePromise<Array<IncludedResourceName>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/circles/all',
        });
    }
    /**
     * @returns void
     * @throws ApiError
     */
    public static updateCircle({
        id,
        requestBody,
    }: {
        id: string,
        requestBody: UpdateCircleRequest,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/circles/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @returns DetailedCircleResponse OK
     * @throws ApiError
     */
    public static getCircleById({
        id,
    }: {
        id: string,
    }): CancelablePromise<DetailedCircleResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/circles/{id}',
            path: {
                'id': id,
            },
        });
    }
}
