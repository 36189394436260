import { TimePicker } from '@mui/x-date-pickers'
import dayjs, { locale } from 'dayjs'
import { Controller } from 'react-hook-form'

export const TimePickerController: React.FC<any> = ({
    name,
    control,
    parser,
    ...rest
}: {
    name: string
    control: any
    parser: (value: string) => any
    [key: string]: any
}) => {
    return (
        <Controller
            name={name}
            control={control}
            // defaultValue={rest?.defaultValue || ''}
            render={({ field, fieldState }) => {
                return (
                    <TimePicker
                        {...field}
                        label={rest?.label}
                        slotProps={{
                            textField: {
                                error: fieldState?.invalid,
                                helperText: fieldState?.error?.message,
                                fullWidth: true
                            }
                        }}
                        {...rest}
                        value={parser(field.value)}
                        onChange={(value) => {
                            field.onChange(dayjs(value, 'HH:mm:ss').format('HH:mm:ss'))
                        }}
                    />
                )
            }}
        />
    )
}
