import { PartiesService, PartyType } from 'src/services/api/emos'
import { MINUTE } from './roles'

export const allPartiesQuery = ({
    isActive,
    partyType,
    circleId
}: {
    isActive?: boolean
    partyType?: PartyType
    circleId?: string
}) => ({
    queryKey: ['allParties', isActive, partyType, circleId],
    queryFn: async () => {
        const res = await PartiesService.listAllParties({
            isActive,
            partyType,
            circleId
        })
        return res
    },
    gcTime: MINUTE * 60,
    staleTime: MINUTE * 60
})
