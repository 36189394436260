import type { FC } from 'react'
import { useCallback } from 'react'

import User03Icon from '@untitled-ui/icons-react/build/esm/User03'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Popover from '@mui/material/Popover'
import SvgIcon from '@mui/material/SvgIcon'
import Typography from '@mui/material/Typography'
import { useAuth } from '../../../hooks/use-auth'
import { paths } from '../../../paths'
import { useTranslation } from 'react-i18next'
import { useRouter } from 'src/hooks/use-router'
import { useToast } from 'src/hooks/useToast'

interface AccountPopoverProps {
    anchorEl: null | Element
    onClose?: () => void
    open?: boolean
}

export const AccountPopover: FC<AccountPopoverProps> = (props) => {
    const { anchorEl, onClose, open, ...other } = props
    const router = useRouter()
    const auth = useAuth()
    const [t] = useTranslation()
    const { errorToast } = useToast()

    const username = auth?.user?.username ?? '-'

    const handleLogout = useCallback(async (): Promise<void> => {
        try {
            onClose?.()

            await auth.signOut()
            sessionStorage.clear()

            router.push(paths.index)
        } catch (err) {
            console.error(err)
            errorToast('Something went wrong!')
        }
    }, [onClose, auth, router, errorToast])

    return (
        <Popover
            anchorEl={anchorEl}
            anchorOrigin={{
                horizontal: 'center',
                vertical: 'bottom'
            }}
            disableScrollLock
            onClose={onClose}
            open={!!open}
            PaperProps={{ sx: { width: 200 } }}
            {...other}
        >
            <Box sx={{ p: 2 }}>
                <Typography variant="body1">{username}</Typography>
                <Typography variant="body2" color="textSecondary">
                    {auth?.user?.node?.name}
                </Typography>
                {/* <Typography color="text.secondary" variant="caption">
                    {email}
                </Typography> */}
            </Box>
            <Divider />
            <Box sx={{ p: 1 }}>
                <ListItemButton
                    // href={paths.account}
                    sx={{
                        borderRadius: 1,
                        px: 1,
                        py: 0.5
                    }}
                    onClick={() => {
                        router.push(paths.account)
                        onClose?.()
                    }}
                >
                    <ListItemIcon>
                        <SvgIcon fontSize="small">
                            <User03Icon />
                        </SvgIcon>
                    </ListItemIcon>
                    <ListItemText primary={<Typography variant="body2">{t('view.nav.account')}</Typography>} />
                </ListItemButton>
            </Box>
            <Divider sx={{ my: '0 !important' }} />
            <Box
                sx={{
                    display: 'flex',
                    p: 1,
                    justifyContent: 'center'
                }}
            >
                <Button color="inherit" onClick={handleLogout} size="small" fullWidth>
                    {t('view.auth.logout.headline')}
                </Button>
            </Box>
        </Popover>
    )
}
