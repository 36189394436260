import { CreateStationRequest, InitiateStationRequest, UpdateStationRequest } from 'src/services/api/emos'

type CreateStationRequestKeys = Array<keyof CreateStationRequest>
export const createStationAttributesToKeep: CreateStationRequestKeys = [
    'maxVoters',
    'number',
    'isActive',
    'isVip',
    'circles',
    'pcId'
]

type UpdateStationRequestKeys = Array<keyof UpdateStationRequest>
export const updateStationAttributesToKeep: UpdateStationRequestKeys = [
    'maxVoters',
    'number',
    'isActive',
    'isVip',
    'circles'
]

type InitiateStationRequestKeys = Array<keyof InitiateStationRequest>
export const initiateStationAttributesToKeep: InitiateStationRequestKeys = ['incident']
