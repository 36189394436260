import { Paper, Typography } from '@mui/material'
import { Stack } from '@mui/system'

const ReportCard = ({ title, children }: { title: string; children: any }) => {
    return (
        <Paper>
            <Stack spacing={1}>
                <Typography sx={{ pt: 2, px: 2 }} variant="body1" color="textSecondary">
                    {title}
                </Typography>
                {children}
            </Stack>
        </Paper>
    )
}

export default ReportCard
