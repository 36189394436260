import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import MuiDrawer from '@mui/material/Drawer'
import Stack from '@mui/material/Stack'
import SvgIcon from '@mui/material/SvgIcon'

import type { FC } from 'react'
import { useMemo } from 'react'
import { usePathname } from 'src/hooks/use-pathname'
import { RouterLink } from 'src/components/router-link'
import { CSSObject, Theme, useTheme } from '@mui/material/styles'
import { Logo } from '../../../components/logo'
import { Scrollbar } from '../../../components/scrollbar'
import { paths } from '../../../paths'
import type { NavColor } from '../../../types/settings'
import type { Section } from '../config'
import { SideNavSection } from './side-nav-section'
import styled from '@emotion/styled'
import { useSettings } from 'src/store/settings'
import { SidebarFooter } from '../mobile-nav/mobile-nav'
import { ChevronsLeftRight, ChevronsRightLeft } from 'lucide-react'

const SIDE_NAV_WIDTH = 280

export const useCssVars = (color: NavColor): Record<string, string> => {
    const theme = useTheme()

    return useMemo((): Record<string, string> => {
        switch (color) {
            case 'blend-in':
                if (theme.palette.mode === 'dark') {
                    return {
                        '--nav-bg': theme.palette.background.default,
                        '--nav-color': theme.palette.neutral[100],
                        '--nav-border-color': theme.palette.neutral[700],
                        '--nav-logo-border': theme.palette.neutral[700],
                        '--nav-section-title-color': theme.palette.neutral[400],
                        '--nav-item-color': theme.palette.neutral[400],
                        '--nav-item-hover-bg': 'rgba(255, 255, 255, 0.04)',
                        '--nav-item-active-bg': 'rgba(255, 255, 255, 0.04)',
                        '--nav-item-active-color': theme.palette.text.primary,
                        '--nav-item-disabled-color': theme.palette.neutral[600],
                        '--nav-item-icon-color': theme.palette.neutral[500],
                        '--nav-item-icon-active-color': theme.palette.primary.main,
                        '--nav-item-icon-disabled-color': theme.palette.neutral[700],
                        '--nav-item-chevron-color': theme.palette.neutral[700],
                        '--nav-scrollbar-color': theme.palette.neutral[400]
                    }
                } else {
                    return {
                        '--nav-bg': theme.palette.background.default,
                        '--nav-color': theme.palette.text.primary,
                        '--nav-border-color': theme.palette.neutral[100],
                        '--nav-logo-border': theme.palette.neutral[100],
                        '--nav-section-title-color': theme.palette.neutral[400],
                        '--nav-item-color': theme.palette.text.secondary,
                        '--nav-item-hover-bg': theme.palette.action.hover,
                        '--nav-item-active-bg': theme.palette.action.selected,
                        '--nav-item-active-color': theme.palette.text.primary,
                        '--nav-item-disabled-color': theme.palette.neutral[400],
                        '--nav-item-icon-color': theme.palette.neutral[400],
                        '--nav-item-icon-active-color': theme.palette.primary.main,
                        '--nav-item-icon-disabled-color': theme.palette.neutral[400],
                        '--nav-item-chevron-color': theme.palette.neutral[400],
                        '--nav-scrollbar-color': theme.palette.neutral[900]
                    }
                }

            case 'discrete':
                if (theme.palette.mode === 'dark') {
                    return {
                        '--nav-bg': theme.palette.neutral[900],
                        '--nav-color': theme.palette.neutral[100],
                        '--nav-border-color': theme.palette.neutral[700],
                        '--nav-logo-border': theme.palette.neutral[700],
                        '--nav-section-title-color': theme.palette.neutral[400],
                        '--nav-item-color': theme.palette.neutral[400],
                        '--nav-item-hover-bg': 'rgba(255, 255, 255, 0.04)',
                        '--nav-item-active-bg': 'rgba(255, 255, 255, 0.04)',
                        '--nav-item-active-color': theme.palette.text.primary,
                        '--nav-item-disabled-color': theme.palette.neutral[600],
                        '--nav-item-icon-color': theme.palette.neutral[500],
                        '--nav-item-icon-active-color': theme.palette.primary.main,
                        '--nav-item-icon-disabled-color': theme.palette.neutral[700],
                        '--nav-item-chevron-color': theme.palette.neutral[700],
                        '--nav-scrollbar-color': theme.palette.neutral[400]
                    }
                } else {
                    return {
                        '--nav-bg': theme.palette.neutral[50],
                        '--nav-color': theme.palette.text.primary,
                        '--nav-border-color': theme.palette.divider,
                        '--nav-logo-border': theme.palette.neutral[200],
                        '--nav-section-title-color': theme.palette.neutral[500],
                        '--nav-item-color': theme.palette.neutral[500],
                        '--nav-item-hover-bg': theme.palette.action.hover,
                        '--nav-item-active-bg': theme.palette.action.selected,
                        '--nav-item-active-color': theme.palette.text.primary,
                        '--nav-item-disabled-color': theme.palette.neutral[400],
                        '--nav-item-icon-color': theme.palette.neutral[400],
                        '--nav-item-icon-active-color': theme.palette.primary.main,
                        '--nav-item-icon-disabled-color': theme.palette.neutral[400],
                        '--nav-item-chevron-color': theme.palette.neutral[400],
                        '--nav-scrollbar-color': theme.palette.neutral[900]
                    }
                }

            case 'evident':
                if (theme.palette.mode === 'dark') {
                    return {
                        '--nav-bg': '#191b22',
                        '--nav-color': theme.palette.neutral[50],
                        '--nav-border-color': theme.palette.neutral[700],
                        '--nav-logo-border': theme.palette.neutral[700],
                        '--nav-section-title-color': theme.palette.neutral[400],
                        '--nav-item-color': theme.palette.neutral[50],
                        '--nav-item-hover-bg': 'rgba(255, 253, 0, 0.1)',
                        '--nav-item-active-bg': 'rgba(255, 253, 0, 0.5)',
                        '--nav-item-active-color': theme.palette.text.primary,
                        '--nav-item-active-border-color': 'rgba(255, 255, 255, 0.18)',
                        '--nav-item-disabled-color': theme.palette.neutral[600],
                        '--nav-item-icon-color': theme.palette.neutral[50],
                        '--nav-item-icon-active-color': theme.palette.neutral[50],
                        '--nav-item-icon-disabled-color': theme.palette.neutral[700],
                        '--nav-item-chevron-color': theme.palette.neutral[700],
                        '--nav-scrollbar-color': theme.palette.neutral[400]
                    }
                } else {
                    return {
                        '--nav-bg': theme.palette.neutral[50],
                        '--nav-color': theme.palette.common.white,
                        '--nav-border-color': theme.palette.neutral[100],
                        '--nav-logo-border': theme.palette.neutral[700],
                        '--nav-section-title-color': theme.palette.neutral[400],
                        '--nav-item-color': theme.palette.neutral[900],
                        '--nav-item-hover-bg': 'rgba(255, 253, 255, 1)',
                        '--nav-item-active-bg': 'rgba(255, 253, 0, 0.5)',
                        '--nav-item-active-color': theme.palette.neutral[900],
                        '--nav-item-active-border-color': theme.palette.neutral[100],
                        '--nav-item-disabled-color': theme.palette.neutral[500],
                        '--nav-item-icon-color': theme.palette.neutral[900],
                        '--nav-item-icon-active-color': theme.palette.neutral[900],
                        '--nav-item-icon-disabled-color': theme.palette.neutral[500],
                        '--nav-item-chevron-color': theme.palette.neutral[800],
                        '--nav-scrollbar-color': theme.palette.neutral[400]
                    }
                }

            default:
                return {}
        }
    }, [theme, color])
}

interface SideNavProps {
    color?: NavColor
    sections?: Section[]
}

const drawerWidth = 300

const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
    }),
    overflowX: 'hidden'
})

const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(10)} + 1px)`
    }
})

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }: any) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme)
    }),
    ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme)
    })
}))

const StyledLogo = ({ small }: { small?: boolean }) => (
    <Box display="flex" alignItems="center">
        <Logo small={small} />
    </Box>
)

export const SideNav: FC<SideNavProps> = (props) => {
    const { color = 'evident', sections = [] } = props
    const pathname = usePathname()
    const cssVars = useCssVars(color)
    const { sidebar, toggleSidebar } = useSettings()

    return (
        <Drawer
            anchor="left"
            open={sidebar}
            PaperProps={{
                sx: {
                    ...cssVars,
                    backgroundColor: 'var(--nav-bg)',
                    borderRightColor: 'var(--nav-border-color)',
                    borderRightStyle: 'solid',
                    borderRightWidth: 1,
                    color: 'var(--nav-color)',
                    width: SIDE_NAV_WIDTH
                }
            }}
            variant="permanent"
        >
            <Scrollbar
                sx={{
                    height: '100%',
                    '& .simplebar-content': {
                        height: '100%'
                    },
                    '& .simplebar-scrollbar:before': {
                        background: 'var(--nav-scrollbar-color)'
                    }
                }}
            >
                <Stack sx={{ height: '100%' }}>
                    <Stack
                        alignItems="center"
                        justifyContent={sidebar ? 'space-between' : 'center'}
                        direction="row"
                        spacing={2}
                        my={2}
                        mx={sidebar ? 2 : 0}
                    >
                        {sidebar && (
                            <Box display="flex" alignItems="center" px={2} component={RouterLink} href={paths.index}>
                                <StyledLogo />
                                {/* <Typography>{t('view.project_name')}</Typography> */}
                            </Box>
                        )}
                        <IconButton onClick={toggleSidebar}>
                            <SvgIcon fontSize="small" sx={{ color: 'text.primary' }}>
                                {sidebar ? <ChevronsRightLeft /> : <ChevronsLeftRight />}
                            </SvgIcon>
                        </IconButton>
                    </Stack>
                    {!sidebar && (
                        <Stack alignItems="center" mb={2}>
                            <Box display="flex" alignItems="center" px={2} component={RouterLink} href={paths.index}>
                                <StyledLogo small />
                            </Box>
                        </Stack>
                    )}
                    <Stack
                        component="nav"
                        spacing={2}
                        sx={{
                            flexGrow: 1,
                            px: sidebar ? 2 : 1
                        }}
                    >
                        {sections.map((section, index) => (
                            <SideNavSection
                                items={section.items}
                                key={index}
                                pathname={pathname}
                                subheader={section.subheader}
                            />
                        ))}
                    </Stack>
                    {sidebar && <SidebarFooter />}
                </Stack>
            </Scrollbar>
        </Drawer>
    )
}
