/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum StationCircleDiffStatus {
    UNDEFINED = 'Undefined',
    NO_DIFF = 'NoDiff',
    LOW_DIFF = 'LowDiff',
    HIGH_DIFF = 'HighDiff',
}
