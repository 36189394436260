/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateBranchRequest } from '../models/CreateBranchRequest';
import type { CreatePbsRequest } from '../models/CreatePbsRequest';
import type { CreatePollingCenterRequest } from '../models/CreatePollingCenterRequest';
import type { CreateSubBranchRequest } from '../models/CreateSubBranchRequest';
import type { DetailedNodeResponse } from '../models/DetailedNodeResponse';
import type { ElectionType } from '../models/ElectionType';
import type { IdResponse } from '../models/IdResponse';
import type { MinimalNodeResponse } from '../models/MinimalNodeResponse';
import type { NodeType } from '../models/NodeType';
import type { PagedNodeResponseListResponse } from '../models/PagedNodeResponseListResponse';
import type { UpdateBranchRequest } from '../models/UpdateBranchRequest';
import type { UpdatePbsRequest } from '../models/UpdatePbsRequest';
import type { UpdatePollingCenterMiddayReportRequest } from '../models/UpdatePollingCenterMiddayReportRequest';
import type { UpdatePollingCenterRequest } from '../models/UpdatePollingCenterRequest';
import type { UpdateSubBranchRequest } from '../models/UpdateSubBranchRequest';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class NodesService {
    /**
     * @returns PagedNodeResponseListResponse OK
     * @throws ApiError
     */
    public static listNodes({
        page,
        rowsPerPage,
        nodeType,
        name,
        code,
        circleId,
        parentId,
        provinceId,
        districtId,
        subDistrictId,
        sortAscending,
        electionType,
    }: {
        page: number,
        rowsPerPage: number,
        nodeType: NodeType,
        name?: string,
        code?: number,
        circleId?: string,
        parentId?: string,
        provinceId?: string,
        districtId?: string,
        subDistrictId?: string,
        sortAscending?: boolean,
        electionType?: ElectionType,
    }): CancelablePromise<PagedNodeResponseListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/nodes',
            query: {
                'Page': page,
                'Name': name,
                'Code': code,
                'CircleId': circleId,
                'ParentId': parentId,
                'ProvinceId': provinceId,
                'DistrictId': districtId,
                'SubDistrictId': subDistrictId,
                'SortAscending': sortAscending,
                'RowsPerPage': rowsPerPage,
                'NodeType': nodeType,
                'ElectionType': electionType,
            },
        });
    }
    /**
     * @returns MinimalNodeResponse OK
     * @throws ApiError
     */
    public static listAllNodes({
        circleId,
        ancestorId,
        provinceId,
        districtId,
        subDistrictId,
        nodeType,
        electionType,
        isActive,
    }: {
        circleId?: string,
        ancestorId?: string,
        provinceId?: string,
        districtId?: string,
        subDistrictId?: string,
        nodeType?: NodeType,
        electionType?: ElectionType,
        isActive?: boolean,
    }): CancelablePromise<Array<MinimalNodeResponse>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/nodes/all',
            query: {
                'CircleId': circleId,
                'AncestorId': ancestorId,
                'ProvinceId': provinceId,
                'DistrictId': districtId,
                'SubDistrictId': subDistrictId,
                'NodeType': nodeType,
                'ElectionType': electionType,
                'IsActive': isActive,
            },
        });
    }
    /**
     * @returns DetailedNodeResponse OK
     * @throws ApiError
     */
    public static getNodeById({
        id,
    }: {
        id: string,
    }): CancelablePromise<DetailedNodeResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/nodes/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * @returns IdResponse Created
     * @throws ApiError
     */
    public static createPbs({
        requestBody,
    }: {
        requestBody: CreatePbsRequest,
    }): CancelablePromise<IdResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/nodes/pbs',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @returns void
     * @throws ApiError
     */
    public static updatePbs({
        id,
        requestBody,
    }: {
        id: string,
        requestBody: UpdatePbsRequest,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/nodes/pbs/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @returns IdResponse Created
     * @throws ApiError
     */
    public static createBranch({
        requestBody,
    }: {
        requestBody: CreateBranchRequest,
    }): CancelablePromise<IdResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/nodes/branch',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @returns void
     * @throws ApiError
     */
    public static updateBranch({
        id,
        requestBody,
    }: {
        id: string,
        requestBody: UpdateBranchRequest,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/nodes/branch/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @returns IdResponse Created
     * @throws ApiError
     */
    public static createSubBranch({
        requestBody,
    }: {
        requestBody: CreateSubBranchRequest,
    }): CancelablePromise<IdResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/nodes/sub-branch',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @returns void
     * @throws ApiError
     */
    public static updateSubBranch({
        id,
        requestBody,
    }: {
        id: string,
        requestBody: UpdateSubBranchRequest,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/nodes/sub-branch/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @returns IdResponse Created
     * @throws ApiError
     */
    public static createPollingCenter({
        requestBody,
    }: {
        requestBody: CreatePollingCenterRequest,
    }): CancelablePromise<IdResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/nodes/polling-center',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @returns void
     * @throws ApiError
     */
    public static updatePollingCenter({
        id,
        requestBody,
    }: {
        id: string,
        requestBody: UpdatePollingCenterRequest,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/nodes/polling-center/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @returns void
     * @throws ApiError
     */
    public static updatePollingCenterMiddayReport({
        id,
        requestBody,
    }: {
        id: string,
        requestBody: UpdatePollingCenterMiddayReportRequest,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/nodes/polling-center/{id}/midday-report',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
