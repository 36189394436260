import { useQueryClient } from '@tanstack/react-query'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Create from 'src/components/dashboard/candidates/create'
import Results from 'src/components/dashboard/candidates/results'
import MiniDrawer from 'src/components/drawer/mini-drawer'
import LayoutPage from 'src/components/page'
import { PermissionsGuard } from 'src/guards/permissions-guard'
import { ACTIONS, RESOURCES, useCheckPermissions } from 'src/hooks/use-check-permissions'

const Candidates = () => {
    const { t } = useTranslation()
    const queryClient = useQueryClient()
    const [createDialog, setCreateDialog] = useState(false)
    const { can } = useCheckPermissions()

    const closeDrawer = () => {
        setCreateDialog(false)
    }
    return (
        <PermissionsGuard resource="candidate" actions={[ACTIONS.create, ACTIONS.update]}>
            <LayoutPage
                title="candidate"
                breadcrumbs={[
                    {
                        title: t('view.candidate.header')
                    }
                ]}
                actions={[
                    {
                        hidden: !can({ resource: RESOURCES.candidate, actions: [ACTIONS.create] }),
                        label: t('view.candidate.new.headline'),
                        onClick: () => setCreateDialog(true),
                        color: 'primary'
                    }
                ]}
            >
                <Results />

                <MiniDrawer open={createDialog} close={closeDrawer} header={t('view.candidate.new.headline')}>
                    {createDialog && (
                        <Create
                            handleSuccess={() => {
                                closeDrawer()
                                queryClient.invalidateQueries({
                                    queryKey: ['candidates']
                                })
                            }}
                            handleClose={() => {
                                closeDrawer()
                            }}
                        />
                    )}
                </MiniDrawer>
            </LayoutPage>
        </PermissionsGuard>
    )
}

export default Candidates
