import React from 'react'
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import Divider from '@mui/material/Divider'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'
import CardContent from '@mui/material/CardContent'
import Avatar from '@mui/material/Avatar'
import Grid from '@mui/material/Grid'
import Skeleton from '@mui/material/Skeleton'
import { Card as CardType, Field } from 'src/types/card'

const CustomTableCell = styled(TableCell)(({ theme }) => ({
    fontWeight: theme.typography.fontWeightMedium
}))

const CardAvatar = styled(Avatar)({
    height: 100,
    width: 100
})

const GetTableCell = ({ field, index, array }: { field: Field; index: number; array: Field[] }) => {
    return (
        <>
            {field.label && (
                <CustomTableCell
                    style={{
                        color: field?.color || '#757575',
                        ...(index === array.length - 1 && { borderBottom: 'none' })
                    }}
                >
                    {field.label}
                </CustomTableCell>
            )}
            <TableCell
                style={{
                    ...(index === array.length - 1 && { borderBottom: 'none' })
                }}
            >
                {typeof field.value === 'string' || typeof field.value === 'number' ? (
                    <Typography variant="body2" style={field?.getStyles}>
                        {field.value}
                    </Typography>
                ) : (
                    field.value
                )}
                {field?.secondary_label}
            </TableCell>
        </>
    )
}

const DetailsCard = ({
    fields,
    children,
    multipleRows = true,
    variant = 'elevation',
    tableRow = false,
    ...rest
}: CardType) => {
    const Template = rest.Template
    const renderFields = () => {
        const visibleFields = fields?.filter(
            (field) => !field.hidden && (field.label || field.skipLabel || field.Template)
        )

        if (visibleFields?.length === 0) return null
        return tableRow ? (
            <Table size={rest.size || 'medium'} sx={{ mx: 1 }}>
                <TableBody>
                    {multipleRows ? (
                        visibleFields?.map((field, index, array) => (
                            <TableRow key={index}>
                                <GetTableCell field={field} index={index} array={array} />
                            </TableRow>
                        ))
                    ) : (
                        <TableRow>
                            {visibleFields?.map((field, index, array) => (
                                <GetTableCell key={index} field={field} index={index} array={array} />
                            ))}
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        ) : (
            <Box px={2} py={2}>
                <Grid container spacing={3}>
                    {visibleFields?.map((field, index) => {
                        const FieldTemplate = field.Template
                        return (
                            <Grid item xs={12} key={index}>
                                <Typography variant="body2" fontWeight="fontWeightLight" color="textSecondary">
                                    {rest.loading ? <Skeleton width={100} /> : field.label}
                                </Typography>
                                <Typography variant="body2" fontWeight="fontWeightLight">
                                    {rest.loading ? <Skeleton width={100} /> : field.secondary_label}
                                </Typography>

                                {FieldTemplate ? (
                                    <FieldTemplate {...field.templateProps} />
                                ) : (
                                    <Typography variant="h6" style={{ overflowWrap: 'break-word' }}>
                                        {rest.loading ? <Skeleton width={200} /> : field.value || '-'}
                                    </Typography>
                                )}
                            </Grid>
                        )
                    })}
                </Grid>
            </Box>
        )
    }

    return (
        <Card variant={tableRow ? variant : 'outlined'} elevation={rest.disableElevation || !tableRow ? 0 : 1}>
            {rest.title && (
                <>
                    <CardHeader
                        title={rest.title}
                        action={rest.action}
                        titleTypographyProps={{
                            variant: 'subtitle1'
                        }}
                        sx={{
                            p: 2,
                            backgroundColor: 'background.default'
                        }}
                    />
                    <Divider />
                </>
            )}
            {rest.avatar && (
                <CardContent>
                    <Box display="flex" alignItems="center" flexDirection="column" textAlign="center">
                        <CardAvatar src={rest.avatar} />
                    </Box>
                </CardContent>
            )}
            {Template ? <Template {...rest.templateProps} /> : renderFields()}
            {children}
        </Card>
    )
}

export default DetailsCard
