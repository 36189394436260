import { Avatar, SxProps, Theme } from '@mui/material'

const UserAvatar = ({
    firstName = '',
    lastName = '',
    src,
    sx,
}: {
    firstName: string | undefined | null
    lastName?: string
    src?: string
    sx?: SxProps<Theme>
}) => {
    return (
        <Avatar sx={sx} src={src} variant="rounded">
            {firstName?.charAt(0).toUpperCase() + lastName?.charAt(0).toUpperCase()}
        </Avatar>
    )
}

export default UserAvatar
