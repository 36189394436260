import { alpha } from '@mui/system/colorManipulator'
import type { PaletteOptions } from '@mui/material/styles/createPalette'

import type { ColorPreset, Contrast } from '..'
import { error, info, neutral, success, warning, blue } from '../colors'
import { getPrimary } from '../utils'

interface Config {
    colorPreset?: ColorPreset
    contrast?: Contrast
}

export const createPalette = (config: Config): PaletteOptions => {
    const { colorPreset, contrast } = config

    return {
        action: {
            active: neutral[500],
            disabled: alpha(neutral[100], 0.38),
            disabledBackground: alpha(neutral[100], 0.12),
            focus: alpha(neutral[100], 0.16),
            hover: alpha(neutral[100], 0.04),
            selected: alpha(neutral[100], 0.12)
        },
        background: {
            default: contrast === 'high' ? '#0B0F19' : '#0E1320',
            paper: '#191b22'
        },
        divider: '#393d45',
        error,
        info,
        mode: 'dark',
        neutral,
        primary: getPrimary(colorPreset),
        success,
        secondary: blue,
        text: {
            primary: '#fff',
            secondary: '#A0AEC0',
            disabled: 'rgba(255, 255, 255, 0.48)'
        },
        warning
    }
}
