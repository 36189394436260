import LayoutPage from 'src/components/page'
import { Grid, Paper } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { allCirclesQuery } from 'src/queries/circles'
import { useTranslation } from 'react-i18next'
import { AdvancedFilters } from '@ditkrg/react-core'
import { Stack } from '@mui/system'
import { useReportsFilters } from 'src/store/reports'
import { useSettings } from 'src/store/settings'
import { useNodeFilters } from 'src/hooks/use-node-filters'
import { PermissionsGuard } from 'src/guards/permissions-guard'
import { ACTIONS } from 'src/hooks/use-check-permissions'
import PartiesAndCandidatesVoteDistribution from 'src/components/dashboard/reports/parties-and-candidates-vote-distribution'
import VoterValidityDistribution from 'src/components/dashboard/reports/voter-validity-distribution'
import VoterParticipationDistribution from 'src/components/dashboard/reports/voter-participation-distribution'
import StationSubmissionReport from 'src/components/dashboard/reports/station-submission-report'
import { useReports } from 'src/hooks/use-reports'
import { useState } from 'react'
import { NodeType } from 'src/services/api/emos'
import ReportAlert from 'src/components/dashboard/reports/alert'

const Reports = () => {
    const { t } = useTranslation()
    const state = useReportsFilters()
    const { filters } = state
    const { language } = useSettings()
    const [key, setKey] = useState(0)

    const { data: circles = [] } = useQuery({
        ...allCirclesQuery()
    })

    const enabledQueries =
        !!filters?.circle &&
        !!filters?.electionType &&
        (!!filters?.[NodeType.POLLING_CENTER] ||
            !!filters?.[NodeType.SUB_BRANCH] ||
            !!filters?.[NodeType.BRANCH] ||
            !!filters?.[NodeType.PBS])

    const { filterInputs } = useNodeFilters({ state })

    const { formTypeOptions, electionTypeFilter, circleFilter } = useReports({
        state,
        setKey,
        circles
    })

    return (
        <PermissionsGuard resource="d_reports" actions={[ACTIONS.read]}>
            <LayoutPage title="Reports" breadcrumbs={[{ title: t('view.reports.header') }]}>
                <Stack gap={2}>
                    <Paper variant="outlined">
                        <AdvancedFilters
                            key={key}
                            state={state as any}
                            filters={[
                                circleFilter,
                                ...filterInputs,
                                electionTypeFilter,
                                {
                                    id: 'formType',
                                    type: 'autocomplete',
                                    header: t('model.votingResult.formType.label'),
                                    props: {
                                        options: formTypeOptions,
                                        optionLabel: 'label',
                                        optionValue: 'value'
                                    }
                                }
                            ]}
                            showCollapseButton={false}
                            locale={language as any}
                            clearButtonProps={{
                                hidden: true
                            }}
                            // actions={[
                            //     ({ filtersState, sync }) => (
                            //         <Button
                            //             color="error"
                            //             onClick={() => {
                            //                 // resetFilters({ filtersState, sync })
                            //             }}
                            //             startIcon={<DeleteIcon />}
                            //         >
                            //             {t('view.common.clear')}
                            //         </Button>
                            //     )
                            // ]}
                        />
                    </Paper>

                    {enabledQueries && <ReportAlert />}

                    <Grid container spacing={2} alignItems="flex-start">
                        <Grid item xs={12} md={8} container spacing={2}>
                            <PartiesAndCandidatesVoteDistribution enabledQueries={enabledQueries} />
                        </Grid>

                        <Grid item xs={12} md={4} container spacing={2}>
                            <VoterValidityDistribution enabledQueries={enabledQueries} />

                            <VoterParticipationDistribution enabledQueries={enabledQueries} />

                            <StationSubmissionReport enabledQueries={enabledQueries} />
                        </Grid>
                    </Grid>
                </Stack>
            </LayoutPage>
        </PermissionsGuard>
    )
}

export default Reports
