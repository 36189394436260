import React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { useTranslation } from 'react-i18next'
import type { UseFormHandleSubmit } from 'react-hook-form'
import { Box, Breakpoint } from '@mui/system'
import LoadingButton from '../button/loading-button'

type Props = {
    formDialogStatus: boolean
    title: string
    contentText?: string
    handleClose: () => void
    handleSubmit: UseFormHandleSubmit<any>
    handleFormSubmit: (data: any) => Promise<void> | void
    loading: boolean
    maxWidth?: Breakpoint | false
    children: React.ReactNode
}

const FormDialog = ({
    formDialogStatus,
    title,
    contentText,
    handleClose,
    handleSubmit,
    handleFormSubmit,
    loading,
    maxWidth,
    children,
    ...rest
}: Props) => {
    const [t] = useTranslation()

    return (
        <Dialog
            open={formDialogStatus}
            onClose={(event, reason) => {
                if (reason !== 'backdropClick') {
                    handleClose()
                }
            }}
            maxWidth={maxWidth || 'md'}
            fullWidth={true}
            PaperProps={{ elevation: 0 }}
            disableEscapeKeyDown
            {...rest}
        >
            <DialogTitle id="form-dialog-title">{title}</DialogTitle>

            <DialogContent>
                <form onSubmit={handleSubmit(handleFormSubmit)}>
                    {contentText && <DialogContentText>{contentText}</DialogContentText>}
                    <Box sx={{ mt: 2 }}>{children}</Box>
                </form>
            </DialogContent>
            <DialogActions>
                <LoadingButton loading={loading} onClick={handleClose}>
                    {t('view.common.form.cancel')}
                </LoadingButton>
                <LoadingButton
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit(handleFormSubmit)}
                    loading={loading}
                >
                    {t('view.common.form.submit')}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    )
}

export default FormDialog
