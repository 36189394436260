import { CirclesService } from 'src/services/api/emos'

export const allCirclesQuery = () => ({
    queryKey: ['allCircles'],
    queryFn: async () => {
        const res = await CirclesService.listAllCircles()
        return res
    },
    gcTime: Infinity,
    staleTime: Infinity
})
