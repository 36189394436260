import React from 'react'

import Chip from '@mui/material/Chip'
import { styled } from '@mui/material/styles'

const Wrapper = styled('div')(() => ({
    justifyContent: 'start',
    display: 'flex',
    flexWrap: 'wrap'
}))

interface ChipListProps {
    items: any[]
    property?: string
    customLabel?: (item: any) => string
    handleClick?: (id: string) => void
    color?: 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning'
    variant?: 'filled' | 'outlined'
    size?: 'small' | 'medium'
}

const ChipList = ({
    items,
    property = 'name',
    customLabel,
    handleClick,
    color = 'primary',
    variant = 'outlined',
    size = 'small'
}: ChipListProps) => {
    return (
        <Wrapper sx={{ gap: 1 }}>
            {items?.map((item: any, index: any) => (
                <Chip
                    size={size}
                    key={item[property] || index}
                    label={customLabel ? customLabel(item) : item[property]}
                    variant={variant}
                    onClick={() => handleClick?.(item.id)}
                    color={color}
                />
            ))}
        </Wrapper>
    )
}

export default ChipList
