import React from 'react'
import { useTranslation } from 'react-i18next'
import { useToast } from 'src/hooks/useToast'
import { AlertDialog } from 'src/components/dialog/alert-dialog'
import { DetailedIncidentResponse, IncidentCommand, IncidentsService } from 'src/services/api/emos'
import { useMutation, useQueryClient } from '@tanstack/react-query'

function UpdateStatusDialog({
    incident,
    dialogStatus,
    setDialog,
    command
}: Readonly<{
    incident: DetailedIncidentResponse
    dialogStatus: boolean
    setDialog: (status: boolean) => void
    command: IncidentCommand
}>) {
    const [t] = useTranslation()
    const { errorsToast, successToast } = useToast()

    const queryClient = useQueryClient()

    const { mutate, isPending } = useMutation({
        mutationKey: ['updateIncidentStatus'],
        mutationFn: async (command: IncidentCommand) => {
            return await IncidentsService.updateIncidentState({
                id: incident.id,
                requestBody: {
                    command
                }
            })
        },
        onSuccess: () => {
            successToast(t(`view.common.actions.status.success`, { name: t('view.incident.name.headline') }))
            setDialog(false)
            queryClient.invalidateQueries({ queryKey: ['incident'] })
        },
        onError: (error) => {
            errorsToast(error)
        }
    })

    return (
        <AlertDialog
            open={dialogStatus}
            title={t(`view.incident.command.${command.toLocaleLowerCase()}.action.title` as any)}
            description={t(`view.incident.command.${command.toLocaleLowerCase()}.action.confirmation` as any, {
                name: t(`view.incident.name.headline` as any)
            })}
            handleClose={() => setDialog(false)}
            handleSubmit={() => mutate(command)}
            loading={isPending}
            submitText={t(`view.incident.command.${command.toLocaleLowerCase()}.action.title` as any)}
        />
    )
}

export default UpdateStatusDialog
