import { Grid } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import HighPieChart from 'src/components/dashboard/reports/highcharts/pie-chart'
import { DashboardsService, ElectionType, NodeType } from 'src/services/api/emos'
import { useReportsFilters } from 'src/store/reports'
import ReportCard from './report-card'
import { REFETCH_INTERVAL } from './constants'

const StationSubmissionReport = ({ enabledQueries }: { enabledQueries: boolean }) => {
    const { t } = useTranslation()
    const state = useReportsFilters()
    const { filters } = state

    const { data: stationSubmissionReport = [] } = useQuery({
        queryKey: ['stationSubmissionReport', filters],
        queryFn: async () => {
            const res = await DashboardsService.stationSubmissionReport({
                circleId: filters?.circle as string,
                ancestorId:
                    filters?.[NodeType.POLLING_CENTER] ||
                    filters?.[NodeType.SUB_BRANCH] ||
                    filters?.[NodeType.BRANCH] ||
                    filters?.[NodeType.PBS],
                electionType: filters?.electionType as ElectionType,
                formType: filters?.formType
            })
            return [
                {
                    name: t('view.reports.operation.submitted'),
                    y: res.submitted,
                    color: '#40C293'
                },
                {
                    name: t('view.reports.operation.notSubmitted'),
                    y: res.notSubmitted,
                    color: '#F56C4C'
                }
            ]
        },
        enabled: enabledQueries,
        refetchInterval: REFETCH_INTERVAL
    })
    return (
        <Grid item xs={12}>
            <ReportCard title={t('view.reports.operation.stationSubmissionReport')}>
                <HighPieChart dataset={stationSubmissionReport} />
            </ReportCard>
        </Grid>
    )
}

export default StationSubmissionReport
