import { useTranslation } from 'react-i18next'
import Profile from 'src/components/dashboard/system-config/profile'
import LayoutPage from 'src/components/page'

const SystemConfiguration = () => {
    const { t } = useTranslation()

    return (
        <LayoutPage
            title="System Configuration"
            breadcrumbs={[
                {
                    title: t('view.systemConfiguration.header')
                }
            ]}
        >
            <Profile />
        </LayoutPage>
    )
}

export default SystemConfiguration
