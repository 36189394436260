import Grid from '@mui/material/Grid'
import { useForm, SubmitHandler } from 'react-hook-form'
import { useState } from 'react'
import { FormFields } from 'mui-rhf-library'
import { createSchema } from './schema'
import { CandidatesService } from 'src/services/api/emos'
import { yupResolver } from '@hookform/resolvers/yup'
import FormActions from 'src/components/drawer/form-actions'
import { useCandidates } from 'src/hooks/use-candidates'
import { useToast } from 'src/hooks/useToast'
import { filterObject } from 'src/utils/filter-object'
import { createCandidateAttributesToKeep } from './constants'

function Create({
    handleSuccess,
    handleClose
}: Readonly<{
    handleSuccess: () => void
    handleClose: () => void
}>) {
    const [btnLoading, setBtnLoading] = useState(false)
    const { errorsToast } = useToast()

    const formConfigs = useForm({
        resolver: yupResolver(createSchema())
    })
    const { handleSubmit, control } = formConfigs
    const handleFormSubmit: SubmitHandler<any> = async (formData) => {
        setBtnLoading(true)

        const filteredData = filterObject({
            obj: formData,
            keys: createCandidateAttributesToKeep
        }) as any

        try {
            await CandidatesService.createCandidate({
                requestBody: {
                    ...filteredData,
                    photo: filteredData.photo[0]?.secret || null
                }
            })
            handleSuccess()
        } catch (err) {
            errorsToast(err)
        } finally {
            setBtnLoading(false)
        }
    }

    const { fields } = useCandidates(formConfigs)

    return (
        <form onSubmit={handleSubmit(handleFormSubmit)}>
            <Grid container spacing={2}>
                <FormFields fields={fields} control={control} />
            </Grid>
            <FormActions loading={btnLoading} handleClose={handleClose} handleSubmit={handleSubmit(handleFormSubmit)} />
        </form>
    )
}

export default Create
