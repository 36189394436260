import type { FC, ReactNode } from 'react'
import { useCallback, useEffect, useState } from 'react'
import { usePathname } from 'src/hooks/use-pathname'
import type { Theme } from '@mui/material'
import useMediaQuery from '@mui/material/useMediaQuery'
import { styled } from '@mui/material/styles'
import type { Section } from '../config'
import type { NavColor } from '../../../types/settings'
import { MobileNav } from '../mobile-nav'
import { SideNav } from './side-nav'
import { TopNav } from './top-nav'
import { useSettings } from 'src/store/settings'

const SIDE_NAV_WIDTH_OPEN = '300px'
const SIDE_NAV_WIDTH_CLOSED = '72px'

const useMobileNav = () => {
    const pathname = usePathname()
    const [isOpen, setIsOpen] = useState<boolean>(false)

    const handlePathnameChange = useCallback((): void => {
        if (isOpen) {
            setIsOpen(false)
        }
    }, [isOpen])

    useEffect(
        () => {
            handlePathnameChange()
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [pathname]
    )

    const handleOpen = useCallback((): void => {
        setIsOpen(true)
    }, [])

    const handleClose = useCallback((): void => {
        setIsOpen(false)
    }, [])

    return {
        isOpen,
        handleOpen,
        handleClose,
    }
}

const VerticalLayoutRoot = styled('div')(({ theme }) => ({
    display: 'flex',
    flex: '1 1 auto',
    maxWidth: '100%',
    // [theme.breakpoints.up('lg')]: {
    //     paddingLeft: SIDE_NAV_WIDTH_CLOSED
    // }
}))

const VerticalLayoutContainer = styled('div')({
    display: 'flex',
    flex: '1 1 auto',
    flexDirection: 'column',
    width: '100%',
})

interface VerticalLayoutProps {
    children?: ReactNode
    navColor?: NavColor
    sections?: Section[]
}

export const VerticalLayout: FC<VerticalLayoutProps> = (props) => {
    const { children, sections, navColor } = props
    const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'))
    const mobileNav = useMobileNav()
    const { sidebar: isDrawerOpen } = useSettings()

    const SIDE_NAV_WIDTH = isDrawerOpen ? SIDE_NAV_WIDTH_OPEN : SIDE_NAV_WIDTH_CLOSED

    return (
        <>
            <TopNav onMobileNavOpen={mobileNav.handleOpen} />
            {lgUp && <SideNav color={navColor} sections={sections} />}
            {!lgUp && (
                <MobileNav
                    color={navColor}
                    onClose={mobileNav.handleClose}
                    open={mobileNav.isOpen}
                    sections={sections}
                />
            )}
            <VerticalLayoutRoot
                sx={{
                    paddingLeft: lgUp ? SIDE_NAV_WIDTH : 0,
                }}
            >
                <VerticalLayoutContainer>{children}</VerticalLayoutContainer>
            </VerticalLayoutRoot>
        </>
    )
}
