import type { FC } from 'react'
import Avatar from '@mui/material/Avatar'
import Box from '@mui/material/Box'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Typography from '@mui/material/Typography'
import { alpha } from '@mui/material/styles'
import WarningIcon from '@mui/icons-material/WarningOutlined'
import LoadingButton from 'src/components/button/loading-button'
import { useTranslation } from 'react-i18next'

interface Props {
    open: boolean
    title: string
    description?: string
    handleClose?: () => void
    maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl'
    children?: any
    loading: boolean
    closeVariant?: string
    closeText?: string
    handleSubmit?: () => void
    submitText?: string
}

export const AlertDialog: FC<Props> = ({
    open,
    title,
    description,
    handleClose,
    maxWidth,
    children,
    loading,
    closeText,
    handleSubmit,
    submitText
}) => {
    const [t] = useTranslation()

    return (
        <Dialog
            open={open}
            onClose={(event, reason) => {
                if (reason !== 'backdropClick') {
                    handleClose?.()
                }
            }}
            maxWidth={maxWidth || 'xl'}
            PaperProps={{ elevation: 0 }}
        >
            <DialogTitle>
                <Box
                    sx={{
                        display: 'flex',
                        pt: 3
                    }}
                >
                    <Avatar
                        sx={{
                            backgroundColor: (theme) => alpha(theme.palette.error.main, 0.08),
                            color: 'error.main',
                            mr: 2
                        }}
                    >
                        <WarningIcon fontSize="small" />
                    </Avatar>
                    <Typography variant="h5">{title}</Typography>
                </Box>
            </DialogTitle>

            <DialogContent>
                {description && (
                    <Typography color="textSecondary" sx={{ mt: 1 }} variant="body2">
                        {description}
                    </Typography>
                )}
                {children}
            </DialogContent>

            <Box mt={2}>
                <DialogActions>
                    {handleClose && (
                        <LoadingButton loading={loading} onClick={handleClose} variant="outlined">
                            {closeText || t('view.common.dialog.close')}
                        </LoadingButton>
                    )}
                    {handleSubmit && (
                        <LoadingButton variant="contained" color="success" onClick={handleSubmit} loading={loading}>
                            {submitText || t('view.common.dialog.submit')}
                        </LoadingButton>
                    )}
                </DialogActions>
            </Box>
        </Dialog>
    )
}
