import type { FC } from 'react'

import Button from '@mui/material/Button'
import Drawer from '@mui/material/Drawer'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/material/styles'

import { Logo } from 'src/components/logo'
import { Scrollbar } from 'src/components/scrollbar'
import { usePathname } from 'src/hooks/use-pathname'
import { paths } from 'src/paths'
import type { NavColor } from 'src/types/settings'

import type { Section } from '../config'
import { MobileNavSection } from './mobile-nav-section'
import { useTranslation } from 'react-i18next'

import { version } from '../../../../package.json'
import { useCssVars } from '../vertical-layout/side-nav'
import { useRouter } from 'src/hooks/use-router'
import { Box } from '@mui/system'

const MOBILE_NAV_WIDTH = 280

interface MobileNavProps {
    color?: NavColor
    onClose?: () => void
    open?: boolean
    sections?: Section[]
}

export const MobileNav: FC<MobileNavProps> = (props) => {
    const { color = 'evident', open, onClose, sections = [] } = props
    const pathname = usePathname()
    const cssVars = useCssVars(color)

    return (
        <Drawer
            anchor="left"
            onClose={onClose}
            open={open}
            PaperProps={{
                sx: {
                    ...cssVars,
                    backgroundColor: 'var(--nav-bg)',
                    color: 'var(--nav-color)',
                    width: MOBILE_NAV_WIDTH
                }
            }}
            variant="temporary"
        >
            <Scrollbar
                sx={{
                    height: '100%',
                    '& .simplebar-content': {
                        height: '100%'
                    },
                    '& .simplebar-scrollbar:before': {
                        background: 'var(--nav-scrollbar-color)'
                    }
                }}
            >
                <Stack sx={{ height: '100%' }}>
                    <Stack alignItems="center" direction="row" spacing={2} sx={{ p: 3 }}>
                        <Box sx={{ width: 180 }}>
                            <Logo />
                        </Box>
                    </Stack>
                    <Stack
                        component="nav"
                        spacing={2}
                        sx={{
                            flexGrow: 1,
                            px: 2
                        }}
                    >
                        {sections.map((section, index) => (
                            <MobileNavSection
                                items={section.items}
                                key={index}
                                pathname={pathname}
                                subheader={section.subheader}
                            />
                        ))}
                    </Stack>
                    <SidebarFooter />
                </Stack>
            </Scrollbar>
        </Drawer>
    )
}

export const SidebarFooter: FC = () => {
    const [t] = useTranslation()
    const router = useRouter()
    return (
        <Stack sx={{ p: 2 }} textAlign="center">
            {/* <Typography color="neutral.400" variant="body2" fontSize={13}>
                {t('view.krg')}
            </Typography>
            <Typography color="neutral.400" variant="body2" fontSize={13} pb={1}>
                {t('view.dit')}
            </Typography> */}
            <Button size="small" fullWidth onClick={() => router.push(paths.releaseNotes)} variant="contained">
                {t('view.version')}: {version}
            </Button>
        </Stack>
    )
}
