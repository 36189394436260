import { Grid, Skeleton, Typography } from '@mui/material'
import { UseFormReturn } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { MinimalStationResponse, StationOperationalStatus } from 'src/services/api/emos'
import React from 'react'
import { Station, StationVirtuosoGrid } from './list-virtualize'

export const StationsList = ({
    stations,
    handleOnClick,
    value,
    isStationInitiation
}: {
    stations: MinimalStationResponse[]
    handleOnClick: (station: MinimalStationResponse) => void
    value: string | null | undefined
    isStationInitiation?: boolean
}) => {
    return (
        <>
            {stations.length > 50 ? (
                <StationVirtuosoGrid
                    value={value}
                    stations={stations}
                    handleOnClick={(station) => {
                        handleOnClick(station)
                    }}
                    isStationInitiation={isStationInitiation}
                />
            ) : (
                stations.map((s) => (
                    <Grid item xs={12} sm={6} md={6} key={s.id}>
                        <Station
                            station={s}
                            value={value}
                            handleOnClick={(station) => {
                                handleOnClick(station)
                            }}
                            isStationInitiation={isStationInitiation}
                        />
                    </Grid>
                ))
            )}
        </>
    )
}

const StationListInput = ({
    formConfigs,
    stations,
    isEdit,
    loading,
    isStationInitiation
}: {
    formConfigs: UseFormReturn<any>
    stations: MinimalStationResponse[]
    isEdit?: boolean
    loading?: boolean
    isStationInitiation?: boolean
}) => {
    const { t } = useTranslation()
    const watcher = formConfigs.watch('stationId')

    const handleStationClick = (station: MinimalStationResponse) => {
        if (!station || (isStationInitiation && station.initiationStatus !== StationOperationalStatus.PENDING)) return
        formConfigs.setValue('stationId', station.id, {
            shouldValidate: true
        })
        formConfigs.setValue('operationType', null)
        formConfigs.setValue('incident', {})
    }

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Typography variant="body2" fontWeight="bold">
                    {t('view.station.header')}
                </Typography>
            </Grid>

            {loading && (
                <>
                    {[...Array(4)].map((_, index) => (
                        <Grid item xs={12} sm={6} md={6} key={index}>
                            <Skeleton
                                variant="rectangular"
                                height={70}
                                sx={{
                                    borderRadius: 1
                                }}
                            />
                        </Grid>
                    ))}
                </>
            )}

            {isEdit ? (
                <Typography variant="body1">
                    {t('view.station.name.headline')}: {stations?.find((station) => station.id === watcher)?.number}
                </Typography>
            ) : (
                <>
                    <StationsList
                        stations={stations}
                        value={watcher}
                        handleOnClick={(station) => handleStationClick(station)}
                        isStationInitiation={isStationInitiation}
                    />

                    <Grid item xs={12}>
                        <Typography variant="caption" color="error">
                            {formConfigs.formState.errors.stationId
                                ? (formConfigs.formState.errors.stationId.message as string)
                                : ''}
                        </Typography>
                    </Grid>
                </>
            )}
        </Grid>
    )
}

export default StationListInput
