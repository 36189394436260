import { Grid } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { DashboardsService, ElectionType, IncidentStatus, NodeType } from 'src/services/api/emos'
import { useOperationReportsFilters } from 'src/store/reports'
import StackedColumnChart from './highcharts/stacked-column-chart'
import { SeriesOptionsType } from 'highcharts'
import { REASONS } from 'src/hooks/use-incidents'
import ReportCard from './report-card'
import { REFETCH_INTERVAL } from './constants'

const STATUSES = [IncidentStatus.RESOLVED, IncidentStatus.OPEN]
export const REASON_COLORS: Record<(typeof REASONS)[keyof typeof REASONS], string> = {
    hot_ethernet_cable: '#ef4444',
    inexperience_of_commission_employees: '#22c55e',
    database_error: '#3b82f6',
    pcos_device: '#ec4899',
    vvd_device: '#a855f7',
    rts_device: '#06b6d4',
    other: '#64748b'
}

const IncidentReport = ({ enabledQueries }: { enabledQueries: boolean }) => {
    const { t } = useTranslation()
    const state = useOperationReportsFilters()
    const { filters } = state

    const { data: incidentReport = [] } = useQuery({
        queryKey: ['incidentReport', filters],
        queryFn: async () => {
            const res = await DashboardsService.incidentReport({
                circleId: filters?.circle as string,
                ancestorId:
                    filters?.[NodeType.POLLING_CENTER] ||
                    filters?.[NodeType.SUB_BRANCH] ||
                    filters?.[NodeType.BRANCH] ||
                    filters?.[NodeType.PBS],
                electionType: filters?.electionType as ElectionType
            })

            const reasons = [...new Set(res.map((item) => item.id.reason))]
                .filter(Boolean) // Optional: Filters out any undefined or null values
                .sort((a, b) => (a && b ? a.localeCompare(b) : 0)) // Sorts alphabetically

            // Group data by reason and status
            const groupedData = res.reduce((acc, { id: { status, reason }, count }) => {
                if (reason && !acc[reason]) {
                    acc[reason] = { Open: 0, Resolved: 0 }
                }
                if (status) {
                    if (reason && status) {
                        acc[reason][status] += count
                    }
                }
                return acc
            }, {})

            // Create the series array per reason
            const series: SeriesOptionsType[] = reasons.map((reason) => {
                return {
                    name: t(`model.incident.reason.options.${reason}` as any),
                    data: STATUSES.map((status) =>
                        reason && groupedData[reason] ? groupedData[reason][status] || 0 : 0
                    ),
                    type: 'column',
                    stack: 'status', // Ensure stacking works across reasons,
                    color: REASON_COLORS[reason as keyof typeof REASONS]
                }
            })

            return series
        },
        enabled: enabledQueries,
        refetchInterval: REFETCH_INTERVAL
    })

    return (
        <Grid item xs={12} md={8}>
            <ReportCard title={t('view.reports.operation.incidentReport')}>
                <StackedColumnChart
                    series={incidentReport}
                    categories={STATUSES.map((status) => t(`model.incident.states.${status}` as any))}
                />
            </ReportCard>
        </Grid>
    )
}

export default IncidentReport
