import { Grid } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import HighPieChart from 'src/components/dashboard/reports/highcharts/pie-chart'
import { DashboardsService, ElectionType, NodeType, VotingResultFormType } from 'src/services/api/emos'
import { useOperationReportsFilters } from 'src/store/reports'
import ReportCard from './report-card'
import { REFETCH_INTERVAL } from './constants'

const MiddayResultReport = ({ enabledQueries }: { enabledQueries: boolean }) => {
    const { t } = useTranslation()
    const state = useOperationReportsFilters()
    const { filters } = state

    const { data: middayResultReport = [] } = useQuery({
        queryKey: ['middayResultReport', filters],
        queryFn: async () => {
            const res = await DashboardsService.middayResultReport({
                circleId: filters?.circle as string,
                ancestorId:
                    filters?.[NodeType.POLLING_CENTER] ||
                    filters?.[NodeType.SUB_BRANCH] ||
                    filters?.[NodeType.BRANCH] ||
                    filters?.[NodeType.PBS],
                electionType: filters?.electionType as ElectionType,
                formType: filters?.formType as VotingResultFormType
            })
            return [
                {
                    y: res.find((r) => r.id === true)?.count || 0,
                    name: t('view.reports.operation.reported')
                },
                {
                    id: 'false',
                    y: res.find((r) => r.id !== true)?.count || 0,
                    name: t('view.reports.operation.notReported')
                }
            ]
        },
        enabled: enabledQueries,
        refetchInterval: REFETCH_INTERVAL
    })

    return (
        <Grid item xs={12} sm={6} md={4}>
            <ReportCard title={t('view.reports.operation.middayResultReport')}>
                <HighPieChart dataset={middayResultReport} />
            </ReportCard>
        </Grid>
    )
}

export default MiddayResultReport
