import { Column } from '@ditkrg/react-core'
import { TFunction } from 'i18next'
import dateFormat from 'src/utils/date-format'
import { useQuery } from '@tanstack/react-query'
import { allCirclesQuery } from 'src/queries/circles'
import { PossibleVotingResultResponse } from 'src/services/api/emos'
import VotingResultFormTypeStatusLabel from './components/form-type-status-label'
import VotingResultStatusLabel from './status-label'

const GenerateColumns = ({ tableState, t }: { tableState: any; t: TFunction }) => {
    const { data: circles = [] } = useQuery({
        ...allCirclesQuery()
    })

    const cols: Column[] = [
        {
            id: 'index',
            header: '#',
            cell: (_, rowIndex) => tableState.rowsPerPage * tableState.page + rowIndex + 1
        },
        {
            id: 'formType',
            header: t('model.votingResult.formType.label'),
            cell: (row) => <VotingResultFormTypeStatusLabel status={row.formType} />
        },
        {
            id: 'circleId',
            header: t('view.circle.name.headline'),
            cell: (row) => circles.find((circle) => circle.id === row.circleId)?.name ?? ''
        },
        {
            id: 'createdAt',
            header: t('model.common.createdAt'),
            cell: (row) => {
                return (
                    dateFormat({
                        date: row.createdAt,
                        format: 'hh:mm:ss A - MM/DD'
                    }) ?? ''
                )
            }
        },
        {
            id: 'status',
            header: t('model.common.status'),
            cell: (row: PossibleVotingResultResponse) => <VotingResultStatusLabel status={row.status} />
        }
    ]
    return cols
}

export default GenerateColumns
