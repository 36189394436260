import { Tooltip } from '@mui/material'
import { useEffect, useState } from 'react'
import { CopyToClipboard as Copy } from 'react-copy-to-clipboard'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { useTranslation } from 'react-i18next'

const CopyToClipboard = ({ text, children }: { text: string; children?: React.ReactNode }) => {
    const [copied, setCopied] = useState(false)
    const [t] = useTranslation()

    useEffect(() => {
        const interval = setInterval(() => {
            if (copied) {
                setCopied(false)
            }
        }, 3000)

        return () => clearInterval(interval)
    }, [copied])

    return (
        <Copy className="copyToClipboard" style={{ cursor: 'pointer' }} text={text} onCopy={() => setCopied(true)}>
            <Tooltip title={copied ? 'copied' : 'copy'}>
                <ContentCopyIcon sx={{ fontSize: 18, color: 'text.secondary' }} />
            </Tooltip>
        </Copy>
    )
}
export default CopyToClipboard
