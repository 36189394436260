import { AdvancedTable } from '@ditkrg/react-core'
import { Card, CardContent, Tab, Tabs } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { NodesService, NodeType, PagedCircleResponseListResponse } from 'src/services/api/emos'
import { useTranslation } from 'react-i18next'
import ProfileDrawer from './profile-drawer'
import React, { useEffect } from 'react'
import { styled } from '@mui/material/styles'
import { useNodesTableState } from 'src/store/nodes-table'
import { useNodes } from 'src/hooks/nodes/use-nodes'
import UseNodesColumns from 'src/hooks/nodes/use-nodes-columns'
import { ROWS_PER_PAGE_OPTIONS } from 'src/utils/constants'
import { useAuth } from 'src/hooks/use-auth'
import { useSettings } from 'src/store/settings'

const StyledTabs = styled(Tabs)(({ theme }) => ({
    borderBottomWidth: 1,
    borderBottomColor: theme.palette.divider,
    borderBottomStyle: 'solid',
    '& .MuiTabs-indicator': {
        backgroundColor: '#1890ff'
    }
}))

const StyledTab = styled((props: any) => <Tab disableRipple {...props} />)(({ theme }) => ({
    marginRight: theme.spacing(1),
    color: theme.palette.text.secondary,
    fontSize: theme.typography.body1.fontSize,

    '&:hover': {
        color: '#40a9ff',
        opacity: 1
    },
    '&.Mui-selected': {
        color: '#1890ff'
    },
    '&.Mui-focusVisible': {
        backgroundColor: '#d1eaff'
    }
}))

// Map each node type to accessible types
export const accessibleNodeTypes: Record<NodeType, NodeType[]> = {
    [NodeType.EMR]: [NodeType.PBS, NodeType.BRANCH, NodeType.SUB_BRANCH, NodeType.POLLING_CENTER],
    [NodeType.PBS]: [NodeType.BRANCH, NodeType.SUB_BRANCH, NodeType.POLLING_CENTER],
    [NodeType.BRANCH]: [NodeType.SUB_BRANCH, NodeType.POLLING_CENTER],
    [NodeType.SUB_BRANCH]: [NodeType.POLLING_CENTER],
    [NodeType.POLLING_CENTER]: []
}

const Results = ({ showTypeTabs = true }: { showTypeTabs?: boolean }) => {
    const { t } = useTranslation()
    const { user } = useAuth()
    const nodesTableState = useNodesTableState()
    const { page, rowsPerPage, nodeType: filteredNodeType, filters } = nodesTableState
    const [drawerOpen, setDrawerOpen] = React.useState(false)
    const [node, setNode] = React.useState<PagedCircleResponseListResponse | null>(null)
    const [type, setType] = React.useState<NodeType>(filteredNodeType ?? NodeType.PBS)
    const { nodeTypeValues } = useNodes({})
    const { generateColumns } = UseNodesColumns({ tableState: nodesTableState, type })
    const { language } = useSettings()

    useEffect(() => {
        if (nodesTableState && filteredNodeType && filteredNodeType !== type) {
            setType(filteredNodeType)
        }
    }, [filteredNodeType, nodesTableState, type])

    const closeDrawer = () => {
        setDrawerOpen(false)
        setNode(null)
    }

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setType(filteredNodeTypes[newValue])
        nodesTableState?.setNodeType(filteredNodeTypes[newValue])
        nodesTableState?.setFilters({})
    }

    const {
        data: nodes,
        isLoading,
        isFetching
    } = useQuery({
        queryKey: ['nodes', rowsPerPage, page, filteredNodeType, filters],
        queryFn: async () => {
            const res = await NodesService.listNodes({
                page: page + 1,
                rowsPerPage,
                ...filters,
                nodeType: filteredNodeType,
                parentId:
                    filters?.[NodeType.POLLING_CENTER] ||
                    filters?.[NodeType.SUB_BRANCH] ||
                    filters?.[NodeType.BRANCH] ||
                    filters?.[NodeType.PBS]
            })
            return res
        },
        placeholderData: (placeholderData) => placeholderData
    })

    const userAccessibleNodeTypes = user?.node?.type ? accessibleNodeTypes[user?.node?.type] || [] : []

    // Function to filter node types
    const filterNodeTypes = (nodeTypes: NodeType[]) => {
        return nodeTypes.filter((nodeType) => userAccessibleNodeTypes.includes(nodeType))
    }

    const filteredNodeTypes = filterNodeTypes(nodeTypeValues)

    return (
        <Card variant="outlined">
            {(nodesTableState || showTypeTabs) && (
                <CardContent sx={{ pt: 2, pb: 0 }}>
                    <StyledTabs
                        value={filteredNodeTypes.findIndex((nodeType) => nodeType === type)}
                        onChange={handleChange}
                    >
                        {filteredNodeTypes.map((nodeType) => {
                            return (
                                <StyledTab
                                    key={nodeType}
                                    label={t(`model.node.type.options.${nodeType}`)}
                                    iconPosition="start"
                                />
                            )
                        })}
                    </StyledTabs>
                </CardContent>
            )}
            <AdvancedTable
                key={type}
                data={nodes?.data ?? []}
                columns={generateColumns()}
                state={nodesTableState}
                totalItems={nodes?.meta?.pagination?.count ?? 0}
                onRowClick={(row) => {
                    setNode(row)
                    setDrawerOpen(true)
                }}
                loading={isLoading || isFetching}
                loadingVariant={isFetching ? 'linear' : 'skeleton'}
                filterLabel={t('view.common.filter.label')}
                clearLabel={t('view.common.clear')}
                paginationVariant="advanced"
                rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
                locale={language as any}
            />

            <ProfileDrawer open={drawerOpen} close={closeDrawer} data={node} />
        </Card>
    )
}

export default Results
