import Button from '@mui/material/Button'
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import MiniDrawer from 'src/components/drawer/mini-drawer'
import { DetailsListProps } from 'src/components/list/details-list'
import { Stack } from '@mui/system'
import { PartiesService } from 'src/services/api/emos'
import Edit from './edit'
import { useToast } from 'src/hooks/useToast'
import ImagePreview from 'src/components/image-preview'
import ModalImage from 'src/components/modal-image'
import { ACTIONS, RESOURCES, useCheckPermissions } from 'src/hooks/use-check-permissions'
import ChipList from 'src/components/chip/chip-list'
import { allCirclesQuery } from 'src/queries/circles'
import AuditsDialog from '../audits/audits-dialog'

interface Props {
    open: boolean
    close: () => void
    data: any
}

const ColorTemplate = ({ value }: { value: string }) => {
    return <div className="swatch" style={{ backgroundColor: value, width: 50, height: 50 }} />
}

const ProfileDrawer = ({ open, close, data }: Props) => {
    const [t] = useTranslation()
    const [edit, setEdit] = useState<boolean>(false)
    const queryClient = useQueryClient()
    const { successToast } = useToast()
    const [image, setImage] = useState<string | null>(null)
    const { can } = useCheckPermissions()
    const [auditsDialogOpen, setAuditsDialogOpen] = useState(false)

    const { data: circles = [] } = useQuery({
        ...allCirclesQuery()
    })

    const closeDrawer = useCallback(() => {
        close()
    }, [close])

    const {
        data: party,
        isLoading,
        isRefetching,
        refetch
    } = useQuery({
        queryKey: ['party', data],
        queryFn: async () => {
            const res = await PartiesService.getPartyById({
                id: data?.id
            })
            return res
        },
        enabled: !!data
    })

    const details: DetailsListProps = useMemo(
        () => ({
            title: t('view.common.details.label'),
            fields: [
                { label: t('model.common.id'), value: party?.id },
                { label: t('model.common.name'), value: party?.name },
                { label: t('model.party.number'), value: party?.number },
                { label: t('model.party.color'), Template: ColorTemplate, templateProps: { value: party?.color } },
                {
                    label: t('model.party.partyType.label'),
                    value: t(`model.party.partyType.options.${party?.partyType}` as any)
                },
                {
                    label: t('model.party.seatType.label'),
                    value: t(`model.party.seatType.options.${party?.seatType}` as any)
                },
                {
                    label: t('view.circle.header'),
                    Template: ChipList,
                    templateProps: {
                        items: circles.filter((circle) => party?.circleIds?.includes(circle.id!))
                    }
                },
                {
                    label: t('model.common.photo'),
                    Template: ImagePreview,
                    templateProps: {
                        image: party?.photo?.url,
                        onClick: () => {
                            setImage(party?.photo?.url ?? '')
                        }
                    }
                },
                {
                    label: t('model.party.isSystem'),
                    value: t(`view.common.yes/no.${party?.isSystem ?? 'false'}` as any)
                },
                {
                    label: t('model.common.isActive'),
                    value: t(`view.common.yes/no.${party?.isActive}` as any)
                }
            ]
        }),
        [
            circles,
            party?.circleIds,
            party?.color,
            party?.id,
            party?.isActive,
            party?.isSystem,
            party?.name,
            party?.number,
            party?.partyType,
            party?.photo?.url,
            party?.seatType,
            t
        ]
    )

    return (
        <MiniDrawer
            open={open}
            close={closeDrawer}
            header={t('view.party.name.headline')}
            details={edit ? undefined : details}
            loading={isLoading}
        >
            {!edit && (
                <Stack direction="row" justifyContent="space-between">
                    <Button onClick={closeDrawer} variant="outlined" size="small">
                        {t('view.common.dialog.close')}
                    </Button>

                    <Stack direction="row" spacing={2} justifyContent="flex-end">
                        {can({
                            resource: RESOURCES.audit,
                            actions: [ACTIONS.read]
                        }) && (
                            <Button
                                variant="contained"
                                size="small"
                                color="info"
                                onClick={() => setAuditsDialogOpen(true)}
                            >
                                {t('view.common.audit')}
                            </Button>
                        )}
                        {can({
                            resource: RESOURCES.party,
                            actions: [ACTIONS.update]
                        }) && (
                            <Button
                                variant="contained"
                                size="small"
                                onClick={() => setEdit(true)}
                                disabled={isRefetching}
                            >
                                {t('view.actions.common.edit')}
                            </Button>
                        )}
                    </Stack>
                </Stack>
            )}
            {edit && party && (
                <Edit
                    party={party}
                    handleSuccess={() => {
                        successToast(
                            t(`view.notifications.common.update`, {
                                name: t(`view.party.name.headline`)
                            })
                        )
                        refetch()
                        queryClient.invalidateQueries({ queryKey: ['users'] })
                        setEdit(false)
                    }}
                    handleClose={() => {
                        setEdit(false)
                    }}
                />
            )}

            {party && (
                <AuditsDialog
                    version={party?.version ?? 0}
                    open={auditsDialogOpen}
                    onClose={() => setAuditsDialogOpen(false)}
                    id={party?.id ?? ''}
                    type="parties"
                />
            )}
            <ModalImage open={!!image} large={image} alt="image" onClose={() => setImage(null)} />
        </MiniDrawer>
    )
}

export default ProfileDrawer
