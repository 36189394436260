export const useFilterOptions = () => {
    const filterPartiesOptions = (options, { inputValue }) => {
        return options.filter((option: { name: string; number: string }) => {
            return (
                option.name?.toLowerCase().includes(inputValue?.toLowerCase?.()) ||
                (option.number + '')?.toLowerCase().includes(inputValue?.toLowerCase?.())
            )
        })
    }

    return {
        filterPartiesOptions
    }
}
