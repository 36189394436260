import { useQueryClient } from '@tanstack/react-query'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Create from 'src/components/dashboard/nodes/create'
import Results from 'src/components/dashboard/nodes/results'
import MiniDrawer from 'src/components/drawer/mini-drawer'
import LayoutPage from 'src/components/page'
import { PermissionsGuard } from 'src/guards/permissions-guard'
import { useNodes } from 'src/hooks/nodes/use-nodes'
import { ACTIONS, RESOURCES, useCheckPermissions } from 'src/hooks/use-check-permissions'
import { useNodesTableState } from 'src/store/nodes-table'

const Nodes = () => {
    const { t } = useTranslation()
    const [createDialog, setCreateDialog] = useState(false)
    const queryClient = useQueryClient()
    const nodesTableState = useNodesTableState()
    const { getNodeValueByIndex } = useNodes({})
    const { can } = useCheckPermissions()

    const closeDrawer = () => {
        setCreateDialog(false)
    }

    return (
        <PermissionsGuard resource="node" actions={[ACTIONS.ui, ACTIONS.create, ACTIONS.update]}>
            <LayoutPage
                title="Node"
                breadcrumbs={[
                    {
                        title: t('view.node.header')
                    }
                ]}
                actions={[
                    {
                        hidden: !can({
                            resource: RESOURCES.node,
                            actions: [ACTIONS.create]
                        }),
                        label: t('view.node.new.headline'),
                        onClick: () => setCreateDialog(true),
                        color: 'primary'
                    }
                ]}
            >
                <Results />

                <MiniDrawer open={createDialog} close={closeDrawer} header={t('view.node.new.headline')}>
                    {createDialog && (
                        <Create
                            handleSuccess={(data) => {
                                closeDrawer()
                                nodesTableState?.setNodeType(data.nodeType)
                                nodesTableState?.setFilter({
                                    key: 'nodeType',
                                    value: getNodeValueByIndex(data.nodeType)
                                })
                                queryClient.invalidateQueries({
                                    queryKey: ['nodes']
                                })
                            }}
                            handleClose={() => {
                                closeDrawer()
                            }}
                        />
                    )}
                </MiniDrawer>
            </LayoutPage>
        </PermissionsGuard>
    )
}

export default Nodes
