import type { FC } from 'react'

import Popover from '@mui/material/Popover'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { ThemePopover } from '../theme-popover'
import { LayoutToggler } from '../layout-toggler'
import { StretchToggler } from '../stretch-toggler'

import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'

interface SettingsPopoverProps {
    anchorEl: null | Element
    onClose?: () => void
    open?: boolean
}

export const SettingsPopover: FC<SettingsPopoverProps> = (props) => {
    const { anchorEl, onClose, open = false, ...other } = props

    const items = [
        {
            name: 'Mode',
            icon: <ThemePopover />
        },
        {
            name: 'Layout',
            icon: <LayoutToggler />
        }
        // {
        //     name: 'Wideness',
        //     icon: <StretchToggler />
        // }
        // {
        //     name: 'Nav',
        //     icon: <NavColorToggler />,
        // },
    ]

    return (
        <Popover
            anchorEl={anchorEl}
            anchorOrigin={{
                horizontal: 'left',
                vertical: 'bottom'
            }}
            disableScrollLock
            onClose={onClose}
            open={open}
            slotProps={{
                paper: { sx: { width: 280 } }
            }}
            {...other}
        >
            <Stack
                alignItems="center"
                direction="row"
                justifyContent="space-between"
                spacing={2}
                sx={{
                    px: 3,
                    py: 2
                }}
            >
                <Typography color="inherit" variant="h6">
                    Settings
                </Typography>
            </Stack>

            <List disablePadding>
                {items.map((item) => (
                    <ListItem
                        divider
                        key={item.name}
                        secondaryAction={item.icon}
                        sx={{
                            py: 1.5
                        }}
                    >
                        {item.name}
                    </ListItem>
                ))}
            </List>
        </Popover>
    )
}
