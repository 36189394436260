import type { PaletteColor } from '@mui/material/styles/createPalette'

import type { ColorPreset } from '.'
import { blue, green, indigo, purple, yellow, black } from './colors'

export const getPrimary = (preset?: ColorPreset): PaletteColor => {
    switch (preset) {
        case 'blue':
            return blue
        case 'green':
            return green
        case 'yellow':
            return yellow
        case 'black':
            return black
        case 'indigo':
            return indigo
        case 'purple':
            return purple
        default:
            console.error('Invalid color preset, accepted values: "blue", "green", "indigo" or "purple"".')
            return blue
    }
}
