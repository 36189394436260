import { AdvancedFilters, AdvancedTable } from '@ditkrg/react-core'
import { Backdrop, Button, Card, CircularProgress, Grid, Paper, Typography } from '@mui/material'
import { useMutation, useQuery } from '@tanstack/react-query'
import { NodeType, PossibleVotingResultResponse, VotingResultsService } from 'src/services/api/emos'
import { useVotingResultsTableState } from 'src/store/table'
import GenerateColumns from './columns'
import { useTranslation } from 'react-i18next'
import React, { useState } from 'react'
import { useRouter } from 'src/hooks/use-router'
import { paths } from 'src/paths'
import { ROWS_PER_PAGE_OPTIONS } from 'src/utils/constants'
import { allStationsQuery } from 'src/queries/stations'
import { useToast } from 'src/hooks/useToast'
import { useSystemConfigurationCheck } from 'src/hooks/use-system-configuration-check'
import { useSettings } from 'src/store/settings'
import { useNodeFilters } from 'src/hooks/use-node-filters'
import { StationsList } from '../stations/list-input'
import DeleteIcon from '@mui/icons-material/Delete'
import { useAuth } from 'src/hooks/use-auth'
import { useAuthChecks } from 'src/hooks/use-auth-checks'

const Stations = () => {
    const { t } = useTranslation()
    const votingResultsTableState = useVotingResultsTableState()
    const filteredStationId = votingResultsTableState?.filters?.['stationId'] || null
    const { language } = useSettings()
    const { filters } = votingResultsTableState
    const { user } = useAuth()
    const { isPCTypeAndHasPCSRole } = useAuthChecks()

    const { filterInputs, isPending } = useNodeFilters({
        state: votingResultsTableState
    })

    const { data = [] } = useQuery({
        ...allStationsQuery({
            ancestorId:
                filters?.[NodeType.POLLING_CENTER] ||
                filters?.[NodeType.SUB_BRANCH] ||
                filters?.[NodeType.BRANCH] ||
                filters?.[NodeType.PBS] ||
                undefined
        }),
        enabled:
            !!filters?.[NodeType.POLLING_CENTER] ||
            !!filters?.[NodeType.SUB_BRANCH] ||
            !!filters?.[NodeType.BRANCH] ||
            !!filters?.[NodeType.PBS]
    })

    const handleStationClick = (station) => {
        if (!station) return
        votingResultsTableState.setFilter({
            key: 'stationId',
            value: station.id as string
        })
    }

    return (
        <>
            <Grid item xs={12}>
                <Typography variant="body2" fontWeight="bold">
                    {t('view.station.header')}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                {!isPending && filterInputs.filter((f) => !f.hidden).length > 0 && (
                    <Paper variant="outlined">
                        <AdvancedFilters
                            state={votingResultsTableState}
                            filters={[...filterInputs]}
                            showCollapseButton={false}
                            locale={language as any}
                            clearButtonProps={{
                                hidden: true
                            }}
                            actions={[
                                ({ filtersState, sync }) => (
                                    <Button
                                        color="error"
                                        onClick={() => {
                                            filtersState.clearFilters()
                                            if (isPCTypeAndHasPCSRole) {
                                                filtersState.setFilter(NodeType.POLLING_CENTER, user?.node?.id)
                                            }
                                            sync?.()
                                        }}
                                        startIcon={<DeleteIcon />}
                                    >
                                        {t('view.common.clear')}
                                    </Button>
                                )
                            ]}
                        />
                    </Paper>
                )}
            </Grid>

            <StationsList
                stations={data}
                value={filteredStationId}
                handleOnClick={(station) => handleStationClick(station)}
            />
        </>
    )
}

const Results = () => {
    const { t } = useTranslation()
    const router = useRouter()
    const votingResultsTableState = useVotingResultsTableState()
    const filteredStationId = votingResultsTableState?.filters?.['stationId'] || null
    const { isInActiveDurationOrDoesNotRespectTimeLimits, systemConfigurationCheck } = useSystemConfigurationCheck()
    const { language } = useSettings()

    const [backdropOpen, setBackdropOpen] = useState(false)
    const { errorsToast, errorToast } = useToast()

    const {
        data: votingResults,
        isLoading,
        isFetching
    } = useQuery({
        queryKey: ['votingResults', filteredStationId],
        queryFn: async () => {
            if (!filteredStationId) return
            const res = await VotingResultsService.listVotingResults({
                stationId: filteredStationId
            })
            // sort by formType
            return res.sort((a, b) => a.formType.localeCompare(b.formType))
        },
        placeholderData: (placeholderData) => placeholderData,
        enabled: !!filteredStationId
    })

    const { mutate } = useMutation({
        mutationKey: ['updateIncidentStatus'],
        mutationFn: async (votingResult: PossibleVotingResultResponse) => {
            return await VotingResultsService.createVotingResults({
                requestBody: {
                    stationId: filteredStationId,
                    formType: votingResult.formType,
                    circleId: votingResult.circleId
                }
            })
        },
        onSuccess: (res) => {
            router.push(paths.votingResults.new(res.id))
        },
        onError: (error) => {
            errorsToast(error)
        },
        onSettled: () => {
            setBackdropOpen(false)
        }
    })

    return (
        <Grid container spacing={1}>
            <Stations />

            <Grid item xs={12}>
                <Card variant="outlined">
                    <AdvancedTable
                        data={filteredStationId ? votingResults ?? [] : []}
                        columns={GenerateColumns({ t, tableState: votingResultsTableState })}
                        state={votingResultsTableState}
                        totalItems={0}
                        onRowClick={(row) => {
                            if (!row.id) {
                                if (
                                    !isInActiveDurationOrDoesNotRespectTimeLimits &&
                                    !systemConfigurationCheck.isInEveningDuration
                                ) {
                                    errorToast(t('view.systemConfiguration.outsideActiveDuration'))
                                    return
                                }
                                setBackdropOpen(true)
                                mutate(row)
                            } else {
                                router.push(paths.votingResults.new(row.id))
                            }
                        }}
                        loading={isLoading || isFetching}
                        loadingVariant={isFetching ? 'linear' : 'skeleton'}
                        filterLabel={t('view.common.filter.label')}
                        clearLabel={t('view.common.clear')}
                        paginationVariant="advanced"
                        rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
                        locale={language as any}
                    />
                </Card>
            </Grid>

            <Backdrop open={backdropOpen}>
                <CircularProgress />
            </Backdrop>
        </Grid>
    )
}

export default Results
