import { Column } from '@ditkrg/react-core'
import dayjs from 'dayjs'
import { TFunction } from 'i18next'
import IncidentStatusLabel from './status-label'
import { formatStationNumber } from '../stations/utils'

const GenerateColumns = ({ tableState, t }: { tableState: any; t: TFunction }) => {
    const cols: Column[] = [
        {
            id: 'index',
            header: '#',
            cell: (_, rowIndex) => tableState.rowsPerPage * tableState.page + rowIndex + 1
        },
        {
            id: 'reason',
            header: t('model.incident.reason.label'),
            cell: (row) => t(`model.incident.reason.options.${row.reason}` as any)
        },
        {
            id: 'timeOfBreakage',
            header: t('model.incident.timeOfBreakage'),
            cell: (row) => dayjs(row.timeOfBreakage).format('hh:mm:ss A - MM/DD')
        },
        {
            id: 'stationId',
            header: t('view.station.name.headline'),
            cell: (row) => `${t('view.station.name.headline')}: ${formatStationNumber(row.station?.number)}`
        },
        {
            id: 'pcId',
            header: t('model.node.type.options.PollingCenter'),
            cell: (row) => row.station?.pc?.name
        },
        {
            id: 'status',
            header: t('model.common.status'),
            cell: (row) => <IncidentStatusLabel status={row.status} />
        }
    ]
    return cols
}

export default GenerateColumns
