import { yupResolver } from '@hookform/resolvers/yup'
import { useState } from 'react'
import { DetailedNodeResponse } from 'src/services/api/emos'
import { SubmitHandler, useForm } from 'react-hook-form'
import { Grid } from '@mui/material'
import { FormFields } from 'mui-rhf-library'
import FormActions from 'src/components/drawer/form-actions'
import { useToast } from 'src/hooks/useToast'
import { useNodesSchema } from 'src/hooks/nodes/use-nodes-schema'
import { nodeTypeUpdateServiceMapper, updateNodeTypeAttributesMapper } from './constants'
import { useNodes } from 'src/hooks/nodes/use-nodes'
import { filterObject } from 'src/utils/filter-object'

const Edit = ({
    node,
    handleSuccess,
    handleClose
}: {
    node: DetailedNodeResponse
    handleSuccess: (formData) => void
    handleClose: () => void
}) => {
    const [btnLoading, setBtnLoading] = useState(false)
    const { errorsToast } = useToast()

    const { createSchema } = useNodesSchema()

    const formConfigs = useForm({
        resolver: yupResolver(createSchema()),
        defaultValues: {
            ...node,
            parentId: node.parent?.id,
            provinceId: node.provinceId,
            districtId: node.districtId,
            circleId: node.circle?.id,
            subDistrictId: node.subDistrictId
        }
    })
    const {
        handleSubmit,
        control,
        formState: { isDirty, errors }
    } = formConfigs

    console.log({ errors })

    const handleFormSubmit: SubmitHandler<any> = async (formData) => {
        setBtnLoading(true)

        const filteredData = filterObject({
            obj: formData,
            keys: updateNodeTypeAttributesMapper[formData.nodeType]
        }) as any

        try {
            await nodeTypeUpdateServiceMapper[formData.nodeType]({
                id: node.id,
                requestBody: { ...filteredData, version: node.version }
            })
            handleSuccess(formData)
        } catch (err) {
            errorsToast(err)
        } finally {
            setBtnLoading(false)
        }
    }

    const { fields } = useNodes({
        formConfigs,
        isEdit: true
    })

    return (
        <form onSubmit={handleSubmit(handleFormSubmit)}>
            <Grid container spacing={2}>
                <FormFields fields={fields} control={control} />
            </Grid>
            <FormActions
                disabled={!isDirty}
                handleClose={handleClose}
                handleSubmit={handleSubmit(handleFormSubmit)}
                loading={btnLoading}
            />
        </form>
    )
}

export default Edit
