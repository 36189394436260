export const filterObject = ({ obj, keys }: { obj: any; keys: string[] }) => {
    const filteredObject = {}

    keys.forEach((attribute) => {
        if (Object.prototype.hasOwnProperty.call(obj, attribute)) {
            filteredObject[attribute] = obj[attribute]
        }
    })

    return filteredObject
}
