/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum NodeType {
    EMR = 'EMR',
    PBS = 'PBS',
    BRANCH = 'Branch',
    SUB_BRANCH = 'SubBranch',
    POLLING_CENTER = 'PollingCenter',
}
