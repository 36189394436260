import React from 'react'
import MuiButton from '@mui/material/Button'
import MuiCircularProgress from '@mui/material/CircularProgress'
import { styled } from '@mui/material/styles'

const ErrorButton = styled(MuiButton)(
    ({ theme }) => `
    color: #fff;
    background-color: ${theme.palette.error.main};
    :hover {
      background-color: ${theme.palette.error.dark};
  }
`
)

const CircularProgress = styled(MuiCircularProgress)(
    () => `
    color: #bdbdbd;
    margin-right: 7px;
`
)

type Props = {
    children?: React.ReactNode
    loading?: boolean
    hasLoadingProgress?: boolean
    colorVariant?: string
    className?: string
    [x: string]: any // for the rest property
}

const LoadingButton: React.FC<Props> = ({
    children,
    loading,
    colorVariant,
    className,
    hasLoadingProgress = true,
    ...rest
}) => {
    const Button = colorVariant === 'error' ? ErrorButton : MuiButton

    return (
        <Button {...rest} disabled={rest?.disabled || loading} className={className} onClick={rest?.onClick}>
            {loading && hasLoadingProgress && <CircularProgress size={18} />}
            {children}
        </Button>
    )
}

export default LoadingButton
