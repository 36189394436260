import { Card, CardContent, Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'
import LayoutPage from 'src/components/page'
import { paths } from 'src/paths'

import { yupResolver } from '@hookform/resolvers/yup'
import { useState } from 'react'
import { ViolationsService } from 'src/services/api/emos'
import { SubmitHandler, useForm } from 'react-hook-form'
import { FormFields } from 'mui-rhf-library'
import FormActions from 'src/components/drawer/form-actions'
import { useToast } from 'src/hooks/useToast'
import { createSchema } from 'src/components/dashboard/violations/schema'
import { useQuery } from '@tanstack/react-query'
import { useParams } from 'react-router'
import { useRouter } from 'src/hooks/use-router'
import { useViolations } from 'src/hooks/violations/use-violations'
import { filterObject } from 'src/utils/filter-object'
import { updateViolationAttributesToKeep } from 'src/components/dashboard/violations/constants'

const Edit = () => {
    const { t } = useTranslation()
    const [btnLoading, setBtnLoading] = useState(false)
    const { errorsToast } = useToast()
    const { id } = useParams<{ id: string }>()
    const router = useRouter()

    const { data: violation } = useQuery({
        queryKey: ['violation', id],
        queryFn: async () => {
            const res = await ViolationsService.getViolationById({
                id: id as string
            })
            formConfigs.reset({
                ...res,
                stationId: res.station?.id
            })
            return res
        },
        enabled: !!id
    })

    const formConfigs = useForm<any>({
        resolver: yupResolver(createSchema() as any),
        defaultValues: {
            ...violation
        }
    })
    const {
        handleSubmit,
        control,
        formState: { isDirty }
    } = formConfigs

    const { fields } = useViolations({
        formConfigs,
        isEdit: true
    })

    const handleFormSubmit: SubmitHandler<any> = async (formData) => {
        if (!violation) return
        setBtnLoading(true)

        const filteredData = filterObject({
            obj: formData,
            keys: updateViolationAttributesToKeep
        }) as any

        try {
            await ViolationsService.updateViolation({
                id: violation.id,
                requestBody: { ...filteredData, version: violation.version }
            })
            router.push(paths.violations.view(violation.id))
        } catch (err) {
            errorsToast(err)
        } finally {
            setBtnLoading(false)
        }
    }

    return (
        <LayoutPage
            title="Violation"
            breadcrumbs={[
                {
                    title: t('view.violation.header'),
                    href: paths.violations.index
                },
                {
                    title: id as string,
                    href: paths.violations.view(id)
                },
                {
                    title: t('view.violation.edit.headline')
                }
            ]}
        >
            <Card variant="outlined">
                <CardContent>
                    <form onSubmit={handleSubmit(handleFormSubmit)}>
                        <Grid container spacing={2}>
                            <FormFields fields={fields} control={control} />
                        </Grid>
                        <FormActions
                            disabled={!isDirty}
                            handleClose={() => {
                                router.push(paths.violations.view(violation?.id))
                            }}
                            handleSubmit={handleSubmit(handleFormSubmit)}
                            loading={btnLoading}
                        />
                    </form>
                </CardContent>
            </Card>
        </LayoutPage>
    )
}

export default Edit
