import {
    CreateBranchRequest,
    CreatePbsRequest,
    CreatePollingCenterRequest,
    CreateSubBranchRequest,
    NodesService,
    NodeType,
    UpdateBranchRequest,
    UpdatePbsRequest,
    UpdatePollingCenterRequest,
    UpdateSubBranchRequest
} from 'src/services/api/emos'

export const nodeTypeCreateServiceMapper = {
    [NodeType.PBS]: NodesService.createPbs,
    [NodeType.BRANCH]: NodesService.createBranch,
    [NodeType.SUB_BRANCH]: NodesService.createSubBranch,
    [NodeType.POLLING_CENTER]: NodesService.createPollingCenter
}

export const nodeTypeUpdateServiceMapper = {
    [NodeType.PBS]: NodesService.updatePbs,
    [NodeType.BRANCH]: NodesService.updateBranch,
    [NodeType.SUB_BRANCH]: NodesService.updateSubBranch,
    [NodeType.POLLING_CENTER]: NodesService.updatePollingCenter
}

// PBS attributes to keep
type CreatePbsRequestKeys = Array<keyof CreatePbsRequest>
export const createPbsAttributesToKeep: CreatePbsRequestKeys = ['name', 'isActive']

type UpdatePbsRequestKeys = Array<keyof UpdatePbsRequest>
export const updatePbsAttributesToKeep: UpdatePbsRequestKeys = ['isActive', 'name']

// Branch attributes to keep
type CreateBranchRequestKeys = Array<keyof CreateBranchRequest>
export const createBranchAttributesToKeep: CreateBranchRequestKeys = [
    'circleId',
    'isActive',
    'name',
    'parentId',
    'subDistrictId'
]

type UpdateBranchRequestKeys = Array<keyof UpdateBranchRequest>
export const updateBranchAttributesToKeep: UpdateBranchRequestKeys = ['isActive', 'name', 'version']

// Sub branch attributes to keep
type CreateSubBranchRequestKeys = Array<keyof CreateSubBranchRequest>
export const createSubBranchAttributesToKeep: CreateSubBranchRequestKeys = [
    'isActive',
    'name',
    'parentId',
    'subDistrictId'
]

type UpdateSubBranchRequestKeys = Array<keyof UpdateSubBranchRequest>
export const updateSubBranchAttributesToKeep: UpdateSubBranchRequestKeys = ['isActive', 'name']

// Polling center attributes to keep
type CreatePollingCenterRequestKeys = Array<keyof CreatePollingCenterRequest>
export const createPollingCenterAttributesToKeep: CreatePollingCenterRequestKeys = [
    'address',
    'code',
    'electionType',
    'isActive',
    'name',
    'parentId',
    'vrcId'
]

type UpdatePollingCenterRequestKeys = Array<keyof UpdatePollingCenterRequest>
export const updatePollingCenterAttributesToKeep: UpdatePollingCenterRequestKeys = [
    'address',
    'code',
    'electionType',
    'isActive',
    'name',
    'version',
    'vrcId'
]

export const createNodeTypeAttributesMapper = {
    [NodeType.PBS]: createPbsAttributesToKeep,
    [NodeType.BRANCH]: createBranchAttributesToKeep,
    [NodeType.SUB_BRANCH]: createSubBranchAttributesToKeep,
    [NodeType.POLLING_CENTER]: createPollingCenterAttributesToKeep
}

export const updateNodeTypeAttributesMapper = {
    [NodeType.PBS]: updatePbsAttributesToKeep,
    [NodeType.BRANCH]: updateBranchAttributesToKeep,
    [NodeType.SUB_BRANCH]: updateSubBranchAttributesToKeep,
    [NodeType.POLLING_CENTER]: updatePollingCenterAttributesToKeep
}
