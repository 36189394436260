import { RolesService } from 'src/services/api/emos'

export const MINUTE = 1000 * 60

export const allRolesQuery = () => ({
    queryKey: ['allRoles'],
    queryFn: async () => {
        const res = await RolesService.listAllRoles()
        return res
    },
    gcTime: MINUTE * 5,
    staleTime: MINUTE * 5
})
