import type { FC, ReactNode } from 'react'
import NotAuthorized from 'src/pages/error/401'
import { useAuth } from 'src/hooks/use-auth'
import { useCheckPermissions } from 'src/hooks/use-check-permissions'

interface RolesGuardProps {
    children: ReactNode
    resource: string
    actions: string[]
}

export const PermissionsGuard: FC<RolesGuardProps> = (props) => {
    const { children } = props
    const { isInitialized } = useAuth()
    const { can } = useCheckPermissions()

    if (!isInitialized) return null

    return can({ resource: props.resource, actions: props.actions }) ? <>{children}</> : <NotAuthorized />
}
