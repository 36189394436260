import { type TableState } from '@ditkrg/react-core'
import { create, StateCreator } from 'zustand'
import { persist, createJSONStorage } from 'zustand/middleware'
import { name } from '../../package.json'
import { IncidentStatus } from 'src/services/api/emos'

export type View = 'table' | 'card'

const defaultValues = {
    page: 0,
    rowsPerPage: 10,
    filters: undefined,
    sortField: undefined,
    view: 'table' as View
}

export type ViewTableState = TableState & {
    view: View
    setView: (view: View) => void
}

export const tableConfigs: StateCreator<ViewTableState> = (set, get) => ({
    // pagination
    ...defaultValues,
    setPage: (page) => set({ page }),
    setRowsPerPage: (rowsPerPage) => set({ rowsPerPage }),
    setFilter: (filter) => {
        const { key, value } = filter
        set((state) => {
            const filters = { ...state.filters }
            if (value) {
                filters[key] = value
            } else {
                delete filters[key]
            }
            return { filters, page: 0 }
        })
    },
    setFilters: (filters) => set({ filters, page: 0 }),
    setSortField: (sortField) => set({ sortField }),
    reset: () => set(defaultValues),
    setView: (view) => set({ view })
})

export const useUsersTableState = create<ViewTableState, [['zustand/persist', ViewTableState]]>(
    persist(tableConfigs, {
        name: name + '/users-table-state',
        storage: createJSONStorage(() => sessionStorage)
    })
)

export const usePartiesTableState = create<ViewTableState, [['zustand/persist', ViewTableState]]>(
    persist(tableConfigs, {
        name: name + '/parties-table-state',
        storage: createJSONStorage(() => sessionStorage)
    })
)

export const useCandidatesTableState = create<ViewTableState, [['zustand/persist', ViewTableState]]>(
    persist(tableConfigs, {
        name: name + '/candidates-table-state',
        storage: createJSONStorage(() => sessionStorage)
    })
)

export const useCirclesTableState = create<ViewTableState, [['zustand/persist', ViewTableState]]>(
    persist(tableConfigs, {
        name: name + '/circles-table-state',
        storage: createJSONStorage(() => sessionStorage)
    })
)

const incidentsDefaultValues = {
    page: 0,
    rowsPerPage: 10,
    filters: {
        status: IncidentStatus.OPEN
    },
    sortField: undefined,
    view: 'table' as View
}

export const incidentsTableConfigs: StateCreator<ViewTableState> = (set, get) => ({
    // pagination
    ...incidentsDefaultValues,
    setPage: (page) => set({ page }),
    setRowsPerPage: (rowsPerPage) => set({ rowsPerPage }),
    setFilter: (filter) => {
        const { key, value } = filter
        set((state) => {
            const filters = { ...state.filters }
            if (value) {
                filters[key] = value
            } else {
                delete filters[key]
            }
            return { filters, page: 0 }
        })
    },
    setFilters: (filters) => set({ filters, page: 0 }),
    setSortField: (sortField) => set({ sortField }),
    reset: () => set(defaultValues),
    setView: (view) => set({ view })
})

export const useIncidentsTableState = create<ViewTableState, [['zustand/persist', ViewTableState]]>(
    persist(incidentsTableConfigs, {
        name: name + '/incidents-table-state',
        storage: createJSONStorage(() => sessionStorage)
    })
)

export const useStationsTableState = create<ViewTableState, [['zustand/persist', ViewTableState]]>(
    persist(tableConfigs, {
        name: name + '/stations-table-state',
        storage: createJSONStorage(() => sessionStorage)
    })
)

export const useViolationsTableState = create<ViewTableState, [['zustand/persist', ViewTableState]]>(
    persist(tableConfigs, {
        name: name + '/violations-table-state',
        storage: createJSONStorage(() => sessionStorage)
    })
)

export const useVotingResultsTableState = create<ViewTableState, [['zustand/persist', ViewTableState]]>(
    persist(tableConfigs, {
        name: name + '/voting-results-table-state',
        storage: createJSONStorage(() => sessionStorage)
    })
)

export const usePollingCentersTableState = create<ViewTableState, [['zustand/persist', ViewTableState]]>(
    persist(tableConfigs, {
        name: name + '/polling-centers-table-state',
        storage: createJSONStorage(() => sessionStorage)
    })
)
