import { AdvancedFilters, AdvancedTable } from '@ditkrg/react-core'
import { Paper } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { ElectionType, NodeType, StationsService } from 'src/services/api/emos'
import { useStationsTableState } from 'src/store/table'
import GenerateColumns from './columns'
import { useTranslation } from 'react-i18next'
import React, { useMemo } from 'react'
import { useRouter } from 'src/hooks/use-router'
import { paths } from 'src/paths'
import CardList from './card-list'
import { useSettings } from 'src/store/settings'
import { useNodeFilters } from 'src/hooks/use-node-filters'
import { useRegion } from 'src/hooks/use-region'
import { useAuthChecks } from 'src/hooks/use-auth-checks'
import { useAuth } from 'src/hooks/use-auth'

const Results = () => {
    const { t } = useTranslation()
    const router = useRouter()
    const stationsTableState = useStationsTableState()
    const { page, rowsPerPage, filters } = stationsTableState
    const { language } = useSettings()
    const { user } = useAuth()

    const { isPCTypeAndHasPCSRole } = useAuthChecks()

    const { filterInputs } = useNodeFilters({
        state: stationsTableState
    })

    const { columns } = useRegion({
        tableState: stationsTableState
    })

    const regionFilterInputs = useMemo(
        (): any[] =>
            columns.map((column) => ({
                id: column.id,
                header: column.header,
                ...column.filter
            })),
        [columns]
    )

    const {
        data: stations,
        isLoading,
        isFetching
    } = useQuery({
        queryKey: ['stations', rowsPerPage, page, filters],
        queryFn: async () => {
            const res = await StationsService.listStations({
                page: page + 1,
                rowsPerPage,
                ancestorId:
                    filters?.[NodeType.POLLING_CENTER] ||
                    filters?.[NodeType.SUB_BRANCH] ||
                    filters?.[NodeType.BRANCH] ||
                    filters?.[NodeType.PBS] ||
                    undefined,
                isInitiated:
                    filters?.isInitiated === 'isInitiated'
                        ? true
                        : filters?.isInitiated === 'isNotInitiated'
                          ? false
                          : undefined,
                hasIncidents: filters?.hasIncidents,
                electionType: filters?.electionType,
                provinceId: filters?.provinceId,
                districtId: filters?.districtId,
                subDistrictId: filters?.subDistrictId
            })
            return res
        },
        placeholderData: (placeholderData) => placeholderData
    })

    return (
        <>
            {stationsTableState.view === 'table' ? (
                <Paper variant="outlined">
                    <AdvancedFilters
                        state={stationsTableState}
                        filters={[
                            ...filterInputs,
                            ...regionFilterInputs,
                            {
                                hidden: isPCTypeAndHasPCSRole || user?.electionTypes?.length === 1,
                                id: 'electionType',
                                type: 'select',
                                header: t('model.user.electionTypes.label'),
                                props: {
                                    options: Object.values(ElectionType).map((value) => ({
                                        label: t(`model.user.electionTypes.options.${value}` as any),
                                        value
                                    }))
                                }
                            },
                            {
                                id: 'isInitiated',
                                type: 'autocomplete',
                                header: t('model.station.initiationStatus'),
                                props: {
                                    options: [
                                        { label: t('model.station.isInitiated'), value: 'isInitiated' },
                                        { label: t('model.station.isNotInitiated'), value: 'isNotInitiated' }
                                    ]
                                }
                            },
                            {
                                id: 'hasIncidents',
                                type: 'checkbox',
                                header: t('model.station.states.NonOperational')
                            }
                        ]}
                        showCollapseButton={false}
                        locale={language as any}
                    />

                    <AdvancedTable
                        data={stations?.data ?? []}
                        columns={GenerateColumns({ t, tableState: stationsTableState })}
                        state={stationsTableState}
                        totalItems={stations?.meta?.pagination?.count ?? 0}
                        onRowClick={(row) => {
                            router.push(paths.stations.view(row.id))
                        }}
                        loading={isLoading || isFetching}
                        loadingVariant={isFetching ? 'linear' : 'skeleton'}
                        filterLabel={t('view.common.filter.label')}
                        clearLabel={t('view.common.clear')}
                        paginationVariant="advanced"
                        locale={language as any}
                    />
                </Paper>
            ) : (
                <CardList
                    stations={stations}
                    filters={[
                        ...filterInputs,
                        ...regionFilterInputs,
                        {
                            hidden: isPCTypeAndHasPCSRole || user?.electionTypes?.length === 1,
                            id: 'electionType',
                            type: 'select',
                            header: t('model.user.electionTypes.label'),
                            props: {
                                options: Object.values(ElectionType).map((value) => ({
                                    label: t(`model.user.electionTypes.options.${value}` as any),
                                    value
                                }))
                            }
                        },
                        {
                            id: 'isInitiated',
                            type: 'checkbox',
                            header: t('model.station.isInitiated')
                        },
                        {
                            id: 'hasIncidents',
                            type: 'checkbox',
                            header: t('model.station.states.NonOperational')
                        }
                    ]}
                />
            )}
        </>
    )
}

export default Results
