import { Column } from '@ditkrg/react-core'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useProvinces } from './use-provinces'
import { useDistricts } from './use-districts'
import { useSubDistricts } from './use-sub-districts'
import { ViewTableState } from 'src/store/table'
import { ReportsState } from 'src/store/reports'
import { FieldProps } from 'mui-rhf-library'
import { UseFormReturn } from 'react-hook-form'
import { useAuth } from './use-auth'
import { NodeType } from 'src/services/api/emos'

export const useRegion = ({
    tableState,
    formConfigs
}: {
    tableState: ViewTableState | ReportsState
    formConfigs?: UseFormReturn<any>
}) => {
    const { t } = useTranslation()
    const { user } = useAuth()
    const { filters } = tableState
    const { provinces, provincesArray } = useProvinces()
    const { districts, districtsArray } = useDistricts()
    const { subDistricts, subDistrictsArray } = useSubDistricts()
    const isEMRNode = user?.node?.type === NodeType.EMR

    const provinceFilter = filters?.provinceId
    const districtFilter = filters?.districtId

    const provinceWatcher = formConfigs?.watch('provinceId')
    const districtWatcher = formConfigs?.watch('districtId')

    const districtOptions = useMemo(() => {
        if (provinceFilter || provinceWatcher) {
            return districtsArray.filter(
                (district) => district.provinceId === provinceFilter || district.provinceId === provinceWatcher
            )
        }
        return districtsArray
    }, [districtsArray, provinceFilter, provinceWatcher])

    const subDistrictOptions = useMemo(() => {
        if (districtFilter || districtWatcher) {
            return subDistrictsArray.filter(
                (subDistrict) => subDistrict.districtId === districtFilter || subDistrict.districtId === districtWatcher
            )
        } else if (provinceFilter || provinceFilter) {
            return subDistrictsArray.filter(
                (subDistrict) => subDistrict.provinceId === provinceFilter || subDistrict.provinceId === provinceWatcher
            )
        }
        return subDistrictsArray
    }, [districtFilter, districtWatcher, provinceFilter, provinceWatcher, subDistrictsArray])

    const columns = useMemo(
        (): Column[] => [
            {
                id: 'provinceId',
                header: t('model.node.province'),
                cell: (row) => provinces[row.provinceId],
                filter: {
                    hidden: !isEMRNode,
                    type: 'autocomplete',
                    props: {
                        optionLabel: 'name',
                        optionValue: 'id',
                        options: provincesArray
                    },
                    onFilterChange({ filtersState, sync }) {
                        filtersState?.setFilter('districtId', null)
                        filtersState?.setFilter('subDistrictId', null)
                        sync?.()
                    }
                }
            },
            {
                id: 'districtId',
                header: t('model.node.district'),
                cell: (row) => districts[row.districtId],
                filter: {
                    hidden: !isEMRNode,
                    type: 'autocomplete',
                    props: {
                        options: districtOptions,
                        optionLabel: 'name',
                        optionValue: 'id'
                    },
                    onFilterChange({ filtersState, sync }) {
                        filtersState?.setFilter('subDistrictId', null)
                        sync?.()
                    }
                }
            },
            {
                id: 'subDistrictId',
                header: t('model.node.subDistrict'),
                cell: (row) => subDistricts[row.subDistrictId],
                filter: {
                    hidden: !isEMRNode,
                    type: 'autocomplete',
                    props: {
                        options: subDistrictOptions,
                        optionLabel: 'name',
                        optionValue: 'id'
                    }
                }
            }
        ],
        [t, isEMRNode, provincesArray, districtOptions, subDistrictOptions, provinces, districts, subDistricts]
    )

    const fields = useMemo(
        (): FieldProps[] => [
            {
                name: 'provinceId',
                label: t('model.node.province'),
                fieldType: 'autocomplete',
                props: {
                    fullWidth: true,
                    options: provincesArray,
                    optionLabel: 'name',
                    optionValue: 'id',
                    onChange: () => {
                        formConfigs?.setValue('districtId', null)
                        formConfigs?.setValue('subDistrictId', null)
                    }
                },
                gridProps: { xs: 12 },
                textFieldProps: {
                    label: t('model.node.province'),
                    fullWidth: true
                }
            },
            {
                name: 'districtId',
                label: t('model.node.district'),
                fieldType: 'autocomplete',
                props: {
                    fullWidth: true,
                    options: districtOptions,
                    optionLabel: 'name',
                    optionValue: 'id',
                    onChange: () => {
                        formConfigs?.setValue('subDistrictId', null)
                    }
                },
                gridProps: { xs: 12 },
                textFieldProps: {
                    label: t('model.node.district'),
                    fullWidth: true
                }
            },
            {
                name: 'subDistrictId',
                label: t('model.node.subDistrict'),
                fieldType: 'autocomplete',
                props: {
                    fullWidth: true,
                    options: subDistrictOptions,
                    optionLabel: 'name',
                    optionValue: 'id'
                },
                gridProps: { xs: 12 },
                textFieldProps: {
                    label: t('model.node.subDistrict'),
                    fullWidth: true
                }
            }
        ],
        [districtOptions, formConfigs, provincesArray, subDistrictOptions, t]
    )

    return { columns, fields }
}
