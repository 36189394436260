import { Column } from '@ditkrg/react-core'
import { TFunction } from 'i18next'

const GenerateColumns = ({ tableState, t }: { tableState: any; t: TFunction }) => {
    const cols: Column[] = [
        {
            id: 'index',
            header: '#',
            cell: (_, rowIndex) => tableState.rowsPerPage * tableState.page + rowIndex + 1
        },
        {
            id: 'name',
            header: t('model.common.name'),
            accessor: 'name'
        },
        {
            id: 'majoritySeats',
            header: t('model.circle.majoritySeats'),
            accessor: 'majoritySeats'
        },
        {
            id: 'minoritySeats',
            header: t('model.circle.minoritySeats'),
            accessor: 'minoritySeats'
        }
    ]
    return cols
}

export default GenerateColumns
