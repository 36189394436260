import { Notifications } from '../en/notifications';

export const notifications: Notifications = {
    access: {
        '403': {
            label: 'داوای لێبوردن ئەکەین...',
            subtitle: 'ڕێپێدانی تەواوت نیە بۆ بینینی ئەم پەڕەیە.',
        },
    },
};
