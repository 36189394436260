import styled from '@emotion/styled'
import { Avatar, Card, Chip, Typography } from '@mui/material'
import { Box, Stack } from '@mui/system'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { GridComponents, Virtuoso } from 'react-virtuoso'
import { useError } from 'src/contexts/count-errors'
import { useToast } from 'src/hooks/useToast'
import {
    DetailedVotingResultResponse,
    MinimalPartyResponse,
    PartyType,
    VotingResultsService
} from 'src/services/api/emos'
import TextFieldCountInput from './components/text-field-count-input'
import { useVirtualItemsKey } from 'src/contexts/virtual-items-key'
import { useVotingResult } from 'src/hooks/use-voting-results'

export const ItemContainer = styled.div`
    box-sizing: border-box;
    padding: 10px;
    width: 100%;
    // background: #f5f5f5;
    display: flex;
    flex: none;
    align-content: stretch;
`

export const ListContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
` as GridComponents['List']

export const ItemWrapper = styled.div`
    flex: 1;
  }
`

export const Party = React.memo<{
    votingResult: DetailedVotingResultResponse
    party: MinimalPartyResponse
    handleOnClick: (party: MinimalPartyResponse) => void
    value: string | null | undefined
    virtuoso: React.MutableRefObject<any>
    index: number
}>(
    ({
        votingResult,
        party,
        handleOnClick,
        value,
        virtuoso,
        index
    }: {
        votingResult: DetailedVotingResultResponse
        party: MinimalPartyResponse
        handleOnClick: (party: MinimalPartyResponse) => void
        value: string | null | undefined
        virtuoso: React.MutableRefObject<any>
        index: number
    }) => {
        const { t } = useTranslation()
        const { errorsToast } = useToast()
        const { setError, errors } = useError()
        const queryClient = useQueryClient()
        const { canEdit } = useVotingResult({ votingResult })

        const partyInVotingResult = votingResult.parties.find((p) => p.id === party.id)

        const [version, setVersion] = React.useState<number>(partyInVotingResult?.version ?? 0)
        const [count, setCount] = React.useState<number | undefined>(partyInVotingResult?.total ?? undefined)

        const isActive = value === party.id
        const { incrementKey } = useVirtualItemsKey()

        const { mutate, isPending, isError } = useMutation({
            mutationKey: ['updatePartyCount'],
            mutationFn: async (dataCount: number) => {
                await VotingResultsService.updatePartyCount({
                    id: votingResult.id,
                    requestBody: {
                        count: dataCount,
                        id: party.id,
                        version
                    }
                })
                return dataCount
            },
            onSuccess: (dataCount) => {
                setVersion(version + 1)
                setCount(dataCount)
                queryClient.setQueryData(['votingResult', votingResult.id], (oldData: DetailedVotingResultResponse) => {
                    const existentParty = oldData.parties.find((p) => p.id === party.id)
                    if (!existentParty) {
                        oldData.parties.push({
                            id: party.id,
                            total: dataCount,
                            partyVotes: dataCount,
                            version: version + 1,
                            candidateVotes: 0
                        })
                    } else {
                        const delta = dataCount - existentParty.total
                        existentParty.total = dataCount
                        existentParty.partyVotes += delta
                        existentParty.version = version + 1
                    }

                    return oldData
                })
                if (errors.find((error) => error.id === party.id)) setError(party.id, false)
                if (dataCount === 0) {
                    incrementKey()
                }
            },
            onError: (error) => {
                errorsToast(error)
                setError(party.id, true)
            }
        })

        return (
            <Card
                key={party.id}
                variant="outlined"
                onClick={() => {
                    handleOnClick(party)
                }}
                sx={{
                    height: 160,
                    boxShadow: isActive ? '0 0 0 2px #0002FF' : 'none',
                    '&:hover': {
                        cursor: 'pointer',
                        boxShadow: !isActive ? '0 0 0 2px #e6e6ff' : '0 0 0 2px #0002FF'
                    }
                }}
            >
                <Box
                    sx={{
                        width: '100%',
                        height: 11,
                        backgroundColor: party.color
                    }}
                />
                <Box sx={{ p: 2 }}>
                    <Stack spacing={2}>
                        <Stack direction="row" justifyContent="space-between">
                            <Stack direction="row" spacing={2} alignItems="center">
                                <Avatar src={party.photo?.url ?? ''} />
                                <Stack spacing={0.5}>
                                    <Typography
                                        variant="subtitle2"
                                        sx={{
                                            userSelect: 'none'
                                        }}
                                    >
                                        {party.number}
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        sx={{
                                            userSelect: 'none'
                                        }}
                                    >
                                        {party.name}
                                    </Typography>
                                </Stack>
                            </Stack>
                            <Stack direction="row" spacing={1}>
                                {party.partyType !== PartyType.INDEPENDENT && partyInVotingResult?.total && (
                                    <Chip size="small" label={partyInVotingResult?.partyVotes} />
                                )}
                                {party.partyType !== PartyType.INDEPENDENT && partyInVotingResult?.total && (
                                    <Chip size="small" label={partyInVotingResult?.candidateVotes} />
                                )}
                            </Stack>
                        </Stack>

                        <TextFieldCountInput
                            disabled={!canEdit}
                            defaultValue={count}
                            isPending={isPending}
                            isError={isError}
                            label={t('model.party.partyCandidateCount')}
                            onChange={(count) => {
                                mutate(count)
                            }}
                            onFocus={() => {
                                handleOnClick(party)
                                virtuoso.current.scrollToIndex({
                                    index: index,
                                    align: 'center',
                                    behavior: 'smooth'
                                })
                                return false
                            }}
                        />
                    </Stack>
                </Box>
            </Card>
        )
    }
)
Party.displayName = 'Party'

export const PartiesVirtuosoGrid = ({
    votingResult,
    parties,
    handleOnClick,
    value
}: {
    votingResult: DetailedVotingResultResponse
    parties: MinimalPartyResponse[]
    handleOnClick: (party: MinimalPartyResponse) => void
    value: string | null | undefined
}) => {
    const virtuoso = useRef(null)

    const { key } = useVirtualItemsKey()

    const itemContent = React.useCallback(
        (index: number, _, { virtuoso }) => (
            <ItemWrapper>
                <Party
                    virtuoso={virtuoso}
                    key={key}
                    votingResult={votingResult}
                    party={parties[index]}
                    value={value}
                    handleOnClick={(party) => {
                        if (!party) return
                        handleOnClick(party)
                    }}
                    index={index}
                />
            </ItemWrapper>
        ),
        [handleOnClick, key, parties, value, votingResult]
    )

    return (
        <Virtuoso
            ref={virtuoso}
            style={{ height: 800 }}
            data={parties}
            context={{ virtuoso }}
            components={{
                Item: ItemContainer,
                List: ListContainer
            }}
            overscan={10}
            increaseViewportBy={10}
            itemContent={itemContent}
        />
    )
}
