import { NodeType } from 'src/services/api/emos'
import { useAuth } from './use-auth'
import { useMemo } from 'react'

export const useAuthChecks = () => {
    const { user } = useAuth()

    const isPCType = useMemo(() => user?.node?.type === NodeType.POLLING_CENTER, [user])

    const hasPCSRole = useMemo(() => user?.roles?.includes('pc_sv'), [user])

    return {
        isPCTypeAndHasPCSRole: isPCType && hasPCSRole,
        isPCType,
        hasPCSRole
    }
}
