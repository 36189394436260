import { useQuery } from '@tanstack/react-query'
import { FieldProps } from 'mui-rhf-library'
import { useMemo } from 'react'
import { UseFormReturn } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import ColorPicker from 'src/components/input/color-picker'
import UploadForm from 'src/components/upload/upload-form'
import { allCirclesQuery } from 'src/queries/circles'
import { PartyType, SeatType } from 'src/services/api/emos'

export const useParties = (formConfigs: UseFormReturn<any>) => {
    const { t } = useTranslation()

    const colorWatcher = formConfigs.watch('color')
    const colorError = formConfigs.formState.errors?.color?.message

    const { data: circles = [], isLoading: isLoadingCircles } = useQuery({
        ...allCirclesQuery()
    })

    const fields = useMemo(
        (): FieldProps[] => [
            {
                name: 'name',
                label: t('model.common.name'),
                fieldType: 'textField',
                props: { fullWidth: true },
                gridProps: { xs: 12 }
            },
            {
                name: 'number',
                label: t('model.common.number'),
                fieldType: 'textField',
                props: { fullWidth: true },
                gridProps: { xs: 12 }
            },
            {
                name: 'color',
                label: 'color',
                fieldType: 'custom',
                CustomComponent: ColorPicker,
                props: {
                    fullWidth: true,
                    color: colorWatcher,
                    onChange: (value) =>
                        formConfigs.setValue('color', value, { shouldValidate: true, shouldDirty: true }),
                    error: colorError
                },
                gridProps: { xs: 12 }
            },
            {
                name: 'photo',
                fieldType: 'custom',
                CustomComponent: UploadForm,
                props: {
                    header: t('model.common.photo'),
                    name: 'photo',
                    formConfigs,
                    maxFiles: 1
                }
            },
            {
                name: 'partyType',
                label: t('model.party.partyType.label'),
                fieldType: 'select',
                props: {
                    fullWidth: true,
                    options: Object.values(PartyType).map((value) => ({
                        value,
                        label: t(`model.party.partyType.options.${value}`)
                    }))
                },
                gridProps: { xs: 12 }
            },
            {
                name: 'seatType',
                label: t('model.party.seatType.label'),
                fieldType: 'select',
                props: {
                    fullWidth: true,
                    options: Object.values(SeatType).map((value) => ({
                        value,
                        label: t(`model.party.seatType.options.${value}`)
                    }))
                },
                gridProps: { xs: 12 }
            },
            {
                name: 'circleIds',
                label: t('view.circle.name.headline'),
                fieldType: 'autocomplete',
                props: {
                    multiple: true,
                    fullWidth: true,
                    options: circles,
                    optionLabel: 'name',
                    optionValue: 'id',
                    loading: isLoadingCircles
                },
                gridProps: { xs: 12 },
                textFieldProps: {
                    label: t('view.circle.name.headline'),
                    fullWidth: true
                }
            },
            {
                name: 'isActive',
                label: t('model.common.isActive'),
                fieldType: 'switch',
                gridProps: { xs: 12 }
            }
        ],
        [circles, colorError, colorWatcher, formConfigs, isLoadingCircles, t]
    )

    return { fields }
}
