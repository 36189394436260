/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreatePartyRequest } from '../models/CreatePartyRequest';
import type { DetailedPartyResponse } from '../models/DetailedPartyResponse';
import type { IdResponse } from '../models/IdResponse';
import type { MinimalPartyResponse } from '../models/MinimalPartyResponse';
import type { PagedPartyResponseListResponse } from '../models/PagedPartyResponseListResponse';
import type { PartyType } from '../models/PartyType';
import type { UpdatePartyRequest } from '../models/UpdatePartyRequest';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class PartiesService {
    /**
     * @returns PagedPartyResponseListResponse OK
     * @throws ApiError
     */
    public static listParties({
        page,
        rowsPerPage,
        name,
        number,
        circleId,
        partyType,
        sortAscending,
    }: {
        page: number,
        rowsPerPage: number,
        name?: string,
        number?: number,
        circleId?: string,
        partyType?: PartyType,
        sortAscending?: boolean,
    }): CancelablePromise<PagedPartyResponseListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/parties',
            query: {
                'Page': page,
                'Name': name,
                'Number': number,
                'CircleId': circleId,
                'PartyType': partyType,
                'SortAscending': sortAscending,
                'RowsPerPage': rowsPerPage,
            },
        });
    }
    /**
     * @returns IdResponse Created
     * @throws ApiError
     */
    public static createParty({
        requestBody,
    }: {
        requestBody: CreatePartyRequest,
    }): CancelablePromise<IdResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/parties',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @returns MinimalPartyResponse OK
     * @throws ApiError
     */
    public static listAllParties({
        isActive,
        circleId,
        partyType,
    }: {
        isActive?: boolean,
        circleId?: string,
        partyType?: PartyType,
    }): CancelablePromise<Array<MinimalPartyResponse>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/parties/all',
            query: {
                'IsActive': isActive,
                'CircleId': circleId,
                'PartyType': partyType,
            },
        });
    }
    /**
     * @returns void
     * @throws ApiError
     */
    public static updateParty({
        id,
        requestBody,
    }: {
        id: string,
        requestBody: UpdatePartyRequest,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/parties/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @returns DetailedPartyResponse OK
     * @throws ApiError
     */
    public static getPartyById({
        id,
    }: {
        id: string,
    }): CancelablePromise<DetailedPartyResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/parties/{id}',
            path: {
                'id': id,
            },
        });
    }
}
