import React from 'react';
import { styled, alpha } from '@mui/material/styles';

const LabelWrapper = styled('span')(
    ({ theme }) => `
      padding: ${theme.spacing(0.5, 1)};
      font-size: ${theme.typography.pxToRem(13)};
      display: inline-flex;
      align-items: center;
      justify-content: center;
      max-height: ${theme.spacing(3)};
      
      &.MuiLabel {
        &-primary {
          color: ${theme.palette.primary.main};
          background-color: ${alpha(theme.palette.primary.main, 0.08)}

        }
        
        &-secondary {
          color: ${theme.palette.secondary.main};
          background-color: ${alpha(theme.palette.secondary.main, 0.08)}

        }
        
        &-success {
          color: ${theme.palette.success.main};
          background-color: ${alpha(theme.palette.success.main, 0.08)}
        }
        
        &-warning {
          color: ${theme.palette.warning.main};
          background-color: ${alpha(theme.palette.warning.main, 0.08)}

        }
              
        &-error {
          color: ${theme.palette.error.main};
          background-color: ${alpha(theme.palette.error.main, 0.08)}

        }
        
        &-info {
          color: ${theme.palette.info.main};
          background-color: ${alpha(theme.palette.info.main, 0.08)}

        }
      }
`
);

const Label = ({
    className = '',
    color = 'secondary',
    children,
    ...rest
}: {
    className?: string;
    color: 'primary' | 'secondary' | 'error' | 'warning' | 'success' | 'info';
    children: any;
}) => {
    return (
        <LabelWrapper
            className={'MuiLabel-' + color}
            {...rest}
        >
            {children}
        </LabelWrapper>
    );
};

export default Label;
