import { Card, Grid } from '@mui/material'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useToast } from 'src/hooks/useToast'
import { DetailedStationResponse, DetailedVotingResultResponse, StationsService } from 'src/services/api/emos'
import TextFieldCountInput from './text-field-count-input'
import { useVotingResult } from 'src/hooks/use-voting-results'
import { Box } from '@mui/system'
import VersionMismatchDialog from './version-mismatch-dialog'
import { useVirtualItemsKey } from 'src/contexts/virtual-items-key'

const StationInvalidPapers = ({ votingResult }: { votingResult: DetailedVotingResultResponse }) => {
    const { t } = useTranslation()
    const { errorsToast } = useToast()
    const [invalidPapers, setInvalidPapers] = useState<number | undefined>(
        votingResult.stationInvalidPapers ?? undefined
    )
    const [previousInvalidPapers, setPreviousInvalidPapers] = useState<number | null>(votingResult.stationInvalidPapers)

    const { canEdit } = useVotingResult({ votingResult })

    const [openVersionMismatchDialog, setOpenVersionMismatchDialog] = useState(false)
    const { key, incrementKey } = useVirtualItemsKey()

    const { mutate, isPending, isError } = useMutation({
        mutationKey: ['updateStationInvalidPapers'],
        mutationFn: async (data: { invalidPapers: number }) => {
            await StationsService.updateStationInvalidPapers({
                id: votingResult.stationId,
                requestBody: {
                    invalidPapers: data.invalidPapers,
                    formType: votingResult.formType,
                    previousInvalidPapers: previousInvalidPapers ?? null
                }
            })
            return data
        },
        onSuccess: (resData) => {
            console.log({ resData })
            setPreviousInvalidPapers(resData.invalidPapers)
            if ('invalidPapers' in resData) {
                setInvalidPapers(resData.invalidPapers)

                if (resData.invalidPapers === 0) {
                    incrementKey()
                }
            }
        },
        onError: (error: any) => {
            errorsToast(error)
            if (error.statusText === 'Conflict') {
                setOpenVersionMismatchDialog(true)
            }
        }
    })

    if (!votingResult) return null

    return (
        <Grid item xs={12}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Card variant="outlined">
                        <Box
                            sx={{
                                width: '100%',
                                height: 11,
                                backgroundColor: 'red'
                            }}
                        />
                        <Box sx={{ py: 3, px: 2 }}>
                            <TextFieldCountInput
                                key={key}
                                disabled={!canEdit}
                                label={t('model.votingResult.invalidPapers')}
                                defaultValue={invalidPapers}
                                isPending={isPending}
                                isError={isError}
                                width="100%"
                                onChange={(value) => {
                                    mutate({
                                        invalidPapers: value
                                    })
                                }}
                                // endAdornment={<FieldInputAdornment />}
                            />
                        </Box>
                    </Card>
                </Grid>
            </Grid>
            <VersionMismatchDialog open={openVersionMismatchDialog} />
        </Grid>
    )
}

export default StationInvalidPapers
