import { Lightbox } from 'react-modal-image'

interface Props {
    small?: string
    large: string | undefined | null
    alt: string
    open: boolean
    onClose: () => void
    [key: string]: any
}

const ModalImage = ({ small, large, alt, open, onClose, ...rest }: Props) => {
    return open ? (
        <Lightbox large={large} small={small} alt={alt} onClose={onClose} {...rest} imageBackgroundColor="white" />
    ) : null
}

export default ModalImage
