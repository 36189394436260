/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CountVoteRequest } from '../models/CountVoteRequest';
import type { CreateVotingResultsRequest } from '../models/CreateVotingResultsRequest';
import type { DetailedVotingResultResponse } from '../models/DetailedVotingResultResponse';
import type { IdResponse } from '../models/IdResponse';
import type { PossibleVotingResultResponse } from '../models/PossibleVotingResultResponse';
import type { UpdateVotingResultAttachmentRequest } from '../models/UpdateVotingResultAttachmentRequest';
import type { UpdateVotingResultStateRequest } from '../models/UpdateVotingResultStateRequest';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class VotingResultsService {
    /**
     * @returns void
     * @throws ApiError
     */
    public static updatePartyCount({
        id,
        requestBody,
    }: {
        id: string,
        requestBody: CountVoteRequest,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/v1/voting-results/{id}/count-party',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @returns void
     * @throws ApiError
     */
    public static updateCandidateCount({
        id,
        requestBody,
    }: {
        id: string,
        requestBody: CountVoteRequest,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/v1/voting-results/{id}/count-candidate',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @returns DetailedVotingResultResponse OK
     * @throws ApiError
     */
    public static getVotingResultById({
        id,
    }: {
        id: string,
    }): CancelablePromise<DetailedVotingResultResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/voting-results/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * @returns void
     * @throws ApiError
     */
    public static updateVotingResultAttachment({
        id,
        requestBody,
    }: {
        id: string,
        requestBody: UpdateVotingResultAttachmentRequest,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/v1/voting-results/{id}/attachment',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @returns void
     * @throws ApiError
     */
    public static updateVotingResultState({
        id,
        requestBody,
    }: {
        id: string,
        requestBody: UpdateVotingResultStateRequest,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/v1/voting-results/{id}/state',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @returns PossibleVotingResultResponse OK
     * @throws ApiError
     */
    public static listVotingResults({
        stationId,
    }: {
        stationId: string,
    }): CancelablePromise<Array<PossibleVotingResultResponse>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/voting-results',
            query: {
                'stationId': stationId,
            },
        });
    }
    /**
     * @returns IdResponse Created
     * @throws ApiError
     */
    public static createVotingResults({
        requestBody,
    }: {
        requestBody: CreateVotingResultsRequest,
    }): CancelablePromise<IdResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/voting-results',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
