import type { FC } from 'react'

import Mail04Icon from '@untitled-ui/icons-react/build/esm/Mail04'
import XIcon from '@untitled-ui/icons-react/build/esm/X'
import Avatar from '@mui/material/Avatar'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import ListItemText from '@mui/material/ListItemText'
import Popover from '@mui/material/Popover'
import Stack from '@mui/material/Stack'
import SvgIcon from '@mui/material/SvgIcon'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'

import { Scrollbar } from 'src/components/scrollbar'
import { Notification, NotificationType } from 'src/services/api/emos'
import React, { useEffect } from 'react'
import { Inbox, MessageCircleMore } from 'lucide-react'
import dateFormat from 'src/utils/date-format'
import { InfiniteData, UseInfiniteQueryResult } from '@tanstack/react-query'
import { CircularProgress } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useInView } from 'react-intersection-observer'
import { useRouter } from 'src/hooks/use-router'
import { paths } from 'src/paths'

const RenderContent = ({ notification }: { notification: Notification }): JSX.Element | null => {
    const { t } = useTranslation()

    const renderNotificationTitle = (notification: Notification) => {
        return `${t('view.station.name.headline')}: ${notification.data.sn} - ${notification.data.pc}`
    }

    const renderNotificationDescription = (notification: Notification) => {
        if (notification.type === NotificationType.INCIDENT_STATE_CHANGED) {
            notification.data['to'] = t(`model.incident.states.${notification.data['from']}` as any)
        }
        return t(`view.notification.${notification.type}.title`, notification.data as any)
    }

    return (
        <>
            <ListItemAvatar sx={{ mt: 0.5 }}>
                <Avatar sx={{ color: 'background.paper' }}>
                    <MessageCircleMore fontSize="small" />
                </Avatar>
            </ListItemAvatar>
            <ListItemText
                primary={
                    <Box
                        sx={{
                            alignItems: 'center',
                            display: 'flex',
                            flexWrap: 'wrap'
                        }}
                    >
                        <Stack>
                            <Typography sx={{ mr: 0.5 }} variant="subtitle2">
                                {renderNotificationTitle(notification)}
                            </Typography>
                        </Stack>
                    </Box>
                }
                secondary={
                    <Stack>
                        <Typography color="text.secondary" variant="body2">
                            {renderNotificationDescription(notification)}
                        </Typography>
                        <Typography color="text.secondary" variant="caption">
                            {dateFormat({
                                date: notification.createdAt
                            })}
                        </Typography>
                    </Stack>
                }
                sx={{ my: 0 }}
            />
        </>
    )
}

interface NotificationsPopoverProps {
    anchorEl: null | Element
    onClose?: () => void
    onMarkAllAsRead?: () => void
    handleRead: (id: string) => void
    open?: boolean
    infiniteQuery: UseInfiniteQueryResult<InfiniteData<Notification[] | undefined, unknown>, Error>
}

export const NotificationsPopover: FC<NotificationsPopoverProps> = (props) => {
    const { t } = useTranslation()
    const { ref, inView } = useInView()
    const router = useRouter()

    const { anchorEl, onClose, onMarkAllAsRead, handleRead, open = false, infiniteQuery, ...other } = props
    const { data: notifications, fetchNextPage, hasNextPage, isFetching } = infiniteQuery

    const isEmpty = false

    useEffect(() => {
        if (inView && hasNextPage) {
            fetchNextPage()
        }
    }, [inView, hasNextPage, fetchNextPage])

    const handleClickNotification = (notification: Notification) => {
        if (
            notification.type === NotificationType.INCIDENT_CREATED ||
            notification.type === NotificationType.INCIDENT_STATE_CHANGED
        ) {
            router.push(paths.incidents.view(notification.data.id))
        } else if (notification.type === NotificationType.STATION_VRS_DONE) {
            router.push(paths.stations.view(notification.data.id))
        }

        onClose?.()
    }

    return (
        <Popover
            anchorEl={anchorEl}
            anchorOrigin={{
                horizontal: 'left',
                vertical: 'bottom'
            }}
            disableScrollLock
            onClose={onClose}
            open={open}
            PaperProps={{ sx: { width: 380 } }}
            {...other}
        >
            <Stack
                alignItems="center"
                direction="row"
                justifyContent="space-between"
                spacing={2}
                sx={{
                    px: 3,
                    py: 2
                }}
            >
                <Typography color="inherit" variant="h6">
                    {t('view.notification.header')}
                </Typography>
                <Tooltip title={t('view.notification.unread.mark_read')}>
                    <IconButton onClick={onMarkAllAsRead} size="small" color="inherit">
                        <SvgIcon>
                            <Mail04Icon />
                        </SvgIcon>
                    </IconButton>
                </Tooltip>
            </Stack>
            {isEmpty ? (
                <Box sx={{ p: 2 }}>
                    <Typography variant="subtitle2">{t('view.notification.empty')}</Typography>
                </Box>
            ) : (
                <Scrollbar sx={{ maxHeight: 400 }}>
                    <List disablePadding>
                        {notifications?.pages?.map((page, index) => (
                            <React.Fragment key={index}>
                                {page?.map((notification: Notification) => (
                                    <ListItem
                                        onClick={(e) => {
                                            e.stopPropagation()
                                            if (!notification.readAt) {
                                                handleRead(notification.id ?? '')
                                                notification.readAt = new Date().toISOString()
                                            }
                                            handleClickNotification(notification)
                                        }}
                                        divider
                                        key={notification.id}
                                        sx={{
                                            alignItems: 'flex-start',
                                            cursor: 'pointer',
                                            '&:hover': {
                                                backgroundColor: 'divider'
                                            },
                                            '& .MuiListItemSecondaryAction-root': {
                                                top: '24%'
                                            },
                                            bgcolor: notification.readAt ? 'background.paper' : 'action.hover'
                                        }}
                                        secondaryAction={
                                            !notification.readAt ? (
                                                <Tooltip title={t('view.notification.unread.label')}>
                                                    <IconButton
                                                        edge="end"
                                                        onClick={(e) => {
                                                            e.stopPropagation()
                                                            if (!notification.readAt) {
                                                                handleRead(notification.id ?? '')
                                                                notification.readAt = new Date().toISOString()
                                                            }
                                                        }}
                                                        size="small"
                                                    >
                                                        <SvgIcon>
                                                            <XIcon />
                                                        </SvgIcon>
                                                    </IconButton>
                                                </Tooltip>
                                            ) : (
                                                <></>
                                            )
                                        }
                                    >
                                        <RenderContent notification={notification} />
                                    </ListItem>
                                ))}
                            </React.Fragment>
                        ))}
                        {notifications ? (
                            <Box display="flex" justifyContent="center">
                                {notifications?.pages?.length > 0 &&
                                notifications?.pages[0] &&
                                notifications?.pages[0]?.length > 0 ? (
                                    <Box ref={ref} py={1} display="flex" justifyContent="center">
                                        {hasNextPage ? (
                                            <CircularProgress size={25} />
                                        ) : (
                                            <Typography variant="subtitle2" textAlign="center">
                                                {t('view.notification.no_more')}
                                            </Typography>
                                        )}
                                    </Box>
                                ) : (
                                    !isFetching && (
                                        <Box display="flex" flexDirection="column" py={1} alignItems="center">
                                            <Inbox />
                                            <Typography variant="subtitle2" textAlign="center" color="text.secondary">
                                                {t('view.notification.empty')}
                                            </Typography>
                                        </Box>
                                    )
                                )}
                            </Box>
                        ) : (
                            <></>
                        )}
                    </List>
                </Scrollbar>
            )}
        </Popover>
    )
}
