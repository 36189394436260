import axios from 'axios';
//@ts-ignore
import normalizer from '@lysyi3m/json-api-normalizer';
import dayjs from 'dayjs';
import { wait } from 'src/utils/wait';

const apiUrlMapper = (url: string) => {
    // list all the api endpoints here
    if (url.includes('/api/auth')) return '/api/auth';
    else if (url.includes('/api/notifications')) return '/api/notifications';
    return '/api/auth';
};

export const setAxiosInterceptors = () => {
    axios.interceptors.response.use(
        async (response) => {
            const normalizedResponse = response.data.jsonapi
                ? await normalizer(response.data)
                : response.data;
            const responseType = response.data?.data?.type;
            const responseStatus = response.data?.data?.attributes?.status;

            if (
                response.status === 202 ||
                (responseType === 'async_request_responses' && responseStatus === 'pending')
            ) {
                // starts polling for the response of the async request
                const url = response.config.url;

                const location = normalizedResponse?.data?.location;

                const now = dayjs(new Date());
                const retryAt = dayjs(normalizedResponse?.data?.retryAt);

                const diff = retryAt.diff(now, 'milliseconds');

                await wait(diff > 0 ? diff : 500);

                return axios.get(`${apiUrlMapper(url as string)}${location}`);
            }

            return { ...response, data: normalizedResponse };
        },
        (error) => {
            return Promise.reject(error);
        }
    );

    axios.interceptors.request.use(
        (config) => {
            // send locale with every request
            // so that the server can use it to localize the response
            try {
                // @ts-ignore
                config.headers = {
                    ...config.headers,
                    'Accept-Language':
                        JSON.parse(window.localStorage.getItem('app.settings') || '{}')?.language ||
                        'ckb',
                };
            } catch (error) {
                config.params = {
                    ...config.params,
                    locale: 'en',
                };
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );
};

setAxiosInterceptors();
