import { useQueryClient } from '@tanstack/react-query'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Create from 'src/components/dashboard/circles/create'
import Results from 'src/components/dashboard/circles/results'
import MiniDrawer from 'src/components/drawer/mini-drawer'
import LayoutPage from 'src/components/page'
import { ACTIONS, RESOURCES, useCheckPermissions } from 'src/hooks/use-check-permissions'

const Circles = () => {
    const { t } = useTranslation()
    const queryClient = useQueryClient()
    const [createDialog, setCreateDialog] = useState(false)
    const { can } = useCheckPermissions()

    const closeDrawer = () => {
        setCreateDialog(false)
    }
    return (
        <LayoutPage
            title="circle"
            breadcrumbs={[
                {
                    title: t('view.circle.header')
                }
            ]}
            actions={[
                {
                    hidden: !can({
                        resource: RESOURCES.circle,
                        actions: [ACTIONS.create]
                    }),
                    label: t('view.circle.new.headline'),
                    onClick: () => setCreateDialog(true),
                    color: 'primary'
                }
            ]}
        >
            <Results />

            <MiniDrawer open={createDialog} close={closeDrawer} header={t('view.circle.new.headline')}>
                {createDialog && (
                    <Create
                        handleSuccess={() => {
                            closeDrawer()
                            queryClient.invalidateQueries({
                                queryKey: ['circles']
                            })
                        }}
                        handleClose={() => {
                            closeDrawer()
                        }}
                    />
                )}
            </MiniDrawer>
        </LayoutPage>
    )
}

export default Circles
