import SvgIcon from '@mui/material/SvgIcon';
import React from 'react';
import { useSettings } from 'src/store/settings';

interface DirectionIconProps {
    children: React.ReactNode;
    size?: 'small' | 'medium' | 'large';
}

export default function DirectionIcon({ children, size = 'medium' }: DirectionIconProps) {
    const { direction } = useSettings();
    return (
        <SvgIcon
            style={{
                transform: direction === 'rtl' ? 'scaleX(-1)' : 'scaleX(1)',
            }}
            fontSize={size}
        >
            {children}
        </SvgIcon>
    );
}
