import * as yup from 'yup';

const en: yup.LocaleObject = {
    mixed: {
        default: 'Field is invalid',
        required: 'Field is required',
        defined: 'Field is required',
        notType: function notType(_ref: any) {
            switch (_ref.type) {
                case 'number':
                    return 'Field must be a number';
                case 'string':
                    return 'Field must be a text';
                default:
                    return 'Field is invalid';
            }
        },
        oneOf: 'Selection is invalid',
    },

    string: {
        min: 'Field must be at least ${min} characters long',
        max: 'Field must be at most ${max} characters long',
        email: 'Field must be a valid email',
    },

    number: {
        min: (m) => `Field must be at least ${m?.min?.toLocaleString()}`,
        max: (m) => `Field must be at most ${m?.max?.toLocaleString()}`,
        lessThan: 'Field must be less than ${less}',
        moreThan: 'Field must be more than ${more}',
        positive: 'Field must be a positive number',
        negative: 'Field must be a negative number',
        integer: 'Field must be a number',
    },

    array: {
        min: 'Field must be at least ${min}',
        max: 'Field must be at most ${max}',
        length: 'Field must be exactly ${length}',
    },
};

export default en;

// export en type but as const

export type YupEnglish = typeof en;
