export const notifications = {
    access: {
        '403': {
            label: 'We are Sorry...',
            subtitle: "You don't have permission to see this page.",
        },
    },
};

export type Notifications = typeof notifications;
