import { yupResolver } from '@hookform/resolvers/yup'
import { useState } from 'react'
import { createSchema } from './schema'
import { CandidatesService, DetailedCandidateResponse } from 'src/services/api/emos'
import { SubmitHandler, useForm } from 'react-hook-form'
import { Grid } from '@mui/material'
import { FormFields } from 'mui-rhf-library'
import FormActions from 'src/components/drawer/form-actions'
import { useToast } from 'src/hooks/useToast'
import { useCandidates } from 'src/hooks/use-candidates'
import { filterObject } from 'src/utils/filter-object'
import { updateCandidateAttributesToKeep } from './constants'

const Edit = ({
    candidate,
    handleSuccess,
    handleClose
}: {
    candidate: DetailedCandidateResponse
    handleSuccess: () => void
    handleClose: () => void
}) => {
    const [btnLoading, setBtnLoading] = useState(false)
    const { errorsToast } = useToast()

    const formConfigs = useForm({
        resolver: yupResolver(createSchema()),
        defaultValues: {
            ...candidate,
            partyId: candidate.party.id,
            circleId: candidate.circle.id,
            photo: candidate.photo ? [{ url: candidate.photo?.url }] : []
        }
    })
    const {
        handleSubmit,
        control,
        formState: { isDirty }
    } = formConfigs

    const { fields } = useCandidates(formConfigs)

    const handleFormSubmit: SubmitHandler<any> = async (formData) => {
        setBtnLoading(true)

        const filteredData = filterObject({
            obj: formData,
            keys: updateCandidateAttributesToKeep
        }) as any

        try {
            await CandidatesService.updateCandidate({
                id: candidate.id,
                requestBody: { ...filteredData, photo: filteredData.photo[0]?.secret || '', version: candidate.version }
            })
            handleSuccess()
        } catch (err) {
            errorsToast(err)
        } finally {
            setBtnLoading(false)
        }
    }

    return (
        <form onSubmit={handleSubmit(handleFormSubmit)}>
            <Grid container spacing={2}>
                <FormFields fields={fields} control={control} />
            </Grid>
            <FormActions
                disabled={!isDirty}
                handleClose={handleClose}
                handleSubmit={handleSubmit(handleFormSubmit)}
                loading={btnLoading}
            />
        </form>
    )
}

export default Edit
