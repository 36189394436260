import { useEffect, useState, type FC } from 'react'
import { useRoutes } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'
import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider } from '@mui/material/styles'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

import 'src/global.css'
import 'src/locales/i18n'
import 'src/lib/axios-config'

import { OpenAPI as EMOSOpenAPI } from 'src/services/api/emos'

import { Rtl } from 'src/components/rtl'
import { useNprogress } from 'src/hooks/use-nprogress'
import { routes } from 'src/routes'
import { createTheme } from 'src/theme'
import { AuthConsumer, AuthContextType, AuthProvider } from './contexts/auth/auth-context'
import { QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { useSettings } from './store/settings'
import { useTranslation } from 'react-i18next'
import { languageOptions } from './layouts/dashboard/language-switch/language-popover'
import { Toaster as Sooner } from 'sonner'
import { LicenseInfo } from '@mui/x-license'

import dayjs from 'dayjs'
import 'dayjs/locale/ar'
import 'src/locales/dayjs/ckb'
import { BreadcrumbProvider } from './contexts/breadcrumb-context'
import { setYupLocale } from './utils/yup'
import NetworkDialog from './components/dialog/network-dialog'

import * as Sentry from '@sentry/react'
import TimezoneDialog from './components/dialog/timezone-dialog'

Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [Sentry.browserTracingIntegration(), Sentry.httpClientIntegration()],
    // Performance Monitoring
    tracesSampleRate: 0.5,
    tunnel: '/sentry'
})

// mui-x-pro license
LicenseInfo.setLicenseKey(import.meta.env.VITE_MUI_LICENSE)

export const App: FC = (props) => {
    useNprogress()
    const element = useRoutes(routes)

    EMOSOpenAPI.BASE = '/api'

    const [queryClient] = useState(
        () =>
            new QueryClient({
                defaultOptions: {
                    queries: {
                        refetchOnWindowFocus: false,
                        networkMode: 'always',
                        retry: 3
                    }
                },
                queryCache: new QueryCache({
                    onError: (error, query) => {
                        if (typeof query.meta?.onError == 'function') {
                            query.meta?.onError?.(error)
                        }
                    },
                    onSuccess: (data, query) => {
                        if (typeof query.meta?.onSuccess == 'function') {
                            query.meta?.onSuccess?.(data)
                        }
                    },
                    onSettled: (data, error, query) => {
                        if (typeof query.meta?.onSettled == 'function') {
                            query.meta?.onSettled?.({ data, error })
                        }
                    }
                })
            })
    )

    const settings = useSettings()

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <AuthProvider>
                <AuthConsumer>
                    {(auth) => {
                        const theme = createTheme({
                            colorPreset: settings.colorPreset,
                            contrast: settings.contrast,
                            direction: settings.direction,
                            paletteMode: settings.paletteMode,
                            responsiveFontSizes: settings.responsiveFontSizes
                        })

                        return (
                            <QueryClientProvider client={queryClient}>
                                <ThemeProvider theme={theme}>
                                    <LanguageSync auth={auth} />
                                    <Helmet>
                                        <meta name="color-scheme" content={settings.paletteMode} />
                                        <meta name="theme-color" content={theme.palette.neutral[900]} />
                                    </Helmet>
                                    <Rtl direction={settings.direction}>
                                        <CssBaseline />
                                        <NetworkDialog />
                                        <TimezoneDialog />
                                        <BreadcrumbProvider>{element}</BreadcrumbProvider>
                                        <Sooner
                                            richColors
                                            position="top-center"
                                            style={{
                                                fontFamily: 'Kurdish'
                                            }}
                                        />
                                    </Rtl>
                                </ThemeProvider>
                            </QueryClientProvider>
                        )
                    }}
                </AuthConsumer>
            </AuthProvider>
        </LocalizationProvider>
    )
}

const LanguageSync = ({ auth }: { auth: AuthContextType }) => {
    const { i18n } = useTranslation()
    const settings = useSettings()

    useEffect(() => {
        const changeLanguage = async () => {
            await i18n.changeLanguage(auth?.user?.locale ?? settings.language)
            setYupLocale(settings.language)
            settings.setLanguage(auth?.user?.locale ?? settings.language)
            settings.setDirection(languageOptions[auth?.user?.locale ?? settings.language]?.dir)
            dayjs.locale(auth?.user?.locale ?? settings.language)
        }

        changeLanguage()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [auth?.user?.locale])

    return null
}
