import { Card, CardContent } from '@mui/material'
import { useTranslation } from 'react-i18next'
import Create from 'src/components/dashboard/stations/create'
import LayoutPage from 'src/components/page'
import { paths } from 'src/paths'

const New = () => {
    const { t } = useTranslation()
    return (
        <LayoutPage
            title="station"
            breadcrumbs={[
                {
                    title: t('view.station.header'),
                    href: paths.stations.index
                },
                {
                    title: t('view.station.new.headline')
                }
            ]}
        >
            <Card variant="outlined">
                <CardContent>
                    <Create />
                </CardContent>
            </Card>
        </LayoutPage>
    )
}

export default New
