import { useTranslation } from 'react-i18next'
import { AlertDialog } from 'src/components/dialog/alert-dialog'

const VersionMismatchDialog = ({ open }: { open: boolean }) => {
    const { t } = useTranslation()
    return (
        <AlertDialog
            maxWidth="md"
            open={open}
            title={t('view.versionMismatch.header')}
            loading={false}
            handleSubmit={() => {
                window.location.reload()
            }}
            submitText={t('view.versionMismatch.refresh')}
        />
    )
}

export default VersionMismatchDialog
