/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateViolationRequest } from '../models/CreateViolationRequest';
import type { DetailedViolationResponse } from '../models/DetailedViolationResponse';
import type { IdResponse } from '../models/IdResponse';
import type { PagedViolationResponseListResponse } from '../models/PagedViolationResponseListResponse';
import type { UpdateViolationRequest } from '../models/UpdateViolationRequest';
import type { UpdateViolationStateRequest } from '../models/UpdateViolationStateRequest';
import type { ViolationStatus } from '../models/ViolationStatus';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class ViolationsService {
    /**
     * @returns PagedViolationResponseListResponse OK
     * @throws ApiError
     */
    public static listViolations({
        page,
        rowsPerPage,
        stationId,
        ancestorId,
        status,
        sortAscending,
    }: {
        page: number,
        rowsPerPage: number,
        stationId?: string,
        ancestorId?: string,
        status?: ViolationStatus,
        sortAscending?: boolean,
    }): CancelablePromise<PagedViolationResponseListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/violations',
            query: {
                'Page': page,
                'StationId': stationId,
                'AncestorId': ancestorId,
                'Status': status,
                'SortAscending': sortAscending,
                'RowsPerPage': rowsPerPage,
            },
        });
    }
    /**
     * @returns IdResponse Created
     * @throws ApiError
     */
    public static createViolation({
        requestBody,
    }: {
        requestBody: CreateViolationRequest,
    }): CancelablePromise<IdResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/violations',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @returns void
     * @throws ApiError
     */
    public static updateViolation({
        id,
        requestBody,
    }: {
        id: string,
        requestBody: UpdateViolationRequest,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/violations/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @returns DetailedViolationResponse OK
     * @throws ApiError
     */
    public static getViolationById({
        id,
    }: {
        id: string,
    }): CancelablePromise<DetailedViolationResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/violations/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * @returns void
     * @throws ApiError
     */
    public static updateViolationState({
        id,
        requestBody,
    }: {
        id: string,
        requestBody: UpdateViolationStateRequest,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/v1/violations/{id}/state',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
