import Breadcrumbs from '@mui/material/Breadcrumbs'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { paths } from 'src/paths'
import { Link as RouterLink } from 'react-router-dom'
import { useSettings } from 'src/store/settings'

interface BreadCrumbsProps {
    items: {
        title: string | number
        href?: string
    }[]
}

export const BreadCrumbs: FC<BreadCrumbsProps> = (props) => {
    const [t] = useTranslation()
    const { paletteMode } = useSettings()

    if (!props.items.length) return null
    return (
        <Breadcrumbs separator="›">
            {[
                {
                    title: t('view.nav.home'),
                    href: paths.index
                },
                ...props.items
            ].map((item) => {
                if (!item.href) {
                    return (
                        <Typography color="text.primary" key={item.title} variant="caption">
                            {item.title}
                        </Typography>
                    )
                }
                return (
                    <Link
                        color={paletteMode === 'dark' ? 'primary' : 'secondary'}
                        to={item.href || '#'}
                        key={item.title}
                        variant="caption"
                        component={RouterLink}
                    >
                        {item.title}
                    </Link>
                )
            })}
        </Breadcrumbs>
    )
}
