/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { District } from '../models/District';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class DistrictsService {
    /**
     * @returns District OK
     * @throws ApiError
     */
    public static listAllDistricts(): CancelablePromise<Array<District>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/districts/all',
        });
    }
}
