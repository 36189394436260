import i18next from 'i18next'
import { toast } from 'sonner'

export const useToast = () => {
    const errorsToast = (errors: any) => {
        errors?.body?.errors?.map((error: any) => {
            toast.error(
                error?.detail ||
                    error?.title ||
                    (i18next.exists(`view.errors.${error?.code}`)
                        ? i18next.t(`view.errors.${error?.code}` as any)
                        : error?.code)
            )
        })
    }

    const successToast = (message: string) => {
        toast.success(message)
    }

    const errorToast = (message: string) => {
        toast.error(message)
    }

    return { errorToast, errorsToast, successToast }
}
