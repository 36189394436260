import Highcharts, { SeriesOptionsType } from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { useSettings } from 'src/store/settings'

type Data = {
    id?: string
    name: string
    y: number
    color?: string
}

const StackedColumnChart = ({
    title,
    series,
    categories
}: {
    title?: string
    series: SeriesOptionsType[]
    categories: string[]
}) => {
    const { paletteMode } = useSettings()

    const options: Highcharts.Options = {
        chart: {
            type: 'column',
            backgroundColor: 'transparent'
        },
        accessibility: {
            enabled: false
        },
        credits: {
            enabled: false
        },
        title: {
            text: title
        },
        tooltip: {
            headerFormat: '<b>{point.x}</b><br/>',
            useHTML: true,
            backgroundColor: 'transparent',
            borderColor: 'transparent',
            shadow: false,
            padding: 0,
            formatter: function () {
                const point = this.point as any
                const total = this.total || 0 // Sum all points.y
                const percentage = ((point.y / total) * 100).toFixed(2) // Calculate percentage

                return `<div style="
                border-radius: 0.5rem;
                background-color: white;
                box-shadow: #CCC 0px 1px 3px 0px;
                padding: 0.5rem;
                text-align: center;
                ">
                    <span style="color: grey; font-size: 16px">${point.category}: ${point.stackTotal}<br/></span>
                    <hr style="border: none; height: 1px; background-color: #ccc; margin: 7px 0;">

                    <p style="color: black; font-size: 16px">${this.series.name}: ${this.y}</p>
                    <span style="color: grey; font-size: 16px">(${percentage}%)</span>
                </div>`
            },
            style: {
                fontSize: '15px',
                fontFamily: 'Kurdish',
                boxShadow: 'none'
            }
        },
        plotOptions: {
            column: {
                stacking: 'normal',
                dataLabels: {
                    enabled: false
                }
            }
        },
        xAxis: {
            categories,
            labels: {
                style: {
                    fontSize: '15px',
                    fontFamily: 'Kurdish',
                    color: paletteMode === 'dark' ? 'white' : 'black'
                }
            }
        },
        yAxis: {
            min: 0,
            title: {
                text: null
            },
            labels: {
                enabled: false
            },
            gridLineWidth: 0
        },
        series,
        legend: {
            layout: 'horizontal', // Arrange the legend items
            align: 'center',
            verticalAlign: 'bottom',
            floating: false,
            borderWidth: 0,
            backgroundColor: 'transparent',
            itemStyle: {
                fontFamily: 'Kurdish',
                fontSize: '15px',
                color: paletteMode === 'dark' ? 'white' : 'black'
            }
        }
    }

    return <HighchartsReact highcharts={Highcharts} options={options} />
}

export default StackedColumnChart
