import React from 'react'
import { useTranslation } from 'react-i18next'
import Label from './index'

const StatusLabel = ({ isActive = false, ...props }: { isActive: boolean | undefined; [x: string]: any }) => {
    const [t] = useTranslation()

    return (
        <Label color={isActive ? 'success' : 'error'} {...props}>
            {t(`view.common.status.${isActive ? 'active' : 'inactive'}.label` as any) || ''}
        </Label>
    )
}

export default StatusLabel
