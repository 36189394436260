import { yupResolver } from '@hookform/resolvers/yup'
import { useState } from 'react'
import { createSchema } from './schema'
import { CirclesService, DetailedCircleResponse } from 'src/services/api/emos'
import { SubmitHandler, useForm } from 'react-hook-form'
import { Grid } from '@mui/material'
import { FormFields } from 'mui-rhf-library'
import FormActions from 'src/components/drawer/form-actions'
import { useToast } from 'src/hooks/useToast'
import { useCircles } from 'src/hooks/use-circles'

const Edit = ({
    circle,
    handleSuccess,
    handleClose
}: {
    circle: DetailedCircleResponse
    handleSuccess: () => void
    handleClose: () => void
}) => {
    const [btnLoading, setBtnLoading] = useState(false)
    const { errorsToast } = useToast()

    const {
        handleSubmit,
        control,
        formState: { isDirty }
    } = useForm({
        resolver: yupResolver(createSchema()),
        defaultValues: {
            ...circle
        }
    })

    const { fields } = useCircles()

    const handleFormSubmit: SubmitHandler<any> = async (formData) => {
        setBtnLoading(true)
        try {
            await CirclesService.updateCircle({
                id: circle.id,
                requestBody: { ...formData, version: circle.version }
            })
            handleSuccess()
        } catch (err) {
            errorsToast(err)
        } finally {
            setBtnLoading(false)
        }
    }

    return (
        <form onSubmit={handleSubmit(handleFormSubmit)}>
            <Grid container spacing={2}>
                <FormFields fields={fields} control={control} />
            </Grid>
            <FormActions
                disabled={!isDirty}
                handleClose={handleClose}
                handleSubmit={handleSubmit(handleFormSubmit)}
                loading={btnLoading}
            />
        </form>
    )
}

export default Edit
