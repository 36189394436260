import { useQuery } from '@tanstack/react-query'
import { FieldProps } from 'mui-rhf-library'
import { useMemo } from 'react'
import { UseFormReturn } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { allStationsQuery } from 'src/queries/stations'
import { useIncidents } from './use-incidents'
import StationListInput from 'src/components/dashboard/stations/list-input'
import SectionDivider from 'src/components/input/section-divider'
import { NodeType, StationOperationalStatus } from 'src/services/api/emos'
import { allNodesQuery } from 'src/queries/nodes'
import { ListboxComponent } from 'src/components/input/virtualize-autocomplete'
import { filterOptionsWithLabelAndValue } from './use-stations'

export const useStationsInitiation = ({
    formConfigs,
    allowStationSelect
}: {
    formConfigs: UseFormReturn<any>
    allowStationSelect?: boolean
}) => {
    const { t } = useTranslation()

    const pcWatcher = formConfigs.watch('pcId')

    const { data: stations = [], isLoading } = useQuery({
        ...allStationsQuery({
            ancestorId: pcWatcher
        })
    })

    const { data: pollingCenters = [] } = useQuery({
        ...allNodesQuery({
            type: NodeType.POLLING_CENTER
        })
    })

    const { fields: incidentFields } = useIncidents({ formConfigs, isStationInitiation: true, hideDivider: true })

    const stationWatcher = formConfigs.watch('stationId')

    const initiationFields = useMemo((): FieldProps[] => {
        return [
            {
                hidden: !allowStationSelect || pollingCenters.length <= 1,
                name: 'pcId',
                label: t('view.pollingCenters.header'),
                fieldType: 'autocomplete',
                props: {
                    fullWidth: true,
                    options: pollingCenters,
                    ListboxComponent,
                    disableListWrap: true,
                    filterOptions: filterOptionsWithLabelAndValue,
                    renderOption: (props, option: any) => [props, `${option.name} (${option.code})`],
                    getOptionLabel: (option: any) => {
                        if (typeof option === 'object') {
                            return option.name
                        } else {
                            const pc = pollingCenters.find((availableOption) => availableOption.id === option)
                            return `${pc?.name} (${pc?.code})`
                        }
                    },
                    customOptionLabel: (option) => `${option?.name} (${option?.code})`,
                    optionLabel: 'name',
                    optionValue: 'id',
                    onChange: () => {
                        formConfigs.setValue('stationId', null)
                        formConfigs.setValue('operationType', null)
                        formConfigs.setValue('incident', {})
                    }
                },
                gridProps: {
                    xs: 12,
                    sm: 6,
                    md: 6
                },
                textFieldProps: {
                    label: t('view.pollingCenters.header'),
                    fullWidth: true
                }
            },
            {
                hidden: !allowStationSelect,
                name: 'stationId',
                label: t('view.station.header'),
                fieldType: 'custom',
                CustomComponent: StationListInput,
                props: {
                    formConfigs,
                    stations,
                    loading: isLoading,
                    isStationInitiation: true
                }
            },
            {
                hidden: !allowStationSelect,
                name: 'divider',
                fieldType: 'custom',
                CustomComponent: SectionDivider,
                props: {
                    header: t('view.common.details.label')
                }
            },
            {
                name: 'operationType',
                label: t('model.station.operationType'),
                fieldType: 'radioGroup',
                props: {
                    disabled: !stationWatcher,
                    fullWidth: true,
                    options: [
                        {
                            value: StationOperationalStatus.OPERATIONAL,
                            label: t(`model.station.states.${StationOperationalStatus.OPERATIONAL}` as any)
                        },
                        {
                            value: StationOperationalStatus.NON_OPERATIONAL,
                            label: t(`model.station.states.${StationOperationalStatus.NON_OPERATIONAL}` as any)
                        }
                    ],
                    onChange: () => {
                        formConfigs.setValue('incident', {})
                    },
                    sx: {
                        '& .MuiSvgIcon-root': {
                            fontSize: 30
                        }
                    }
                },
                gridProps: { xs: 12 }
            },
            ...incidentFields
        ]
    }, [allowStationSelect, formConfigs, incidentFields, isLoading, pollingCenters, stationWatcher, stations, t])

    return { initiationFields }
}
