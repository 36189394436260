import Masonry, { MasonryOwnProps } from '@mui/lab/Masonry'
import React from 'react'
import DetailsCard from './details-card'
import { Card as CardType } from 'src/types/card'

const MasonryCards = ({ cards, ...rest }: { cards: CardType[] } & Omit<MasonryOwnProps, 'children'>) => {
    return (
        <Masonry {...rest}>
            {cards
                .filter((card) => card && !card.hidden)
                .map((card, index) => (
                    <DetailsCard {...card} key={index}>
                        {card?.children}
                    </DetailsCard>
                ))}
        </Masonry>
    )
}

export default MasonryCards
