import { useEffect, useState, type FC } from 'react'
import Badge from '@mui/material/Badge'
import IconButton from '@mui/material/IconButton'
import SvgIcon from '@mui/material/SvgIcon'
import Tooltip from '@mui/material/Tooltip'

import { usePopover } from 'src/hooks/use-popover'

import { NotificationsPopover } from './notifications-popover'
import { useInfiniteQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import { NotificationsService } from 'src/services/api/emos'
import { useToast } from 'src/hooks/useToast'
import { Bell } from 'lucide-react'
import { useTranslation } from 'react-i18next'

export const NotificationsButton: FC = () => {
    const popover = usePopover<HTMLButtonElement>()
    const [unread, setUnread] = useState(0)
    const queryClient = useQueryClient()
    const { t } = useTranslation()

    useEffect(() => {
        // opening a connection to the server to begin receiving events from it
        const eventSource = new EventSource('/api/v1/notifications/realtime', {
            withCredentials: true
        })

        // eventSource.onerror = (error) => {
        //     errorToast('EventSource failed:')
        //     console.error('EventSource failed:', error)
        // }

        eventSource.addEventListener('unread_messages', (event) => {
            const data = JSON.parse(event.data)
            setUnread(data.unread)
        })

        eventSource.addEventListener('new_unread_messages', (event) => {
            const data = JSON.parse(event.data)
            setUnread((prev) => prev + data.unread)
        })

        // terminating the connection on component unmount
        return () => eventSource.close()
    }, [])

    const { mutate: handleRead } = useMutation({
        mutationKey: ['markRead'],
        mutationFn: (notificationId: string | undefined) => {
            const res = NotificationsService.markNotificationAsRead({ id: notificationId })
            if (!notificationId) {
                setUnread(0)
                popover.handleClose()
                return res
            }
            setUnread((prev) => prev - 1)
            return res
        }
    })

    const infiniteQuery = useInfiniteQuery({
        queryKey: ['notifications'],
        queryFn: async ({ pageParam }) => {
            try {
                const res = await NotificationsService.listNotifications({
                    lastId: pageParam || undefined
                })
                return res
            } catch (err) {
                console.log({ err })
            }
        },
        refetchOnWindowFocus: false,
        getNextPageParam: (lastPage) => {
            return lastPage && lastPage.length > 0 ? lastPage[lastPage.length - 1]?.id : undefined
        },
        initialPageParam: '',
        enabled: !!popover.open
    })

    useEffect(() => {
        if (!popover.open) {
            queryClient.resetQueries({ queryKey: ['notifications'], exact: true })
        }
    }, [popover.open, queryClient])

    return (
        <>
            <Tooltip title={t('view.notification.header')}>
                <IconButton ref={popover.anchorRef} onClick={popover.handleOpen}>
                    <Badge color="error" badgeContent={unread}>
                        <SvgIcon>
                            <Bell />
                        </SvgIcon>
                    </Badge>
                </IconButton>
            </Tooltip>
            <NotificationsPopover
                anchorEl={popover.anchorRef.current}
                onClose={() => {
                    popover.handleClose()
                }}
                open={popover.open}
                infiniteQuery={infiniteQuery}
                handleRead={(notificationId) => {
                    handleRead(notificationId)
                }}
                onMarkAllAsRead={() => {
                    handleRead(undefined)
                }}
            />
        </>
    )
}
