import React, { createContext, useContext, useMemo, useState } from 'react'

// Define the shape of each error object
interface ErrorItem {
    id: string
    value: boolean
}

// Update the ErrorContextType to handle an array of ErrorItem objects
interface ErrorContextType {
    errors: ErrorItem[]
    setError: (id: string, value: boolean) => void
}

// Initial state with an empty errors array
const initialState: ErrorContextType = {
    errors: [],
    setError: () => {}
}

// Create the context
const ErrorContext = createContext<ErrorContextType>(initialState)

export const useError = () => {
    return useContext(ErrorContext)
}

export const ErrorProvider = ({ children }: { children: React.ReactNode }) => {
    const [errors, setErrors] = useState<ErrorItem[]>([])

    const setError = (id: string, value: boolean) => {
        console.log({ id, value })
        setErrors((prevErrors) => {
            const existingError = prevErrors.find((error) => error.id === id)
            if (existingError) {
                return prevErrors.map((error) => (error.id === id ? { ...error, value } : error))
            } else {
                return [...prevErrors, { id, value }]
            }
        })
    }

    const value = useMemo(() => ({ errors, setError }), [errors])

    return <ErrorContext.Provider value={value}>{children}</ErrorContext.Provider>
}
