export const view = {
    project_name: 'EMOS',
    krg: 'Kurdistan Regional Government',
    dit: 'Department of Information Technology',
    version: 'Version',
    dashboard: {
        header: 'EMOS',
        description: 'EMOS',
        name: {
            headline: 'Dashboard'
        }
    },
    nav: {
        home: 'Home',
        search: 'Search',
        users: 'Users',
        account: 'Account',
        application: 'Application',
        management: 'Management'
    },
    auth: {
        login: {
            headline: 'Login',
            unauthorized_message: 'Unauthorized access'
        },
        logout: {
            headline: 'Logout',
            confirmation: 'Are you sure you want to logout?'
        }
    },
    languages: {
        label: 'Language',
        en: 'English',
        ckb: 'کوردی',
        nkb: 'کوردی ناوەندی',
        ar: 'العربیە',
        translation: {
            en: 'English',
            ar: 'Arabic',
            ckb: 'Kurdish'
        }
    },
    user: {
        header: 'Users',
        name: {
            headline: 'User'
        },
        all: {
            headline: 'All Users'
        },
        new: {
            headline: 'New User'
        },
        edit: {
            headline: 'Edit User'
        },
        profile: {
            headline: 'User Profile'
        },
        onboard: {
            headline: 'Search Users',
            subtitle: 'Fill user information'
        },
        alert: {
            guarantor_inactive: 'Guarantor status is not active'
        }
    },
    candidate: {
        header: 'Candidates',
        name: {
            headline: 'Candidate'
        },
        all: {
            headline: 'All Candidates'
        },
        new: {
            headline: 'New Candidate'
        },
        edit: {
            headline: 'Edit Candidate'
        }
    },
    party: {
        header: 'Parties',
        name: {
            headline: 'Party'
        },
        all: {
            headline: 'All Parties'
        },
        new: {
            headline: 'New Party'
        },
        edit: {
            headline: 'Edit Party'
        },
        all_parties_counted: 'All parties have been counted',
        not_counted: 'Not Counted'
    },
    node: {
        header: 'Nodes',
        name: {
            headline: 'Node'
        },
        all: {
            headline: 'All Nodes'
        },
        new: {
            headline: 'New Node'
        },
        edit: {
            headline: 'Edit Node'
        }
    },
    circle: {
        header: 'Circles',
        name: {
            headline: 'Circle'
        },
        all: {
            headline: 'All Circles'
        },
        new: {
            headline: 'New Circle'
        },
        edit: {
            headline: 'Edit Circle'
        }
    },
    systemConfiguration: {
        header: 'System Configuration',
        name: {
            headline: 'System Configuration'
        },
        edit: {
            headline: 'Edit System Configuration'
        },
        outsideActiveDuration: 'You are outside the active duration'
    },
    incident: {
        header: 'Incidents',
        name: {
            headline: 'Incident'
        },
        all: {
            headline: 'All Incidents'
        },
        new: {
            headline: 'New Incident',
            subtitle: 'Report a new incident'
        },
        edit: {
            headline: 'Edit Incident'
        },
        command: {
            resolve: {
                action: {
                    title: 'Resolve Incident',
                    confirmation: 'Are you sure you want to resolve the incident?'
                }
            },
            reopen: {
                action: {
                    title: 'Reopen Incident',
                    confirmation: 'Are you sure you want to Reopen the incident?'
                }
            }
        }
    },
    violation: {
        header: 'Violations',
        name: {
            headline: 'Violation'
        },
        all: {
            headline: 'All Violations'
        },
        new: {
            headline: 'New Violation',
            subtitle: 'Report a violation'
        },
        edit: {
            headline: 'Edit Violation'
        },
        command: {
            reopen: {
                action: {
                    title: 'Reopen Violation',
                    confirmation: 'Are you sure you want to reopen the violation?'
                }
            },
            drop: {
                action: {
                    title: 'Drop Violation',
                    confirmation: 'Are you sure you want to drop the violation?'
                }
            },
            submit: {
                action: {
                    title: 'Submit Violation',
                    confirmation: 'Are you sure you want to submit the violation?'
                }
            }
        },
        detail: 'Violation Detail'
    },
    station: {
        header: 'Stations',
        name: {
            headline: 'Station'
        },
        all: {
            headline: 'All Stations'
        },
        new: {
            headline: 'New Station'
        },
        edit: {
            headline: 'Edit Station'
        },
        initiate: {
            headline: 'Initiate Station',
            subtitle: 'Initiate a station',
            success: 'Station was initiated successfully'
        },
        middayReport: {
            headline: 'Midday Report',
            subtitle: 'Update midday report',
            success: 'Midday report was updated successfully'
        }
    },
    votingResult: {
        header: 'Voting Results',
        name: {
            headline: 'Voting Result',
            subtitle: 'Log the voting results'
        },
        all: {
            headline: 'All Voting Results'
        },
        new: {
            headline: 'New Voting Result'
        },
        edit: {
            headline: 'Edit Voting Result'
        },
        command: {
            Submit: {
                action: {
                    title: 'Submit',
                    confirmation: 'Are you sure you want to submit the voting result?'
                }
            },
            Draft: {
                action: {
                    title: 'Draft',
                    confirmation: 'Are you sure you want to mark the voting result as draft?'
                }
            }
        }
    },
    reports: {
        header: 'Reports',
        disclaimer: 'The data is being refreshed every 3 minutes',
        partyVotes: 'Total Party Votes',
        candidateVotes: 'Total Candidate Votes',
        partiesAndCandidatesVotes: 'Total Party and Candidate Votes',
        totalVotes: 'Total Votes',
        voterValidityDistribution: 'Voter Validity',
        voterParticipationDistribution: 'Voter Participation',
        participated: 'Participated',
        notParticipated: 'Not Participated',
        validVotes: 'Valid Votes',
        invalidVotes: 'Invalid Votes',
        operation: {
            header: 'Operation Reports',
            stationInitiationReport: 'Station Initiation Report',
            middayResultReport: 'Midday Result Report',
            reported: 'Reported',
            notReported: 'Not Reported',
            violationReportStatus: 'Violation Report Status',
            violationReportPerType: 'Violation Report Per Type',
            stationSubmissionReport: 'Station Submission Report',
            submitted: 'Submitted',
            notSubmitted: 'Not Submitted',
            incidentReport: 'Incident Report'
        }
    },
    pollingCenters: {
        header: 'Polling Centers'
    },
    notification: {
        header: 'Notifications',
        all: {
            headline: 'All Notifications',
            label: 'All'
        },
        unread: {
            headline: 'Unread Notifications',
            label: 'Unread',
            mark_read: 'Mark as read'
        },
        read: {
            headline: 'Read Notifications',
            label: 'Read'
        },
        no_more: 'No more notifications',
        empty: 'No notifications',
        incident_created: {
            title: 'Incident was created.'
        },
        incident_state_changed: {
            title: 'Incident state was changed to {{to}}.'
        },
        station_vrs_done: {
            title: 'All voting result forms have been submitted.'
        }
    },
    common: {
        uploads: {
            upload: 'Upload',
            capture: 'Capture',
            use: 'Use',
            retake: 'Retake',
            switch: 'Switch Camera'
        },
        view: 'View',
        all: 'All',
        choose: 'Choose',
        no_data: 'No data is available',
        found: 'Found',
        language_change: 'Language changed successfully',
        add_file: 'Add File',
        clear: 'Clear Filters',
        from: 'From',
        to: 'To',
        form: {
            submit: 'Submit',
            save: 'Save',
            update: 'Update',
            cancel: 'Cancel'
        },
        dialog: {
            submit: 'Submit',
            cancel: 'Cancel',
            close: 'Close',
            yes: 'Yes',
            confirm: 'Confirm',
            edit: 'Edit',
            add: 'Add',
            delete: 'Delete',
            back: 'Back'
        },
        pagination: {
            next: 'Next',
            back: 'Back',
            rows_per_page: 'Rows Per Page',
            of: 'of'
        },
        details: {
            label: 'Details',
            translations: 'Translations'
        },
        status: {
            active: {
                label: 'Active',
                action: {
                    title: 'Activate',
                    confirmation: 'Are you sure you want to activate the {{name}}?'
                }
            },
            inactive: {
                label: 'Inactive',
                action: {
                    title: 'Deactivate',
                    confirmation: 'Are you sure you want to deactivate the {{name}}?'
                }
            },
            pending: {
                label: 'Pending'
            },
            complete: {
                label: 'Complete'
            },
            unknown: {
                label: 'Unknown'
            }
        },
        actions: {
            new: {
                success: 'New {{name}} has been created',
                fail: 'Failed to create new {{name}}'
            },
            update: {
                success: '{{name}} has been updated',
                fail: 'Failed to update {{name}}'
            },
            status: {
                success: '{{name}} status has been updated',
                fail: 'Failed to update {{name}} status'
            }
        },
        'yes/no': {
            true: 'Yes',
            false: 'No'
        },
        filter: {
            label: 'Filter',
            reset: 'Reset Filters'
        },
        search: {
            label: 'Search',
            tip: 'Search by entering a keyword and pressing Enter'
        },
        changes: {
            from: 'From',
            to: 'To'
        },
        audit: 'Audit'
    },
    notifications: {
        auth: {
            login: 'You are logged in',
            fail_login: 'Failed! Please check your email and password',
            logout: 'You have been logged out',
            update_profile: 'Profile has been updated',
            update_password: 'Password has been changed'
        },
        users: {
            create: 'New user has been created',
            update: 'User has been updated',
            update_password: 'User password has been updated'
        },
        common: {
            create: 'New {{name}} has been created',
            update: '{{name}} has been updated',
            activate: '{{name}} has been activated',
            deactivate: '{{name}} has been deactivated'
        }
    },
    sidebar: {
        management: 'Management',
        reports: 'Reports'
    },
    actions: {
        label: 'Actions',
        common: {
            edit: 'Edit',
            edit_name: 'Edit {{name}}',
            remove: 'Remove',
            print: 'Print Visa'
        }
    },
    roles: {
        admin: 'Admin',
        pb_sv: 'Provincial Bureau Supervisor',
        branch_sv: 'Branch Supervisor',
        sb_sv: 'Sub-Branch Supervisor',
        pc_sv: 'Polling Center Supervisor',
        sys_mgr: 'System Manager',
        voting_result_editor_F: 'Voting Result Editor (Final)',
        voting_result_editor_A_M: 'Voting Result Editor (Electronic/Manual)',
        data_exporter: 'Data Exporter',
        violation_mgr: 'Violation Manager'
    },
    account: {
        profile: {
            headline: 'Profile'
        }
    },
    error: {
        '403': 'Access Not Granted',
        '404': 'Page not Found',
        go_back: 'Go Back'
    },
    validations: {
        max_files: 'Files cannot be more than {{maxFiles}}',
        'file-invalid-type': 'File type is invalid',
        'too-many-files': 'Number of files are more than the allowed amount',
        mb: 'File size cannot be more than {{size}} MB',
        upload_failed: 'Upload failed',
        file_too_large: 'File is too large',
        passwordConfirm: 'Passwords do not match',
        required: 'Field is required',
        fail_save: 'Failed to save',
        parties_fail_save: 'Failed to save the data of some of the parties.',
        node_required: 'At least one of EMR, PBS, branch, sub branch, or polling center must be provided',
        blank: 'Field cannot be blank',
        cannot_be_in_future: 'Cannot be in the future'
    },
    network: {
        offline: 'You are offline. Please check your internet connection.',
        online: 'You are online.'
    },
    versionMismatch: {
        header: 'Someone else had made changes to the data, please refresh the page to see the latest changes.',
        refresh: 'Refresh'
    },
    timezone: {
        header: 'Timezone',
        timezone_difference:
            'Your device time seems to differ from the server by more than 5 minutes. Please check your system time for a more accurate experience.'
    },
    export: {
        middayVoters: 'Export Midday Voters',
        stations: 'Export Stations'
    },
    errors: {
        circle_not_found: 'Circle not found',
        parent_not_found: 'Parent not found',
        station_not_found: 'Station not found',
        station_not_initiated: 'Station is not initiated',
        sub_district_not_found: 'Sub district not found',
        node_not_found: 'Node not found',
        pc_in_user_cannot_change_election_type: 'PC in user cannot change election type',
        invalid_parent: 'Invalid parent',
        duplicate_entry: 'Duplicate entry',
        candidate_not_found: 'Candidate not found',
        party_not_found: 'Party not found',
        cannot_update_own_user: 'Cannot update own user',
        user_already_exists: 'User already exists',
        unsupported_content_type: 'Unsupported content type',
        station_already_initiated: 'Station already initiated',
        not_in_morning_duration: 'Not in morning duration',
        not_in_afternoon_duration: 'Not in afternoon duration',
        not_in_evening_duration: 'Not in evening duration',
        voters_exceed_max_voters: 'Voters exceed the maximum voters',
        comment_required: 'Comment is required',
        attachment_required: 'Attachment is required',
        candidate_vote_count_exceeded_total_votes: 'Candidate vote count exceeded the total votes',
        invalid_circle: 'Invalid circle',
        invalid_election_type: 'Invalid election type',
        total_votes_exceeded_max_votes: 'Total votes exceeded the maximum votes',
        party_already_has_voting_results_in_circle: 'Party already has voting results in circle',
        station_must_have_circles: 'Station must have circles',
        invalid_roles_for_node: 'Invalid roles for node',
        cannot_submit_voting_result_without_parties_or_invalid_papers:
            'Cannot submit voting result without parties or invalid papers',
        cannot_edit_voting_result_when_not_draft: 'Cannot edit voting result when not draft'
    }
}

export type View = typeof view
