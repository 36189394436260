import { AdvancedFilters, AdvancedTable } from '@ditkrg/react-core'
import { Button, Card } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { IncidentsService, IncidentStatus, NodeType } from 'src/services/api/emos'
import { useIncidentsTableState } from 'src/store/table'
import GenerateColumns from './columns'
import { useTranslation } from 'react-i18next'
import React from 'react'
import { useRouter } from 'src/hooks/use-router'
import { paths } from 'src/paths'
import { ROWS_PER_PAGE_OPTIONS } from 'src/utils/constants'
import { useSettings } from 'src/store/settings'
import { useNodeFilters } from 'src/hooks/use-node-filters'
import { allStationsQuery } from 'src/queries/stations'
import { REASONS } from 'src/hooks/use-incidents'
import DeleteIcon from '@mui/icons-material/Delete'

const Results = () => {
    const { t } = useTranslation()
    const router = useRouter()
    const incidentsTableState = useIncidentsTableState()
    const { page, rowsPerPage, filters } = incidentsTableState
    const { language } = useSettings()
    const { data: stations = [], isPending } = useQuery({
        ...allStationsQuery({
            ancestorId:
                filters?.[NodeType.POLLING_CENTER] ||
                filters?.[NodeType.SUB_BRANCH] ||
                filters?.[NodeType.BRANCH] ||
                filters?.[NodeType.PBS]
        })
    })
    const { filterInputs } = useNodeFilters({
        state: incidentsTableState
    })

    const {
        data: incidents,
        isLoading,
        isFetching
    } = useQuery({
        queryKey: ['incidents', rowsPerPage, page, filters],
        queryFn: async () => {
            const res = await IncidentsService.listIncidents({
                page: page + 1,
                rowsPerPage,
                stationId: filters?.stationId,
                ancestorId:
                    filters?.[NodeType.POLLING_CENTER] ||
                    filters?.[NodeType.SUB_BRANCH] ||
                    filters?.[NodeType.BRANCH] ||
                    filters?.[NodeType.PBS] ||
                    undefined,
                status: filters?.status,
                reason: filters?.reason
            })
            return res
        },
        placeholderData: (placeholderData) => placeholderData
    })

    return (
        <Card variant="outlined">
            <AdvancedFilters
                state={incidentsTableState}
                filters={[
                    ...filterInputs,
                    {
                        id: 'stationId',
                        type: 'autocomplete',
                        header: t('view.station.name.headline'),
                        props: {
                            options: stations,
                            customOptionLabel: (option) => `${t('view.station.name.headline')}: ${option?.number}`,
                            optionValue: 'id',
                            disabled: isPending,
                            virtualized: true
                        }
                    },
                    {
                        id: 'reason',
                        type: 'select',
                        header: t('model.incident.reason.label'),
                        props: {
                            options: Object.values(REASONS).map((reason) => ({
                                value: reason,
                                label: t(`model.incident.reason.options.${reason}` as any)
                            }))
                        }
                    },
                    {
                        id: 'status',
                        type: 'select',
                        header: t('model.common.status'),
                        props: {
                            options: Object.values(IncidentStatus).map((status) => ({
                                value: status,
                                label: t(`model.incident.states.${status}` as any)
                            }))
                        }
                    }
                ]}
                showCollapseButton={false}
                locale={language as any}
                clearButtonProps={{
                    hidden: true
                }}
                actions={[
                    ({ filtersState, sync }) => (
                        <Button
                            color="error"
                            onClick={() => {
                                filtersState.clearFilters()
                                filtersState.setFilter('status', IncidentStatus.OPEN)
                                sync?.()
                            }}
                            startIcon={<DeleteIcon />}
                        >
                            {t('view.common.clear')}
                        </Button>
                    )
                ]}
            />
            <AdvancedTable
                data={incidents?.data ?? []}
                columns={GenerateColumns({ t, tableState: incidentsTableState })}
                state={incidentsTableState}
                totalItems={incidents?.meta?.pagination?.count ?? 0}
                onRowClick={(row) => {
                    router.push(paths.incidents.view(row.id))
                }}
                loading={isLoading || isFetching}
                loadingVariant={isFetching ? 'linear' : 'skeleton'}
                filterLabel={t('view.common.filter.label')}
                clearLabel={t('view.common.clear')}
                paginationVariant="advanced"
                rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
                locale={language as any}
            />
        </Card>
    )
}

export default Results
