import React from 'react'
import { useTranslation } from 'react-i18next'
import { styled, alpha } from '@mui/material/styles'
import { VotingResultFormType } from 'src/services/api/emos'

const LabelWrapper = styled('span')(
    ({ theme }) => `
      padding: ${theme.spacing(0.5, 1)};
      font-size: ${theme.typography.pxToRem(15)};
      display: inline-flex;
      align-items: center;
      justify-content: center;
`
)

type Mapper = {
    [key in VotingResultFormType]: {
        text: string
        color: string
    }
}

const VotingResultFormTypeStatusLabel = ({ status }: { status: VotingResultFormType }) => {
    const [t, i18next] = useTranslation()

    const mapper: Mapper = {
        Automatic: {
            text: VotingResultFormType.AUTOMATIC,
            color: '#9c27b0'
        },
        Manual: {
            text: VotingResultFormType.MANUAL,
            color: '#1565c0'
        },
        Final: {
            text: VotingResultFormType.FINAL,
            color: '#1b5e20'
        }
    }

    const { text, color } = status ? mapper[status] : { text: status, color: '#616161' }

    return (
        <LabelWrapper style={{ backgroundColor: alpha(color, 0.1), color: color }}>
            {i18next.exists(`model.votingResult.formType.options.${text}`)
                ? t(`model.votingResult.formType.options.${text}` as any)
                : text}
        </LabelWrapper>
    )
}

export default VotingResultFormTypeStatusLabel
