import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'

export default function dateFormat({
    date,
    fromNow,
    format = 'DD-MM-YYYY hh:mm:ss A'
}: {
    date: string | Date | undefined | null
    fromNow?: boolean
    format?: string
}) {
    dayjs.extend(relativeTime)
    if (!date) return
    if (fromNow) return dayjs(date).fromNow()
    else return dayjs(date).format(format)
}
