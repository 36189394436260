import { Grid } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import HighBarChart from 'src/components/dashboard/reports/highcharts/bar-chart'
import { allCandidatesQuery } from 'src/queries/candidates'
import { allPartiesQuery } from 'src/queries/parties'
import { DashboardsService, ElectionType, NodeType, SeatType, VotingResultFormType } from 'src/services/api/emos'
import { useReportsFilters, useReportsSeatTypeFilters } from 'src/store/reports'
import { DataGridPro, GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro'
import { Box } from '@mui/system'
import { AdvancedFilters } from '@ditkrg/react-core'
import { useSettings } from 'src/store/settings'
import ReportCard from './report-card'
import { REFETCH_INTERVAL } from './constants'
import numeral from 'numeral'
import { useMemo } from 'react'

const processItems = (items: any[], source: any[], filters: any, key: 'parties' | 'candidates') => {
    const totalCount = items.reduce((acc, item) => acc + item.count, 0)
    const newItems = items.map((item) => {
        const sourceItem = source.find((s) => s.id === item.id)
        const calculatedPercentage = item.count / totalCount
        return {
            ...item,
            name: sourceItem?.name,
            color: sourceItem?.color || '#1355FF',
            number: sourceItem?.number,
            sourceItem,
            percentage: numeral(calculatedPercentage || 0).format('0.00%')
        }
    })

    return {
        top10: newItems
            .sort((a, b) => b.count - a.count)
            .slice(0, 10)
            .sort((a, b) => {
                return a.number - b.number
            }),
        others: newItems.sort((a, b) => b.count - a.count)
    }
}

const PartiesAndCandidatesVoteDistribution = ({ enabledQueries }: { enabledQueries: boolean }) => {
    const { t } = useTranslation()
    const state = useReportsFilters()
    const seatTypeState = useReportsSeatTypeFilters()

    const { filters } = state
    const { filters: seatTypeFilters } = seatTypeState

    const { language } = useSettings()

    const { data: allParties = [], isSuccess } = useQuery({
        ...allPartiesQuery({})
    })
    const { data: allCandidates = [], isSuccess: candidatesIsSuccess } = useQuery({
        ...allCandidatesQuery({})
    })

    const { data: voteDistribution = [] } = useQuery({
        queryKey: ['voteDistribution', filters],
        queryFn: async () => {
            const res = await DashboardsService.voteDistribution({
                circleId: filters?.circle as string,
                ancestorId:
                    filters?.[NodeType.POLLING_CENTER] ||
                    filters?.[NodeType.SUB_BRANCH] ||
                    filters?.[NodeType.BRANCH] ||
                    filters?.[NodeType.PBS],
                electionType: filters?.electionType as ElectionType,
                formType: filters?.formType as VotingResultFormType
            })

            return {
                parties: processItems(res.parties || [], allParties, filters, 'parties'),
                candidates: processItems(res.candidates || [], allCandidates, filters, 'candidates')
            }
        },
        enabled: enabledQueries && isSuccess && candidatesIsSuccess,
        refetchInterval: REFETCH_INTERVAL
    })

    const partiesColumns: GridColDef<any[number]>[] = [
        {
            field: 'index',
            headerName: '#',
            width: 75,
            sortable: false,
            disableColumnMenu: true,
            resizable: false,
            renderCell: (params: GridRenderCellParams<any>) => {
                return (params.api.getRowIndexRelativeToVisibleRows(params.row.id) || 0) + 1
            }
        },
        {
            field: 'number',
            headerName: t('model.common.number'),
            width: 100,
            sortable: false,
            disableColumnMenu: true,
            resizable: false
        },
        {
            field: 'name',
            headerName: t('model.common.name'),
            width: 350,
            sortable: true,
            disableColumnMenu: true,
            resizable: false
        },
        {
            field: 'count',
            headerName: t('view.reports.totalVotes'),
            width: 150,
            sortable: true,
            disableColumnMenu: true,
            resizable: false,
            valueGetter: (count, row) => {
                return `${numeral(count).format('0,0')} (${row.percentage})`
            }
        }
    ]

    const seatTypes = [
        { label: t('model.party.seatType.options.Majority'), value: SeatType.MAJORITY },
        { label: t('model.party.seatType.options.Minority'), value: 'Minority' }
    ]

    const top10PartiesProcessed = useMemo(() => {
        if (!voteDistribution) return []
        if (!voteDistribution['parties']) return []
        if (!voteDistribution['parties']?.['top10']) return []

        if (seatTypeFilters?.seatType) {
            return voteDistribution['parties']?.['top10'].filter((item) => {
                return seatTypeFilters.seatType === SeatType.MAJORITY
                    ? item.sourceItem.seatType === seatTypeFilters?.seatType
                    : item.sourceItem.seatType !== SeatType.MAJORITY
            })
        }

        return voteDistribution['parties']?.['top10']
    }, [voteDistribution, seatTypeFilters])

    return (
        <>
            <Grid item xs={12}>
                <ReportCard title={t('view.reports.partiesAndCandidatesVotes')}>
                    <Grid item xs={12}>
                        <AdvancedFilters
                            state={seatTypeState as any}
                            filters={[
                                {
                                    id: 'seatType',
                                    type: 'autocomplete',
                                    header: t('model.party.seatType.label'),
                                    props: {
                                        options: seatTypes,
                                        optionLabel: 'label',
                                        optionValue: 'value'
                                    },
                                    gridProps: {
                                        xs: 6
                                    }
                                }
                            ]}
                            showCollapseButton={false}
                            locale={language as any}
                            clearButtonProps={{
                                hidden: true
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <HighBarChart dataset={top10PartiesProcessed} />
                    </Grid>
                </ReportCard>
            </Grid>

            {/* <Grid item xs={12}>
                <Typography variant="body1" sx={{ pb: 1 }} color="textSecondary">
                    {t('view.reports.candidateVotes')}
                </Typography>
                <Paper>
                    <HighBarChart dataset={voteDistribution['candidates']?.['top10'] ?? []} />
                </Paper>
            </Grid> */}
            <Grid item xs={12}>
                <ReportCard title={t('view.reports.partiesAndCandidatesVotes')}>
                    <Box sx={{ height: 400 }}>
                        <DataGridPro
                            rows={voteDistribution['parties']?.['others'] ?? []}
                            columns={partiesColumns}
                            disableRowSelectionOnClick
                            hideFooter
                            localeText={{
                                noResultsOverlayLabel: t('view.common.no_data')
                            }}
                        />
                    </Box>
                </ReportCard>
            </Grid>
            <Grid item xs={12}>
                <ReportCard title={t('view.reports.candidateVotes')}>
                    <Box sx={{ height: 400 }}>
                        <DataGridPro
                            rows={voteDistribution['candidates']?.['others'] ?? []}
                            columns={partiesColumns}
                            disableRowSelectionOnClick
                            hideFooter
                            localeText={{
                                noResultsOverlayLabel: t('view.common.no_data')
                            }}
                        />
                    </Box>
                </ReportCard>
            </Grid>
        </>
    )
}

export default PartiesAndCandidatesVoteDistribution
