import { CreateViolationRequest, UpdateViolationRequest, UpdateViolationStateRequest } from 'src/services/api/emos'

type CreateViolationRequestKeys = Array<keyof CreateViolationRequest>
export const createViolationAttributesToKeep: CreateViolationRequestKeys = [
    'allegedViolator',
    'complainant',
    'description',
    'stationId',
    'type'
]

type UpdateViolationRequestKeys = Array<keyof UpdateViolationRequest>
export const updateViolationAttributesToKeep: UpdateViolationRequestKeys = [
    'allegedViolator',
    'complainant',
    'description'
]

type UpdateViolationStateRequestKeys = Array<keyof UpdateViolationStateRequest>
export const updateViolationStateAttributesToKeep: UpdateViolationStateRequestKeys = [
    'attachment',
    'comment',
    'command'
]
