/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateStationRequest } from '../models/CreateStationRequest';
import type { DetailedStationResponse } from '../models/DetailedStationResponse';
import type { ElectionType } from '../models/ElectionType';
import type { IdResponse } from '../models/IdResponse';
import type { InitiateStationRequest } from '../models/InitiateStationRequest';
import type { MinimalStationResponse } from '../models/MinimalStationResponse';
import type { PagedStationResponseListResponse } from '../models/PagedStationResponseListResponse';
import type { UpdateStationInvalidPapersRequest } from '../models/UpdateStationInvalidPapersRequest';
import type { UpdateStationRequest } from '../models/UpdateStationRequest';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class StationsService {
    /**
     * @returns PagedStationResponseListResponse OK
     * @throws ApiError
     */
    public static listStations({
        page,
        rowsPerPage,
        ancestorId,
        isInitiated,
        hasIncidents,
        provinceId,
        districtId,
        subDistrictId,
        electionType,
    }: {
        page: number,
        rowsPerPage: number,
        ancestorId?: string,
        isInitiated?: boolean,
        hasIncidents?: boolean,
        provinceId?: string,
        districtId?: string,
        subDistrictId?: string,
        electionType?: ElectionType,
    }): CancelablePromise<PagedStationResponseListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/stations',
            query: {
                'Page': page,
                'AncestorId': ancestorId,
                'IsInitiated': isInitiated,
                'HasIncidents': hasIncidents,
                'ProvinceId': provinceId,
                'DistrictId': districtId,
                'SubDistrictId': subDistrictId,
                'ElectionType': electionType,
                'RowsPerPage': rowsPerPage,
            },
        });
    }
    /**
     * @returns IdResponse Created
     * @throws ApiError
     */
    public static createStation({
        requestBody,
    }: {
        requestBody: CreateStationRequest,
    }): CancelablePromise<IdResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/stations',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @returns MinimalStationResponse OK
     * @throws ApiError
     */
    public static listAllStations({
        isInitiated,
        hasIncidents,
        ancestorId,
    }: {
        isInitiated?: boolean,
        hasIncidents?: boolean,
        ancestorId?: string,
    }): CancelablePromise<Array<MinimalStationResponse>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/stations/all',
            query: {
                'IsInitiated': isInitiated,
                'HasIncidents': hasIncidents,
                'AncestorId': ancestorId,
            },
        });
    }
    /**
     * @returns void
     * @throws ApiError
     */
    public static updateStation({
        id,
        requestBody,
    }: {
        id: string,
        requestBody: UpdateStationRequest,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/stations/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @returns DetailedStationResponse OK
     * @throws ApiError
     */
    public static getStationById({
        id,
    }: {
        id: string,
    }): CancelablePromise<DetailedStationResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/stations/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * @returns void
     * @throws ApiError
     */
    public static updateStationInvalidPapers({
        id,
        requestBody,
    }: {
        id: string,
        requestBody: UpdateStationInvalidPapersRequest,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/stations/{id}/invalid-papers',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @returns void
     * @throws ApiError
     */
    public static initiateStation({
        id,
        requestBody,
    }: {
        id: string,
        requestBody: InitiateStationRequest,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/stations/{id}/initiate',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
