import dayjs from 'dayjs'
import { FieldProps } from 'mui-rhf-library'
import { useMemo } from 'react'
import { UseFormReturn } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { TimePickerControllerWrapper } from 'src/components/dashboard/system-config/edit'
import { ElectionType } from 'src/components/dashboard/system-config/profile'

export const useSystemConfiguration = ({ formConfigs }: { formConfigs: UseFormReturn<any> }) => {
    const { t } = useTranslation()

    const configFields = useMemo((): FieldProps[] => {
        return [
            {
                name: 'restrictAgentLoginToActiveDuration',
                label: t('model.systemConfiguration.restrictAgentLoginToActiveDuration'),
                fieldType: 'checkbox',
                gridProps: { xs: 12 }
            },
            {
                name: 'respectTimeLimits',
                label: t('model.systemConfiguration.respectTimeLimits'),
                fieldType: 'checkbox',
                gridProps: { xs: 12 }
            }
        ]
    }, [t])

    const timeParser = (value: string) => {
        if (!value) return null
        return dayjs(`${dayjs().format('YYYY-MM-DDT')}${value}`)
    }

    const generateElectionDayFields = (type: ElectionType): FieldProps[] => {
        return [
            {
                name: `${type}.activeDuration.min`,
                label: `${t('model.systemConfiguration.activeDuration')} (${t('view.common.from')})`,
                fieldType: 'datePicker',
                views: ['year', 'month', 'day'],
                format: 'YYYY-MM-DD',
                gridProps: { xs: 12, md: 6 },
                props: {
                    fullWidth: true
                },
                parser: (value: string) => {
                    return dayjs(value)
                }
            },
            {
                name: `${type}.activeDuration.max`,
                label: `${t('model.systemConfiguration.activeDuration')} (${t('view.common.to')})`,
                fieldType: 'datePicker',
                views: ['year', 'month', 'day'],
                format: 'YYYY-MM-DD',
                gridProps: { xs: 12, md: 6 },
                props: {
                    fullWidth: true
                },
                parser: (value: string) => {
                    return dayjs(value)
                }
            },
            {
                name: `${type}.morningDuration.min`,
                label: `${t('model.systemConfiguration.morningDuration')} (${t('view.common.from')})`,
                fieldType: 'custom',
                gridProps: { xs: 12, md: 6 },
                CustomComponent: TimePickerControllerWrapper,
                props: {
                    name: `${type}.morningDuration.min`,
                    control: formConfigs.control,
                    parser: timeParser
                }
            },
            {
                name: `${type}.morningDuration.max`,
                label: `${t('model.systemConfiguration.morningDuration')} (${t('view.common.to')})`,
                fieldType: 'custom',
                gridProps: { xs: 12, md: 6 },
                CustomComponent: TimePickerControllerWrapper,
                props: {
                    name: `${type}.morningDuration.max`,
                    control: formConfigs.control,
                    parser: timeParser
                }
            },
            {
                name: `${type}.afternoonDuration.min`,
                label: `${t('model.systemConfiguration.afternoonDuration')} (${t('view.common.from')})`,
                fieldType: 'custom',
                gridProps: { xs: 12, md: 6 },
                CustomComponent: TimePickerControllerWrapper,
                props: {
                    name: `${type}.afternoonDuration.min`,
                    control: formConfigs.control,
                    parser: timeParser
                }
            },
            {
                name: `${type}.afternoonDuration.max`,
                label: `${t('model.systemConfiguration.afternoonDuration')} (${t('view.common.to')})`,
                fieldType: 'custom',
                gridProps: { xs: 12, md: 6 },
                CustomComponent: TimePickerControllerWrapper,
                props: {
                    name: `${type}.afternoonDuration.max`,
                    control: formConfigs.control,
                    parser: timeParser
                }
            },
            {
                name: `${type}.eveningDuration.min`,
                label: `${t('model.systemConfiguration.eveningDuration')} (${t('view.common.from')})`,
                fieldType: 'custom',
                gridProps: { xs: 12, md: 6 },
                CustomComponent: TimePickerControllerWrapper,
                props: {
                    name: `${type}.eveningDuration.min`,
                    control: formConfigs.control,
                    parser: timeParser
                }
            },
            {
                name: `${type}.eveningDuration.max`,
                label: `${t('model.systemConfiguration.eveningDuration')} (${t('view.common.to')})`,
                fieldType: 'custom',
                gridProps: { xs: 12, md: 6 },
                CustomComponent: TimePickerControllerWrapper,
                props: {
                    name: `${type}.eveningDuration.max`,
                    control: formConfigs.control,
                    parser: timeParser
                }
            }
        ]
    }

    return {
        configFields,
        generateElectionDayFields
    }
}
