import React, { useEffect } from 'react'
import { AlertDialog } from './alert-dialog'
import { useTranslation } from 'react-i18next'
import { useAuth } from 'src/hooks/use-auth'
import dayjs from 'dayjs'

const TimezoneDialog = () => {
    const [open, setOpen] = React.useState(false)
    const [t] = useTranslation()
    const { user } = useAuth()

    useEffect(() => {
        if (user?.erbilTime) {
            // Parse the timestamp with dayjs
            const parsedTime = dayjs(user.erbilTime)
            // Get the current device time
            const currentTime = dayjs()
            // Get the difference in minutes
            const differenceInMinutes = currentTime.diff(parsedTime, 'minute')

            if (Math.abs(differenceInMinutes) > 5) {
                setOpen(true)
            } else {
                setOpen(false)
            }
        }
    }, [user?.erbilTime])

    return (
        <AlertDialog
            open={open}
            title={t('view.timezone.timezone_difference')}
            loading={false}
            handleSubmit={() => {
                window.location.reload()
            }}
            submitText={t('view.versionMismatch.refresh')}
        />
    )
}

export default TimezoneDialog
