import { CreatePartyRequest, UpdatePartyRequest } from 'src/services/api/emos'

type CreatePartyRequestKeys = Array<keyof CreatePartyRequest>
export const createPartyAttributesToKeep: CreatePartyRequestKeys = [
    'circleIds',
    'color',
    'isActive',
    'name',
    'number',
    'partyType',
    'photo',
    'seatType'
]

type UpdatePartyRequestKeys = Array<keyof UpdatePartyRequest>
export const updatePartyAttributesToKeep: UpdatePartyRequestKeys = [
    'circleIds',
    'color',
    'isActive',
    'name',
    'number',
    'partyType',
    'photo',
    'seatType'
]
