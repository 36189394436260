import Button from '@mui/material/Button'
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import MiniDrawer from 'src/components/drawer/mini-drawer'
import { DetailsListProps } from 'src/components/list/details-list'
import { Stack } from '@mui/system'
import { CandidatesService } from 'src/services/api/emos'
import Edit from './edit'
import { useToast } from 'src/hooks/useToast'
import ImagePreview from 'src/components/image-preview'
import ModalImage from 'src/components/modal-image'
import StatusLabel from 'src/components/label/status-label'
import { ACTIONS, RESOURCES, useCheckPermissions } from 'src/hooks/use-check-permissions'
import AuditsDialog from '../audits/audits-dialog'

interface Props {
    open: boolean
    close: () => void
    data: any
}

const ProfileDrawer = ({ open, close, data }: Props) => {
    const [t] = useTranslation()
    const [edit, setEdit] = useState<boolean>(false)
    const queryClient = useQueryClient()
    const { successToast } = useToast()
    const { can } = useCheckPermissions()
    const [image, setImage] = useState<string | null>(null)
    const closeDrawer = useCallback(() => {
        close()
    }, [close])
    const [auditsDialogOpen, setAuditsDialogOpen] = useState(false)

    const {
        data: candidate,
        isLoading,
        isRefetching,
        refetch
    } = useQuery({
        queryKey: ['candidate', data],
        queryFn: async () => {
            const res = await CandidatesService.getCandidateById({
                id: data?.id
            })
            return res
        },
        enabled: !!data
    })

    const details: DetailsListProps = useMemo(
        () => ({
            title: t('view.common.details.label'),
            fields: candidate
                ? [
                      { label: t('model.common.id'), value: candidate.id },
                      { label: t('model.common.name'), value: candidate.name },
                      { label: t('model.common.number'), value: candidate.number },
                      { label: t('view.circle.name.headline'), value: candidate.circle.name },
                      { label: t('view.party.name.headline'), value: candidate.party.name },
                      {
                          label: t('model.common.gender.label'),
                          value: t(`model.common.gender.options.${candidate.gender.toLowerCase()}` as any)
                      },
                      {
                          label: t('model.common.photo'),
                          Template: ImagePreview,
                          templateProps: {
                              image: candidate.photo?.url,
                              onClick: () => {
                                  setImage(candidate.photo?.url ?? '')
                              }
                          }
                      },
                      {
                          label: t('model.common.status'),
                          Template: StatusLabel,
                          templateProps: {
                              isActive: candidate?.isActive
                          }
                      }
                  ]
                : []
        }),
        [candidate, t]
    )

    return (
        <MiniDrawer
            open={open}
            close={closeDrawer}
            header={t('view.candidate.name.headline')}
            details={edit ? undefined : details}
            loading={isLoading}
        >
            {!edit && (
                <Stack direction="row" justifyContent="space-between">
                    <Button onClick={closeDrawer} variant="outlined" size="small">
                        {t('view.common.dialog.close')}
                    </Button>

                    <Stack direction="row" spacing={2} justifyContent="flex-end">
                        {can({
                            resource: RESOURCES.audit,
                            actions: [ACTIONS.read]
                        }) && (
                            <Button
                                variant="contained"
                                size="small"
                                color="info"
                                onClick={() => setAuditsDialogOpen(true)}
                            >
                                {t('view.common.audit')}
                            </Button>
                        )}
                        {can({
                            resource: RESOURCES.candidate,
                            actions: [ACTIONS.update]
                        }) && (
                            <Button
                                variant="contained"
                                size="small"
                                onClick={() => setEdit(true)}
                                disabled={isRefetching}
                            >
                                {t('view.actions.common.edit')}
                            </Button>
                        )}
                    </Stack>
                </Stack>
            )}
            {edit && candidate && (
                <Edit
                    candidate={candidate}
                    handleSuccess={() => {
                        successToast(
                            t(`view.notifications.common.update`, {
                                name: t(`view.candidate.name.headline`)
                            })
                        )
                        refetch()
                        queryClient.invalidateQueries({ queryKey: ['users'] })
                        setEdit(false)
                    }}
                    handleClose={() => {
                        setEdit(false)
                    }}
                />
            )}

            {candidate && (
                <AuditsDialog
                    version={candidate?.version ?? 0}
                    open={auditsDialogOpen}
                    onClose={() => setAuditsDialogOpen(false)}
                    id={candidate?.id ?? ''}
                    type="candidates"
                />
            )}
            <ModalImage open={!!image} large={image} alt="image" onClose={() => setImage(null)} />
        </MiniDrawer>
    )
}

export default ProfileDrawer
