import { ColorPreset, Contrast } from 'src/theme'
import { create, StateCreator } from 'zustand'
import { persist, createJSONStorage } from 'zustand/middleware'
import { name } from '../../package.json'

export type Locale = 'en' | 'ckb' | string
interface SettingsState {
    colorPreset: ColorPreset
    contrast: Contrast
    direction: 'ltr' | 'rtl'
    language: Locale
    layout: 'vertical' | 'horizontal'
    navColor: 'evident' | 'discrete' | 'blend-in'
    paletteMode: 'light' | 'dark'
    responsiveFontSizes: boolean
    stretch: boolean
    sidebar: boolean

    setColorPreset: (colorPreset: ColorPreset) => void
    setContrast: (contrast: Contrast) => void
    setDirection: (direction: 'ltr' | 'rtl') => void
    setLanguage: (language: Locale) => void
    setLayout: (layout: 'vertical' | 'horizontal') => void
    setNavColor: (navColor: 'evident' | 'discrete' | 'blend-in') => void
    setPaletteMode: (paletteMode: 'light' | 'dark') => void
    setResponsiveFontSizes: (responsiveFontSizes: boolean) => void
    setStretch: (stretch: boolean) => void
    toggleSidebar: () => void
    setSidebar: (sidebar: boolean) => void
}

const settingsConfigs: StateCreator<SettingsState> = (set, get) => ({
    colorPreset: 'black',
    contrast: 'high',
    direction: 'ltr',
    language: 'ckb',
    layout: 'horizontal',
    navColor: 'evident',
    paletteMode: 'light',
    responsiveFontSizes: true,
    stretch: false,
    sidebar: false,

    setColorPreset: (colorPreset) => set({ colorPreset }),
    setContrast: (contrast) => set({ contrast }),
    setDirection: (direction) => set({ direction }),
    setLanguage: (language) => set({ language }),
    setLayout: (layout) => set({ layout }),
    setNavColor: (navColor) => set({ navColor }),
    setPaletteMode: (paletteMode) => set({ paletteMode }),
    setResponsiveFontSizes: (responsiveFontSizes) => set({ responsiveFontSizes }),
    setStretch: (stretch) => set({ stretch }),
    toggleSidebar: () => set({ sidebar: !get().sidebar }),
    setSidebar: (sidebar) => set({ sidebar })
})

export const useSettings = create<SettingsState, [['zustand/persist', SettingsState]]>(
    persist(settingsConfigs, {
        name: name + '/settings',
        storage: createJSONStorage(() => localStorage)
    })
)
