import Button from '@mui/material/Button'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import DetailsList, { DetailsListProps } from 'src/components/list/details-list'
import { Stack } from '@mui/system'
import { SystemConfigService } from 'src/services/api/emos'
import Edit from './edit'
import { Grid, Paper, Typography } from '@mui/material'
import { useToast } from 'src/hooks/useToast'
import dayjs from 'dayjs'

export type ElectionType = 'privateElectionDay' | 'publicElectionDay'
export const ELECTION_TYPES: ElectionType[] = ['privateElectionDay', 'publicElectionDay']

const DayRange = ({ min, max }: { min: string; max: string }) => {
    return (
        <Typography variant="body2">
            {min} - {max}
        </Typography>
    )
}

const ProfileDrawer = () => {
    const [t] = useTranslation()
    const [edit, setEdit] = useState<boolean>(false)
    const { successToast } = useToast()
    const queryClient = useQueryClient()

    const {
        data: systemConfiguration,
        isLoading,
        isRefetching
    } = useQuery({
        queryKey: ['systemConfiguration'],
        queryFn: async () => {
            const res = await SystemConfigService.getSystemConfig()
            return res
        }
    })

    const concatWithDate = (time?: string) => {
        if (!time) return null
        return dayjs(`${dayjs().format('YYYY-MM-DDT')}${time}`).format('hh:mm A')
    }

    const generateDetails = useCallback(
        (type: ElectionType) => {
            if (!systemConfiguration || !type) return { fields: [] }
            return {
                title: t(`model.systemConfiguration.${type}`),
                fields: systemConfiguration
                    ? [
                          {
                              label: t('model.systemConfiguration.activeDuration'),
                              Template: DayRange,
                              templateProps: {
                                  min: systemConfiguration[type].activeDuration.min,
                                  max: systemConfiguration[type].activeDuration.max
                              }
                          },

                          {
                              label: t('model.systemConfiguration.morningDuration'),
                              Template: DayRange,
                              templateProps: {
                                  min: concatWithDate(systemConfiguration[type].morningDuration.min),
                                  max: concatWithDate(systemConfiguration[type].morningDuration.max)
                              }
                          },
                          {
                              label: t('model.systemConfiguration.afternoonDuration'),
                              Template: DayRange,
                              templateProps: {
                                  min: concatWithDate(systemConfiguration[type].afternoonDuration.min),
                                  max: concatWithDate(systemConfiguration[type].afternoonDuration.max)
                              }
                          },
                          {
                              label: t('model.systemConfiguration.eveningDuration'),
                              Template: DayRange,
                              templateProps: {
                                  min: concatWithDate(systemConfiguration[type].eveningDuration.min),
                                  max: concatWithDate(systemConfiguration[type].eveningDuration.max)
                              }
                          }
                      ]
                    : []
            } as DetailsListProps
        },
        [systemConfiguration, t]
    )

    if (isLoading) return <div>Loading...</div>

    return (
        <>
            {
                <Stack gap={2}>
                    {!edit && (
                        <>
                            <Grid container spacing={1}>
                                <Grid item xs={12} md={6}>
                                    <Paper sx={{ p: 2 }}>
                                        <DetailsList
                                            details={{
                                                fields: [
                                                    {
                                                        label: t(
                                                            'model.systemConfiguration.restrictAgentLoginToActiveDuration'
                                                        ),
                                                        value: t(
                                                            `view.common.yes/no.${systemConfiguration?.restrictAgentLoginToActiveDuration ?? false}`
                                                        )
                                                    }
                                                ]
                                            }}
                                        />
                                    </Paper>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Paper sx={{ p: 2 }}>
                                        <DetailsList
                                            details={{
                                                fields: [
                                                    {
                                                        label: t('model.systemConfiguration.respectTimeLimits'),
                                                        value: t(
                                                            `view.common.yes/no.${systemConfiguration?.respectTimeLimits ?? false}`
                                                        )
                                                    }
                                                ]
                                            }}
                                        />
                                    </Paper>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                {ELECTION_TYPES.map((type) => (
                                    <Grid item xs={12} md={6} key={type}>
                                        <Paper sx={{ p: 2 }}>
                                            <DetailsList details={generateDetails(type)} />
                                        </Paper>
                                    </Grid>
                                ))}
                                <Grid item xs={12}>
                                    {!edit && (
                                        <Stack direction="row-reverse">
                                            <Button
                                                variant="contained"
                                                size="small"
                                                onClick={() => setEdit(true)}
                                                disabled={isRefetching}
                                            >
                                                {t('view.actions.common.edit')}
                                            </Button>
                                        </Stack>
                                    )}
                                </Grid>
                            </Grid>
                        </>
                    )}

                    {edit && systemConfiguration && (
                        <Edit
                            systemConfiguration={systemConfiguration}
                            handleSuccess={() => {
                                successToast(
                                    t(`view.notifications.common.update`, {
                                        name: t(`view.systemConfiguration.name.headline`)
                                    })
                                )
                                queryClient.invalidateQueries({ queryKey: ['systemConfiguration'] })
                                setEdit(false)
                            }}
                            handleClose={() => {
                                setEdit(false)
                            }}
                        />
                    )}
                </Stack>
            }
        </>
    )
}

export default ProfileDrawer
