import type { FC } from 'react';
import TopIcon from '@mui/icons-material/AlignVerticalTop';
import LeftIcon from '@mui/icons-material/AlignHorizontalLeft';
import { useSettings } from 'src/store/settings';

import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

export const LayoutToggler: FC = () => {
    const { layout, setLayout } = useSettings();

    return (
        <ToggleButtonGroup
            exclusive
            value={layout}
            onChange={(_, newLayout) => {
                newLayout && setLayout(newLayout);
            }}
            size="small"
        >
            <ToggleButton value="horizontal">
                <TopIcon fontSize="small" />
            </ToggleButton>
            <ToggleButton value="vertical">
                <LeftIcon fontSize="small" />
            </ToggleButton>
        </ToggleButtonGroup>
    );
};
