import { useTranslation } from 'react-i18next'
import Results from 'src/components/dashboard/voting-results/results'
import LayoutPage from 'src/components/page'

const VotingResults = () => {
    const { t } = useTranslation()

    return (
        <LayoutPage
            title="Voting Results"
            breadcrumbs={[
                {
                    title: t('view.votingResult.header')
                }
            ]}
        >
            <Results />
        </LayoutPage>
    )
}

export default VotingResults
