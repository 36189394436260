import { type FC } from 'react'
import Menu01Icon from '@untitled-ui/icons-react/build/esm/Menu01'
import type { Theme } from '@mui/material'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'
import SvgIcon from '@mui/material/SvgIcon'
import useMediaQuery from '@mui/material/useMediaQuery'
import { alpha } from '@mui/material/styles'
import { AccountButton } from '../account-button'
import { LanguageSwitch } from '../language-switch'
import { useSettings } from 'src/store/settings'
import { ThemePopover } from '../theme-popover'
import { NotificationsButton } from '../notifications-button'
import { BreadCrumbs } from 'src/components/breadcrumbs'
import { useBreadcrumb } from 'src/contexts/breadcrumb-context'
import { SettingsButton } from '../settings-popover/settings-button'

const TOP_NAV_HEIGHT = 64
const SIDE_NAV_WIDTH_OPEN = 300
const SIDE_NAV_WIDTH_CLOSED = 72

interface TopNavProps {
    onMobileNavOpen?: () => void
}

export const TopNav: FC<TopNavProps> = (props) => {
    const { onMobileNavOpen, ...other } = props
    const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'))
    const { sidebar } = useSettings()
    const SIDE_NAV_WIDTH = sidebar ? SIDE_NAV_WIDTH_OPEN : SIDE_NAV_WIDTH_CLOSED

    const { breadcrumb } = useBreadcrumb()

    return (
        <Box
            component="header"
            sx={{
                backdropFilter: 'blur(6px)',
                backgroundColor: (theme) => alpha(theme.palette.background.default, 0.8),
                position: 'sticky',
                left: {
                    lg: `${SIDE_NAV_WIDTH}px`
                },
                top: 0,
                width: {
                    lg: `calc(100% - ${SIDE_NAV_WIDTH}px)`
                },
                zIndex: (theme) => theme.zIndex.appBar
            }}
            {...other}
        >
            <Stack
                alignItems="center"
                direction="row"
                justifyContent="space-between"
                spacing={2}
                sx={{
                    minHeight: TOP_NAV_HEIGHT,
                    px: 2
                }}
            >
                <Stack alignItems="center" direction="row" spacing={2}>
                    {!lgUp && (
                        <IconButton onClick={onMobileNavOpen}>
                            <SvgIcon>
                                <Menu01Icon />
                            </SvgIcon>
                        </IconButton>
                    )}
                    {breadcrumb && <BreadCrumbs items={breadcrumb} />}
                </Stack>
                <Stack alignItems="center" direction="row" spacing={1} flexWrap="wrap">
                    <LanguageSwitch />
                    <NotificationsButton />
                    <SettingsButton />
                    {/* <ThemePopover /> */}
                    <AccountButton />
                </Stack>
            </Stack>
        </Box>
    )
}
