import { useQuery } from '@tanstack/react-query'
import dayjs from 'dayjs'
import { FieldProps } from 'mui-rhf-library'
import { useMemo } from 'react'
import { UseFormReturn } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { TimePickerControllerWrapper } from 'src/components/dashboard/system-config/edit'
import { allStationsQuery } from 'src/queries/stations'
import StationListInput from 'src/components/dashboard/stations/list-input'
import SectionDivider from 'src/components/input/section-divider'
import { StationOperationalStatus } from 'src/services/api/emos'

export const REASONS = {
    HOT_ETHERNET_CABLE: 'hot_ethernet_cable',
    INEXPERIENCE_OF_COMMISSION_EMPLOYEES: 'inexperience_of_commission_employees',
    DATABASE_ERROR: 'database_error',
    PCOS_DEVICE: 'pcos_device',
    VVD_DEVICE: 'vvd_device',
    RTS_DEVICE: 'rts_device',
    OTHER: 'other'
}

export const useIncidents = ({
    formConfigs,
    isStationInitiation,
    hideDivider,
    isEdit
}: {
    formConfigs: UseFormReturn<any>
    isStationInitiation?: boolean
    hideDivider?: boolean
    isEdit?: boolean
}) => {
    const { t } = useTranslation()

    const { data: stations = [], isLoading } = useQuery({
        ...allStationsQuery({
            isInitiated: true
        }),
        enabled: !isStationInitiation
    })

    const timeParser = (value: string) => {
        if (!value) return null
        return dayjs(value, 'hh:mm:ss')
    }

    const operationTypeWatcher = formConfigs.watch('operationType')

    const isInitiationAndOperational =
        isStationInitiation && (operationTypeWatcher === StationOperationalStatus.OPERATIONAL || !operationTypeWatcher)

    const isNotStationInitiationAndNoStationSelected = !isStationInitiation && !formConfigs.watch('stationId')

    const fields = useMemo((): FieldProps[] => {
        return [
            {
                hidden: isStationInitiation,
                name: isStationInitiation ? 'incident.stationId' : 'stationId',
                label: t('view.station.header'),
                fieldType: 'custom',
                CustomComponent: StationListInput,
                props: {
                    formConfigs,
                    stations,
                    isEdit,
                    loading: isLoading
                }
            },
            {
                hidden: isInitiationAndOperational || hideDivider || isNotStationInitiationAndNoStationSelected,
                name: 'divider',
                fieldType: 'custom',
                CustomComponent: SectionDivider,
                props: {
                    header: t('view.common.details.label')
                }
            },
            {
                hidden: isStationInitiation || isNotStationInitiationAndNoStationSelected,
                name: 'timeOfBreakage',
                label: t('model.incident.timeOfBreakage'),
                fieldType: 'custom',
                gridProps: {
                    xs: 12,
                    sm: 6,
                    md: 6
                },
                CustomComponent: TimePickerControllerWrapper,
                props: {
                    name: 'timeOfBreakage',
                    control: formConfigs.control,
                    label: t('model.incident.timeOfBreakage'),
                    parser: timeParser
                }
            },
            {
                hidden: isInitiationAndOperational || isNotStationInitiationAndNoStationSelected,
                name: isStationInitiation ? 'incident.reason' : 'reason',
                label: t('model.incident.reason.label'),
                fieldType: 'autocomplete',
                props: {
                    fullWidth: true,
                    options: Object.values(REASONS).map((reason) => ({
                        value: reason,
                        label: t(`model.incident.reason.options.${reason}` as any)
                    }))
                },
                gridProps: {
                    xs: 12,
                    sm: 6,
                    md: 6
                },
                textFieldProps: {
                    label: t('model.incident.reason.label'),
                    fullWidth: true
                }
            },
            {
                hidden: isInitiationAndOperational || isNotStationInitiationAndNoStationSelected,
                name: isStationInitiation ? 'incident.description' : 'description',
                label: t('model.incident.description'),
                fieldType: 'textField',
                props: {
                    fullWidth: true
                },
                gridProps: {
                    xs: 12,
                    sm: 6,
                    md: 6
                }
            }
        ]
    }, [
        isStationInitiation,
        t,
        formConfigs,
        stations,
        isEdit,
        isLoading,
        isInitiationAndOperational,
        hideDivider,
        isNotStationInitiationAndNoStationSelected
    ])

    return { fields }
}
