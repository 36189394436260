import { Column } from '@ditkrg/react-core'
import { Avatar, Typography } from '@mui/material'
import { Stack } from '@mui/system'
import { useQuery } from '@tanstack/react-query'
import { TFunction } from 'i18next'
import ChipList from 'src/components/chip/chip-list'
import { allCirclesQuery } from 'src/queries/circles'
import { PartyType } from 'src/services/api/emos'

const GenerateColumns = ({ tableState, t }: { tableState: any; t: TFunction }) => {
    const { data: circles = [], isLoading: isLoadingCircles } = useQuery({
        ...allCirclesQuery()
    })

    const cols: Column[] = [
        {
            id: 'index',
            header: '#',
            cell: (_, rowIndex) => tableState.rowsPerPage * tableState.page + rowIndex + 1
        },
        {
            id: 'name',
            header: t('model.common.name'),
            cell: (row) => {
                return (
                    <Stack direction="row" spacing={1} alignItems="center">
                        <Avatar src={row?.photo?.url || ''} />
                        <Typography variant="body2">{row?.name}</Typography>
                    </Stack>
                )
            },
            filter: {
                type: 'text'
            }
        },
        {
            id: 'number',
            header: t('model.common.number'),
            accessor: 'number',
            filter: {
                type: 'text'
            }
        },
        {
            id: 'partyType',
            header: t('model.party.partyType.label'),
            cell: (row) => t(`model.party.partyType.options.${row?.partyType}` as any),
            filter: {
                type: 'select',
                props: {
                    options: Object.values(PartyType).map((value) => ({
                        value,
                        label: t(`model.party.partyType.options.${value}`)
                    }))
                }
            }
        },
        {
            id: 'seatType',
            header: t('model.party.seatType.label'),
            cell: (row) => t(`model.party.seatType.options.${row?.seatType}` as any)
        },
        {
            hidden: true,
            id: 'circleId',
            header: t('view.circle.name.headline'),
            cell: (row) => row.circle?.name,
            filter: {
                type: 'autocomplete',
                props: {
                    disabled: isLoadingCircles,
                    options: circles,
                    optionValue: 'id',
                    optionLabel: 'name'
                }
            }
        },
        {
            id: 'circleIds',
            header: t('view.circle.header'),
            cell: (row) => (
                <ChipList
                    items={row?.circleIds || []}
                    customLabel={(circle) => circles.find((c) => c.id === circle)?.name || ''}
                />
            ),
            cellProps: {
                sx: {
                    maxWidth: 200
                }
            }
        }
    ]
    return cols
}

export default GenerateColumns
