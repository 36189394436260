import { TFunction } from 'i18next'
import { ViolationCommandViolationStatusViolationMetaTransitionResponse, ViolationType } from 'src/services/api/emos'
import * as yup from 'yup'

export const createSchema = () => {
    return yup.object().shape({
        stationId: yup.string().required(),
        description: yup.string().required(),
        type: yup.string().oneOf(Object.values(ViolationType)).required(),
        complainant: yup.object().shape({
            name: yup.string().required(),
            type: yup.string().required(),
            partyId: yup.string().nullable()
        }),
        allegedViolator: yup.object().shape({
            name: yup.string().required(),
            type: yup.string(),
            partyId: yup.string().nullable()
        })
    })
}

export const transitionSchema = ({
    t,
    transition
}: {
    t: TFunction
    transition: ViolationCommandViolationStatusViolationMetaTransitionResponse
}) =>
    yup.object().shape({
        comment: yup.string().test({
            name: 'comment-is-required',
            message: t('view.validations.required'),
            test: (value) => {
                return transition.meta?.requiresComment ? !!value : true
            }
        }),
        attachment: yup.array().test({
            name: 'attachment-is-required',
            message: t('view.validations.required'),
            test: (value) => {
                return transition.meta?.requiresAttachment ? !!value?.length : true
            }
        })
    })
