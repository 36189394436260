import React, { useState } from 'react'
import { LinearProgress, Table as MuiTable, TablePagination, Typography } from '@mui/material'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { useTranslation } from 'react-i18next'
import { styled } from '@mui/material/styles'
import get from 'lodash.get'
import dateFormat from 'src/utils/date-format'

const Table = styled(MuiTable)(
    () => `
    min-width: 650px;
`
)

const rowsPerPageOptions = [5, 10, 25]

function AppTable({
    loading,
    columns,
    rows,
    customActionsFunction,
    hover,
    handleRowAction,
    selectedWith,
    showIndex,
    hidePagination
}: Readonly<{
    loading: boolean
    columns: any[]
    rows: any[]
    customActionsFunction?: (row: any) => any
    hover?: boolean
    handleRowAction?: (row: any) => void
    selectedWith?: any
    showIndex?: boolean
    hidePagination?: boolean
}>) {
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [page, setPage] = useState(0)
    const [t, i18next] = useTranslation()

    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    }
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0)
    }

    const handleSelectedWith = (row) => {
        return row.match[selectedWith?.key] === selectedWith?.value
    }

    return (
        <>
            <TableContainer component={Paper} variant="outlined">
                {loading && <LinearProgress />}
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            {columns.map((column) => (
                                <TableCell
                                    key={column.id}
                                    align={column?.align}
                                    className="capitalize"
                                    style={{ maxWidth: column.width }}
                                >
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                            <TableRow
                                key={index}
                                hover={hover}
                                style={hover ? { cursor: 'pointer' } : {}}
                                selected={selectedWith && handleSelectedWith(row)}
                            >
                                {showIndex && (
                                    <TableCell
                                        onClick={() => {
                                            if (handleRowAction) handleRowAction(row)
                                        }}
                                    >
                                        {page * rowsPerPage + index + 1}
                                    </TableCell>
                                )}
                                {columns
                                    .filter((column) => column.field !== 'index' && column.field !== 'actions')
                                    .map((column, i) => (
                                        <TableCell
                                            key={`tableCell-${i}`}
                                            align={column?.align}
                                            component="td"
                                            scope="row"
                                            className={column.capitalize ? 'capitalize' : ''}
                                            sx={{
                                                maxWidth: column.width,
                                                verticalAlign: '-webkit-baseline-middle'
                                            }}
                                            onClick={() => {
                                                if (handleRowAction) handleRowAction(row)
                                            }}
                                        >
                                            {(() => {
                                                if (column.isDate)
                                                    return row[column.field]
                                                        ? dateFormat({
                                                              date: row[column.field]
                                                          })
                                                        : ''
                                                else if (column.template)
                                                    return column.template({ ...row, ...column, t, i18next })
                                                else return get(row, column.field)
                                            })()}
                                        </TableCell>
                                    ))}

                                {customActionsFunction && (
                                    <TableCell align="right"> {customActionsFunction(row)} </TableCell>
                                )}
                            </TableRow>
                        ))}
                        {!loading && rows?.length <= 0 && (
                            <TableRow>
                                <TableCell colSpan={columns.length} align="center">
                                    <Typography color="textSecondary">{t('view.common.no_data')}</Typography>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            {!hidePagination && (
                <TablePagination
                    rowsPerPageOptions={rowsPerPageOptions}
                    component="div"
                    count={rows?.length || 0}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage={t('view.common.pagination.rows_per_page')}
                />
            )}
        </>
    )
}

export default AppTable
