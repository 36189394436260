export const paths = {
    index: '/',
    management: '/management',
    users: '/management/users',
    account: '/account',
    releaseNotes: '/release-notes',
    allReports: '/reports',
    reports: '/reports/reports',
    operationReports: '/reports/operation-reports',
    auth: {
        login: '/auth/sign_in',
        logout: '/auth/sing_out'
    },
    candidates: '/management/candidates',
    parties: '/management/parties',
    nodes: '/management/nodes',
    circles: '/management/circles',
    systemConfiguration: '/management/system-configuration',
    incidents: {
        index: '/incidents',
        new: '/incidents/new',
        edit: (id) => `/incidents/${id}/edit`,
        view: (id) => `/incidents/${id}`
    },
    pollingCenters: '/polling-centers',
    stations: {
        index: '/stations',
        new: '/stations/new',
        edit: (id) => `/stations/${id}/edit`,
        view: (id) => `/stations/${id}`,
        initiate: (id) => `/stations/${id}/initiate`
    },
    violations: {
        index: '/violations',
        new: '/violations/new',
        edit: (id) => `/violations/${id}/edit`,
        view: (id) => `/violations/${id}`
    },
    votingResults: {
        index: '/voting-results',
        new: (id) => `/voting-results/${id}/new`,
        edit: (id) => `/voting-results/${id}/edit`,
        view: (id) => `/voting-results/${id}`
    }
}
