import React from 'react'
import { AdvancedFilters, AdvancedTable } from '@ditkrg/react-core'
import { Card } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { NodeType, ViolationsService, ViolationStatus } from 'src/services/api/emos'
import { useViolationsTableState } from 'src/store/table'
import { useTranslation } from 'react-i18next'
import { useRouter } from 'src/hooks/use-router'
import { paths } from 'src/paths'
import { GenerateColumns } from './columns'
import { ROWS_PER_PAGE_OPTIONS } from 'src/utils/constants'
import { useSettings } from 'src/store/settings'
import { useNodeFilters } from 'src/hooks/use-node-filters'
import { allStationsQuery } from 'src/queries/stations'

const Results = () => {
    const { t } = useTranslation()
    const router = useRouter()
    const violationsTableState = useViolationsTableState()
    const { page, rowsPerPage, filters } = violationsTableState
    const { language } = useSettings()
    const { filterInputs } = useNodeFilters({
        state: violationsTableState
    })

    const { data: stations = [], isPending } = useQuery({
        ...allStationsQuery({})
    })

    const {
        data: violations,
        isLoading,
        isFetching
    } = useQuery({
        queryKey: ['violations', rowsPerPage, page, filters],
        queryFn: async () => {
            const res = await ViolationsService.listViolations({
                page: page + 1,
                rowsPerPage,
                stationId: filters?.stationId,
                ancestorId:
                    filters?.[NodeType.POLLING_CENTER] ||
                    filters?.[NodeType.SUB_BRANCH] ||
                    filters?.[NodeType.BRANCH] ||
                    filters?.[NodeType.PBS] ||
                    undefined,
                status: filters?.status
            })
            return res
        },
        placeholderData: (placeholderData) => placeholderData
    })

    return (
        <Card variant="outlined">
            <AdvancedFilters
                state={violationsTableState}
                filters={[
                    ...filterInputs,
                    {
                        id: 'stationId',
                        type: 'autocomplete',
                        header: t('view.station.name.headline'),
                        props: {
                            options: stations,
                            customOptionLabel: (option) => `${t('view.station.name.headline')}: ${option?.number}`,
                            optionValue: 'id',
                            disabled: isPending,
                            virtualized: true
                        }
                    },
                    {
                        id: 'status',
                        type: 'select',
                        header: t('model.common.status'),
                        props: {
                            options:
                                Object.values(ViolationStatus).map((status) => ({
                                    value: status,
                                    label: t(`model.violation.states.${status.toLowerCase()}` as any)
                                })) ?? []
                        }
                    }
                ]}
                showCollapseButton={false}
                locale={language as any}
            />
            <AdvancedTable
                data={violations?.data ?? []}
                columns={GenerateColumns({ tableState: violationsTableState })}
                state={violationsTableState}
                totalItems={violations?.meta?.pagination?.count ?? 0}
                onRowClick={(row) => {
                    router.push(paths.violations.view(row.id))
                }}
                loading={isLoading || isFetching}
                loadingVariant={isFetching ? 'linear' : 'skeleton'}
                filterLabel={t('view.common.filter.label')}
                clearLabel={t('view.common.clear')}
                paginationVariant="advanced"
                rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
                locale={language as any}
            />
        </Card>
    )
}

export default Results
