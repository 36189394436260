/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum ViolationStatus {
    OPEN = 'Open',
    REPORTED = 'Reported',
    SUBMITTED = 'Submitted',
    DROPPED = 'Dropped',
}
