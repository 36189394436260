import dayjs from 'dayjs'
import * as yup from 'yup'

const minMaxTimeSchema = yup.string().required()

const dateSchema = yup
    .string()
    .required()
    .transform((value) => {
        if (!value) return null
        return dayjs(value).format('YYYY-MM-DD')
    })

export const createSchema = () => {
    return yup.object().shape({
        restrictAgentLoginToActiveDuration: yup.boolean(),
        privateElectionDay: yup.object().shape({
            activeDuration: yup.object().shape({
                min: dateSchema,
                max: dateSchema
            }),
            morningDuration: yup.object().shape({
                min: minMaxTimeSchema,
                max: minMaxTimeSchema
            }),
            afternoonDuration: yup.object().shape({
                min: minMaxTimeSchema,
                max: minMaxTimeSchema
            }),
            eveningDuration: yup.object().shape({
                min: minMaxTimeSchema,
                max: minMaxTimeSchema
            })
        }),
        publicElectionDay: yup.object().shape({
            activeDuration: yup.object().shape({
                min: dateSchema,
                max: dateSchema
            }),
            morningDuration: yup.object().shape({
                min: minMaxTimeSchema,
                max: minMaxTimeSchema
            }),
            afternoonDuration: yup.object().shape({
                min: minMaxTimeSchema,
                max: minMaxTimeSchema
            }),
            eveningDuration: yup.object().shape({
                min: minMaxTimeSchema,
                max: minMaxTimeSchema
            })
        })
    })
}
