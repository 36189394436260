import { FormFields } from 'mui-rhf-library'
import { SubmitHandler, useForm } from 'react-hook-form'
import FormActions from 'src/components/drawer/form-actions'
import { yupResolver } from '@hookform/resolvers/yup'
import { initiateSchema } from './schema'
import { useStationsInitiation } from 'src/hooks/use-stations-initiation'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { StationsService } from 'src/services/api/emos'
import { generateTimeOfBreakage } from '../incidents/utils'
import { useToast } from 'src/hooks/useToast'
import { Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'

const InitiateStation = ({
    id,
    handleClose,
    handleSuccess,
    allowStationSelect,
    keepAlive
}: {
    id?: string
    handleClose: () => void
    handleSuccess?: () => void
    allowStationSelect?: boolean
    keepAlive?: boolean
}) => {
    const { errorsToast, successToast } = useToast()
    const queryClient = useQueryClient()
    const { t } = useTranslation()

    const formConfigs = useForm<any>({
        resolver: yupResolver(initiateSchema(t)),
        defaultValues: {
            stationId: id
        }
    })

    const {
        handleSubmit,
        control,
        formState: { isDirty },
        reset
    } = formConfigs

    const { initiationFields } = useStationsInitiation({ formConfigs, allowStationSelect })

    const { mutate, isPending } = useMutation({
        mutationKey: ['initiateStation'],
        mutationFn: async (formData: any) => {
            return await StationsService.initiateStation({
                id: formData.stationId,
                requestBody: {
                    incident:
                        Object.keys(formData.incident).length === 0
                            ? null
                            : {
                                  ...formData.incident,
                                  timeOfBreakage: formData.incident?.timeOfBreakage
                                      ? generateTimeOfBreakage(formData.incident.timeOfBreakage)
                                      : null
                              }
                }
            })
        },
        onSuccess: () => {
            reset()
            queryClient.invalidateQueries({ queryKey: ['allStations'] })
            successToast(t('view.station.initiate.success'))
            handleSuccess?.()
            if (!keepAlive) {
                handleClose()
            }
        },
        onError: (error) => {
            errorsToast(error)
        }
    })

    const handleFormSubmit: SubmitHandler<any> = async (formData) => {
        mutate(formData)
    }

    return (
        <form onSubmit={handleSubmit(handleFormSubmit)}>
            <Grid container spacing={2}>
                <FormFields fields={initiationFields} control={control} />
            </Grid>
            <FormActions
                disabled={!isDirty}
                handleClose={() => {
                    handleClose()
                }}
                handleSubmit={handleSubmit(handleFormSubmit)}
                loading={isPending}
            />
        </form>
    )
}

export default InitiateStation
