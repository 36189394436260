import { Grid, Typography } from '@mui/material'
import { useMutation } from '@tanstack/react-query'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useToast } from 'src/hooks/useToast'
import { DetailedVotingResultResponse, VotingResultsService } from 'src/services/api/emos'
import { UploadField } from '@ditkrg/react-core'
import WebcamDialog from 'src/components/upload/webcam-dialog'
import axios from 'axios'
import { Camera, Upload } from 'lucide-react'
import { ACTIONS, RESOURCES, useCheckPermissions } from 'src/hooks/use-check-permissions'
import { useVotingResult } from 'src/hooks/use-voting-results'
import VersionMismatchDialog from './version-mismatch-dialog'
import { useVersion } from 'src/contexts/version-context'

interface UploadResponse {
    secret: string
    url: string
    contentType: string
}

const MAX_FILES = 1

const VotingResultAttachment = ({ votingResult }: { votingResult: DetailedVotingResultResponse }) => {
    const { t } = useTranslation()
    const { errorsToast, errorToast } = useToast()
    const { can } = useCheckPermissions()
    const [loading, setLoading] = useState(false)
    const [isUploading, setIsUploading] = useState(false)
    const [attachments, setAttachments] = useState<{ url?: string | null; secret: string }[]>(
        votingResult.attachment ? [{ url: votingResult.attachment?.url, secret: '' }] : []
    )
    const { canEdit } = useVotingResult({ votingResult })

    const { version, incrementVersion } = useVersion()

    const [openWebcam, setOpenWebcam] = useState(false)

    const [openVersionMismatchDialog, setOpenVersionMismatchDialog] = useState(false)

    const { mutate, isPending, isError } = useMutation({
        mutationKey: ['updateVotingResultAttachment'],
        mutationFn: async (data: { attachment: { url: string | null; secret: string } }) => {
            if (!votingResult) throw new Error('Voting Result not found')
            await VotingResultsService.updateVotingResultAttachment({
                id: votingResult.id,
                requestBody: {
                    version,
                    attachment: data.attachment?.secret
                }
            })
            return data
        },
        onSuccess: (resData) => {
            incrementVersion()
            if (resData.attachment) {
                if (resData.attachment.secret) {
                    setAttachments([{ url: resData.attachment.url, secret: resData.attachment.secret }])
                } else {
                    setAttachments([])
                }
            }
        },
        onError: (error: any) => {
            errorsToast(error)
            if (error.statusText === 'Conflict') {
                setOpenVersionMismatchDialog(true)
            }
        }
    })

    const onUpload = (file: any) => {
        mutate({
            attachment: file.response
        })
    }

    const onRemove = (file: any) => {
        mutate({
            attachment: { url: null, secret: '' }
        })
    }

    const handleFileChange = async (event) => {
        if (loading || isUploading) return

        const files = event.target.files
        // go through all the files and make sure they are of the right type
        const acceptedFiles = [] as any
        for (const element of files) {
            if (isValidFileType(element)) {
                acceptedFiles.push(element)
            } else {
                errorToast(t('view.validations.file-invalid-type'))
            }
        }

        if (files?.length <= MAX_FILES) {
            for (const file of acceptedFiles) {
                try {
                    setLoading(true)
                    await startUpload(file)
                } catch (e) {
                    console.log({ e })
                } finally {
                    event.target.value = ''
                    setLoading(false)
                }
            }
        } else {
            errorToast(t('view.validations.max_files', { maxFiles: MAX_FILES }))
        }
    }

    const startUpload = (currentFile: File): Promise<string> => {
        setLoading(true)
        setIsUploading(true)

        const uploadFile = async (): Promise<UploadResponse> => {
            const response = await axios.post<UploadResponse>(
                '/api/v1/uploads',
                { file: currentFile },
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
            )
            return response.data
        }

        return uploadFile()
            .then((response) => {
                onUpload({ response })
                return 'success upload'
            })
            .catch((error) => {
                errorToast(t('view.validations.upload_failed'))
                throw error
            })
            .finally(() => {
                setIsUploading(false)
                setLoading(false)
            })
    }

    const isValidFileType = (file) => {
        return ['image/jpeg', 'image/png', 'image/jpg'].includes(file.type)
    }

    if (!votingResult) return null

    return (
        <Grid item xs={12}>
            <Grid container spacing={2}>
                {can({
                    resource: RESOURCES.voting_result,
                    actions: [ACTIONS.attach]
                }) && (
                    <Grid item xs={12} sm={6}>
                        <UploadField
                            height={85}
                            width={85}
                            name="attachment"
                            label={
                                (
                                    <Typography variant="body2" color="textSecondary">
                                        {t('model.votingResult.receiptPhoto')}
                                    </Typography>
                                ) as any
                            }
                            onUpload={onUpload}
                            onRemove={onRemove}
                            files={attachments}
                            imageSrc="/assets/upload-icon.svg"
                            maxFiles={1}
                            disabled={isPending || !canEdit}
                            errorMessage={isError ? t('view.validations.fail_save') : ''}
                            accept={['image/jpg', 'image/jpeg', 'image/png', 'application/pdf']}
                            uploadUrl="/api/v1/uploads"
                            options={[
                                {
                                    label: t('view.common.uploads.upload'),
                                    icon: <Upload />,
                                    action(openFile, event) {
                                        openFile()
                                    }
                                },
                                {
                                    label: t('view.common.uploads.capture'),
                                    icon: <Camera />,
                                    action: () => {
                                        setOpenWebcam(true)
                                    }
                                }
                            ]}
                        />
                    </Grid>
                )}
                {openWebcam && (
                    <WebcamDialog open={openWebcam} setOpen={setOpenWebcam} handleFileChange={handleFileChange} />
                )}
            </Grid>
            <VersionMismatchDialog open={openVersionMismatchDialog} />
        </Grid>
    )
}

export default VotingResultAttachment
