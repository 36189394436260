import { FilterItem } from '@ditkrg/react-core'
import { useQuery } from '@tanstack/react-query'
import { FieldProps } from 'mui-rhf-library'
import { useMemo } from 'react'
import { UseFormReturn } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { allNodesQuery } from 'src/queries/nodes'
import { NodeType } from 'src/services/api/emos'
import { ReportsState } from 'src/store/reports'
import { ViewTableState } from 'src/store/table'
import { useAuth } from './use-auth'
import { accessibleNodeTypes } from 'src/components/dashboard/nodes/results'
import { ListboxComponent } from 'src/components/input/virtualize-autocomplete'

export const useNodeFilters = ({
    state,
    onChange,
    formConfigs
}: {
    state: ViewTableState | ReportsState
    onChange?: () => void
    formConfigs?: UseFormReturn<any>
}) => {
    const { t } = useTranslation()
    const { filters } = state
    const { user } = useAuth()
    const userNodeType = user?.node?.type || ''

    const emsFilter = filters?.ems
    const pbsFilter = filters?.[NodeType.PBS]
    const branchFilter = filters?.[NodeType.BRANCH]
    const subBranchFilter = filters?.[NodeType.SUB_BRANCH]

    const emsWatcher = formConfigs?.watch('emsId')
    const pbsWatcher = formConfigs?.watch('pbsId')
    const branchWatcher = formConfigs?.watch('branchId')
    const subBranchWatcher = formConfigs?.watch('subBranchId')

    const electionTypesWatcher = formConfigs?.watch('electionTypes')

    const electionTypeFilter = useMemo(() => {
        if (electionTypesWatcher?.length > 1) {
            return undefined
        }
        return electionTypesWatcher?.[0]
    }, [electionTypesWatcher])

    const { data: nodes = [], isPending } = useQuery({
        ...allNodesQuery({
            electionType: electionTypeFilter
        }),
        placeholderData: (placeholderData) => placeholderData,
        staleTime: Infinity,
        gcTime: Infinity,
        meta: {
            onSuccess: (res) => {
                if (res.length === 1 && res[0].type === NodeType.POLLING_CENTER) {
                    state.setFilter({
                        key: NodeType.POLLING_CENTER,
                        value: res[0].id
                    })
                }
            }
        }
    })

    const EMROptions = useMemo(() => nodes.filter((node) => node.type === NodeType.EMR), [nodes])

    const PBSOptions = useMemo(() => {
        if (emsFilter || emsWatcher) {
            return nodes.filter(
                (node) =>
                    node.type === NodeType.PBS && (node.path.includes(emsFilter) || node.path.includes(emsWatcher))
            )
        }
        return nodes.filter((node) => node.type === NodeType.PBS)
    }, [emsFilter, emsWatcher, nodes])

    const branchOptions = useMemo(() => {
        if (pbsFilter || pbsWatcher) {
            return nodes.filter(
                (node) =>
                    node.type === NodeType.BRANCH && (node.path.includes(pbsFilter) || node.path.includes(pbsWatcher))
            )
        }
        return nodes.filter((node) => node.type === NodeType.BRANCH)
    }, [nodes, pbsFilter, pbsWatcher])

    const subBranchOptions = useMemo(() => {
        if (branchFilter || branchWatcher) {
            return nodes.filter(
                (node) =>
                    node.type === NodeType.SUB_BRANCH &&
                    (node.path.includes(branchFilter) || node.path.includes(branchWatcher))
            )
        } else if (pbsFilter || pbsWatcher) {
            return nodes.filter(
                (node) =>
                    node.type === NodeType.SUB_BRANCH &&
                    (node.path.includes(pbsFilter) || node.path.includes(pbsWatcher))
            )
        }
        return nodes.filter((node) => node.type === NodeType.SUB_BRANCH)
    }, [pbsFilter, branchFilter, pbsWatcher, branchWatcher, nodes])

    const pcOptions = useMemo(() => {
        if (subBranchFilter || subBranchWatcher) {
            return nodes.filter(
                (node) =>
                    node.type === NodeType.POLLING_CENTER &&
                    (node.path.includes(subBranchFilter) || node.path.includes(subBranchWatcher))
            )
        } else if (branchFilter || branchWatcher) {
            return nodes.filter(
                (node) =>
                    node.type === NodeType.POLLING_CENTER &&
                    (node.path.includes(branchFilter) || node.path.includes(branchWatcher))
            )
        } else if (pbsFilter || pbsWatcher) {
            return nodes.filter(
                (node) =>
                    node.type === NodeType.POLLING_CENTER &&
                    (node.path.includes(pbsFilter) || node.path.includes(pbsWatcher))
            )
        }

        return nodes.filter((node) => node.type === NodeType.POLLING_CENTER)
    }, [pbsFilter, branchFilter, subBranchFilter, pbsWatcher, branchWatcher, subBranchWatcher, nodes])

    const filterOptionsWithLabelAndValue = (options, { inputValue }) => {
        return options.filter((option: { name: string; id: string }) => {
            return (
                option.name?.toLowerCase().includes(inputValue?.toLowerCase?.()) ||
                option.id?.toLowerCase().includes(inputValue?.toLowerCase?.())
            )
        })
    }

    const filterInputs = useMemo(
        (): FilterItem[] => [
            {
                hidden: !accessibleNodeTypes[userNodeType]?.includes(NodeType.PBS),
                id: NodeType.PBS,
                type: 'autocomplete',
                header: t('model.node.type.options.PBS'),
                props: {
                    options: PBSOptions,
                    optionLabel: 'name',
                    optionValue: 'id'
                },
                onFilterChange: ({ filtersState, sync }) => {
                    filtersState?.setFilter(NodeType.BRANCH, null)
                    filtersState?.setFilter(NodeType.SUB_BRANCH, null)
                    filtersState?.setFilter(NodeType.POLLING_CENTER, null)
                    filtersState?.setFilter('stationId', null)
                    sync?.()
                    onChange?.()
                }
            },
            {
                hidden: !accessibleNodeTypes[userNodeType]?.includes(NodeType.BRANCH),
                id: NodeType.BRANCH,
                type: 'autocomplete',
                header: t('model.node.type.options.Branch'),
                props: {
                    options: branchOptions,
                    optionLabel: 'name',
                    optionValue: 'id'
                },
                onFilterChange: ({ filtersState, sync }) => {
                    filtersState?.setFilter(NodeType.SUB_BRANCH, null)
                    filtersState?.setFilter(NodeType.POLLING_CENTER, null)
                    filtersState?.setFilter('stationId', null)
                    sync?.()
                    onChange?.()
                }
            },
            {
                hidden: !accessibleNodeTypes[userNodeType]?.includes(NodeType.SUB_BRANCH),
                id: NodeType.SUB_BRANCH,
                type: 'autocomplete',
                header: t('model.node.type.options.SubBranch'),
                props: {
                    options: subBranchOptions,
                    optionLabel: 'name',
                    optionValue: 'id'
                },
                onFilterChange: ({ filtersState, sync }) => {
                    filtersState?.setFilter(NodeType.POLLING_CENTER, null)
                    filtersState?.setFilter('stationId', null)
                    sync?.()
                    onChange?.()
                }
            },
            {
                hidden: !accessibleNodeTypes[userNodeType]?.includes(NodeType.POLLING_CENTER),
                id: NodeType.POLLING_CENTER,
                type: 'autocomplete',
                header: t('model.node.type.options.PollingCenter'),
                props: {
                    options: pcOptions,
                    optionLabel: 'name',
                    optionValue: 'id',
                    virtualized: true,
                    filterOptions: filterOptionsWithLabelAndValue,
                    customOptionLabel: (option) => `${option?.name} (${option?.code})`
                },
                onFilterChange: ({ filtersState, sync }) => {
                    filtersState?.setFilter('stationId', null)
                    sync?.()
                    onChange?.()
                }
            }
        ],
        [userNodeType, t, PBSOptions, branchOptions, subBranchOptions, pcOptions, onChange]
    )

    const nodeFields = useMemo(
        (): FieldProps[] => [
            {
                name: 'emrId',
                label: t('model.node.type.options.EMR'),
                fieldType: 'autocomplete',
                props: {
                    fullWidth: true,
                    options: EMROptions,
                    optionLabel: 'name',
                    optionValue: 'id',
                    onChange: () => {
                        formConfigs?.setValue('pbsId', null, {
                            shouldDirty: true
                        })
                        formConfigs?.setValue('branchId', null, {
                            shouldDirty: true
                        })
                        formConfigs?.setValue('subBranchId', null, {
                            shouldDirty: true
                        })
                        formConfigs?.setValue('pcId', null, {
                            shouldDirty: true
                        })
                    }
                },
                textFieldProps: { fullWidth: true, label: t('model.node.type.options.EMR') },
                gridProps: { xs: 12 }
            },
            {
                name: 'pbsId',
                label: t('model.node.type.options.PBS'),
                fieldType: 'autocomplete',
                props: {
                    fullWidth: true,
                    options: PBSOptions,
                    optionLabel: 'name',
                    optionValue: 'id',
                    onChange: () => {
                        formConfigs?.setValue('branchId', null, {
                            shouldDirty: true
                        })
                        formConfigs?.setValue('subBranchId', null, {
                            shouldDirty: true
                        })
                        formConfigs?.setValue('pcId', null, {
                            shouldDirty: true
                        })
                    }
                },
                textFieldProps: { fullWidth: true, label: t('model.node.type.options.PBS') },
                gridProps: { xs: 12 }
            },
            {
                name: 'branchId',
                label: t('model.node.type.options.Branch'),
                fieldType: 'autocomplete',
                props: {
                    fullWidth: true,
                    options: branchOptions,
                    optionLabel: 'name',
                    optionValue: 'id',
                    onChange: () => {
                        formConfigs?.setValue('subBranchId', null, {
                            shouldDirty: true
                        })
                        formConfigs?.setValue('pcId', null, {
                            shouldDirty: true
                        })
                    }
                },
                textFieldProps: { fullWidth: true, label: t('model.node.type.options.Branch') },
                gridProps: { xs: 12 }
            },
            {
                name: 'subBranchId',
                label: t('model.node.type.options.SubBranch'),
                fieldType: 'autocomplete',
                props: {
                    fullWidth: true,
                    options: subBranchOptions,
                    optionLabel: 'name',
                    optionValue: 'id',
                    onChange: () => {
                        formConfigs?.setValue('pcId', null, {
                            shouldValidate: true,
                            shouldDirty: true
                        })
                    }
                },
                textFieldProps: { fullWidth: true, label: t('model.node.type.options.SubBranch') },
                gridProps: { xs: 12 }
            },
            {
                name: 'pcId',
                label: t('model.node.type.options.PollingCenter'),
                fieldType: 'autocomplete',
                props: {
                    fullWidth: true,
                    options: pcOptions,
                    optionValue: 'id',
                    optionLabel: 'name',
                    ListboxComponent,
                    disableListWrap: true,
                    filterOptions: filterOptionsWithLabelAndValue,
                    renderOption: (props, option: any) => [props, `${option.name} (${option.code})`],
                    getOptionLabel: (option: any) => {
                        if (typeof option === 'object') {
                            return option.name
                        } else {
                            const pc = pcOptions.find((availableOption) => availableOption.id === option)
                            return `${pc?.name} (${pc?.code})`
                        }
                    },
                    customOptionLabel: (option) => `${option?.name} (${option?.code})`
                },
                textFieldProps: { fullWidth: true, label: t('model.node.type.options.PollingCenter') },
                gridProps: { xs: 12 }
            }
        ],
        [t, EMROptions, PBSOptions, branchOptions, subBranchOptions, pcOptions, formConfigs]
    )

    return { filterInputs, nodeFields, isPending }
}
