import Grid from '@mui/material/Grid'
import { useForm, SubmitHandler } from 'react-hook-form'
import { useState } from 'react'
import { FormFields } from 'mui-rhf-library'
import { yupResolver } from '@hookform/resolvers/yup'
import FormActions from 'src/components/drawer/form-actions'
import { useToast } from 'src/hooks/useToast'
import { useNodes } from 'src/hooks/nodes/use-nodes'
import { createNodeTypeAttributesMapper, nodeTypeCreateServiceMapper } from './constants'
import { useNodesSchema } from 'src/hooks/nodes/use-nodes-schema'
import { useTranslation } from 'react-i18next'
import { filterObject } from 'src/utils/filter-object'

function Create({
    handleSuccess,
    handleClose
}: Readonly<{
    handleSuccess: (data: any) => void
    handleClose: () => void
}>) {
    const { t } = useTranslation()
    const [btnLoading, setBtnLoading] = useState(false)
    const { errorsToast, successToast } = useToast()
    const { createSchema } = useNodesSchema()

    const formConfigs = useForm({
        resolver: yupResolver(createSchema()),
        defaultValues: {
            isActive: true
        }
    })
    const { handleSubmit, control, formState } = formConfigs
    const { errors } = formState

    const handleFormSubmit: SubmitHandler<any> = async (formData) => {
        setBtnLoading(true)

        const filteredData = filterObject({
            obj: formData,
            keys: createNodeTypeAttributesMapper[formData.nodeType]
        }) as any

        try {
            await nodeTypeCreateServiceMapper[formData.nodeType]({
                requestBody: {
                    ...filteredData
                }
            })
            successToast(
                t(`view.common.actions.new.success`, { name: t(`model.node.type.options.${formData.nodeType}` as any) })
            )
            handleSuccess(formData)
        } catch (err) {
            errorsToast(err)
        } finally {
            setBtnLoading(false)
        }
    }

    const { fields } = useNodes({
        formConfigs
    })

    return (
        <form onSubmit={handleSubmit(handleFormSubmit)}>
            <Grid container spacing={2}>
                <FormFields fields={fields} control={control} />
            </Grid>
            <FormActions loading={btnLoading} handleClose={handleClose} handleSubmit={handleSubmit(handleFormSubmit)} />
        </form>
    )
}

export default Create
