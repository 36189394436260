import * as yup from 'yup'

export const createSchema = () => {
    return yup.object().shape({
        name: yup.string().required(),
        color: yup.string().required(),
        photo: yup.array(),
        isSystem: yup.boolean().nullable(),
        isActive: yup.boolean(),
        partyType: yup.string().required(),
        seatType: yup.string().required(),
        number: yup.number().required().max(10000),
        circleIds: yup.array().of(yup.string())
    })
}
