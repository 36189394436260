import Webcam from 'react-webcam'
import React, { useEffect, useState } from 'react'
import Stack from '@mui/system/Stack'
import { Fab } from '@mui/material'
import CameraAltIcon from '@mui/icons-material/CameraAlt'
import CameraswitchIcon from '@mui/icons-material/Cameraswitch'
import { useTranslation } from 'react-i18next'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import ReplayCircleFilledIcon from '@mui/icons-material/ReplayCircleFilled'
import Modal from '../dialog/modal'

const WebcamDialog = ({
    open,
    setOpen,
    handleFileChange
}: {
    open: boolean
    setOpen: (open: boolean) => void
    handleFileChange: any
}) => {
    const [videoConstraints, setVideoConstraints] = React.useState('environment')
    const [loading, setLoading] = React.useState(false)
    const { t } = useTranslation()

    const webcamRef: any = React.useRef(null)
    const [imgSrc, setImgSrc] = React.useState(null)
    const user = {
        facingMode: 'user',
        width: { ideal: 2048 },
        height: { ideal: 1500 },
        frameRate: { ideal: 30 }
    }
    const environment = {
        facingMode: 'environment',
        width: { ideal: 2048 },
        height: { ideal: 1500 },
        frameRate: { ideal: 30 }
    }

    const changeCamera = React.useCallback(() => {
        setVideoConstraints(videoConstraints === 'user' ? 'environment' : 'user')
    }, [videoConstraints])

    const capture = React.useCallback(() => {
        setLoading(true)
        const imageSrc = webcamRef?.current?.getScreenshot()
        setImgSrc(imageSrc)
        setLoading(false)
    }, [])

    const handleUse = React.useCallback(async () => {
        const src = imgSrc
        if (!src) return
        setLoading(true)
        const base64 = await fetch(src)
        const blob = await base64.blob()
        const file = new File([blob], 'capture.jpg', { type: 'image/jpeg' })

        const files = {
            target: {
                files: [file]
            }
        }

        handleFileChange(files)
        setOpen(false)
        setLoading(false)
    }, [handleFileChange, imgSrc, setOpen])

    const handleRetake = React.useCallback(() => {
        setImgSrc(null)
    }, [])

    useEffect(() => {
        const disablePinchZoom = (event) => {
            if (event.touches.length > 1) {
                event.preventDefault()
            }
        }

        // Add the event listener to prevent pinch zoom
        document.body.addEventListener('touchmove', disablePinchZoom, { passive: false })

        return () => {
            // Clean up the event listener when the component unmounts
            document.body.removeEventListener('touchmove', disablePinchZoom)
        }
    }, [])

    const [isLandscape, setIsLandscape] = useState(window.innerWidth > window.innerHeight)

    useEffect(() => {
        const checkOrientation = () => {
            setIsLandscape(window.innerWidth > window.innerHeight)
        }

        // Initial check
        checkOrientation()

        // Add listeners for orientation change and window resize
        window.addEventListener('orientationchange', checkOrientation)
        window.addEventListener('resize', checkOrientation)

        // Clean up the listeners when the component unmounts
        return () => {
            window.removeEventListener('orientationchange', checkOrientation)
            window.removeEventListener('resize', checkOrientation)
        }
    }, [])

    return (
        <Modal
            fullScreen
            title={t('view.common.uploads.capture')}
            open={open}
            handleClose={() => {
                setOpen(false)
            }}
            dense
        >
            <Stack alignItems="center">
                {!imgSrc && (
                    <Stack spacing={1}>
                        <Webcam
                            audio={false}
                            ref={webcamRef}
                            screenshotFormat="image/jpeg"
                            // style={{ width: '100%', height: 'auto' }}
                            style={{ border: '1px solid lightgrey' }}
                            width={isLandscape ? '95%' : '100%'}
                            height={isLandscape ? 500 : 800}
                            forceScreenshotSourceSize
                            screenshotQuality={1}
                            videoConstraints={videoConstraints === 'user' ? user : environment}
                        />
                        <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
                            <Fab
                                color="primary"
                                onClick={capture}
                                disabled={loading}
                                variant="extended"
                                sx={{ width: '40%' }}
                            >
                                <CameraAltIcon sx={{ mr: 1 }} />
                                {t('view.common.uploads.capture')}
                            </Fab>
                            <Fab onClick={changeCamera} disabled={loading} variant="extended" sx={{ width: '40%' }}>
                                <CameraswitchIcon sx={{ mr: 1 }} />
                                {t('view.common.uploads.switch')}
                            </Fab>
                        </Stack>
                    </Stack>
                )}

                {imgSrc && (
                    <Stack spacing={2}>
                        <img src={imgSrc} style={{ maxHeight: '65vh', width: '100%' }} alt="CapturedImage" />

                        <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
                            <Fab
                                onClick={() => handleUse()}
                                disabled={loading}
                                variant="extended"
                                color="success"
                                sx={{ width: '40%' }}
                            >
                                <CheckCircleIcon sx={{ mr: 1 }} />
                                {t('view.common.uploads.use')}
                            </Fab>
                            <Fab onClick={handleRetake} disabled={loading} variant="extended" sx={{ width: '40%' }}>
                                <ReplayCircleFilledIcon sx={{ mr: 1 }} />
                                {t('view.common.uploads.retake')}
                            </Fab>
                        </Stack>
                    </Stack>
                )}
            </Stack>
        </Modal>
    )
}

export default WebcamDialog
