import { Stack } from '@mui/system'
import LoadingButton from '../button/loading-button'
import { useTranslation } from 'react-i18next'

const FormActions = ({
    handleClose,
    handleSubmit,
    disabled,
    loading
}: {
    handleClose: () => void
    handleSubmit: () => void
    disabled?: boolean
    loading: boolean
}) => {
    const { t } = useTranslation()
    return (
        <Stack
            direction="row"
            spacing={2}
            marginTop={3}
            mx={{
                display: 'flex',
                justifyContent: 'space-between'
            }}
        >
            <LoadingButton onClick={handleClose} variant="contained" loading={loading} size="small" color="error">
                {t('view.common.form.cancel')}
            </LoadingButton>
            <LoadingButton
                loading={loading}
                onClick={handleSubmit}
                variant="contained"
                size="small"
                disabled={disabled}
            >
                {t('view.common.form.submit')}
            </LoadingButton>
        </Stack>
    )
}

export default FormActions
