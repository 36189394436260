export const model = {
    user: {
        name: 'Name',
        password: 'Password',
        currentPassword: 'Current Password',
        newPassword: 'New Password',
        passwordConfirmation: 'Password Confirmation',
        username: 'Username',
        phoneNumber: 'Phone Number',
        locale: 'Language',
        roles: 'Roles',
        image: 'Image',
        lastLogin: 'Last Login',
        electionTypes: {
            label: 'Election Type',
            options: {
                Public: 'Public',
                Private: 'Private'
            }
        }
    },
    party: {
        partyType: {
            label: 'Party Type',
            options: {
                Party: 'Party',
                Coalition: 'Coalition',
                Independent: 'Independent'
            }
        },
        seatType: {
            label: 'Seat Type',
            options: {
                Majority: 'Majority',
                Turkmen: 'Turkmen',
                Chaldean: 'Chaldean',
                Minority: 'Minority'
            }
        },
        number: 'Number',
        color: 'Color',
        isSystem: 'Is System',
        partyCandidateCount: 'Total party & candidate votes',
        candidateCount: 'Candidate votes',
        totalVotes: 'Total Votes'
    },
    circle: {
        majoritySeats: 'Majority Seats',
        minoritySeats: 'Minority Seats'
    },
    node: {
        type: {
            label: 'Type',
            options: {
                HEC: 'Higher Election Center',
                EMR: 'EMR',
                PBS: 'PBS',
                Branch: 'Branch',
                SubBranch: 'Sub Branch',
                PollingCenter: 'Polling Center'
            }
        },
        parent: 'Parent',
        province: 'Province',
        district: 'District',
        subDistrict: 'Sub District',
        address: 'Address',
        maxVoters: 'Max Voters',
        maxVotersMessage: 'Participated voters must be less than or equal to max voters of the polling center',
        vrcId: 'VRC',
        middayVoters: 'Midday Voters',
        middayVotersRecordedAt: 'Midday Voters Recorded At',
        pollingCenterCode: 'Polling Center Code',
        pollingCenterName: 'Polling Center Name'
    },
    systemConfiguration: {
        restrictAgentLoginToActiveDuration: 'Restrict Agent Login To Active Duration',
        respectTimeLimits: 'Respect Time Limits',
        privateElectionDay: 'Private Election Day',
        publicElectionDay: 'Public Election Day',
        activeDuration: 'Active Duration',
        morningDuration: 'Morning Duration',
        afternoonDuration: 'Afternoon Duration',
        eveningDuration: 'Evening Duration',
        common: {
            min: 'Minimum',
            max: 'Maximum'
        }
    },
    incident: {
        reason: {
            label: 'Reason',
            options: {
                hot_ethernet_cable: 'Hot Ethernet Cable',
                inexperience_of_commission_employees: 'Inexperience of Commission Employees',
                database_error: 'Database Error',
                other: 'Other',
                pcos_device: 'PCOS Device Not Working',
                rts_device: 'RTS Device Not Working',
                vvd_device: 'VVD Device Not Working'
            }
        },
        timeOfBreakage: 'Time of Breakage',
        description: 'Description',
        commands: {
            resolve: 'Resolve',
            reopen: 'Reopen'
        },
        states: {
            Open: 'Open',
            Resolved: 'Resolved'
        }
    },
    station: {
        maxVoters: 'Max Voters',
        initiationStatus: 'Initiation Status',
        currentStatus: 'Current Status',
        operationType: 'Operation Type',
        states: {
            Pending: 'Pending',
            Operational: 'Operational',
            NonOperational: 'Non Operational'
        },
        voters: 'Participated Voters',
        middayVoters: 'Midday Voters',
        middayVotersRecordedAt: 'Midday Voters Recorded At',
        initiatedAt: 'Initiated At',
        isInitiated: 'Is Initiated',
        isNotInitiated: 'Is Not Initiated',
        hasIncidents: 'Has Incidents',
        number: 'Station Number',
        isVip: 'Is VIP',
        invalidPapersDiff: 'Invalid Papers Difference',
        validVotesDiff: 'Valid Votes Difference',
        Undefined: 'Undefined',
        NoDiff: 'No Difference',
        LowDiff: 'Low Difference',
        HighDiff: 'High Difference'
    },
    violation: {
        type: {
            label: 'Type',
            options: {
                SystemPartyAgainstOtherParty: 'System Party Against Other Party',
                OtherPartyAgainstSystemParty: 'Other Party Against System Party',
                OtherPartyAgainstOtherParty: 'Other Party Against Other Party'
            }
        },
        complainant: {
            label: 'Complainant',
            name: 'Complainant Name',
            type: {
                label: 'Complainant Type',
                options: {
                    Voter: 'Voter',
                    Agent: 'Agent',
                    Candidate: 'Candidate'
                }
            },
            partyId: 'Complainant Party'
        },
        allegedViolator: {
            label: 'Alleged Violator',
            name: 'Alleged Violator Name',
            type: {
                label: 'Alleged Violator Type',
                options: {
                    Unknown: 'Unknown',
                    CommissionEmployee: 'Commission Employee',
                    Commission: 'Commission',
                    Candidate: 'Candidate',
                    SecurityMember: 'Security Member',
                    PoliticalParty: 'Political Party'
                }
            },
            partyId: 'Alleged Violator Party'
        },
        states: {
            open: 'Open',
            reported: 'Reported',
            submitted: 'Submitted',
            dropped: 'Dropped'
        },
        commands: {
            drop: 'Drop',
            reopen: 'Reopen',
            submit: 'Submit'
        }
    },
    votingResult: {
        invalidPapers: 'Invalid Papers',
        receiptPhoto: 'Receipt Photo',
        formType: {
            label: 'Form Type',
            options: {
                Automatic: 'Electronic',
                Manual: 'Manual',
                Final: 'Final'
            }
        },
        commands: {
            Submit: 'Submit',
            Draft: 'Draft'
        },
        status: {
            Draft: 'Draft',
            Submitted: 'Submitted'
        }
    },
    common: {
        id: 'ID',
        name: 'Name',
        status: 'Status',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        code: 'Code',
        isActive: 'Is Active',
        photo: 'Photo',
        number: 'Number',
        gender: {
            label: 'Gender',
            options: {
                male: 'Male',
                female: 'Female'
            }
        },
        comment: 'Comment',
        attachment: 'Attachment',
        count: 'Count',
        ancestorId: 'Ancestor',
        lastComment: 'Last Comment',
        updatedBy: 'Updated By',
        createdBy: 'Created By'
    }
}

export type Model = typeof model
