import { AdvancedTable } from '@ditkrg/react-core'
import { Card } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { CandidatesService, PagedCircleResponseListResponse } from 'src/services/api/emos'
import { useCandidatesTableState } from 'src/store/table'
import GenerateColumns from './columns'
import { useTranslation } from 'react-i18next'
import ProfileDrawer from './profile-drawer'
import React from 'react'
import { ROWS_PER_PAGE_OPTIONS } from 'src/utils/constants'
import { useSettings } from 'src/store/settings'

const Results = () => {
    const { t } = useTranslation()
    const { language } = useSettings()
    const candidatesTableState = useCandidatesTableState()
    const { page, rowsPerPage, filters } = candidatesTableState
    const [drawerOpen, setDrawerOpen] = React.useState(false)
    const [candidate, setCandidate] = React.useState<PagedCircleResponseListResponse | null>(null)

    const closeDrawer = () => {
        setDrawerOpen(false)
        setCandidate(null)
    }

    const {
        data: candidates,
        isLoading,
        isFetching
    } = useQuery({
        queryKey: ['candidates', rowsPerPage, page, filters],
        queryFn: async () => {
            const res = await CandidatesService.listCandidates({
                page: page + 1,
                rowsPerPage,
                ...filters
            })
            return res
        },
        placeholderData: (placeholderData) => placeholderData
    })

    return (
        <Card variant="outlined">
            <AdvancedTable
                data={candidates?.data ?? []}
                columns={GenerateColumns({ t, tableState: candidatesTableState })}
                state={candidatesTableState}
                totalItems={candidates?.meta?.pagination?.count ?? 0}
                onRowClick={(row) => {
                    setCandidate(row)
                    setDrawerOpen(true)
                }}
                loading={isLoading || isFetching}
                loadingVariant={isFetching ? 'linear' : 'skeleton'}
                filterLabel={t('view.common.filter.label')}
                clearLabel={t('view.common.clear')}
                paginationVariant="advanced"
                rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
                locale={language as any}
            />

            <ProfileDrawer open={drawerOpen} close={closeDrawer} data={candidate} />
        </Card>
    )
}

export default Results
