/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BooleanNullableDashboardCountResponse } from '../models/BooleanNullableDashboardCountResponse';
import type { DashboardStationSubmissionResponse } from '../models/DashboardStationSubmissionResponse';
import type { ElectionType } from '../models/ElectionType';
import type { IncidentReportDashboardResponse } from '../models/IncidentReportDashboardResponse';
import type { StringDashboardCountResponse } from '../models/StringDashboardCountResponse';
import type { ViolationReportIdDtoDashboardCountResponse } from '../models/ViolationReportIdDtoDashboardCountResponse';
import type { VoteDistributionFaceResponse } from '../models/VoteDistributionFaceResponse';
import type { VoterParticipationDashboardResponse } from '../models/VoterParticipationDashboardResponse';
import type { VoterValidityDashboardResponse } from '../models/VoterValidityDashboardResponse';
import type { VotingResultFormType } from '../models/VotingResultFormType';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class DashboardsService {
    /**
     * @returns VoterValidityDashboardResponse OK
     * @throws ApiError
     */
    public static voterValidityDistribution({
        circleId,
        ancestorId,
        electionType,
        formType,
    }: {
        circleId: string,
        ancestorId: string,
        electionType: ElectionType,
        formType: VotingResultFormType,
    }): CancelablePromise<VoterValidityDashboardResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/dashboards/reports/voter-validity',
            query: {
                'CircleId': circleId,
                'AncestorId': ancestorId,
                'ElectionType': electionType,
                'FormType': formType,
            },
        });
    }
    /**
     * @returns VoteDistributionFaceResponse OK
     * @throws ApiError
     */
    public static voteDistribution({
        circleId,
        ancestorId,
        electionType,
        formType,
    }: {
        circleId: string,
        ancestorId: string,
        electionType: ElectionType,
        formType: VotingResultFormType,
    }): CancelablePromise<VoteDistributionFaceResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/dashboards/reports/vote-distribution',
            query: {
                'CircleId': circleId,
                'AncestorId': ancestorId,
                'ElectionType': electionType,
                'FormType': formType,
            },
        });
    }
    /**
     * @returns VoterParticipationDashboardResponse OK
     * @throws ApiError
     */
    public static voterParticipationDistribution({
        circleId,
        ancestorId,
        electionType,
        formType,
    }: {
        circleId: string,
        ancestorId: string,
        electionType: ElectionType,
        formType: VotingResultFormType,
    }): CancelablePromise<VoterParticipationDashboardResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/dashboards/reports/voter-participation',
            query: {
                'CircleId': circleId,
                'AncestorId': ancestorId,
                'ElectionType': electionType,
                'FormType': formType,
            },
        });
    }
    /**
     * @returns DashboardStationSubmissionResponse OK
     * @throws ApiError
     */
    public static stationSubmissionReport({
        circleId,
        ancestorId,
        electionType,
        formType,
    }: {
        circleId: string,
        ancestorId: string,
        electionType: ElectionType,
        formType: VotingResultFormType,
    }): CancelablePromise<DashboardStationSubmissionResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/dashboards/reports/station-submission',
            query: {
                'CircleId': circleId,
                'AncestorId': ancestorId,
                'ElectionType': electionType,
                'FormType': formType,
            },
        });
    }
    /**
     * @returns IncidentReportDashboardResponse OK
     * @throws ApiError
     */
    public static incidentReport({
        ancestorId,
        electionType,
        circleId,
    }: {
        ancestorId: string,
        electionType: ElectionType,
        circleId?: string,
    }): CancelablePromise<Array<IncidentReportDashboardResponse>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/dashboards/operational/incident-report',
            query: {
                'AncestorId': ancestorId,
                'CircleId': circleId,
                'ElectionType': electionType,
            },
        });
    }
    /**
     * @returns ViolationReportIdDtoDashboardCountResponse OK
     * @throws ApiError
     */
    public static violationReport({
        ancestorId,
        electionType,
        circleId,
    }: {
        ancestorId: string,
        electionType: ElectionType,
        circleId?: string,
    }): CancelablePromise<Array<ViolationReportIdDtoDashboardCountResponse>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/dashboards/operational/violation-report',
            query: {
                'AncestorId': ancestorId,
                'CircleId': circleId,
                'ElectionType': electionType,
            },
        });
    }
    /**
     * @returns BooleanNullableDashboardCountResponse OK
     * @throws ApiError
     */
    public static middayResultReport({
        circleId,
        ancestorId,
        electionType,
        formType,
    }: {
        circleId: string,
        ancestorId: string,
        electionType: ElectionType,
        formType: VotingResultFormType,
    }): CancelablePromise<Array<BooleanNullableDashboardCountResponse>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/dashboards/operational/midday-results',
            query: {
                'CircleId': circleId,
                'AncestorId': ancestorId,
                'ElectionType': electionType,
                'FormType': formType,
            },
        });
    }
    /**
     * @returns StringDashboardCountResponse OK
     * @throws ApiError
     */
    public static stationInitiationReport({
        ancestorId,
        electionType,
        circleId,
    }: {
        ancestorId: string,
        electionType: ElectionType,
        circleId?: string,
    }): CancelablePromise<Array<StringDashboardCountResponse>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/dashboards/operational/station-initiation',
            query: {
                'AncestorId': ancestorId,
                'CircleId': circleId,
                'ElectionType': electionType,
            },
        });
    }
}
