import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'

import { paths } from 'src/paths'
import { RouterLink } from 'src/components/router-link'
import { useTranslation } from 'react-i18next'

const Page = () => {
    const { t } = useTranslation()
    return (
        <Box
            component="main"
            sx={{
                alignItems: 'center',
                display: 'flex',
                flexGrow: 1,
                py: '80px'
            }}
        >
            <Container maxWidth="lg">
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        mb: 6
                    }}
                >
                    <Box
                        alt="Not authorized"
                        component="img"
                        src="/assets/errors/error-401.png"
                        sx={{
                            height: 'auto',
                            maxWidth: '100%',
                            width: 400
                        }}
                    />
                </Box>
                <Typography align="center" variant={'h3'}>
                    {t('view.error.403')}
                </Typography>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        mt: 6
                    }}
                >
                    <Button variant="contained" component={RouterLink} href={paths.index}>
                        {t('view.nav.home')}
                    </Button>
                </Box>
            </Container>
        </Box>
    )
}

export default Page
