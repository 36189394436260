import { useEffect, useMemo } from 'react'
import { ElectionType, IncludedResourceName, NodeType, VotingResultFormType } from 'src/services/api/emos'
import { ACTIONS, RESOURCES, useCheckPermissions } from './use-check-permissions'
import { useTranslation } from 'react-i18next'
import { ReportsState } from 'src/store/reports'
import { useAuth } from './use-auth'
import { FilterItem } from '@ditkrg/react-core'

export const useReports = ({
    state,
    setKey,
    circles
}: {
    state: ReportsState
    setKey: React.Dispatch<React.SetStateAction<number>>
    circles: IncludedResourceName[]
}) => {
    const { can } = useCheckPermissions()
    const { t } = useTranslation()
    const { user } = useAuth()
    const { filters } = state

    const electionTypeFilterValue = filters?.electionType
    const nodeFilter =
        filters?.[NodeType.POLLING_CENTER] ||
        filters?.[NodeType.SUB_BRANCH] ||
        filters?.[NodeType.BRANCH] ||
        filters?.[NodeType.PBS]

    const firstCircle = circles[0]?.id

    const isCircleHidden = useMemo(() => {
        return (
            user?.electionTypes.length === 1 &&
            user?.electionTypes[0] === ElectionType.PUBLIC &&
            (user.node?.vipStationCount === 0 || !user.node?.vipStationCount)
        )
    }, [user?.electionTypes, user?.node?.vipStationCount])

    const circleIdFilterValue = useMemo(() => {
        return filters?.circle
    }, [filters?.circle])

    useEffect(() => {
        if (user?.electionTypes.length === 1) {
            if (
                !electionTypeFilterValue ||
                (electionTypeFilterValue && electionTypeFilterValue !== user.electionTypes[0])
            ) {
                state.setFilter({
                    key: 'electionType',
                    value: user.electionTypes[0]
                })
            }
        }
        if (!circleIdFilterValue || (isCircleHidden && circleIdFilterValue !== user?.node?.circleId)) {
            state.setFilter({
                key: 'circle',
                value: user?.node?.circleId || firstCircle
            })
        }
        if (!nodeFilter && user?.node?.type && user?.node?.id) {
            state.setFilter({
                key: user?.node?.type,
                value: user?.node?.id
            })
        }
        setKey((key) => key + 1)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [firstCircle, user])

    const formTypeOptions = useMemo(() => {
        return Object.values(VotingResultFormType)
            .filter((value) => {
                if (
                    !can({
                        resource: RESOURCES.voting_result,
                        actions: [ACTIONS.read_f]
                    }) &&
                    value === VotingResultFormType.FINAL
                ) {
                    return false
                }
                return true
            })
            .map((value) => ({
                label: t(`model.votingResult.formType.options.${value}` as any),
                value
            }))
    }, [can, t])

    const electionTypeFilter = useMemo((): FilterItem => {
        return {
            hidden: user?.electionTypes.length === 1,
            id: 'electionType',
            type: 'autocomplete',
            header: t('model.user.electionTypes.label'),
            props: {
                options: Object.values(ElectionType).map((value) => ({
                    label: t(`model.user.electionTypes.options.${value}` as any),
                    value
                })),
                optionLabel: 'label',
                optionValue: 'value'
            }
        }
    }, [t, user?.electionTypes.length])

    const circleFilter = useMemo((): FilterItem => {
        return {
            hidden: isCircleHidden,
            id: 'circle',
            type: 'autocomplete',
            header: t('view.circle.name.headline'),
            props: {
                options: circles,
                optionLabel: 'name',
                optionValue: 'id'
            }
        }
    }, [circles, isCircleHidden, t])

    const resetFilters = ({ filtersState, sync }) => {
        filtersState.clearFilters()
        if (user?.electionTypes.length === 1) {
            filtersState.setFilter('electionType', user.electionTypes[0])
        }
        filtersState.setFilter('circle', user?.node?.circleId)
        sync?.()
    }

    return {
        resetFilters,
        formTypeOptions,
        electionTypeFilter,
        circleFilter
    }
}
