import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { useSettings } from 'src/store/settings'

type Data = {
    id?: string
    name: string
    y: number
    color?: string
}

const PieChart = ({ title, dataset }: { title?: string; dataset: Data[] }) => {
    const { paletteMode } = useSettings()

    const options: Highcharts.Options = {
        chart: {
            type: 'pie',
            backgroundColor: 'transparent'
        },
        accessibility: {
            enabled: false
        },
        credits: {
            enabled: false
        },
        title: {
            text: title
        },
        colors: ['#3498db', '#e74c3c', '#2ecc71', '#f39c12', '#8e44ad'], // Define a set of colors here
        tooltip: {
            headerFormat: '<strong>{point.number}</strong><br/>',
            useHTML: true,
            backgroundColor: 'transparent',
            borderColor: 'transparent',
            shadow: false,
            padding: 0,
            shared: true,
            formatter: function () {
                const point = this.point.options as any
                const total = this.total || 0 // Sum all points.y
                const percentage = ((point.y / total) * 100).toFixed(2) // Calculate percentage

                return `<div style="
                    border-radius: 0.5rem;
					background-color: white;
					box-shadow: #CCC 0px 1px 3px 0px;
					padding: 0.5rem;
                    text-align: center;
                    ">
                        <span style="color: grey; font-size: 16px">${point.name}<br/></span>
                        <hr style="border: none; height: 1px; background-color: #ccc; margin: 7px 0;">
                       
                       <div style="
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            gap: 5px;
                       ">
                            <div style="
                                height: 10px;
                                width: 10px; 
                                background-color: ${point.color}; 
                                border-radius: 50%
                            ">
                            </div>
                            <span style="
                                color: black; 
                                font-size: 17px
                            ">
                                ${this.point.y}
                            </span>
                            <span style="
                                color: grey; 
                                font-size: 17px
                            ">
                                (${percentage}%)
                            </span>
                        </div>
                    </div>`
            },
            style: {
                fontSize: '15px',
                fontFamily: 'Kurdish',
                boxShadow: 'none'
            }
        },

        plotOptions: {
            pie: {
                cursor: 'pointer',
                showInLegend: true,
                dataLabels: {
                    enabled: true,
                    format: '<b>{point.y}</b>',
                    distance: -50,
                    style: {
                        fontSize: '16px',
                        textOutline: 'none'
                    }
                }
            }
        },
        series: [
            {
                type: 'pie',
                data: dataset
            }
        ],
        legend: {
            layout: 'horizontal', // Arrange the legend items
            align: 'center',
            verticalAlign: 'bottom',
            floating: false,
            borderWidth: 0,
            backgroundColor: 'transparent',
            itemStyle: {
                fontFamily: 'Kurdish',
                fontSize: '15px',
                color: paletteMode === 'dark' ? '#fff' : '#000'
            }
        }
    }

    return <HighchartsReact highcharts={Highcharts} options={options} />
}

export default PieChart
