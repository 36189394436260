import { AdvancedTable } from '@ditkrg/react-core'
import { Card } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { PagedPartyResponse, PartiesService } from 'src/services/api/emos'
import { usePartiesTableState } from 'src/store/table'
import GenerateColumns from './columns'
import { useTranslation } from 'react-i18next'
import ProfileDrawer from './profile-drawer'
import React from 'react'
import { ROWS_PER_PAGE_OPTIONS } from 'src/utils/constants'
import { useSettings } from 'src/store/settings'

const Results = () => {
    const { t } = useTranslation()
    const partiesTableState = usePartiesTableState()
    const { page, rowsPerPage, filters } = partiesTableState
    const [drawerOpen, setDrawerOpen] = React.useState(false)
    const [party, setParty] = React.useState<PagedPartyResponse | null>(null)
    const { language } = useSettings()

    const closeDrawer = () => {
        setDrawerOpen(false)
        setParty(null)
    }

    const {
        data: parties,
        isLoading,
        isFetching
    } = useQuery({
        queryKey: ['parties', rowsPerPage, page, filters],
        queryFn: async () => {
            const res = await PartiesService.listParties({
                page: page + 1,
                rowsPerPage,
                ...filters
            })
            return res
        },
        placeholderData: (placeholderData) => placeholderData
    })

    return (
        <Card variant="outlined">
            <AdvancedTable
                data={parties?.data ?? []}
                columns={GenerateColumns({ t, tableState: partiesTableState })}
                state={partiesTableState}
                totalItems={parties?.meta?.pagination?.count ?? 0}
                onRowClick={(row) => {
                    setParty(row)
                    setDrawerOpen(true)
                }}
                loading={isLoading || isFetching}
                loadingVariant={isFetching ? 'linear' : 'skeleton'}
                filterLabel={t('view.common.filter.label')}
                clearLabel={t('view.common.clear')}
                paginationVariant="advanced"
                rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
                locale={language as any}
            />

            <ProfileDrawer open={drawerOpen} close={closeDrawer} data={party} />
        </Card>
    )
}

export default Results
