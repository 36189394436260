import Grid from '@mui/material/Grid'
import { useForm, SubmitHandler } from 'react-hook-form'
import { useState } from 'react'
import { FormFields } from 'mui-rhf-library'
import { createSchema } from './schema'
import { CirclesService } from 'src/services/api/emos'
import { yupResolver } from '@hookform/resolvers/yup'
import FormActions from 'src/components/drawer/form-actions'
import { useToast } from 'src/hooks/useToast'
import { useCircles } from 'src/hooks/use-circles'

function Create({
    handleSuccess,
    handleClose
}: Readonly<{
    handleSuccess: () => void
    handleClose: () => void
}>) {
    const [btnLoading, setBtnLoading] = useState(false)
    const { errorsToast } = useToast()

    const { handleSubmit, control } = useForm({
        resolver: yupResolver(createSchema())
    })

    const handleFormSubmit: SubmitHandler<any> = async (formData) => {
        setBtnLoading(true)
        try {
            await CirclesService.createCircle({
                requestBody: {
                    ...formData
                }
            })
            handleSuccess()
        } catch (err) {
            errorsToast(err)
        } finally {
            setBtnLoading(false)
        }
    }

    const { fields } = useCircles()

    return (
        <form onSubmit={handleSubmit(handleFormSubmit)}>
            <Grid container spacing={2}>
                <FormFields fields={fields} control={control} />
            </Grid>
            <FormActions loading={btnLoading} handleClose={handleClose} handleSubmit={handleSubmit(handleFormSubmit)} />
        </form>
    )
}

export default Create
