import { useQuery } from '@tanstack/react-query'
import { allDistrictsQuery } from 'src/queries/districts'

export const useDistricts = () => {
    const { data = [] } = useQuery({
        ...allDistrictsQuery()
    })

    const transformedData = data.reduce((acc, district) => {
        acc[district.id as string] = district.name
        return acc
    }, {})

    return { districts: transformedData, districtsArray: data }
}
