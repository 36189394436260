import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import Alert from '@mui/material/Alert'
import { Box, Stack } from '@mui/system'
import { useTranslation } from 'react-i18next'
import { useAuth } from 'src/hooks/use-auth'
import { useRouter } from 'src/hooks/use-router'
import { GuestGuard } from 'src/guards/guest-guard'
import { Grid, IconButton, InputAdornment } from '@mui/material'
import { Logo } from 'src/components/logo'
import { useMemo, useState } from 'react'
import { FieldProps, FormFields } from 'mui-rhf-library'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { useMutation } from '@tanstack/react-query'
import LoadingButton from 'src/components/button/loading-button'
import { paths } from 'src/paths'
import { toast } from 'sonner'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'

const Login = () => {
    const router = useRouter()
    const { signIn, signOut } = useAuth()
    const [t] = useTranslation()

    const schema = yup.object().shape({
        username: yup.string().required(),
        password: yup.string().required()
    })

    const { control, handleSubmit } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            username: '',
            password: ''
        }
    })

    const { mutate, isPending, error } = useMutation({
        mutationKey: ['signIn'],
        mutationFn: signIn,
        onSuccess: () => {
            router.push(paths.index)
        },
        onError: () => {
            toast.error(t('view.auth.login.unauthorized_message'))
        }
    })

    const [showPassword, setShowPassword] = useState(false)
    const handleClickShowPassword = () => setShowPassword((show) => !show)

    const fields = useMemo((): FieldProps[] => {
        return [
            {
                name: 'username',
                fieldType: 'textField',
                label: t('model.user.username'),
                gridProps: {
                    xs: 12
                },
                props: {
                    fullWidth: true,
                    disabled: isPending
                }
            },
            {
                name: 'password',
                fieldType: 'textField',
                label: t('model.user.password'),
                gridProps: {
                    xs: 12
                },
                props: {
                    fullWidth: true,
                    disabled: isPending,
                    type: showPassword ? 'text' : 'password',
                    InputProps: {
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    edge="end"
                                >
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        )
                    }
                }
            }
        ]
    }, [isPending, showPassword, t])

    const handleFormSubmit = (data: any) => {
        mutate(data)
    }

    return (
        <GuestGuard>
            <Grid container sx={{ height: '80vh', alignContent: 'center' }} justifyContent="center">
                <Grid item xs={10} md={4}>
                    <Stack direction="column" spacing={1}>
                        <Box sx={{ width: '30%' }}>
                            <Logo />
                        </Box>
                        <Card elevation={1}>
                            <CardContent>
                                <Stack direction="column" spacing={3}>
                                    <Typography variant="h6">{t('view.auth.login.headline')}</Typography>

                                    <Grid item xs={12}>
                                        {error && (
                                            <Alert variant="outlined" severity="error">
                                                {t('view.auth.login.unauthorized_message')}
                                            </Alert>
                                        )}
                                    </Grid>

                                    <form onSubmit={handleSubmit(handleFormSubmit)}>
                                        <Grid container spacing={3}>
                                            <FormFields fields={fields} control={control} />
                                            <Grid item xs={12}>
                                                <LoadingButton
                                                    fullWidth
                                                    size="large"
                                                    type="submit"
                                                    variant="contained"
                                                    loading={isPending}
                                                >
                                                    {t('view.auth.login.headline')}
                                                </LoadingButton>
                                            </Grid>
                                        </Grid>
                                    </form>
                                </Stack>
                            </CardContent>
                        </Card>
                    </Stack>
                </Grid>
            </Grid>
        </GuestGuard>
    )
}

export default Login
