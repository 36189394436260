/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateCandidateRequest } from '../models/CreateCandidateRequest';
import type { DetailedCandidateResponse } from '../models/DetailedCandidateResponse';
import type { IdResponse } from '../models/IdResponse';
import type { MinimalCandidateResponse } from '../models/MinimalCandidateResponse';
import type { PagedCandidateResponseListResponse } from '../models/PagedCandidateResponseListResponse';
import type { UpdateCandidateRequest } from '../models/UpdateCandidateRequest';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class CandidatesService {
    /**
     * @returns PagedCandidateResponseListResponse OK
     * @throws ApiError
     */
    public static listCandidates({
        page,
        rowsPerPage,
        name,
        number,
        circleId,
        partyId,
        sortAscending,
    }: {
        page: number,
        rowsPerPage: number,
        name?: string,
        number?: number,
        circleId?: string,
        partyId?: string,
        sortAscending?: boolean,
    }): CancelablePromise<PagedCandidateResponseListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/candidates',
            query: {
                'Page': page,
                'Name': name,
                'Number': number,
                'CircleId': circleId,
                'PartyId': partyId,
                'SortAscending': sortAscending,
                'RowsPerPage': rowsPerPage,
            },
        });
    }
    /**
     * @returns IdResponse Created
     * @throws ApiError
     */
    public static createCandidate({
        requestBody,
    }: {
        requestBody: CreateCandidateRequest,
    }): CancelablePromise<IdResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/candidates',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @returns MinimalCandidateResponse OK
     * @throws ApiError
     */
    public static listAllCandidates({
        circleId,
        partyId,
        isActive,
    }: {
        circleId?: string,
        partyId?: string,
        isActive?: boolean,
    }): CancelablePromise<Array<MinimalCandidateResponse>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/candidates/all',
            query: {
                'CircleId': circleId,
                'PartyId': partyId,
                'IsActive': isActive,
            },
        });
    }
    /**
     * @returns void
     * @throws ApiError
     */
    public static updateCandidate({
        id,
        requestBody,
    }: {
        id: string,
        requestBody: UpdateCandidateRequest,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/candidates/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @returns DetailedCandidateResponse OK
     * @throws ApiError
     */
    public static getCandidateById({
        id,
    }: {
        id: string,
    }): CancelablePromise<DetailedCandidateResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/candidates/{id}',
            path: {
                'id': id,
            },
        });
    }
}
