import React from 'react'
import { useTranslation } from 'react-i18next'
import { styled, alpha } from '@mui/material/styles'
import { ViolationStatus } from 'src/services/api/emos'

const LabelWrapper = styled('span')(
    ({ theme }) => `
      padding: ${theme.spacing(0.5, 1)};
      font-size: ${theme.typography.pxToRem(15)};
      display: inline-flex;
      align-items: center;
      justify-content: center;
`
)

type Mapper = {
    [key in ViolationStatus]: {
        text: string
        color: string
    }
}

const ViolationStatusLabel = ({ status }: { status?: ViolationStatus }) => {
    const [t, i18next] = useTranslation()

    const mapper: Mapper = {
        Open: {
            text: 'open',
            color: '#616161'
        },
        Reported: {
            text: 'reported',
            color: '#f44336'
        },
        Submitted: {
            text: 'submitted',
            color: '#1b5e20'
        },
        Dropped: {
            text: 'dropped',
            color: '#e91e63'
        }
    }

    const { text, color } = status ? mapper[status] : { text: status, color: '#616161' }

    return (
        <LabelWrapper style={{ backgroundColor: alpha(color, 0.1), color: color }}>
            {i18next.exists(`model.violation.states.${text}`) ? t(`model.violation.states.${text}` as any) : text}
        </LabelWrapper>
    )
}

export default ViolationStatusLabel
