import Button from '@mui/material/Button'
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import MiniDrawer from 'src/components/drawer/mini-drawer'
import { DetailsListProps } from 'src/components/list/details-list'
import { Stack } from '@mui/system'
import {
    ElectionType,
    NodesService,
    NodeType,
    PagedNodeResponse,
    VotingRegistrationCentersService
} from 'src/services/api/emos'
import Edit from './edit'
import { useToast } from 'src/hooks/useToast'
import ModalImage from 'src/components/modal-image'
import StatusLabel from 'src/components/label/status-label'
import dateFormat from 'src/utils/date-format'
import { ACTIONS, RESOURCES, useCheckPermissions } from 'src/hooks/use-check-permissions'
import { useProvinces } from 'src/hooks/use-provinces'
import { useDistricts } from 'src/hooks/use-districts'
import { useSubDistricts } from 'src/hooks/use-sub-districts'
import AuditsDialog from '../audits/audits-dialog'
import { Typography } from '@mui/material'
import numeral from 'numeral'

interface Props {
    open: boolean
    close: () => void
    data: any
}

const MiddayMaxVotersLabel = ({ row, value }: { row: PagedNodeResponse; value: 'middayVoters' | 'maxVoters' }) => {
    const [t] = useTranslation()

    if (row.nodeType === NodeType.POLLING_CENTER) {
        return row.electionType === ElectionType.PRIVATE
            ? row.privateElectionData?.[value]
            : row.publicElectionData?.[value]
    }

    return (
        <Stack>
            <Typography variant="body2">
                {t('model.user.electionTypes.options.Private')}:{' '}
                {numeral(row.privateElectionData?.[value]).format('0,0')}
            </Typography>
            <Typography variant="body2">
                {t('model.user.electionTypes.options.Public')}: {numeral(row.publicElectionData?.[value]).format('0,0')}
            </Typography>
        </Stack>
    )
}

const ProfileDrawer = ({ open, close, data }: Props) => {
    const [t] = useTranslation()
    const [edit, setEdit] = useState<boolean>(false)
    const queryClient = useQueryClient()
    const { successToast } = useToast()
    const [image, setImage] = useState<string | null>(null)
    const { can } = useCheckPermissions()
    const { provinces } = useProvinces()
    const { districts } = useDistricts()
    const { subDistricts } = useSubDistricts()
    const [auditsDialogOpen, setAuditsDialogOpen] = useState(false)

    const closeDrawer = useCallback(() => {
        setEdit(false)
        close()
    }, [close])

    const {
        data: node,
        isLoading,
        isRefetching,
        refetch
    } = useQuery({
        queryKey: ['node', data],
        queryFn: async () => {
            const res = await NodesService.getNodeById({
                id: data?.id
            })
            return res
        },
        enabled: !!data
    })

    const { data: vrcOptions } = useQuery({
        queryKey: ['nodeVrcOptions', node],
        queryFn: async () => {
            const res = await VotingRegistrationCentersService.listAllVotingRegistrationCenters()
            return res
        },
        placeholderData: (placeholderData) => placeholderData,
        enabled: !!node?.vrcId
    })

    const details: DetailsListProps = useMemo(
        () => ({
            title: t('view.common.details.label'),
            fields: node
                ? [
                      { label: t('model.common.id'), value: node.id },
                      { label: t('model.common.name'), value: node.name },
                      { label: t('model.common.code'), value: node.code, hidden: !node.code },
                      { label: t('model.node.address'), value: node.address, hidden: !node.address },
                      { label: t('model.node.parent'), value: node.parent?.name, hidden: !node.parent },
                      { label: t('view.circle.name.headline'), value: node.circle?.name, hidden: !node.circle },
                      {
                          label: t('model.node.province'),
                          value: provinces[node?.provinceId ?? ''],
                          hidden: !node.provinceId
                      },
                      {
                          label: t('model.node.district'),
                          value: districts[node?.districtId ?? ''],
                          hidden: !node.districtId
                      },
                      {
                          label: t('model.node.subDistrict'),
                          value: subDistricts[node?.subDistrictId ?? ''],
                          hidden: !node.subDistrictId
                      },
                      { label: t('view.circle.name.headline'), value: node.circle?.name, hidden: !node.circle },
                      {
                          label: t('model.user.electionTypes.label'),
                          value: t(`model.user.electionTypes.options.${node.electionType}` as any),
                          hidden: !node.electionType
                      },
                      {
                          label: t('model.node.vrcId'),
                          value: vrcOptions?.find((vrc) => vrc.id === node.vrcId)?.name,
                          hidden: !node.vrcId
                      },
                      {
                          label: t('model.station.maxVoters'),
                          Template: MiddayMaxVotersLabel,
                          templateProps: {
                              row: node,
                              value: 'maxVoters'
                          },
                          hidden: !node.privateElectionData || !node.publicElectionData
                      },
                      {
                          label: t('model.station.middayVoters'),
                          Template: MiddayMaxVotersLabel,
                          templateProps: {
                              row: node,
                              value: 'middayVoters'
                          },
                          hidden: !node.privateElectionData || !node.publicElectionData
                      },
                      {
                          label: t('model.station.middayVotersRecordedAt'),
                          value: dateFormat({
                              date: node.middayVotersRecordedAt
                          }),
                          hidden: !node.middayVotersRecordedAt
                      },
                      {
                          label: t('model.common.status'),
                          Template: StatusLabel,
                          templateProps: {
                              isActive: node.isActive
                          }
                      }
                  ]
                : []
        }),
        [districts, node, provinces, subDistricts, t, vrcOptions]
    )

    return (
        <MiniDrawer
            open={open}
            close={closeDrawer}
            header={t('view.node.name.headline')}
            details={edit ? undefined : details}
            loading={isLoading}
        >
            {!edit && (
                <Stack direction="row" justifyContent="space-between">
                    <Button onClick={closeDrawer} variant="outlined" size="small">
                        {t('view.common.dialog.close')}
                    </Button>

                    <Stack direction="row" spacing={2} justifyContent="flex-end">
                        {can({
                            resource: RESOURCES.audit,
                            actions: [ACTIONS.read]
                        }) && (
                            <Button
                                variant="contained"
                                size="small"
                                onClick={() => setAuditsDialogOpen(true)}
                                disabled={isRefetching}
                                color="info"
                            >
                                {t('view.common.audit')}
                            </Button>
                        )}
                        {can({
                            resource: RESOURCES.node,
                            actions: [ACTIONS.update]
                        }) && (
                            <Button
                                variant="contained"
                                size="small"
                                onClick={() => setEdit(true)}
                                disabled={isRefetching}
                            >
                                {t('view.actions.common.edit')}
                            </Button>
                        )}
                    </Stack>
                </Stack>
            )}
            {edit && node && (
                <Edit
                    node={node}
                    handleSuccess={(data) => {
                        successToast(
                            t(`view.notifications.common.update`, {
                                name: t(`model.node.type.options.${data.nodeType}` as any)
                            })
                        )
                        refetch()
                        queryClient.invalidateQueries({ queryKey: ['nodes'] })
                        setEdit(false)
                    }}
                    handleClose={() => {
                        setEdit(false)
                    }}
                />
            )}
            <ModalImage open={!!image} large={image} alt="image" onClose={() => setImage(null)} />

            {node && (
                <AuditsDialog
                    version={node?.version ?? 0}
                    open={auditsDialogOpen}
                    onClose={() => setAuditsDialogOpen(false)}
                    id={node?.id ?? ''}
                    type="nodes"
                />
            )}
        </MiniDrawer>
    )
}

export default ProfileDrawer
