import styled from '@emotion/styled'
import { Card, Radio, Typography } from '@mui/material'
import { Box, Stack } from '@mui/system'
import { CircleCheck, CircleX } from 'lucide-react'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { GridComponents, VirtuosoGrid, VirtuosoGridHandle } from 'react-virtuoso'
import { MinimalStationResponse, StationOperationalStatus } from 'src/services/api/emos'
import { formatStationNumber } from './utils'

export const ItemContainer = styled.div`
    box-sizing: border-box;
    padding: 10px;
    width: 50%;
    background: #f5f5f5;
    display: flex;
    flex: none;
    align-content: stretch;
    /*
  @media (max-width: 1024px) {
    width: 33%;
  }

  @media (max-width: 768px) {
    width: 50%;
  }

  @media (max-width: 480px) {
    width: 100%;
  }
  */
`

export const ListContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
` as GridComponents['List']

export const ItemWrapper = styled.div`
    flex: 1;
  }
`

export const Station = React.memo<{
    station: MinimalStationResponse
    handleOnClick: (station: MinimalStationResponse) => void
    value: string | null | undefined
    isStationInitiation?: boolean
}>(
    ({
        station,
        handleOnClick,
        value,
        isStationInitiation
    }: {
        station: MinimalStationResponse
        handleOnClick: (station: MinimalStationResponse) => void
        value: string | null | undefined
        isStationInitiation?: boolean
    }) => {
        const { t } = useTranslation()

        const isActive = value === station.id

        const isOperational = isStationInitiation && station.initiationStatus === StationOperationalStatus.OPERATIONAL
        const isNonOperational =
            isStationInitiation && station.initiationStatus === StationOperationalStatus.NON_OPERATIONAL

        const cardBackgroundColor = useMemo(() => {
            if (isOperational) {
                return '#f1fff1'
            } else if (isNonOperational) {
                return '#ffebeb'
            } else if (isActive) {
                return '#e6e6ff'
            }
            return 'background.paper'
        }, [isOperational, isNonOperational, isActive])

        return (
            <Card
                variant="outlined"
                onClick={() => {
                    if (station) {
                        handleOnClick(station)
                    }
                }}
                sx={{
                    alignContent: 'center',
                    height: 100,
                    backgroundColor: cardBackgroundColor,
                    boxShadow: isActive ? '0 0 0 2px #0002FF' : 'none',
                    '&:hover': {
                        cursor: isOperational || isNonOperational ? 'not-allowed' : 'pointer',
                        boxShadow: !isActive ? '0 0 0 2px #e6e6ff' : '0 0 0 2px #0002FF'
                    }
                }}
            >
                <Box sx={{ p: 2 }}>
                    <Stack direction="row" alignItems="center" justifyContent="space-between">
                        <Stack direction="column">
                            <Typography
                                variant="body2"
                                color={isActive || isOperational || isNonOperational ? 'black' : 'textPrimary'}
                            >
                                {`${t('view.station.name.headline')}: ${formatStationNumber(station.number)}`}
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                                {`${station.pcName}`}
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                                {`${station.pcCode}`}
                            </Typography>
                        </Stack>
                        {(!isStationInitiation || (!isOperational && !isNonOperational)) && (
                            <Radio
                                checked={value === station.id}
                                value={station.id}
                                name="station-radio-buttons"
                                sx={{
                                    '&.Mui-checked': {
                                        color: '#0002FF'
                                    }
                                }}
                            />
                        )}
                        {isOperational && (
                            <Box mr={1}>
                                <CircleCheck style={{ color: 'green' }} />
                            </Box>
                        )}
                        {isNonOperational && (
                            <Box mr={1}>
                                <CircleX style={{ color: 'red' }} />
                            </Box>
                        )}
                    </Stack>
                </Box>
            </Card>
        )
    }
)
Station.displayName = 'Station'

export const StationVirtuosoGrid = ({
    stations,
    handleOnClick,
    value,
    isStationInitiation
}: {
    stations: MinimalStationResponse[]
    handleOnClick: (station: MinimalStationResponse) => void
    value: string | null | undefined
    isStationInitiation?: boolean
}) => {
    const ref = React.createRef<VirtuosoGridHandle>()

    const itemContent = React.useCallback(
        (index: number) => (
            <ItemWrapper>
                <Station
                    isStationInitiation={isStationInitiation}
                    station={stations[index]}
                    value={value}
                    handleOnClick={(station) => {
                        handleOnClick(station)
                    }}
                />
            </ItemWrapper>
        ),
        [handleOnClick, isStationInitiation, stations, value]
    )

    return (
        <VirtuosoGrid
            ref={ref}
            components={{
                Item: ItemContainer,
                List: ListContainer
            }}
            totalCount={stations.length}
            itemContent={itemContent}
            style={{ height: 300, width: '100%' }}
        />
    )
}
