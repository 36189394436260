import { useTranslation } from 'react-i18next'
import Results from 'src/components/dashboard/violations/results'
import LayoutPage from 'src/components/page'
import { PermissionsGuard } from 'src/guards/permissions-guard'
import { ACTIONS, RESOURCES, useCheckPermissions } from 'src/hooks/use-check-permissions'
import { useRouter } from 'src/hooks/use-router'
import { paths } from 'src/paths'

const Violations = () => {
    const { t } = useTranslation()
    const router = useRouter()
    const { can } = useCheckPermissions()

    return (
        <PermissionsGuard resource="violation" actions={[ACTIONS.read]}>
            <LayoutPage
                title="violation"
                breadcrumbs={[
                    {
                        title: t('view.violation.header')
                    }
                ]}
                actions={[
                    {
                        hidden: !can({
                            resource: RESOURCES.violation,
                            actions: [ACTIONS.create]
                        }),
                        label: t('view.violation.new.headline'),
                        onClick: () => router.push(paths.violations.new),
                        color: 'primary'
                    }
                ]}
            >
                <Results />
            </LayoutPage>
        </PermissionsGuard>
    )
}

export default Violations
