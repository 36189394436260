import type { FC } from 'react'
import Box from '@mui/material/Box'
import ButtonBase from '@mui/material/ButtonBase'
import { usePopover } from 'src/hooks/use-popover'
import { AccountPopover } from './account-popover'
import UserAvatar from 'src/components/dashboard/users/user-avatar'
import { useAuth } from 'src/hooks/use-auth'
import { Stack } from '@mui/system'
import { Typography } from '@mui/material'

export const AccountButton: FC = () => {
    const popover = usePopover<HTMLButtonElement>()
    const { user } = useAuth()

    return (
        <Stack direction="row" spacing={1} alignItems="center">
            <Box
                component={ButtonBase}
                onClick={popover.handleOpen}
                ref={popover.anchorRef}
                sx={{
                    alignItems: 'center',
                    display: 'flex',
                    borderWidth: 2,
                    borderStyle: 'solid',
                    borderColor: 'neutral.600',
                    height: 40,
                    width: 40,
                    borderRadius: '50%'
                }}
            >
                <UserAvatar
                    firstName={user?.username}
                    sx={{
                        backgroundColor: 'divider',
                        color: 'text.primary'
                    }}
                />
            </Box>
            <Stack
                direction="column"
                alignItems="start"
                component={ButtonBase}
                onClick={popover.handleOpen}
                ref={popover.anchorRef}
            >
                <Typography color="textPrimary" variant="caption">
                    {user?.username}
                </Typography>
                <Typography color="textSecondary" variant="caption">
                    {user?.node?.name}
                </Typography>
            </Stack>
            <AccountPopover anchorEl={popover.anchorRef.current} onClose={popover.handleClose} open={popover.open} />
        </Stack>
    )
}
