import { Card, Grid, Typography } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { DetailedVotingResultResponse } from 'src/services/api/emos'
import VotingResultAttachment from './voting-result-attachment'
import { formatStationNumber } from '../../stations/utils'
import PCNameCode from '../../nodes/pc-name-code'
import { allCirclesQuery } from 'src/queries/circles'
import { useMemo } from 'react'

const StationDetails = ({ votingResult }: { votingResult: DetailedVotingResultResponse | undefined }) => {
    const { t } = useTranslation()

    const { data: circles = [] } = useQuery({
        ...allCirclesQuery()
    })

    const items = useMemo(
        () =>
            !votingResult
                ? []
                : [
                      {
                          name: 'number',
                          label: t('model.station.number'),
                          value: formatStationNumber(votingResult.stationNumber)
                      },
                      {
                          name: 'maxVoters',
                          value: votingResult?.maxVotes,
                          label: t('model.station.maxVoters')
                      },
                      {
                          name: 'subBranch',
                          label: t('model.node.type.options.SubBranch'),
                          value: votingResult.subBranchName
                      },
                      {
                          name: 'pcId',
                          label: t('model.node.type.options.PollingCenter'),
                          value: `${votingResult.pcName} (${votingResult.pcCode})`,
                          Template: () => (
                              <PCNameCode name={votingResult.pcName} code={votingResult.pcCode} variant="body1" />
                          )
                      },
                      {
                          hidden: !votingResult?.circleId,
                          name: 'circle',
                          label: t('view.circle.name.headline'),
                          value: votingResult?.circleId && circles.find((c) => c.id === votingResult.circleId)?.name
                      }
                  ],
        [votingResult, t, circles]
    )

    if (!votingResult) return null
    return (
        <Grid item xs={12}>
            <Card variant="outlined" sx={{ p: 2 }}>
                <Grid container spacing={3}>
                    {items.map((item) => {
                        const Template = item.Template || (null as any)
                        return (
                            <Grid item xs={6} sm={4} md={4} lg={4} xl={3} key={item.name}>
                                {Template ? (
                                    <Template item={item} />
                                ) : (
                                    <Typography variant="body1">{item.value ?? '-'}</Typography>
                                )}
                                <Typography color="textSecondary" variant="caption">
                                    {item.label}
                                </Typography>
                            </Grid>
                        )
                    })}
                    <Grid item xs={6} sm={4} md={4} lg={4}>
                        {votingResult && <VotingResultAttachment votingResult={votingResult} />}
                    </Grid>
                </Grid>
            </Card>
        </Grid>
    )
}

export default StationDetails
