import { ElectionType, NodesService, NodeType } from 'src/services/api/emos'

export const allNodesQuery = ({
    type,
    isActive,
    electionType
}: {
    type?: NodeType
    isActive?: boolean
    electionType?: ElectionType
}) => ({
    queryKey: ['allNodes', type, isActive, electionType],
    queryFn: async () => {
        const res = await NodesService.listAllNodes({
            nodeType: type,
            isActive,
            electionType
        })
        return res
    }
})

function buildTree(data) {
    const root = []

    const findOrCreateNode = (nodes, id, name) => {
        let node = nodes.find((node) => node.value === id)
        if (!node) {
            node = { value: id, title: name || id, children: [] }
            nodes.push(node)
        }
        return node
    }

    data.forEach((item) => {
        let currentLevel = root

        item.path.forEach((id, index) => {
            const foundItem = data.find((d) => d.id === id)
            const name = foundItem ? foundItem.name : id
            const node = findOrCreateNode(currentLevel, id, name)
            if (index === item.path.length - 1) {
                node.value = item.id
                node.title = item.name
            }
            currentLevel = node.children
        })
    })

    return root
}

export const allNodesTreeQuery = ({
    type,
    isActive,
    electionType
}: {
    type?: NodeType
    isActive?: boolean
    electionType?: ElectionType
}) => ({
    queryKey: ['allNodesTree', type, electionType, isActive],
    queryFn: async () => {
        const res = await NodesService.listAllNodes({
            nodeType: type,
            isActive,
            electionType
        })
        const treeData = buildTree(res)
        return treeData
    },
    gcTime: Infinity,
    staleTime: Infinity
})
