import React, { createContext, useCallback, useContext, useMemo, useState } from 'react'

interface VirtualListItemsKeysContextType {
    key: number
    incrementKey: () => void
}

const initialState: VirtualListItemsKeysContextType = {
    key: 0,
    incrementKey: () => {}
}

const VirtualItemsKeyContext = createContext<VirtualListItemsKeysContextType>(initialState)

export const useVirtualItemsKey = () => {
    return useContext(VirtualItemsKeyContext)
}

export const VirtualItemsKeyProvider = ({ children }: { children: React.ReactNode }) => {
    const [key, setKey] = useState<number>(0)

    const incrementKey = useCallback(() => {
        setKey((prev) => prev + 1)
    }, [])

    const value = useMemo(() => ({ key, incrementKey }), [key, incrementKey])

    return <VirtualItemsKeyContext.Provider value={value}>{children}</VirtualItemsKeyContext.Provider>
}
