import { CreateIncidentRequest, UpdateIncidentRequest } from 'src/services/api/emos'

type CreateIncidentRequestKeys = Array<keyof CreateIncidentRequest>
export const createIncidentAttributesToKeep: CreateIncidentRequestKeys = [
    'description',
    'reason',
    'timeOfBreakage',
    'stationId'
]

type UpdateIncidentRequestKeys = Array<keyof UpdateIncidentRequest>
export const updateIncidentAttributesToKeep: UpdateIncidentRequestKeys = ['description', 'reason', 'timeOfBreakage']
