import React, { createContext, useContext, useMemo, useState } from 'react'

export type Breadcrumb = {
    title: string | number
    href?: string
}

interface BreadcrumbContextType {
    breadcrumb: Breadcrumb[]
    setBreadcrumb: (breadcrumb: Breadcrumb[]) => void
}

const initialState: BreadcrumbContextType = {
    breadcrumb: [],
    setBreadcrumb: () => {}
}

// Create the context
const BreadcrumbContext = createContext<BreadcrumbContextType>(initialState)

// Custom hook to use the BreadcrumbContext
export const useBreadcrumb = () => {
    return useContext(BreadcrumbContext)
}

// Breadcrumb provider component
export const BreadcrumbProvider = ({ children }: { children: React.ReactNode }) => {
    const [breadcrumb, setBreadcrumb] = useState<Breadcrumb[]>([])

    const value = useMemo(() => ({ breadcrumb, setBreadcrumb }), [breadcrumb, setBreadcrumb])

    return <BreadcrumbContext.Provider value={value}>{children}</BreadcrumbContext.Provider>
}
