import { View } from '../en/view'

export const view: View = {
    project_name: 'EMOS',
    krg: 'حکومەتی هەرێمی کوردستان',
    dit: 'فەرمانگەی تەکنەلۆجیای زانیاری',
    version: 'وەشان',
    dashboard: {
        header: 'بەخێربێیت بۆ داشبۆردەکەت',
        description: 'EMOS',
        name: {
            headline: 'داشبۆرد'
        }
    },
    nav: {
        home: 'سەرەکی',
        search: 'گەڕان',
        users: 'بەکارهێنەران',
        account: 'هەژمار',
        application: 'سیستەم',
        management: 'بەڕێوەبردن'
    },
    auth: {
        login: {
            headline: 'چوونە ژوورەوە بۆ ناو هەژمارەکەت',
            unauthorized_message: 'ڕێگە پێنەدراوە یاخود مۆڵەتی پێویستت نییە، تکایە پەیوەندی بکە بە بەڕێوەبەری سیستەم'
        },
        logout: {
            headline: 'چوونەدەرەوە',
            confirmation: 'ئایا دڵنیایت لە چوونەدەرەوە؟'
        }
    },
    languages: {
        label: 'زمان',
        en: 'English',
        ckb: 'کوردی',
        nkb: 'کوردی ناوەندی',
        ar: 'العربیە',
        translation: {
            en: 'ئینگلیزی',
            ar: 'عەرەبی',
            ckb: 'کوردی'
        }
    },
    user: {
        header: 'بەکارهێنەرەکان',
        name: {
            headline: 'بەکارهێنەر'
        },
        all: {
            headline: 'هەموو بەکارهێنەرەکان'
        },
        new: {
            headline: 'بەکارهێنەری نوێ'
        },
        edit: {
            headline: 'دەستکاریکردنی بەکارهێنەر'
        },
        profile: {
            headline: 'پرۆفایلی بەکارهێنەر'
        },
        onboard: {
            headline: 'گەڕانی بەکارهێنەر',
            subtitle: 'زانیارییەکانی بەکارهێنەر پڕبکەرەوە'
        },
        alert: {
            guarantor_inactive: 'دۆخی دەستەبەر ناچالاکە'
        }
    },
    candidate: {
        header: 'کاندیدەکان',
        name: {
            headline: 'کاندید'
        },
        all: {
            headline: 'هەموو کاندیدەکان'
        },
        new: {
            headline: 'کاندیدی نوێ'
        },
        edit: {
            headline: 'دەستکاریکردنی کاندید'
        }
    },
    party: {
        header: 'حزبەکان',
        name: {
            headline: 'حزب'
        },
        all: {
            headline: 'هەموو حزبەکان'
        },
        new: {
            headline: 'حزبی نوێ'
        },
        edit: {
            headline: 'دەستکاریکردنی حزب'
        },
        all_parties_counted: 'هەموو حزبەکان هەژمارکراون',
        not_counted: 'هەژمار نەکراو'
    },
    circle: {
        header: 'بازنەکان',
        name: {
            headline: 'بازنە'
        },
        all: {
            headline: 'هەموو بازنەکان'
        },
        new: {
            headline: 'بازنەی نوێ'
        },
        edit: {
            headline: 'دەستکاریکردنی بازنە'
        }
    },
    systemConfiguration: {
        header: 'ڕێکخستنی سیستەم',
        name: {
            headline: 'ڕێکخستنی سیستەم'
        },
        edit: {
            headline: 'دەستکاریکردنی ڕێکخستنی سیستەم'
        },
        outsideActiveDuration: 'لەدەرەی کاتی چالاکدایە'
    },
    incident: {
        header: 'ڕووداوەکان',
        name: {
            headline: 'ڕووداو'
        },
        all: {
            headline: 'هەموو ڕووداوەکان'
        },
        new: {
            headline: 'ڕووداوی نوێ',
            subtitle: 'تۆمارکردنی ڕووداوی نوێ'
        },
        edit: {
            headline: 'دەستکاریکردنی ڕووداو'
        },
        command: {
            resolve: {
                action: {
                    title: 'چارەسەرکردنی ڕووداو',
                    confirmation: 'ئایا دڵنیایت لە چارەسەرکردنی ڕووداو؟'
                }
            },
            reopen: {
                action: {
                    title: 'کردنەوەی ڕووداو',
                    confirmation: 'ئایا دڵنیایت لە کردنەوەی ڕووداو؟'
                }
            }
        }
    },
    node: {
        header: 'بەشەکان',
        name: {
            headline: 'بەش'
        },
        all: {
            headline: 'هەموو بەشەکان'
        },
        new: {
            headline: 'بەشی نوێ'
        },
        edit: {
            headline: 'دەستکاریکردنی بەش'
        }
    },
    violation: {
        header: 'پێشێلکارییەکان',
        name: {
            headline: 'پێشێلکاری'
        },
        all: {
            headline: 'هەموو پێشێلکارییەکان'
        },
        new: {
            headline: 'پێشێلکاریی نوێ',
            subtitle: 'تۆمارکردنی پێشێلکاریی نوێ'
        },
        edit: {
            headline: 'دەستکاریکردنی پێشێلکاری'
        },
        command: {
            reopen: {
                action: {
                    title: 'کردنەوەی پێشێلکاری',
                    confirmation: 'ئایا دڵنیایت لە کردنەوەی پێشێلکاری؟'
                }
            },
            drop: {
                action: {
                    title: 'داخستنی پێشێلکاری',
                    confirmation: 'ئایا دڵنیایت لە داخستنی پێشێلکاری؟'
                }
            },
            submit: {
                action: {
                    title: 'پێشکەشکردنی پێشێلکاری',
                    confirmation: 'ئایا دڵنیایت لە پێشکەشکردنی پێشێلکاری؟'
                }
            }
        },
        detail: 'وردەکاری سکاڵا'
    },
    station: {
        header: 'وێستگەکان',
        name: {
            headline: 'وێستگە'
        },
        all: {
            headline: 'هەموو وێستگەکان'
        },
        new: {
            headline: 'وێستگەی نوێ'
        },
        edit: {
            headline: 'دەستکاریکردنی وێستگە'
        },
        initiate: {
            headline: 'دەستبەکاربوونی وێستگە',
            subtitle: 'ڕێکەوت و کاتی کردنەوەی وێستگە',
            success: 'وێستگە بە سەرکەوتوویی دەستبەکاربوو'
        },
        middayReport: {
            headline: 'ڕاپۆرتی نیوەی ڕۆژ',
            subtitle: 'تۆمارکردنی ڕاپۆرتی نیوەی ڕۆژ',
            success: 'ڕاپۆرتی نیوەی ڕۆژ بە سەرکەوتوویی نوێکرایەوە'
        }
    },
    votingResult: {
        header: 'ئەنجامەکانی دەنگدان',
        name: {
            headline: 'ئەنجامی دەنگدان',
            subtitle: 'تۆمارکردنی ئەنجامی دەنگدان'
        },
        all: {
            headline: 'هەموو ئەنجامەکانی دەنگدان'
        },
        new: {
            headline: 'ئەنجامی دەنگدانی نوێ'
        },
        edit: {
            headline: 'دەستکاریکردنی ئەنجامی دەنگدان'
        },
        command: {
            Submit: {
                action: {
                    title: 'پێشکەشکردن',
                    confirmation: 'ئایا دڵنیایت لە پێشکەشکردنی ئەنجامی دەنگدان؟'
                }
            },
            Draft: {
                action: {
                    title: 'دەستکاریکردن',
                    confirmation: 'ئایا دڵنیایت لە گۆڕینی ئەنجامی دەنگدان بۆ دەستکاریکردن؟'
                }
            }
        }
    },
    reports: {
        header: 'ڕاپۆرتەکان',
        disclaimer: 'ئاگاداربوون: ئەم ڕاپۆرتانە هەموو ٣ دەقە جارێک نوێ دەبنەوە',
        partyVotes: 'کۆی دەنگی حزب',
        candidateVotes: 'کۆی دەنگی پاڵێوراوەکان',
        partiesAndCandidatesVotes: 'دەنگی حزب، هاوپەیمانی، کاندیدی سەربەخۆ',
        totalVotes: 'کۆی دەنگەکان',
        voterValidityDistribution: 'ژمارە و ڕێژەی جۆری دەنگ',
        voterParticipationDistribution: 'ڕێژەی بەشداری',
        participated: 'بەشداری کردووە',
        notParticipated: 'بەشداری نەکردووە',
        validVotes: 'دروست',
        invalidVotes: 'پووچەڵ',
        operation: {
            header: 'ڕاپۆرتی کارکردن',
            stationInitiationReport: 'ڕاپۆرتی دەستبەکاربوونی وێستگە',
            middayResultReport: 'ڕاپۆرتی نیوەی ڕۆژ',
            reported: 'ڕاپۆرتکراوە',
            notReported: 'ڕاپۆرت نەکراوە',
            violationReportStatus: 'ڕاپۆرتی پێشێلکاری بەپێی دۆخ',
            violationReportPerType: 'ڕاپۆرتی پێشێلکاری بەپێی جۆر',
            stationSubmissionReport: 'پێشکەشکردنی وێستگە',
            submitted: 'پێشکەش کراوە',
            notSubmitted: 'پێشکەش نەکراوە',
            incidentReport: 'ڕاپۆرتی ڕووداو'
        }
    },
    pollingCenters: {
        header: 'بنکەکان'
    },
    notification: {
        header: 'ئاگادارکردنەوەکان',
        all: {
            headline: 'هەموو ئاگادارکردنەوەکان',
            label: 'هەمووی'
        },
        unread: {
            headline: 'ئاگادارکردنەوە نەخوێندراوەکان',
            label: 'نەخوێندراوەکان',
            mark_read: 'نیشانەکردن وەک خوێندراوە'
        },
        read: {
            headline: 'ئاگادارکردنەوە خوێندراوەکان',
            label: 'خوێندراوەکان'
        },
        no_more: 'هیچ ئاگادارکردنەوەیەکی دیکە بوونی نییە',
        empty: 'هیچ ئاگادارکردنەوەیەک بەردەست نییە',
        incident_created: {
            title: 'ڕووداوی نوێ دروستکرا.'
        },
        incident_state_changed: {
            title: 'دۆخی ڕووداو گۆڕا بۆ {{to}}.'
        },
        station_vrs_done: {
            title: 'هەموو فۆرمەکانی ئەجامەکانی دەنگدان پێشکەشکران.'
        }
    },
    common: {
        uploads: {
            upload: 'بارکردن',
            capture: 'گرتنی وێنە',
            use: 'بەکارهێنان',
            retake: 'گرتنەوەی وێنە',
            switch: 'گۆڕینی کامێرا'
        },
        all: 'هەمووی',
        choose: 'هەڵبژاردن',
        no_data: 'هیچ داتایەک بەردەست نییە',
        found: 'دۆزرایەوە',
        language_change: 'زمانی سیستەم نوێکرایەوە',
        actions: {
            new: {
                success: 'بە سەرکەوتوویی {{name}}ی نوێ دروستکرا',
                fail: 'هەڵەیەک ڕوویدا لە دروستکردنی {{name}}'
            },
            update: {
                success: 'بە سەرکەوتوویی {{name}} نوێکرایەوە',
                fail: 'هەڵەیەک ڕوویدا لە نوێکردنەوەی {{name}}'
            },
            status: {
                success: 'دۆخی {{name}} بە سەرکەوتوویی نوێکرایەوە',
                fail: 'هەڵەیەک ڕوویدا لە نوێکردنەوەی دۆخی {{name}}'
            }
        },
        add_file: 'زیادکردنی هاوپێچ',
        clear: 'بەتاڵکردنەوەی فلتەر',
        from: 'لە',
        to: 'بۆ',
        view: 'سەیرکردن',
        form: {
            submit: 'پێشکەشکردن',
            save: 'تۆمارکردن',
            update: 'نوێکردنەوە',
            cancel: 'داخستن'
        },
        dialog: {
            submit: 'پێشکەشکردن',
            cancel: 'داخستن',
            close: 'داخستن',
            yes: 'بەڵێ',
            confirm: 'دووپاتکردنەوە',
            edit: 'دەستکاریکردن',
            add: 'زیادکردن',
            delete: 'سڕینەوە',
            back: 'گەڕانەوە'
        },
        pagination: {
            next: 'دواتر',
            back: 'گەڕانەوە',
            rows_per_page: 'ڕیز لە پەڕە',
            of: 'لە'
        },
        details: {
            label: 'وردەکارییەکان',
            translations: 'وەرگێڕانەکان'
        },
        status: {
            active: {
                label: 'چالاک',
                action: {
                    title: 'چالاک کردن',
                    confirmation: 'ئایا دڵنیایت لە چالاک کردنی {{name}}؟'
                }
            },
            inactive: {
                label: 'ناچالاک',
                action: {
                    title: 'ناچالاک کردن',
                    confirmation: 'ئایا دڵنیایت لە ناچالاک کردنی {{name}}؟'
                }
            },
            pending: {
                label: 'لە کاردایە'
            },
            complete: {
                label: 'تەواو بووە'
            },
            unknown: {
                label: 'نەزانراو'
            }
        },
        'yes/no': {
            true: 'بەڵێ',
            false: 'نەخێر'
        },
        filter: {
            label: 'فلتەر',
            reset: 'بەتاڵکردنەوەی فلتەر'
        },
        search: {
            label: 'گەڕان',
            tip: 'گەڕان لەڕێگەی نووسینی وشە و دواتر داگرتنی (Enter)'
        },
        audit: 'گۆڕانکارییەکان',
        changes: {
            from: 'لە',
            to: 'بۆ'
        }
    },
    notifications: {
        auth: {
            login: 'بە سەرکەوتوویی چوویتە ژوورەوە',
            fail_login: 'هەڵەیە! تکایە دڵنیا ببەرەوە لە ئیمەیڵ و وشەی تێپەڕبوون',
            logout: 'چوویتە دەرەوە',
            update_profile: 'پرۆفایل نوێکرایەوە',
            update_password: 'وشەی تێپەڕبوون گۆڕا'
        },
        users: {
            create: 'بەکارهێنەری نوێ دروستکرا',
            update: 'بەکارهێنەر نوێکرایەوە',
            update_password: 'وشەی تێپەڕی بەکارهێنەر نوێکرایەوە'
        },
        common: {
            create: '{{name}}ی نوێ زیادکرا',
            update: '{{name}} نوێکرایەوە',
            activate: '{{name}} چالاک کرا',
            deactivate: '{{name}} ناچالاک کرا'
        }
    },
    sidebar: {
        management: 'بەڕێوەبردن',
        reports: 'ڕاپۆرتەکان'
    },
    actions: {
        label: 'کردارەکان',
        common: {
            edit: 'دەستکاریکردن',
            edit_name: 'دەستکاریکردنی {{name}}',
            remove: 'سڕینەوە',
            print: 'چاپکردنی ڤیزا'
        }
    },
    roles: {
        admin: 'ئادمین',
        pb_sv: 'سەرپەرشتیاری مەکتەب ڕێکخستن',
        branch_sv: 'سەرپەرشتیاری لق',
        sb_sv: 'سەرپەرشتیاری ناوچه',
        pc_sv: 'سەرپەرشتیاری بنکە',
        sys_mgr: 'بەڕێوەبەری سیستەم',
        voting_result_editor_F: 'دەستکاریکەری ئەنجامی دەنگدان (کۆتایی)',
        voting_result_editor_A_M: 'دەستکاریکەری ئەنجامی دەنگدان (ئەلیکترۆنی/دەستی)',
        data_exporter: 'ڕاکێشەری زانیاری',
        violation_mgr: 'بەڕێوەبەری پێشێلکارییەکان'
    },
    account: {
        profile: {
            headline: 'پرۆفایل'
        }
    },
    error: {
        '403': 'ببوره‌، مافی بینینی ئه‌م به‌شه‌ت نییه‌',
        '404': 'ببوره‌، ئه‌و په‌یجه‌ی به‌دوایدا ده‌گه‌ڕێی بوونی نییه‌',
        go_back: 'گه‌ڕانه‌وه‌'
    },
    validations: {
        max_files: 'ژمارەی هاوپێچەکان نابێت زیاتر بێت لە {{maxFiles}}',
        'file-invalid-type': 'جۆری هاوپێچ ڕێگەپێنەدراوە',
        'too-many-files': 'ژمارەی هاوپێچەکان زیاترە لەوەی کە ڕێگەپێدراوە',
        mb: 'File size cannot be more than {{size}} MB',
        upload_failed: 'هەڵه ڕوویدا لە بارکردن',
        file_too_large: 'هاوپێچ گەورەیە',
        passwordConfirm: 'وشەی تێپەڕبوون هاوشێوە نییە',
        required: 'داواکراوە',
        fail_save: 'هەڵەیەک ڕوویدا لە پاشەکەوتکردنی زانیارییەکان',
        parties_fail_save: 'هەڵەیەک ڕوویدا لە پاشەکەوتکردنی زانیارییەکانی هەندێک لە حزبەکان',
        node_required:
            'بە لایەنی کەمەوە یەکێک لە ژووری بەڕێوەبردنی هەڵبژاردن, مەکتەب رێکخستن, لق، ناوچە یاخود بنکە داواکراوە',
        blank: 'نابێت بەتاڵ بێت',
        cannot_be_in_future: 'نابێت لە داهاتوودا بێت'
    },
    network: {
        offline: 'تۆ ئۆفلاینیت تکایە پەیوەندی ئینتەرنێتەکەت بپشکنە.',
        online: 'هێڵی ئینتەرنێت بەردەستە'
    },
    versionMismatch: {
        header: 'کەسێکی تر گۆڕانکاری لە داتاکەدا کردبوو، تکایە لاپەڕەکە تازە بکەوە بۆ بینینی دوایین گۆڕانکاریەکان.',
        refresh: 'تازەکردنەوە'
    },
    timezone: {
        header: 'کاتی ئامێر',
        timezone_difference:
            'وادیارە کاتی ئامێرەکەت جیاوازە لەگەڵ ڕاژەکارەکە بە زیاتر لە 5 خولەک. تکایە کاتی سیستەمەکەت بپشکنە بۆ ئەزموونێکی وردتر.'
    },
    export: {
        middayVoters: 'داگرتنی ڕاپۆرتی نیوەی ڕۆژ',
        stations: 'داگرتنی وێستگەکان'
    },
    errors: {
        circle_not_found: 'بازنە نەدۆزرایەوە',
        parent_not_found: 'باوان نەدۆزرایەوە',
        station_not_found: 'وێستگە نەدۆزرایەوە',
        station_not_initiated: 'وێستگە دەستبەکار نەکراوە',
        sub_district_not_found: 'ناوچە نەدۆزرایەوە',
        node_not_found: 'بەش نەدۆزرایەوە',
        pc_in_user_cannot_change_election_type: 'ناتوانێت جۆری هەڵبژاردن بگۆڕێت',
        invalid_parent: 'باوان نادروستە',
        duplicate_entry: 'دووبارە',
        candidate_not_found: 'کاندید نەدۆزرایەوە',
        party_not_found: 'حزب نەدۆزرایەوە',
        cannot_update_own_user: 'بەکارهێنەر ناتوانێ خۆی نوێ بکاتەوە',
        user_already_exists: 'بەکارهێنەر بوونی هەیە',
        unsupported_content_type: 'Unsupported content type',
        station_already_initiated: 'وێستگە پێشتر دەستبەکاربووە',
        not_in_morning_duration: 'لە ماوەی بەیانیدا نییە',
        not_in_afternoon_duration: 'لە ماوەی دوانیوەڕۆدا نییە',
        not_in_evening_duration: 'لە ماوەی ئێوارەدا نییە',
        voters_exceed_max_voters: 'ژمارەی دەنگدەران ژمارەی دەنگدەرانی تێپەڕاندووە',
        comment_required: 'سەرنج داواکراوە',
        attachment_required: 'هاوەپێچ داواکراوە',
        candidate_vote_count_exceeded_total_votes: 'ژمارەی دەنگی کاندید کۆی گشتی دەنگدەرانی تێپەڕاندووە',
        invalid_circle: 'بازنەی نادروست',
        invalid_election_type: 'جۆری هەڵبژاردنی نادروست',
        total_votes_exceeded_max_votes: 'کۆی دەنگەکان کۆی گشتی دەنگدەرانی لە وێستگە تێپەڕاندووە',
        party_already_has_voting_results_in_circle: 'حزب پێشتر ئەنجامی دەنگدان لە بازنە هەیە',
        station_must_have_circles: 'وێستگە پێویستە بازنەی هەبێت',
        invalid_roles_for_node: 'ڕۆڵەکانی نادروست بۆ بەش',
        cannot_submit_voting_result_without_parties_or_invalid_papers:
            'بەبێ پڕکردنەوەی کۆی پەڕاوی دەنگدانی پووچەڵ و حزب ناکرێت ئەنجامی دەنگدان پێشکەش بکرێت',
        cannot_edit_voting_result_when_not_draft: 'ناتوانرێت ئەنجامی دەنگدان دەستکاری بکرێت لە کاتی ڕەشنووس'
    }
}
