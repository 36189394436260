import { Card, CardContent, Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'
import LayoutPage from 'src/components/page'
import { paths } from 'src/paths'
import { yupResolver } from '@hookform/resolvers/yup'
import { useState } from 'react'
import { ElectionType, StationsService } from 'src/services/api/emos'
import { SubmitHandler, useForm } from 'react-hook-form'
import { FormFields } from 'mui-rhf-library'
import FormActions from 'src/components/drawer/form-actions'
import { useToast } from 'src/hooks/useToast'
import { useQuery } from '@tanstack/react-query'
import { useParams } from 'react-router'
import { useRouter } from 'src/hooks/use-router'
import { useStations } from 'src/hooks/use-stations'
import { schema } from 'src/components/dashboard/stations/schema'
import { filterObject } from 'src/utils/filter-object'
import { updateStationAttributesToKeep } from '../../components/dashboard/stations/constants'

const Edit = () => {
    const { t } = useTranslation()
    const [btnLoading, setBtnLoading] = useState(false)
    const { errorsToast } = useToast()
    const { id } = useParams<{ id: string }>()
    const router = useRouter()

    const { data: station } = useQuery({
        queryKey: ['station', id],
        queryFn: async () => {
            const res = await StationsService.getStationById({
                id: id as string
            })
            formConfigs.reset({
                ...res,
                pcId: res.pc.id,
                circlesIds: res.circles.map((circle) => circle.id)
            })
            return res
        },
        enabled: !!id
    })

    const formConfigs = useForm<any>({
        resolver: yupResolver(schema())
    })
    const {
        handleSubmit,
        control,
        formState: { isDirty }
    } = formConfigs

    const { fields } = useStations({
        isEdit: true,
        formConfigs
    })

    const handleFormSubmit: SubmitHandler<any> = async (formData) => {
        if (!station) return
        setBtnLoading(true)

        const filteredData = filterObject({
            obj: formData,
            keys: updateStationAttributesToKeep
        }) as any

        try {
            await StationsService.updateStation({
                id: station.id,
                requestBody: {
                    ...filteredData,
                    version: station.version,
                    circles:
                        !filteredData.isVip && formData.electionType !== ElectionType.PRIVATE
                            ? undefined
                            : filteredData.circles
                }
            })
            router.push(paths.stations.view(station.id))
        } catch (err) {
            errorsToast(err)
        } finally {
            setBtnLoading(false)
        }
    }

    return (
        <LayoutPage
            title="station"
            breadcrumbs={[
                {
                    title: t('view.station.header'),
                    href: paths.stations.index
                },
                {
                    title: station?.number ?? id ?? t('view.station.name.headline'),
                    href: paths.stations.view(id)
                },
                {
                    title: t('view.station.edit.headline')
                }
            ]}
        >
            <Card variant="outlined">
                <CardContent>
                    <form onSubmit={handleSubmit(handleFormSubmit)}>
                        <Grid container spacing={2}>
                            <FormFields fields={fields} control={control} />
                        </Grid>
                        <FormActions
                            disabled={!isDirty}
                            handleClose={() => {
                                router.push(paths.stations.view(station?.id))
                            }}
                            handleSubmit={handleSubmit(handleFormSubmit)}
                            loading={btnLoading}
                        />
                    </form>
                </CardContent>
            </Card>
        </LayoutPage>
    )
}

export default Edit
