import type { FC, ReactNode } from 'react'
import { useSections } from './config'
import { HorizontalLayout } from './horizontal-layout'
import { VerticalLayout } from './vertical-layout'
import { useSettings } from 'src/store/settings'

interface LayoutProps {
    children?: ReactNode
}

export const Layout: FC<LayoutProps> = (props) => {
    const settings = useSettings()
    const sections = useSections()

    if (settings.layout === 'horizontal') {
        return <HorizontalLayout sections={sections} navColor={settings.navColor} {...props} />
    }

    return <VerticalLayout sections={sections} navColor={settings.navColor} {...props} />
}
