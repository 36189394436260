import type { FC, ReactNode } from 'react'
import { useEffect, useState } from 'react'
import { useAuth } from 'src/hooks/use-auth'

interface AuthGuardProps {
    children: ReactNode
}

export const AuthGuard: FC<AuthGuardProps> = (props) => {
    const { children } = props
    const { isAuthenticated, errorCode, isInitialized } = useAuth()
    const [checked, setChecked] = useState(false)

    useEffect(
        () => {
            if (!isInitialized) {
                return
            }

            if (!isAuthenticated) {
                console.log({ errorCode })
                // const locale = JSON.parse(window.localStorage.getItem('app.settings') ?? '{}')?.language || 'ckb'
                window.location.href = `/login`
            } else {
                setChecked(true)
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [isInitialized]
    )

    if (!checked) {
        return null
    }

    return <>{children}</>
}
