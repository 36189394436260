import { Column, FilterItem } from '@ditkrg/react-core'
import { useQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { ElectionType, NodeType, PagedNodeResponse } from 'src/services/api/emos'
import { allCirclesQuery } from 'src/queries/circles'
import numeral from 'numeral'
import { Stack } from '@mui/system'
import { Typography } from '@mui/material'
import { useNodeFilters } from '../use-node-filters'
import { useRegion } from '../use-region'
import { useAuth } from '../use-auth'

const accessibleNodeTypes: Record<NodeType, NodeType[]> = {
    [NodeType.EMR]: [],
    [NodeType.PBS]: [],
    [NodeType.BRANCH]: [NodeType.PBS],
    [NodeType.SUB_BRANCH]: [NodeType.PBS, NodeType.BRANCH],
    [NodeType.POLLING_CENTER]: [NodeType.PBS, NodeType.BRANCH, NodeType.SUB_BRANCH]
}

const UseNodesColumns = ({ tableState, type }: { tableState: any; type: NodeType }) => {
    const { t } = useTranslation()
    const { user } = useAuth()

    const { filterInputs } = useNodeFilters({
        state: tableState
    } as any)
    const filterInputColumns: Column[] = filterInputs
        .filter((filterInput) => accessibleNodeTypes[type].includes(filterInput.id as NodeType))
        .map((filterInput: FilterItem) => ({
            header: filterInput.header as string,
            id: filterInput.id,
            filter: filterInput,
            hidden: true
        }))

    const { columns: regionColumns } = useRegion({
        tableState
    })

    const { data: circles = [], isLoading: isLoadingCircles } = useQuery({
        ...allCirclesQuery()
    })

    const generateColumns = () => {
        switch (type) {
            case NodeType.BRANCH:
                return branchColumns
            case NodeType.SUB_BRANCH:
                return subBranchColumns
            case NodeType.POLLING_CENTER:
                return pollingCenterColumns
            case NodeType.PBS:
                return PBSColumns
            default:
                return PBSColumns
        }
    }

    const commonColumns: Column[] = [
        {
            id: 'index',
            header: '#',
            cell: (_, rowIndex) => tableState.rowsPerPage * tableState.page + rowIndex + 1
        },
        {
            id: 'name',
            header: t('model.common.name'),
            accessor: 'name',
            filter: {
                type: 'text'
            }
        }
    ]

    const maxMiddayVotersColumns: Column[] = [
        {
            id: 'maxVoters',
            header: t('model.node.maxVoters'),
            cell: (row: PagedNodeResponse) => (
                <Stack>
                    <Typography variant="body2">
                        {t('model.user.electionTypes.options.Public')}:{' '}
                        {numeral(row.publicElectionData?.maxVoters).format('0,0')}
                    </Typography>
                    <Typography variant="body2">
                        {t('model.user.electionTypes.options.Private')}:{' '}
                        {numeral(row.privateElectionData?.maxVoters).format('0,0')}
                    </Typography>
                </Stack>
            )
        },
        {
            id: 'middayVoters',
            header: t('model.node.middayVoters'),
            cell: (row: PagedNodeResponse) => (
                <Stack>
                    <Typography variant="body2">
                        {t('model.user.electionTypes.options.Public')}:{' '}
                        {numeral(row.publicElectionData?.middayVoters).format('0,0')}
                    </Typography>
                    <Typography variant="body2">
                        {t('model.user.electionTypes.options.Private')}:{' '}
                        {numeral(row.privateElectionData?.middayVoters).format('0,0')}
                    </Typography>
                </Stack>
            )
        }
    ]

    const provincesCirclesDistrictSubDistrictsColumns: Column[] = [
        {
            id: 'circleId',
            header: t('view.circle.name.headline'),
            cell: (row) => row.circle?.name,
            filter: {
                type: 'autocomplete',
                props: {
                    disabled: isLoadingCircles,
                    options: circles,
                    optionValue: 'id',
                    optionLabel: 'name'
                }
            }
        },
        ...regionColumns
    ]

    const PBSColumns: Column[] = [...commonColumns, ...maxMiddayVotersColumns, ...filterInputColumns]

    const branchColumns: Column[] = [
        ...commonColumns,
        ...maxMiddayVotersColumns,
        ...provincesCirclesDistrictSubDistrictsColumns,
        ...filterInputColumns
    ]

    const subBranchColumns: Column[] = [
        ...commonColumns,
        ...maxMiddayVotersColumns,
        ...provincesCirclesDistrictSubDistrictsColumns,
        ...filterInputColumns
    ]

    const pollingCenterColumns: Column[] = [
        ...commonColumns,
        {
            id: 'maxVoters',
            header: t('model.node.maxVoters'),
            cell: (row: PagedNodeResponse) =>
                numeral(
                    row.electionType === ElectionType.PRIVATE
                        ? row.privateElectionData?.maxVoters
                        : row.publicElectionData?.maxVoters
                ).format('0,0')
        },
        {
            id: 'middayVoters',
            header: t('model.node.middayVoters'),
            cell: (row: PagedNodeResponse) =>
                numeral(
                    row.electionType === ElectionType.PRIVATE
                        ? row.privateElectionData?.middayVoters
                        : row.publicElectionData?.middayVoters
                ).format('0,0')
        },
        {
            id: 'code',
            header: t('model.common.code'),
            accessor: 'code',
            filter: {
                type: 'text'
            }
        },
        {
            id: 'vrcId',
            header: t('model.node.vrcId'),
            accessor: 'vrcId'
        },
        {
            id: 'electionType',
            header: t('model.user.electionTypes.label'),
            cell: (row) => t(`model.user.electionTypes.options.${row.electionType}` as any),
            filter: {
                hidden: user?.electionTypes.length === 1,
                type: 'select',
                props: {
                    options: Object.values(ElectionType).map((value) => ({
                        label: t(`model.user.electionTypes.options.${value}` as any),
                        value
                    }))
                }
            }
        },
        ...provincesCirclesDistrictSubDistrictsColumns,
        ...filterInputColumns
    ]

    return { generateColumns }
}

export default UseNodesColumns
