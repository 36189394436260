import { CandidatesService } from 'src/services/api/emos'
import { MINUTE } from './roles'

export const allCandidatesQuery = ({
    circleId,
    isActive,
    partyId
}: {
    circleId?: string
    isActive?: boolean
    partyId?: string
}) => ({
    queryKey: ['allCandidates', circleId, isActive, partyId],
    queryFn: async () => {
        const res = await CandidatesService.listAllCandidates({
            circleId,
            isActive,
            partyId
        })
        return res
    },
    gcTime: MINUTE * 60,
    staleTime: MINUTE * 60
})
