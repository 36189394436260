import { StationsService } from 'src/services/api/emos'

export const allStationsQuery = ({
    isInitiated,
    hasIncidents,
    ancestorId
}: {
    isInitiated?: boolean
    hasIncidents?: boolean
    ancestorId?: string
}) => ({
    queryKey: ['allStations', isInitiated, hasIncidents, ancestorId],
    queryFn: async () => {
        const res = await StationsService.listAllStations({
            isInitiated,
            hasIncidents,
            ancestorId
        })
        return res
    }
})
