import { ElectionType, VotingResultFormType } from 'src/services/api/emos'
import { create, StateCreator } from 'zustand'
import { persist, createJSONStorage } from 'zustand/middleware'

export interface ReportsState {
    filters?: {
        [key: string]: any
    }
    setFilter: (filter: { key: string; value: string }) => void
    setFilters: (filters: { [key: string]: string }) => void
    sortField?: {
        field: string
        direction: 'asc' | 'desc'
    }
    reset: () => void
}

const defaultValues = {
    filters: {
        electionType: ElectionType.PUBLIC,
        formType: VotingResultFormType.AUTOMATIC
    }
}

export const reportsConfigs: StateCreator<ReportsState> = (set, get) => ({
    ...defaultValues,
    setFilter: (filter) => {
        const { key, value } = filter
        set((state) => {
            const filters = { ...state.filters }
            if (value) {
                filters[key] = value
            } else {
                delete filters[key]
            }
            return { filters }
        })
    },
    setFilters: (filters) => set({ filters }),

    reset: () => set(defaultValues)
})

export const useReportsFilters = create<ReportsState, [['zustand/persist', ReportsState]]>(
    persist(reportsConfigs, {
        name: 'reports-filters-state',
        storage: createJSONStorage(() => sessionStorage)
    })
)

export const useOperationReportsFilters = create<ReportsState, [['zustand/persist', ReportsState]]>(
    persist(reportsConfigs, {
        name: 'operation-reports-filters-state',
        storage: createJSONStorage(() => sessionStorage)
    })
)

export const useReportsSeatTypeFilters = create<ReportsState, [['zustand/persist', ReportsState]]>(
    persist(reportsConfigs, {
        name: 'reports-seat-types-filters-state',
        storage: createJSONStorage(() => sessionStorage)
    })
)
