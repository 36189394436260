import { ToggleButton, ToggleButtonGroup } from '@mui/material'
import { LayoutGrid, Rows3 } from 'lucide-react'
import { useTranslation } from 'react-i18next'
import Results from 'src/components/dashboard/stations/results'
import LayoutPage from 'src/components/page'
import { PermissionsGuard } from 'src/guards/permissions-guard'
import { ACTIONS, RESOURCES, useCheckPermissions } from 'src/hooks/use-check-permissions'
import { useRouter } from 'src/hooks/use-router'
import { paths } from 'src/paths'
import { useStationsTableState } from 'src/store/table'

const Stations = () => {
    const { t } = useTranslation()
    const router = useRouter()
    const stationsTableState = useStationsTableState()
    const { can } = useCheckPermissions()

    return (
        <PermissionsGuard resource="station" actions={[ACTIONS.read]}>
            <LayoutPage
                title="Station"
                breadcrumbs={[
                    {
                        title: t('view.station.header')
                    }
                ]}
                actions={[
                    {
                        component: (
                            <ToggleButtonGroup
                                value={stationsTableState.view}
                                exclusive
                                onChange={(_, nextView) => {
                                    if (!nextView) return
                                    stationsTableState.setView(nextView)
                                }}
                                sx={{ backgroundColor: 'background.paper' }}
                            >
                                <ToggleButton value="table" aria-label="table">
                                    <Rows3 />
                                </ToggleButton>
                                <ToggleButton value="module" aria-label="module">
                                    <LayoutGrid />
                                </ToggleButton>
                            </ToggleButtonGroup>
                        )
                    },
                    {
                        hidden: !can({
                            resource: RESOURCES.station,
                            actions: [ACTIONS.create]
                        }),
                        label: t('view.station.new.headline'),
                        onClick: () => router.push(paths.stations.new),
                        color: 'primary'
                    }
                ]}
            >
                <Results />
            </LayoutPage>
        </PermissionsGuard>
    )
}

export default Stations
