import { Suspense, lazy } from 'react'
import type { RouteObject } from 'react-router'
import { Outlet } from 'react-router-dom'
import { AuthGuard } from 'src/guards/auth-guard'
import { GuestGuard } from 'src/guards/guest-guard'

import { Layout as DashboardLayout } from 'src/layouts/dashboard'
import Candidates from 'src/pages/candidates'
import Login from 'src/pages/login'
import Parties from 'src/pages/parties'
import Nodes from 'src/pages/nodes'
import { paths } from 'src/paths'
import Circles from 'src/pages/circles'
import SystemConfiguration from 'src/pages/system-configuration'
import Incidents from 'src/pages/incidents/index'
import NewIncident from 'src/pages/incidents/new'
import IncidentProfile from 'src/pages/incidents/profile'
import EditIncident from 'src/pages/incidents/edit'

import Stations from 'src/pages/stations/index'
import NewStation from 'src/pages/stations/new'
import StationProfile from 'src/pages/stations/profile'
import EditStation from 'src/pages/stations/edit'
import Initiate from 'src/pages/stations/initiate'

import Violations from 'src/pages/violations'
import NewViolation from 'src/pages/violations/new'
import ViolationProfile from 'src/pages/violations/profile'
import EditViolation from 'src/pages/violations/edit'

import VotingResults from 'src/pages/voting-result'
import NewVotingResult from 'src/pages/voting-result/new'

import Reports from 'src/pages/reports'
import OperationReports from 'src/pages/operation-reports'
import PollingCenters from 'src/pages/polling-centers'

const HomePage = lazy(() => import('src/pages'))
const UsersPage = lazy(() => import('src/pages/users'))
const AccountPage = lazy(() => import('src/pages/account'))
const ReleaseNotes = lazy(() => import('src/pages/release-notes'))

export const routes: RouteObject[] = [
    {
        element: (
            <AuthGuard>
                <DashboardLayout>
                    <Suspense>
                        <Outlet />
                    </Suspense>
                </DashboardLayout>
            </AuthGuard>
        ),
        children: [
            {
                index: true,
                element: <HomePage />
            },
            {
                path: paths.reports,
                element: <Reports />
            },
            {
                path: paths.operationReports,
                element: <OperationReports />
            },
            {
                path: paths.pollingCenters,
                element: <PollingCenters />
            },
            {
                path: paths.nodes,
                element: <Nodes />
            },
            {
                path: paths.parties,
                element: <Parties />
            },
            {
                path: paths.candidates,
                element: <Candidates />
            },
            {
                path: paths.users,
                element: <UsersPage />
            },
            {
                path: paths.account,
                element: <AccountPage />
            },
            {
                path: paths.releaseNotes,
                element: <ReleaseNotes />
            },
            {
                path: paths.circles,
                element: <Circles />
            },
            {
                path: paths.systemConfiguration,
                element: <SystemConfiguration />
            },
            {
                path: paths.incidents.index,
                children: [
                    {
                        index: true,
                        element: <Incidents />
                    },
                    {
                        path: paths.incidents.new,
                        element: <NewIncident />
                    },
                    {
                        path: paths.incidents.view(':id'),
                        element: <IncidentProfile />
                    },
                    {
                        path: paths.incidents.edit(':id'),
                        element: <EditIncident />
                    }
                ]
            },
            {
                path: paths.stations.index,
                children: [
                    {
                        index: true,
                        element: <Stations />
                    },
                    {
                        path: paths.stations.new,
                        element: <NewStation />
                    },
                    {
                        path: paths.stations.view(':id'),
                        element: <StationProfile />
                    },
                    {
                        path: paths.stations.edit(':id'),
                        element: <EditStation />
                    },
                    {
                        path: paths.stations.initiate(':id'),
                        element: <Initiate />
                    }
                ]
            },
            {
                path: paths.violations.index,
                children: [
                    {
                        index: true,
                        element: <Violations />
                    },
                    {
                        path: paths.violations.new,
                        element: <NewViolation />
                    },
                    {
                        path: paths.violations.view(':id'),
                        element: <ViolationProfile />
                    },
                    {
                        path: paths.violations.edit(':id'),
                        element: <EditViolation />
                    }
                ]
            },
            {
                path: paths.votingResults.index,
                children: [
                    {
                        index: true,
                        element: <VotingResults />
                    },
                    {
                        path: paths.votingResults.new(':id'),
                        element: <NewVotingResult />
                    }
                ]
            }
        ]
    },
    {
        element: (
            <GuestGuard>
                <Suspense>
                    <Outlet />
                </Suspense>
            </GuestGuard>
        ),
        children: [
            {
                path: '/login',
                element: <Login />
            }
        ]
    }
]
