/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum AllegedViolatorType {
    UNKNOWN = 'Unknown',
    COMMISSION_EMPLOYEE = 'CommissionEmployee',
    COMMISSION = 'Commission',
    CANDIDATE = 'Candidate',
    SECURITY_MEMBER = 'SecurityMember',
    POLITICAL_PARTY = 'PoliticalParty',
}
