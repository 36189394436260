import React from 'react'
import { useTranslation } from 'react-i18next'
import { styled, alpha } from '@mui/material/styles'
import { IncidentStatus } from 'src/services/api/emos'

const LabelWrapper = styled('span')(
    ({ theme }) => `
      padding: ${theme.spacing(0.5, 1)};
      font-size: ${theme.typography.pxToRem(15)};
      display: inline-flex;
      align-items: center;
      justify-content: center;
`
)

type Mapper = {
    [key in IncidentStatus]: {
        text: string
        color: string
    }
}

const IncidentStatusLabel = ({ status }: { status?: IncidentStatus }) => {
    const [t, i18next] = useTranslation()

    const mapper: Mapper = {
        Open: {
            text: IncidentStatus.OPEN,
            color: '#d32f2f'
        },
        Resolved: {
            text: IncidentStatus.RESOLVED,
            color: '#1b5e20'
        }
    }

    const { text, color } = status ? mapper[status] : { text: status, color: '#616161' }

    return (
        <LabelWrapper style={{ backgroundColor: alpha(color, 0.1), color: color }}>
            {i18next.exists(`model.incident.states.${text}`) ? t(`model.incident.states.${text}` as any) : text}
        </LabelWrapper>
    )
}

export default IncidentStatusLabel
