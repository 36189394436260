import * as yup from 'yup'

export const createSchema = () => {
    return yup.object().shape({
        name: yup.string().required().min(2).max(999),
        number: yup
            .number()
            .required()
            .max(999)
            .transform((value) => (isNaN(value) ? undefined : value)),
        partyId: yup.string().required(),
        circleId: yup.string().required(),
        photo: yup.array(),
        gender: yup.string().required(),
        isActive: yup.boolean()
    })
}
