import { TableState } from '@ditkrg/react-core'
import { NodeType } from 'src/services/api/emos'
import { create, StateCreator } from 'zustand'
import { persist, createJSONStorage } from 'zustand/middleware'
import { name } from '../../package.json'

export type NodesTableState = TableState & {
    nodeType: NodeType
    setNodeType: (nodeType: NodeType) => void
}

const defaultValues = {
    page: 0,
    rowsPerPage: 10,
    filters: {},
    sortField: undefined,
    nodeType: NodeType.PBS
}

export const tableConfigs: StateCreator<NodesTableState> = (set, get) => ({
    ...defaultValues,
    setPage: (page) => set({ page }),
    setRowsPerPage: (rowsPerPage) => set({ rowsPerPage }),
    setFilter: (filter) => {
        const { key, value } = filter
        set((state) => {
            const filters = { ...state.filters }
            if (value) {
                filters[key] = value
            } else {
                delete filters[key]
            }
            return { filters, page: 0 }
        })
    },
    setFilters: (filters) => set({ filters, page: 0 }),
    setSortField: (sortField) => set({ sortField }),
    reset: () => set(defaultValues),
    setNodeType: (nodeType) => set({ nodeType })
})

export const useNodesTableState = create<NodesTableState, [['zustand/persist', NodesTableState]]>(
    persist(tableConfigs, {
        name: name + '/nodes-table-state',
        storage: createJSONStorage(() => sessionStorage)
    })
)
