import { AdvancedFilters, AdvancedPagination } from '@ditkrg/react-core'
import { Card, CardContent, CardHeader, Grid, Typography } from '@mui/material'
import { Stack } from '@mui/system'
import { PagedStationResponseListResponse } from 'src/services/api/emos'
import { useSettings } from 'src/store/settings'
import { useStationsTableState } from 'src/store/table'
import StationStatusLabel from './status-label'
import { useTranslation } from 'react-i18next'
import { useRouter } from 'src/hooks/use-router'
import { paths } from 'src/paths'
import { styled } from '@mui/material/styles'
import { formatStationNumber } from './utils'
import PCNameCode from '../nodes/pc-name-code'
import dateFormat from 'src/utils/date-format'

const StyledCard = styled(Card)(
    ({ theme }) => `
    :hover {
      cursor: pointer;
      box-shadow: ${theme.shadows[7]};
  }
`
)

const CardList = ({ stations, filters }: { stations: PagedStationResponseListResponse | undefined; filters: any }) => {
    const tableState = useStationsTableState()
    const { language } = useSettings()
    const { t } = useTranslation()
    const router = useRouter()

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Card>
                    <AdvancedFilters filters={filters} state={tableState} />
                </Card>
            </Grid>
            {stations?.data?.map((station) => {
                if (!station) return null
                return (
                    <Grid item key={station.id} xs={12} md={6}>
                        <StyledCard variant="outlined" onClick={() => router.push(paths.stations.view(station.id))}>
                            <CardHeader
                                title={
                                    <Stack>
                                        <Typography>{`${t('view.station.name.headline')}: ${formatStationNumber(station.number)}`}</Typography>
                                    </Stack>
                                }
                                action={<StationStatusLabel status={station.currentStatus} />}
                                sx={{
                                    borderBottom: 1,
                                    borderColor: 'divider'
                                }}
                            />
                            <CardContent>
                                <Grid container spacing={2}>
                                    {[
                                        {
                                            name: 'incidents',
                                            label: t('view.incident.header'),
                                            value: station.incidents.toString()
                                        },
                                        {
                                            name: 'maxVoters',
                                            value: station.maxVoters.toString(),
                                            label: t('model.station.maxVoters')
                                        },
                                        {
                                            name: 'currentStatus',
                                            label: t('model.station.currentStatus'),
                                            Template: StationStatusLabel,
                                            templateProps: { name: 'currentStatus', status: station.currentStatus }
                                        },
                                        {
                                            name: 'initiationStatus',
                                            label: t('model.station.initiationStatus'),
                                            Template: StationStatusLabel,
                                            templateProps: {
                                                name: 'initiationStatus',
                                                status: station.initiationStatus
                                            }
                                        },
                                        {
                                            name: 'pcId',
                                            label: t('model.node.type.options.PollingCenter'),
                                            Template: PCNameCode,
                                            templateProps: {
                                                name: station.pc?.name,
                                                code: station.pc?.code
                                            }
                                        },
                                        {
                                            name: 'initiatedAt',
                                            label: t('model.station.initiatedAt'),
                                            value:
                                                dateFormat({
                                                    date: station.initiatedAt
                                                }) ?? ''
                                        }
                                    ].map((item) => {
                                        const Template = item.Template
                                        return (
                                            <Grid item xs={12} md={6} lg={4} key={item.name}>
                                                {Template ? (
                                                    <Template {...item.templateProps} />
                                                ) : (
                                                    <Typography variant="h6">{item.value}</Typography>
                                                )}
                                                <Typography color="textSecondary" variant="body2">
                                                    {item.label}
                                                </Typography>
                                            </Grid>
                                        )
                                    })}
                                </Grid>
                            </CardContent>
                        </StyledCard>
                    </Grid>
                )
            })}
            <Grid item xs={12}>
                <Card>
                    <AdvancedPagination
                        state={tableState}
                        totalItems={stations?.meta?.pagination?.count ?? 0}
                        locale={language}
                    />
                </Card>
            </Grid>
        </Grid>
    )
}

export default CardList
