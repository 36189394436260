import { useQuery } from '@tanstack/react-query'
import { useMemo } from 'react'
import { ElectionDayConfiguration, ElectionType, SystemConfigService } from 'src/services/api/emos'
import { ACTIONS, RESOURCES, useCheckPermissions } from './use-check-permissions'
import { useAuth } from './use-auth'

type TimeCheck = {
    respectTimeLimits: boolean
    isInActiveDuration: boolean
    isInMorningDuration: boolean
    isInAfternoonDuration: boolean
    isInEveningDuration: boolean
    privateElectionDay: {
        isInActiveDuration: boolean
        isInMorningDuration: boolean
        isInAfternoonDuration: boolean
        isInEveningDuration: boolean
    }
    publicElectionDay: {
        isInActiveDuration: boolean
        isInMorningDuration: boolean
        isInAfternoonDuration: boolean
        isInEveningDuration: boolean
    }
}

const isCurrentTimeInRange = (min?: string, max?: string, isDateOnly = false): boolean => {
    if (!min || !max) {
        return false // Handle undefined values gracefully
    }

    const now = new Date()

    if (isDateOnly) {
        // Format the current date as 'YYYY-MM-DD'
        const currentDate = now.toISOString().split('T')[0]
        return currentDate >= min && currentDate <= max
    } else {
        // Format the current time as 'HH:MM:SS'
        const currentTime = now.toTimeString().split(' ')[0]
        return currentTime >= min && currentTime <= max
    }
}

export const useSystemConfigurationCheck = () => {
    const { can } = useCheckPermissions()
    const { user } = useAuth()

    const { data, isPending, isFetching } = useQuery({
        queryKey: ['systemConfiguration'],
        queryFn: async () => {
            const res = await SystemConfigService.getSystemConfig()
            return res
        },
        gcTime: 1000 * 60 * 60, // 1 hour
        staleTime: 1000 * 60 * 60, // 1 hour,
        refetchInterval: 5000 * 60 // 5 minutes
    })

    const DEFAULT_SYSTEM_CONFIGURATION_CHECK = useMemo<TimeCheck>(
        () => ({
            respectTimeLimits: true,
            isInActiveDuration: false,
            isInMorningDuration: false,
            isInAfternoonDuration: false,
            isInEveningDuration: false,
            privateElectionDay: {
                isInActiveDuration: false,
                isInMorningDuration: false,
                isInAfternoonDuration: false,
                isInEveningDuration: false
            },
            publicElectionDay: {
                isInActiveDuration: false,
                isInMorningDuration: false,
                isInAfternoonDuration: false,
                isInEveningDuration: false
            }
        }),
        []
    )

    const systemConfigurationCheck = useMemo<TimeCheck>(() => {
        if (!data) return DEFAULT_SYSTEM_CONFIGURATION_CHECK

        const { publicElectionDay, privateElectionDay, respectTimeLimits } = data

        const checkDurations = (electionDay: ElectionDayConfiguration) => ({
            isInActiveDuration: isCurrentTimeInRange(
                electionDay.activeDuration.min,
                electionDay.activeDuration.max,
                true
            ),
            isInMorningDuration: isCurrentTimeInRange(electionDay.morningDuration.min, electionDay.morningDuration.max),
            isInAfternoonDuration: isCurrentTimeInRange(
                electionDay.afternoonDuration.min,
                electionDay.afternoonDuration.max
            ),
            isInEveningDuration: isCurrentTimeInRange(electionDay.eveningDuration.min, electionDay.eveningDuration.max)
        })

        const privateDurations = checkDurations(privateElectionDay)
        const publicDurations = checkDurations(publicElectionDay)

        return {
            respectTimeLimits: respectTimeLimits || false,
            isInActiveDuration: privateDurations.isInActiveDuration || publicDurations.isInActiveDuration,
            isInMorningDuration: privateDurations.isInMorningDuration || publicDurations.isInMorningDuration,
            isInAfternoonDuration: privateDurations.isInAfternoonDuration || publicDurations.isInAfternoonDuration,
            isInEveningDuration: privateDurations.isInEveningDuration || publicDurations.isInEveningDuration,
            privateElectionDay: privateDurations,
            publicElectionDay: publicDurations
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [DEFAULT_SYSTEM_CONFIGURATION_CHECK, data, isFetching])

    const isInActiveDurationOrDoesNotRespectTimeLimits = useMemo(() => {
        if (systemConfigurationCheck.respectTimeLimits) {
            return (
                systemConfigurationCheck.isInActiveDuration || can({ resource: RESOURCES.bah, actions: [ACTIONS.all] })
            )
        }
        return true
    }, [can, systemConfigurationCheck.isInActiveDuration, systemConfigurationCheck.respectTimeLimits])

    const userSystemConfigurationCheck = useMemo(() => {
        if (user?.electionTypes && user?.electionTypes.length > 1) {
            return systemConfigurationCheck
        } else if (user?.electionTypes.includes(ElectionType.PRIVATE)) {
            return systemConfigurationCheck.privateElectionDay
        } else if (user?.electionTypes.includes(ElectionType.PUBLIC)) {
            return systemConfigurationCheck.publicElectionDay
        } else {
            return DEFAULT_SYSTEM_CONFIGURATION_CHECK
        }
    }, [user?.electionTypes, systemConfigurationCheck, DEFAULT_SYSTEM_CONFIGURATION_CHECK])

    const canBahAll = can({ resource: RESOURCES.bah, actions: [ACTIONS.all] })
    const noTimeLimits = !systemConfigurationCheck.respectTimeLimits
    const isActiveMorning =
        userSystemConfigurationCheck.isInActiveDuration && userSystemConfigurationCheck.isInMorningDuration
    const isActiveAfternoon =
        userSystemConfigurationCheck.isInActiveDuration && userSystemConfigurationCheck.isInAfternoonDuration
    const isActiveEvening =
        userSystemConfigurationCheck.isInActiveDuration && userSystemConfigurationCheck.isInEveningDuration

    return {
        DEFAULT_SYSTEM_CONFIGURATION_CHECK,
        isPending,
        systemConfigurationCheck,
        isInActiveDurationOrDoesNotRespectTimeLimits,
        userSystemConfigurationCheck,
        canBahAll,
        noTimeLimits,
        isActiveMorning,
        isActiveAfternoon,
        isActiveEvening
    }
}
