const ImagePreview = ({ image, onClick }: { image: string; onClick?: () => void }) => {
    if (!image) return null
    return (
        <button type="button" onClick={onClick} style={{ border: 'none', background: 'none', padding: 0 }}>
            <img src={image ?? ''} alt="Preview" width="100" style={{ cursor: 'pointer' }} />
        </button>
    )
}

export default ImagePreview
