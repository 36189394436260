import * as yup from 'yup'
import ckb from './validations/ckb'
import ar from './validations/ar'
import en from './validations/en'
import { Locale } from 'src/store/settings'

export function setYupLocale(locale: Locale): void {
    if (locale === 'ckb') yup.setLocale(ckb)
    else if (locale === 'ar') yup.setLocale(ar)
    else if (locale === 'en') yup.setLocale(en)
    else yup.setLocale(en)
}
