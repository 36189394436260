import { Grid } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { TextFieldController } from 'mui-rhf-library'
import { UseFieldArrayReturn, UseFormReturn } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { allCirclesQuery } from 'src/queries/circles'

const CirclesMaxVotersInput = ({
    formConfigs,
    circlesFieldArray
}: {
    formConfigs: UseFormReturn<any>
    circlesFieldArray: UseFieldArrayReturn<any, 'circles', 'key' | 'id'>
}) => {
    const { t } = useTranslation()
    const { data: circles = [] } = useQuery({
        ...allCirclesQuery()
    })

    const { fields } = circlesFieldArray

    return (
        <Grid container spacing={2}>
            {fields.map((field, index) => {
                return (
                    <Grid item xs={12} sm={4} key={field.key}>
                        <TextFieldController
                            fullWidth
                            control={formConfigs.control}
                            name={`circles[${index}].maxVoters`}
                            label={`${t('model.node.maxVoters')}: ${circles.find((circle) => circle.id === field.id)?.name}`}
                            type="number"
                        />
                    </Grid>
                )
            })}
        </Grid>
    )
}

export default CirclesMaxVotersInput
