import React from 'react'
import { useTranslation } from 'react-i18next'
import { styled, alpha } from '@mui/material/styles'
import { StationOperationalStatus } from 'src/services/api/emos'

const LabelWrapper = styled('span')(
    ({ theme }) => `
      padding: ${theme.spacing(0.5, 1)};
      font-size: ${theme.typography.pxToRem(15)};
      display: inline-flex;
      align-items: center;
      justify-content: center;
`
)

type Mapper = {
    [key in StationOperationalStatus]: {
        text: string
        color: string
    }
}

const StationStatusLabel = ({ status }: { status?: StationOperationalStatus }) => {
    const [t, i18next] = useTranslation()

    const mapper: Mapper = {
        Pending: {
            text: 'Pending',
            color: '#616161'
        },
        Operational: {
            text: 'Operational',
            color: '#1b5e20'
        },
        NonOperational: {
            text: 'NonOperational',
            color: '#e91e63'
        }
    }

    const { text, color } = status ? mapper[status] : { text: status, color: '#616161' }

    return (
        <LabelWrapper style={{ backgroundColor: alpha(color, 0.1), color: color }}>
            {i18next.exists(`model.station.states.${text}`) ? t(`model.station.states.${text}` as any) : text}
        </LabelWrapper>
    )
}

export default StationStatusLabel
