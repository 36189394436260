import { useAuth } from './use-auth'

export const ACTIONS = {
    read: 'r',
    update: 'u',
    create: 'c',
    ui: 'ui',
    read_am: 'r_am',
    update_am: 'u_am',
    read_f: 'r_f',
    update_f: 'u_f',
    initiate: 'initiate',
    mid_rep: 'mid-rep',
    attach: 'attach',
    all: '*',
    u_am: 'u_am',
    u_f: 'u_f'
}

export const RESOURCES = {
    voting_result: 'voting-result',
    audit: 'audit',
    candidate: 'candidate',
    circle: 'circle',
    node: 'node',
    party: 'party',
    user: 'user',
    export: 'export',
    d_reports: 'd_reports',
    d_operational: 'd_operational',
    station: 'station',
    incident: 'incident',
    violation: 'violation',
    polling_center: 'polling-center',
    sys_config: 'sys-config',
    pc_node: 'pc-node',
    bah: 'bah'
}

export const useCheckPermissions = () => {
    const { user } = useAuth()

    const can = ({ resource, actions }: { resource: string; actions: string[] }): boolean => {
        if (!user?.permissions || !Array.isArray(user.permissions)) {
            return false
        }

        // Admin check
        if (user.permissions.includes('*')) {
            return true
        }

        // Check if the user has permission for any of the actions
        return actions.some((action) => {
            const permissionToCheck = `${resource}:${action}`
            return (
                user.permissions?.some(
                    (permission) => permission === permissionToCheck || permission === `${resource}:*`
                ) || false
            )
        })
    }

    return { can }
}
