import { useQuery } from '@tanstack/react-query'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import MasonryCards from 'src/components/card/masonry-cards'
import UpdateStatusDialog from 'src/components/dashboard/violations/update-status-dialog'
import ViolationStatusLabel from 'src/components/dashboard/violations/status-label'
import LayoutPage from 'src/components/page'
import { useRouter } from 'src/hooks/use-router'
import { paths } from 'src/paths'
import {
    ViolationCommandViolationStatusViolationMetaTransitionResponse,
    ViolationsService
} from 'src/services/api/emos'
import { Card } from 'src/types/card'
import dateFormat from 'src/utils/date-format'
import { allPartiesQuery } from 'src/queries/parties'
import ImagePreview from 'src/components/image-preview'
import ModalImage from 'src/components/modal-image'
import { ACTIONS, RESOURCES, useCheckPermissions } from 'src/hooks/use-check-permissions'
import { PermissionsGuard } from 'src/guards/permissions-guard'
import AuditsDialog from 'src/components/dashboard/audits/audits-dialog'
import { formatStationNumber } from 'src/components/dashboard/stations/utils'

const Profile = () => {
    const router = useRouter()
    const { t } = useTranslation()
    const { can } = useCheckPermissions()
    const { id } = useParams<{ id: string }>()
    const [updateStatusDialog, setUpdateStatusDialog] = useState<boolean>(false)
    const [transition, setTransition] = useState<
        ViolationCommandViolationStatusViolationMetaTransitionResponse | undefined
    >(undefined)
    const [image, setImage] = useState<string | null>(null)
    const [auditsDialogOpen, setAuditsDialogOpen] = useState(false)

    const { data: allParties = [] } = useQuery({
        ...allPartiesQuery({})
    })

    const { data: violation } = useQuery({
        queryKey: ['violation', id],
        queryFn: async () => {
            const res = await ViolationsService.getViolationById({
                id: id as string
            })
            return res
        },
        enabled: !!id
    })

    const cards = useMemo(
        (): Card[] =>
            violation
                ? [
                      {
                          fields: [
                              {
                                  label: t('view.station.name.headline') + ' ' + t('model.common.number'),
                                  value: formatStationNumber(violation.station.number)
                              },
                              {
                                  label: t('model.user.electionTypes.label'),
                                  value: t(`model.user.electionTypes.options.${violation.station.electionType}` as any)
                              },
                              {
                                  label: t('model.node.type.options.PollingCenter'),
                                  value: violation.station.pc.name
                              },
                              {
                                  label: t('model.incident.description'),
                                  value: violation.description
                              },
                              {
                                  label: t('model.violation.type.label'),
                                  value: t(`model.violation.type.options.${violation.type}`)
                              },
                              {
                                  label: t('model.common.status'),
                                  Template: ViolationStatusLabel,
                                  templateProps: {
                                      status: violation.status
                                  }
                              },
                              {
                                  label: t('model.common.createdAt'),
                                  value: dateFormat({
                                      date: violation.createdAt,
                                      format: 'hh:mm:ss A - MM/DD'
                                  })
                              }
                          ]
                      },
                      {
                          fields: [
                              {
                                  label: t('model.violation.complainant.name'),
                                  value: violation.complainant.name
                              },
                              {
                                  label: t('model.violation.complainant.type.label'),
                                  value: t(`model.violation.complainant.type.options.${violation.complainant.type}`)
                              },
                              {
                                  label: t('model.violation.complainant.partyId'),
                                  value: allParties.find((party) => party.id === violation.complainant.partyId)?.name
                              }
                          ]
                      },
                      {
                          fields: [
                              {
                                  label: t('model.violation.allegedViolator.name'),
                                  value: violation.allegedViolator.name
                              },
                              {
                                  label: t('model.violation.allegedViolator.type.label'),
                                  value: violation.allegedViolator.type
                              },
                              {
                                  hidden: !violation.allegedViolator.partyId,
                                  label: t('model.violation.allegedViolator.partyId'),
                                  value: allParties.find((party) => party.id === violation.allegedViolator.partyId)
                                      ?.name
                              }
                          ]
                      },
                      {
                          fields: [
                              {
                                  label: t('model.common.attachment'),
                                  Template: ImagePreview,
                                  templateProps: {
                                      image: violation.attachment?.url,
                                      onClick: () => {
                                          setImage(violation.attachment?.url ?? '')
                                      }
                                  }
                              },
                              {
                                  label: t('model.common.comment'),
                                  value: violation.lastComment
                              }
                          ]
                      }
                  ]
                : [],
        [violation, t, allParties]
    )

    return (
        <PermissionsGuard resource="violation" actions={[ACTIONS.read]}>
            <LayoutPage
                title="Violation"
                breadcrumbs={[
                    {
                        href: paths.violations.index,
                        title: t('view.violation.header')
                    },
                    {
                        title: id ?? t('view.violation.name.headline')
                    }
                ]}
                actions={[
                    {
                        label: t('view.common.dialog.back'),
                        onClick: () => router.push(paths.violations.index),
                        variant: 'outlined'
                    },
                    {
                        hidden: !can({
                            resource: RESOURCES.violation,
                            actions: [ACTIONS.update]
                        }),
                        label: t('view.common.dialog.edit'),
                        onClick: () => router.push(paths.violations.edit(id))
                    },
                    ...(violation?.transitions?.map((transition) => ({
                        label: t(`model.violation.commands.${transition.command.toLowerCase()}` as any),
                        color: 'secondary',
                        onClick: () => {
                            setUpdateStatusDialog(true)
                            setTransition(transition)
                        }
                    })) ?? []),
                    {
                        hidden: !can({
                            resource: RESOURCES.audit,
                            actions: [ACTIONS.read]
                        }),
                        label: t('view.common.audit'),
                        onClick: () => setAuditsDialogOpen(true),
                        color: 'info'
                    }
                ]}
            >
                <MasonryCards
                    cards={cards}
                    columns={{
                        xs: 1,
                        sm: 2,
                        md: 2,
                        lg: 3
                    }}
                    spacing={2}
                />
                <>
                    {violation && (
                        <AuditsDialog
                            version={violation?.version ?? 0}
                            open={auditsDialogOpen}
                            onClose={() => setAuditsDialogOpen(false)}
                            id={violation?.id ?? ''}
                            type="violations"
                        />
                    )}
                </>
            </LayoutPage>
            {updateStatusDialog && violation && transition && (
                <UpdateStatusDialog
                    violation={violation}
                    dialogStatus={updateStatusDialog}
                    setDialog={setUpdateStatusDialog}
                    transition={transition}
                />
            )}

            <ModalImage open={!!image} large={image} alt="image" onClose={() => setImage(null)} />
        </PermissionsGuard>
    )
}

export default Profile
