import * as yup from 'yup'
import { createSchema } from '../incidents/schema'
import { TFunction } from 'i18next'
import { StationOperationalStatus } from 'src/services/api/emos'

export const schema = () => {
    return yup.object().shape({
        number: yup
            .number()
            .required()
            .transform((value) => (isNaN(value) ? undefined : value)),
        maxVoters: yup
            .number()
            .required()
            .transform((value) => (isNaN(value) ? undefined : value)),
        pcId: yup.string().required(),
        isActive: yup.boolean(),
        circles: yup.array().of(
            yup.object().shape({
                id: yup.string().required(),
                maxVoters: yup
                    .number()
                    .nullable()
                    .transform((value) => (isNaN(value) ? null : value))
            })
        )
    })
}

export const initiateSchema = (t: TFunction) => {
    return yup.object().shape({
        stationId: yup.string().required(),
        operationType: yup.string().required().oneOf(Object.values(StationOperationalStatus)),
        incident: createSchema({
            isStationInitiation: true,
            t
        })
    })
}
