import { FC, ReactNode, useEffect, useCallback, useState } from 'react'
import { RouterLink } from 'src/components/router-link'
import ChevronDownIcon from '@untitled-ui/icons-react/build/esm/ChevronDown'
import ChevronRightIcon from '@untitled-ui/icons-react/build/esm/ChevronRight'
import Box from '@mui/material/Box'
import ButtonBase from '@mui/material/ButtonBase'
import Collapse from '@mui/material/Collapse'
import SvgIcon from '@mui/material/SvgIcon'
import DirectionIcon from 'src/components/direction-icon'
import { useSettings } from 'src/store/settings'

interface SideNavItemProps {
    active?: boolean
    children?: ReactNode
    depth?: number
    disabled?: boolean
    icon?: ReactNode
    label?: ReactNode
    open?: boolean
    path?: string
    title: string
}

export const SideNavItem: FC<SideNavItemProps> = (props) => {
    const { active, children, depth = 0, disabled, icon, label, open: openProp, path, title } = props
    const [open, setOpen] = useState<boolean>(!!openProp)
    const { sidebar, setSidebar } = useSettings()

    const handleToggle = useCallback((): void => {
        setOpen((prevOpen) => !prevOpen)
    }, [])

    useEffect(() => {
        if (!sidebar) {
            setOpen(false)
        }
    }, [sidebar])

    // Icons can be defined at top level only, deep levels have bullets instead of actual icons.

    let startIcon: ReactNode

    if (depth === 0) {
        startIcon = icon
    } else {
        startIcon = <Box>{icon}</Box>
    }

    const offset = depth === 0 ? 0 : (depth - 1) * 16

    // Branch

    if (children) {
        return (
            <li>
                <ButtonBase
                    disabled={disabled}
                    onClick={() => {
                        if (sidebar) {
                            handleToggle()
                        } else {
                            setOpen(true)
                            setSidebar(true)
                        }
                    }}
                    sx={{
                        alignItems: 'center',
                        borderRadius: 1,
                        display: 'flex',
                        justifyContent: sidebar ? 'flex-start' : 'center',
                        pl: `${16 + offset}px`,
                        pr: '16px',
                        py: sidebar ? '6px' : '10px',
                        textAlign: 'left',
                        width: '100%',
                        ...(active && {
                            ...(depth === 0 && {
                                backgroundColor: 'var(--nav-item-active-bg)'
                            })
                        }),
                        '&:hover': {
                            boxShadow: '0 0 0 1px var(--nav-item-active-border-color)',
                            backgroundColor: active ? 'var(--nav-item-active-bg)' : 'var(--nav-item-hover-bg)'
                        }
                    }}
                >
                    {startIcon && (
                        <Box
                            component="span"
                            sx={{
                                alignItems: 'center',
                                color: 'var(--nav-item-icon-color)',
                                display: 'inline-flex',
                                justifyContent: 'center',
                                mr: sidebar ? 2 : 0,
                                ...(active && {
                                    color: 'var(--nav-item-icon-active-color)'
                                })
                            }}
                        >
                            {startIcon}
                        </Box>
                    )}
                    {sidebar && (
                        <>
                            <Box
                                component="span"
                                sx={{
                                    color: 'var(--nav-item-color)',
                                    flexGrow: 2,
                                    fontFamily: (theme) => theme.typography.fontFamily,
                                    fontSize: depth > 1 ? 13 : 15,
                                    fontWeight: depth > 1 ? 500 : 600,
                                    lineHeight: '25px',
                                    whiteSpace: 'nowrap',
                                    ...(active && {
                                        color: 'var(--nav-item-active-color)'
                                    }),
                                    ...(disabled && {
                                        color: 'var(--nav-item-disabled-color)'
                                    })
                                }}
                            >
                                {title}
                            </Box>
                            <DirectionIcon size="small">
                                <SvgIcon
                                    sx={{
                                        color: 'var(--nav-item-color)',
                                        fontSize: 16,
                                        ml: 2
                                    }}
                                >
                                    {open ? <ChevronDownIcon /> : <ChevronRightIcon />}
                                </SvgIcon>
                            </DirectionIcon>
                        </>
                    )}
                </ButtonBase>
                {sidebar && (
                    <Collapse in={open} sx={{ mt: 0.5 }}>
                        {children}
                    </Collapse>
                )}
            </li>
        )
    }

    let linkProps: any = undefined

    if (path) {
        const isExternal = path.startsWith('http')

        linkProps = isExternal
            ? {
                  component: 'a',
                  href: path,
                  target: '_blank'
              }
            : {
                  component: RouterLink,
                  href: path
              }
    }

    return (
        <li>
            <ButtonBase
                disabled={disabled}
                disableRipple
                sx={{
                    alignItems: 'center',
                    borderRadius: 1,
                    display: 'flex',
                    justifyContent: sidebar ? 'flex-start' : 'center',
                    pl: `${16 + offset}px`,
                    pr: '16px',
                    py: '6px',
                    textAlign: 'left',
                    width: '100%',
                    ...(active && {
                        ...{
                            backgroundColor: 'var(--nav-item-active-bg)',
                            boxShadow: '0 0 0 1px var(--nav-item-active-border-color)'
                        }
                    }),
                    '&:hover': {
                        boxShadow: '0 0 0 1px var(--nav-item-active-border-color)',
                        backgroundColor: active ? 'var(--nav-item-active-bg)' : 'var(--nav-item-hover-bg)'
                    }
                }}
                {...linkProps}
            >
                {startIcon && (
                    <Box
                        component="span"
                        sx={{
                            alignItems: 'center',
                            color: 'var(--nav-item-icon-color)',
                            display: 'inline-flex',
                            justifyContent: 'center',
                            mr: sidebar ? 2 : 0,
                            ...(active && {
                                color: 'var(--nav-item-icon-active-color)'
                            })
                        }}
                    >
                        {startIcon}
                    </Box>
                )}
                {sidebar && (
                    <Box
                        component="span"
                        sx={{
                            color: 'var(--nav-item-color)',
                            flexGrow: 1,
                            fontFamily: (theme) => theme.typography.fontFamily,
                            fontSize: 15,
                            fontWeight: 600,
                            lineHeight: '24px',
                            whiteSpace: 'nowrap',
                            ...(active && {
                                color: 'var(--nav-item-active-color)'
                            }),
                            ...(disabled && {
                                color: 'var(--nav-item-disabled-color)'
                            })
                        }}
                    >
                        {title}
                    </Box>
                )}
                {label && sidebar && (
                    <Box component="span" sx={{ ml: 2 }}>
                        {label}
                    </Box>
                )}
            </ButtonBase>
        </li>
    )
}
