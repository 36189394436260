/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
/* eslint-disable no-prototype-builtins */
import * as React from 'react'
import useMediaQuery from '@mui/material/useMediaQuery'
import ListSubheader from '@mui/material/ListSubheader'
import { useTheme } from '@mui/material/styles'
import { VariableSizeList, ListChildComponentProps } from 'react-window'
import Typography from '@mui/material/Typography'
import { useSettings } from 'src/store/settings'
const LISTBOX_PADDING = 8 // px

function renderRow(props: ListChildComponentProps) {
    const { data, index, style } = props
    const dataSet = data[index]
    const inlineStyle = {
        ...style,
        top: (style.top as number) + LISTBOX_PADDING
    }
    if (dataSet.hasOwnProperty('group')) {
        return (
            <ListSubheader key={dataSet.key} component="div" style={inlineStyle}>
                {dataSet.group}
            </ListSubheader>
        )
    }
    return (
        <Typography component="li" {...dataSet[0]} key={dataSet[0].key} style={inlineStyle}>
            {dataSet[1]}
        </Typography>
    )
}
const OuterElementContext = React.createContext({})
const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
    const outerProps = React.useContext(OuterElementContext)
    return <div ref={ref} {...props} {...outerProps} />
})
function useResetCache(data: any) {
    const ref = React.useRef<VariableSizeList>(null)
    React.useEffect(() => {
        if (ref.current != null) {
            ref.current.resetAfterIndex(0, true)
        }
    }, [data])
    return ref
}
// Adapter for react-window
const ListboxComponent = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLElement>>(
    function ListboxComponent(props, ref) {
        const { children, ...other } = props
        const itemData: React.ReactChild[] = []
        ;(children as React.ReactChild[]).forEach((item: React.ReactChild & { children?: React.ReactChild[] }) => {
            itemData.push(item)
            itemData.push(...(item.children || []))
        })
        const theme = useTheme()
        const smUp = useMediaQuery(theme.breakpoints.up('sm'), {
            noSsr: true
        })
        const itemCount = itemData.length
        const itemSize = smUp ? 36 : 48
        const getChildSize = (child: React.ReactChild) => {
            if (child.hasOwnProperty('group')) {
                return 48
            }
            return itemSize + 12
        }
        const getHeight = () => {
            if (itemCount > 8) {
                return 8 * itemSize
            }
            return itemData.map(getChildSize).reduce((a, b) => a + b, 0)
        }
        const gridRef = useResetCache(itemCount)
        const settings = useSettings()
        return (
            <div ref={ref}>
                <OuterElementContext.Provider value={other}>
                    <VariableSizeList
                        itemData={itemData}
                        height={getHeight() + 2 * LISTBOX_PADDING}
                        width="100%"
                        ref={gridRef}
                        outerElementType={OuterElementType}
                        innerElementType="ul"
                        itemSize={(index) => getChildSize(itemData[index])}
                        overscanCount={5}
                        itemCount={itemCount}
                        style={{ direction: settings?.direction }}
                    >
                        {renderRow}
                    </VariableSizeList>
                </OuterElementContext.Provider>
            </div>
        )
    }
)
export { ListboxComponent }
