import type { FC } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

interface SeoProps {
    title?: string;
}

export const Seo: FC<SeoProps> = (props) => {
    const { title } = props;
    const [t] = useTranslation();

    const fullTitle = title ? title + ` | ${t('view.project_name')}` : t('view.project_name');

    return (
        <Helmet>
            <title>{fullTitle}</title>
        </Helmet>
    );
};
