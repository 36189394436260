/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Notification } from '../models/Notification';
import type { Void } from '../models/Void';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class NotificationsService {
    /**
     * @returns Notification OK
     * @throws ApiError
     */
    public static listNotifications({
        lastId,
    }: {
        lastId?: string,
    }): CancelablePromise<Array<Notification>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/notifications',
            query: {
                'lastId': lastId,
            },
        });
    }
    /**
     * @returns void
     * @throws ApiError
     */
    public static markNotificationAsRead({
        id,
    }: {
        id?: string,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/notifications/read',
            query: {
                'id': id,
            },
        });
    }
    /**
     * @returns Void OK
     * @throws ApiError
     */
    public static streamNotifications(): CancelablePromise<Void> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/notifications/realtime',
        });
    }
}
