import { Chip, Divider, Typography } from '@mui/material'
import { Stack } from '@mui/system'
import { useQuery } from '@tanstack/react-query'
import { Power } from 'lucide-react'
import numeral from 'numeral'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import MasonryCards from 'src/components/card/masonry-cards'
import AuditsDialog from 'src/components/dashboard/audits/audits-dialog'
import StationStatusLabel from 'src/components/dashboard/stations/status-label'
import { formatStationNumber } from 'src/components/dashboard/stations/utils'
import LayoutPage from 'src/components/page'
import { ACTIONS, RESOURCES, useCheckPermissions } from 'src/hooks/use-check-permissions'
import { useRouter } from 'src/hooks/use-router'
import { paths } from 'src/paths'
import { allCirclesQuery } from 'src/queries/circles'
import { StationCircle, StationCircleDiffStatus, StationsService, StationVoteDiffData } from 'src/services/api/emos'
import { Card } from 'src/types/card'
import dateFormat from 'src/utils/date-format'

const DIFF_STATUS_COLOR_MAP = {
    [StationCircleDiffStatus.HIGH_DIFF]: 'error',
    [StationCircleDiffStatus.LOW_DIFF]: 'info',
    [StationCircleDiffStatus.NO_DIFF]: 'success',
    [StationCircleDiffStatus.UNDEFINED]: 'default'
}

const CountDiff = ({ value, label }: { value?: StationVoteDiffData; label: string }) => {
    const { t } = useTranslation()

    console.log({ value })

    if (!value) return null
    return (
        <Stack direction="row" spacing={1} alignItems="center">
            <Typography>{label}:</Typography>
            <Typography>{numeral(value?.percentage).multiply(100).format('0')}%</Typography>
            <Chip
                label={t(`model.station.${value?.status}` as any)}
                color={DIFF_STATUS_COLOR_MAP[value?.status as StationCircleDiffStatus] as any}
                variant="filled"
            />
        </Stack>
    )
}

const CircleFields = ({ circle, isLastIndex }: { circle: StationCircle; isLastIndex?: boolean }) => {
    const { t } = useTranslation()

    const { data: circles = [] } = useQuery({
        ...allCirclesQuery()
    })

    const items = useMemo(
        () =>
            circle
                ? [
                      {
                          name: 'maxVoters',
                          label: t('model.node.maxVoters'),
                          value: circle.maxVoters
                      }
                  ]
                : [],
        [circle, t]
    )

    return (
        <Stack spacing={1} key={circle.id}>
            <Typography>{circles.find((c) => c.id === circle.id)?.name}</Typography>
            {items.map((item) => {
                return (
                    <Typography key={item.name}>
                        {item.label}: {item.value}
                    </Typography>
                )
            })}
            {!isLastIndex && <Divider />}
        </Stack>
    )
}

const Profile = () => {
    const { t } = useTranslation()
    const router = useRouter()
    const { id } = useParams<{ id: string }>()
    const { can } = useCheckPermissions()
    const [auditsDialogOpen, setAuditsDialogOpen] = useState(false)

    const { data: station } = useQuery({
        queryKey: ['station', id],
        queryFn: async () => {
            const res = await StationsService.getStationById({
                id: id as string
            })
            return res
        },
        enabled: !!id
    })

    const cards = useMemo(
        (): Card[] =>
            station
                ? [
                      {
                          fields: [
                              {
                                  label: t('model.node.pollingCenterName'),
                                  value: station.pc?.name
                              },
                              {
                                  label: t('model.node.pollingCenterCode'),
                                  value: station.pc?.code
                              },
                              {
                                  label: t('model.station.number'),
                                  value: formatStationNumber(station.number)
                              },
                              {
                                  label: t('model.station.isVip'),
                                  value: t(`view.common.yes/no.${station.isVip ? 'true' : 'false'}`)
                              },
                              {
                                  label: t('model.user.electionTypes.label'),
                                  value: t(`model.user.electionTypes.options.${station.electionType}` as any)
                              },
                              {
                                  label: t('model.station.maxVoters'),
                                  value: numeral(station.maxVoters.toString()).format('0,0')
                              },
                              {
                                  label: 'invalidPapersDiff',
                                  Template: CountDiff,
                                  templateProps: {
                                      label: t('model.station.invalidPapersDiff'),
                                      value: station.voteDiff?.invalidPapers
                                  }
                              },
                              {
                                  label: 'validVotesDiff',
                                  Template: CountDiff,
                                  templateProps: {
                                      label: t('model.station.validVotesDiff'),
                                      value: station.voteDiff?.validVotes
                                  }
                              },
                              {
                                  label: t('view.incident.all.headline'),
                                  value: numeral(station.incidents.toString()).format('0,0')
                              },

                              {
                                  label: t('model.station.currentStatus'),
                                  Template: StationStatusLabel,
                                  templateProps: {
                                      status: station.currentStatus
                                  }
                              },
                              {
                                  label: t('model.station.initiationStatus'),
                                  Template: StationStatusLabel,
                                  templateProps: {
                                      status: station.initiationStatus
                                  }
                              },
                              {
                                  label: t('model.station.initiatedAt'),
                                  value: dateFormat({
                                      date: station.initiatedAt
                                  })
                              }
                          ]
                      },
                      {
                          title: t('view.circle.header'),
                          fields: station.circles.map((circle) => ({
                              Template: CircleFields,
                              templateProps: {
                                  circle,
                                  isLastIndex: station.circles.indexOf(circle) === station.circles.length - 1
                              }
                          }))
                      }
                  ]
                : [],
        [station, t]
    )

    return (
        <LayoutPage
            title="station"
            breadcrumbs={[
                {
                    href: paths.stations.index,
                    title: t('view.station.header')
                },
                {
                    title: station?.number ?? id ?? t('view.station.name.headline')
                }
            ]}
            actions={[
                {
                    label: t('view.common.dialog.back'),
                    onClick: () => router.push(paths.stations.index),
                    variant: 'outlined'
                },
                {
                    hidden: !can({
                        resource: RESOURCES.station,
                        actions: [ACTIONS.update]
                    }),
                    label: t('view.common.dialog.edit'),
                    onClick: () => router.push(paths.stations.edit(id))
                },
                {
                    hidden: !station?.canInitiate,
                    label: t('view.station.initiate.headline'),
                    onClick: () => router.push(paths.stations.initiate(id)),
                    color: 'secondary',
                    startIcon: <Power />
                },
                {
                    hidden: !can({
                        resource: RESOURCES.audit,
                        actions: [ACTIONS.read]
                    }),
                    label: t('view.common.audit'),
                    onClick: () => setAuditsDialogOpen(true),
                    color: 'info'
                }
            ]}
        >
            <MasonryCards
                cards={cards}
                columns={{
                    xs: 1,
                    sm: 2,
                    md: 2,
                    lg: 3
                }}
                spacing={2}
            />

            <>
                {station && (
                    <AuditsDialog
                        version={station?.version ?? 0}
                        open={auditsDialogOpen}
                        onClose={() => setAuditsDialogOpen(false)}
                        id={station?.id ?? ''}
                        type="stations"
                    />
                )}
            </>
        </LayoutPage>
    )
}

export default Profile
