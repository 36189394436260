import { useNodes } from './use-nodes'
import * as yup from 'yup'
import { NodeType } from 'src/services/api/emos'

export const useNodesSchema = () => {
    const { nodeTypeValues } = useNodes({})

    const createBranchSchema = yup.object().shape({
        parentId: yup
            .string()
            .nullable()
            .when('nodeType', {
                is: (value) => value === NodeType.BRANCH,
                then: (schema) => schema.required()
            }),
        provinceId: yup
            .string()
            .nullable()
            .when('nodeType', {
                is: (value) => value === NodeType.BRANCH,
                then: (schema) => schema.required()
            }),
        districtId: yup
            .string()
            .nullable()
            .when('nodeType', {
                is: (value) => value === NodeType.BRANCH,
                then: (schema) => schema.required()
            }),
        subDistrictId: yup
            .string()
            .nullable()
            .when('nodeType', {
                is: (value) => value === NodeType.BRANCH,
                then: (schema) => schema.required()
            }),
        circleId: yup
            .string()
            .nullable()
            .when('nodeType', {
                is: (value) => value === NodeType.BRANCH,
                then: (schema) => schema.required()
            })
    })

    const createSubBranchSchema = yup.object().shape({
        parentId: yup
            .string()
            .nullable()
            .when('nodeType', {
                is: (value) => value === NodeType.SUB_BRANCH,
                then: (schema) => schema.required()
            })
    })

    const createPollingCenterSchema = yup.object().shape({
        code: yup.string().when('nodeType', {
            is: (value) => value === NodeType.POLLING_CENTER,
            then: (schema) => schema.required().min(6).max(6)
        }),
        address: yup.string().when('nodeType', {
            is: (value) => value === NodeType.POLLING_CENTER,
            then: (schema) => schema.required().min(1).max(50)
        }),
        parentId: yup.string().when('nodeType', {
            is: (value) => value === NodeType.POLLING_CENTER,
            then: (schema) => schema.required()
        }),
        electionType: yup.string().when('nodeType', {
            is: (value) => value === NodeType.POLLING_CENTER,
            then: (schema) => schema.required()
        }),
        vrcId: yup.string().when('nodeType', {
            is: (value) => value === NodeType.POLLING_CENTER,
            then: (schema) => schema.required()
        })
    })

    const createSchema = () => {
        return yup.object().shape({
            id: yup.string().nullable(),
            nodeType: yup.string().required().oneOf(nodeTypeValues),
            name: yup.string().required().min(1).max(50),
            isActive: yup.boolean().nullable(),
            ...createBranchSchema.fields,
            ...createSubBranchSchema.fields,
            ...createPollingCenterSchema.fields
        })
    }

    return { createSchema }
}
