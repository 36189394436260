import { CircularProgress, TextField } from '@mui/material'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDebouncedCallback } from 'use-debounce'

const TextFieldCountInput = ({
    defaultValue,
    isPending,
    onChange,
    onFocus,
    isError,
    label,
    onClick,
    disabled,
    width = '80%',
    endAdornment,
    ...rest
}: {
    defaultValue: number | undefined
    isPending: boolean
    isError: boolean
    onChange: (value: number) => void
    onFocus?: () => void
    label?: string
    onClick?: () => void
    disabled?: boolean
    width?: string
    endAdornment?: JSX.Element
    rest?: any
}) => {
    const { t } = useTranslation()
    const [valueNotAllowed, setValueNotAllowed] = useState(false)
    const [isBlank, setIsBlank] = useState(false)

    const debouncedOnChange = useDebouncedCallback((value) => {
        // if (value === '') {
        //     setIsBlank(true)
        //     return
        // }
        if (value === defaultValue) return
        if (value > 99000) {
            setValueNotAllowed(true)
            return
        }
        setValueNotAllowed(false)
        onChange(parseInt(value || 0))
    }, 1100)

    const errorMessage = useMemo(() => {
        if (isBlank) {
            return t('view.validations.blank')
        }
        if (valueNotAllowed || isError) {
            return t('view.validations.fail_save')
        }

        return ''
    }, [valueNotAllowed, isError, isBlank, t])

    // const inputRef = useRef<HTMLInputElement | null>(null)

    // useEffect(() => {
    //     const input = inputRef.current

    //     const preventScroll = (e) => {
    //         // Prevent default behavior to stop value change on scroll
    //         e.preventDefault()
    //     }

    //     if (input) {
    //         // Add the event listener with capture mode
    //         input.addEventListener('wheel', preventScroll, { passive: false })
    //     }

    //     return () => {
    //         if (input) {
    //             input.removeEventListener('wheel', preventScroll)
    //         }
    //     }
    // }, [])

    return (
        <TextField
            // ref={inputRef}
            size="small"
            label={label ?? t('model.common.count')}
            defaultValue={defaultValue}
            inputProps={{
                type: 'number',
                min: 0,
                max: 99000,
                step: 1,
                pattern: '[0-9]*',
                inputMode: 'numeric'
            }}
            disabled={isPending || disabled}
            variant="outlined"
            sx={{ width }}
            onKeyDown={(e) => {
                if (e.key === '-' || e.key === '.') {
                    e.preventDefault()
                }
            }}
            onChange={(e) => {
                debouncedOnChange(e.target.value)
            }}
            InputProps={{
                disableInjectingGlobalStyles: true, // GlobalStyles for the auto-fill keyframes will not be injected/removed on mount/unmount
                endAdornment: isPending ? <CircularProgress size={20} /> : endAdornment ?? null
            }}
            onFocus={(event) => {
                event.target.select()
                onFocus?.()
            }}
            error={!!errorMessage}
            helperText={errorMessage}
            onClick={onClick}
            onContextMenu={(e) => e.preventDefault()}
            onWheelCapture={(e) => {
                return (e.target as HTMLInputElement).blur() // Type assertion to HTMLInputElement
            }}
            {...rest}
        />
    )
}

export default TextFieldCountInput
