import { FieldProps } from 'mui-rhf-library'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

export const useCircles = () => {
    const { t } = useTranslation()
    const fields = useMemo(
        (): FieldProps[] => [
            {
                name: 'name',
                label: t('model.common.name'),
                fieldType: 'textField',
                props: { fullWidth: true },
                gridProps: { xs: 12 }
            },
            {
                name: 'majoritySeats',
                label: t('model.circle.majoritySeats'),
                fieldType: 'textField',
                props: { fullWidth: true, type: 'number' },
                gridProps: { xs: 12 }
            },
            {
                name: 'minoritySeats',
                label: t('model.circle.minoritySeats'),
                fieldType: 'textField',
                props: { fullWidth: true, type: 'number' },
                gridProps: { xs: 12 }
            }
        ],
        [t]
    )

    return { fields }
}
