import type { FC } from 'react'
import { useCallback, useState } from 'react'

import { useTranslation } from 'react-i18next'
import ListItemText from '@mui/material/ListItemText'
import MenuItem from '@mui/material/MenuItem'
import Popover from '@mui/material/Popover'
import Typography from '@mui/material/Typography'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'
import { SelfService } from 'src/services/api/emos'
import { useAuth } from 'src/hooks/use-auth'

type Language = 'en' | 'ckb'

type LanguageOptions = {
    [key in Language]: {
        label: string
        dir: 'ltr' | 'rtl'
    }
}

interface LanguagePopoverProps {
    anchorEl: null | Element
    onClose?: () => void
    open?: boolean
}

export const languageOptions: LanguageOptions = {
    en: {
        label: 'view.languages.en',
        dir: 'ltr'
    },
    ckb: {
        label: 'view.languages.ckb',
        dir: 'rtl'
    }
}

export const LanguagePopover: FC<LanguagePopoverProps> = (props) => {
    const { t } = useTranslation()
    const { anchorEl, onClose, open = false, ...other } = props
    const [backdropOpen, setBackdropOpen] = useState(false)
    const { user } = useAuth()

    const handleChange = useCallback(async (language: Language, dir: 'ltr' | 'rtl'): Promise<void> => {
        setBackdropOpen(true)
        SelfService.updateSelf({
            requestBody: {
                locale: language as any
            }
        })
            .then(async () => {
                window.location.reload()
            })
            .catch((err) => {})
    }, [])

    return (
        <Popover
            anchorEl={anchorEl}
            anchorOrigin={{
                horizontal: 'right',
                vertical: 'bottom'
            }}
            disableScrollLock
            transformOrigin={{
                horizontal: 'right',
                vertical: 'top'
            }}
            onClose={onClose}
            open={open}
            {...other}
        >
            <Backdrop open={backdropOpen}>
                <CircularProgress />
            </Backdrop>
            {(Object.keys(languageOptions) as Language[]).map((language) => {
                const option = languageOptions[language]

                return (
                    <MenuItem
                        disabled={user?.locale === language}
                        onClick={() => handleChange(language, option.dir)}
                        key={language}
                    >
                        <ListItemText primary={<Typography variant="subtitle2">{t(option.label as any)}</Typography>} />
                    </MenuItem>
                )
            })}
        </Popover>
    )
}
