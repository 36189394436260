import { Typography } from '@mui/material'
import { Stack } from '@mui/system'
import { FC } from 'react'

interface PCNameCodeProps {
    name: string
    code?: string | number | null
    variant?: any
}

const PCNameCode: FC<PCNameCodeProps> = ({ name, code, variant = 'body2' }) => {
    return (
        <Stack spacing={0.5}>
            <Typography variant={variant}>{name}</Typography>
            <Typography variant={variant}>{code}</Typography>
        </Stack>
    )
}

export default PCNameCode
