import { YupEnglish } from './en';

const ckb: YupEnglish = {
    mixed: {
        default: 'نادروستە',
        required: 'داواکراوە',
        defined: 'پێویستە پێناسەکراو بێت',
        notType: function notType(_ref: any) {
            switch (_ref.type) {
                case 'number':
                    return 'پێویستە ژمارە بێت';
                case 'string':
                    return 'پێویستە نووسین بێت';
                default:
                    return 'نادروستە';
            }
        },
        oneOf: 'هەڵبژاردەکەت نادروستە',
    },

    string: {
        min: 'دەبێت بە لایەنی کەمەوە ${min} کاراکتەر بێت',
        max: 'دەبێت بە لایەنی زۆرەوە ${max} کاراکتەر بێت',
        email: 'پێویستە لە جۆری ئیمەیڵی دروست بێت',
    },

    number: {
        min: (m) => `پێویستە زیاتر یاخود یەکسان بێت بە ${m?.min?.toLocaleString()}`,
        max: (m) => `پێویستە کەمتر یاخو یەکسان بێت بە ${m?.max?.toLocaleString()}`,
        lessThan: 'پێویستە کەمتر بێت لە ${less}',
        moreThan: 'پێویستە زیاتر بێت لە ${more}',
        positive: 'پێویستە ژمارەی پۆزەتیڤ بێت',
        negative: 'پێویستە ژمارەی نێگەتیڤ بێت',
        integer: 'پێویستە لە جۆری ژمارە بێت',
    },
    array: {
        min: 'پێویستە بەلایەنی کەمەوە ${min} دانە دیاری بکرێت',
        max: 'پێویستە بە لایەنی زۆرەوە ${max} دانە دیاری بکرێت',
    },
};

export default ckb;
