import React, { useCallback, useEffect } from 'react'
import { AlertDialog } from './alert-dialog'
import { useTranslation } from 'react-i18next'
import { useToast } from 'src/hooks/useToast'

const NetworkDialog = () => {
    const [open, setOpen] = React.useState(false)
    const [t] = useTranslation()
    const { successToast } = useToast()

    const onlineHandler = useCallback(() => {
        setOpen(false)
        successToast(t('view.network.online'))
    }, [successToast, t])

    const offlineHandler = useCallback(() => {
        setOpen(true)
    }, [])

    useEffect(() => {
        window.addEventListener('online', onlineHandler)
        window.addEventListener('offline', offlineHandler)

        return () => {
            window.removeEventListener('online', onlineHandler)
            window.removeEventListener('offline', offlineHandler)
        }
    }, [offlineHandler, onlineHandler])

    return <AlertDialog open={open} title={t('view.network.offline')} loading={false} />
}

export default NetworkDialog
