import { useQuery } from '@tanstack/react-query'
import { allSubDistrictsQuery } from 'src/queries/sub-districts'

export const useSubDistricts = () => {
    const { data = [] } = useQuery({
        ...allSubDistrictsQuery()
    })

    const transformedData = data.reduce((acc, subDistrict) => {
        acc[subDistrict.id as string] = subDistrict.name
        return acc
    }, {})

    return { subDistricts: transformedData, subDistrictsArray: data }
}
