import type { FC } from 'react'
import SunIcon from '@mui/icons-material/WbSunnyOutlined'
import MoonIcon from '@mui/icons-material/DarkModeOutlined'
import { useSettings } from 'src/store/settings'
import { IconButton } from '@mui/material'

export const ThemePopover: FC = () => {
    const { paletteMode, setPaletteMode, setColorPreset } = useSettings()

    return (
        <IconButton
            onClick={() => {
                setPaletteMode?.(paletteMode === 'light' ? 'dark' : 'light')
                setColorPreset?.(paletteMode === 'light' ? 'yellow' : 'black')
            }}
        >
            {paletteMode === 'light' ? <MoonIcon fontSize="small" /> : <SunIcon fontSize="small" />}
        </IconButton>
    )
}
