import { Alert } from '@mui/material'
import { Box, Stack } from '@mui/system'
import { useTranslation } from 'react-i18next'
import { PartiesVirtuosoGrid } from 'src/components/dashboard/voting-results/party-list-virtualize'
import { useError } from 'src/contexts/count-errors'
import { DetailedVotingResultResponse, MinimalPartyResponse } from 'src/services/api/emos'
import StationInvalidPapers from './station-invalid-papers'

const PartiesVotingCount = ({
    votingResult,
    allParties,
    selectedParty,
    setSelectedParty
}: {
    votingResult: DetailedVotingResultResponse
    allParties: MinimalPartyResponse[]
    selectedParty: MinimalPartyResponse | undefined
    setSelectedParty: (party: MinimalPartyResponse | undefined) => void
}) => {
    const { t } = useTranslation()
    const { errors } = useError()

    return (
        <Stack spacing={2}>
            {/* {!!errors?.length && <Alert severity="error">{t('view.validations.parties_fail_save')}</Alert>} */}

            {allParties.length === votingResult.parties.length && (
                <Alert severity="success">{t('view.party.all_parties_counted')}</Alert>
            )}

            <Box sx={{ pt: 2, px: 2 }}>
                <StationInvalidPapers votingResult={votingResult} />
            </Box>

            <PartiesVirtuosoGrid
                votingResult={votingResult}
                parties={allParties}
                handleOnClick={(party) => {
                    setSelectedParty(party)
                }}
                value={selectedParty?.id}
            />
        </Stack>
    )
}

export default PartiesVotingCount
