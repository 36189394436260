import { Grid } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import HighPieChart from 'src/components/dashboard/reports/highcharts/pie-chart'
import { DashboardsService, ElectionType, NodeType, ViolationStatus, ViolationType } from 'src/services/api/emos'
import { useOperationReportsFilters } from 'src/store/reports'
import ReportCard from './report-card'
import { REFETCH_INTERVAL } from './constants'

const ViolationReports = ({ enabledQueries }: { enabledQueries: boolean }) => {
    const { t } = useTranslation()
    const state = useOperationReportsFilters()
    const { filters } = state

    const { data: violationReport = {} } = useQuery({
        queryKey: ['violationReport', filters],
        queryFn: async () => {
            const res = await DashboardsService.violationReport({
                circleId: filters?.circle as string,
                ancestorId:
                    filters?.[NodeType.POLLING_CENTER] ||
                    filters?.[NodeType.SUB_BRANCH] ||
                    filters?.[NodeType.BRANCH] ||
                    filters?.[NodeType.PBS],
                electionType: filters?.electionType as ElectionType
            })

            return {
                violationReportStatus: [
                    {
                        id: ViolationStatus.OPEN,
                        y: res.find((r) => r.id?.status === ViolationStatus.OPEN)?.count || 0,
                        name: t('model.violation.states.open')
                    },
                    {
                        id: ViolationStatus.DROPPED,
                        y: res.find((r) => r.id?.status === ViolationStatus.DROPPED)?.count || 0,
                        name: t('model.violation.states.dropped')
                    },
                    {
                        id: ViolationStatus.REPORTED,
                        y: res.find((r) => r.id?.status === ViolationStatus.REPORTED)?.count || 0,
                        name: t('model.violation.states.reported')
                    },
                    {
                        id: ViolationStatus.SUBMITTED,
                        y: res.find((r) => r.id?.status === ViolationStatus.SUBMITTED)?.count || 0,
                        name: t('model.violation.states.submitted')
                    }
                ],
                violationReportPerType: [
                    {
                        id: ViolationType.OTHER_PARTY_AGAINST_OTHER_PARTY,
                        y: res.find((r) => r.id?.type === ViolationType.OTHER_PARTY_AGAINST_OTHER_PARTY)?.count || 0,
                        name: t('model.violation.type.options.OtherPartyAgainstOtherParty')
                    },
                    {
                        id: ViolationType.OTHER_PARTY_AGAINST_SYSTEM_PARTY,
                        y: res.find((r) => r.id?.type === ViolationType.OTHER_PARTY_AGAINST_SYSTEM_PARTY)?.count || 0,
                        name: t('model.violation.type.options.OtherPartyAgainstSystemParty')
                    },
                    {
                        id: ViolationType.SYSTEM_PARTY_AGAINST_OTHER_PARTY,
                        y: res.find((r) => r.id?.type === ViolationType.SYSTEM_PARTY_AGAINST_OTHER_PARTY)?.count || 0,
                        name: t('model.violation.type.options.SystemPartyAgainstOtherParty')
                    }
                ]
            }
        },
        enabled: enabledQueries,
        refetchInterval: REFETCH_INTERVAL
    })

    return (
        <>
            <Grid item xs={12} sm={6} md={4}>
                <ReportCard title={t('view.reports.operation.violationReportStatus')}>
                    <HighPieChart dataset={violationReport['violationReportStatus'] ?? []} />
                </ReportCard>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
                <ReportCard title={t('view.reports.operation.violationReportPerType')}>
                    <HighPieChart dataset={violationReport['violationReportPerType'] || []} />
                </ReportCard>
            </Grid>
        </>
    )
}

export default ViolationReports
