import { useMemo } from 'react'
import { DetailedVotingResultResponse, VotingResultFormType, VotingResultStatus } from 'src/services/api/emos'
import { ACTIONS, RESOURCES, useCheckPermissions } from './use-check-permissions'

export const useVotingResult = ({ votingResult }: { votingResult: DetailedVotingResultResponse }) => {
    const { can } = useCheckPermissions()

    const isSubmitted = useMemo(() => votingResult.status === VotingResultStatus.SUBMITTED, [votingResult.status])

    const hasPermissionToEdit = useMemo(
        () =>
            can({
                actions: [votingResult.formType === VotingResultFormType.FINAL ? ACTIONS.u_f : ACTIONS.u_am],
                resource: RESOURCES.voting_result
            }),
        [can, votingResult.formType]
    )

    return {
        canEdit: !isSubmitted && hasPermissionToEdit
    }
}
