import * as yup from 'yup'

export const createSchema = () => {
    return yup.object().shape({
        name: yup.string().required(),
        majoritySeats: yup
            .number()
            .min(0)
            .nullable()
            .transform((value) => (isNaN(value) ? undefined : value)),
        minoritySeats: yup
            .number()
            .min(0)
            .nullable()
            .transform((value) => (isNaN(value) ? undefined : value))
    })
}
