import type { FC } from 'react'
import Menu01Icon from '@untitled-ui/icons-react/build/esm/Menu01'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'
import SvgIcon from '@mui/material/SvgIcon'
import useMediaQuery from '@mui/material/useMediaQuery'
import type { Theme } from '@mui/material/styles/createTheme'

import { Logo } from 'src/components/logo'
import { RouterLink } from 'src/components/router-link'
import { Scrollbar } from 'src/components/scrollbar'
import { usePathname } from 'src/hooks/use-pathname'
import { paths } from 'src/paths'
import type { NavColor } from 'src/types/settings'

import { AccountButton } from '../account-button'
import type { Section } from '../config'
import { LanguageSwitch } from '../language-switch'
import { TopNavSection } from './top-nav-section'
import { useCssVars } from '../vertical-layout/side-nav'
import { Container } from '@mui/system'
import { NotificationsButton } from '../notifications-button'
import { BreadCrumbs } from 'src/components/breadcrumbs'
import { useBreadcrumb } from 'src/contexts/breadcrumb-context'
import { ThemePopover } from '../theme-popover'

interface TopNavProps {
    color?: NavColor
    onMobileNav?: () => void
    sections?: Section[]
}

export const TopNav: FC<TopNavProps> = (props) => {
    const { color = 'evident', onMobileNav, sections = [] } = props
    const pathname = usePathname()
    const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'))
    const cssVars = useCssVars(color)
    const { breadcrumb } = useBreadcrumb()

    return (
        <Box
            component="header"
            sx={{
                ...cssVars,
                backgroundColor: 'var(--nav-bg)',
                borderBottomColor: 'var(--nav-border-color)',
                borderBottomStyle: 'solid',
                borderBottomWidth: 1,
                color: 'var(--nav-color)',
                left: 0,
                // position: 'sticky',
                top: 0,
                zIndex: (theme) => theme.zIndex.appBar
            }}
        >
            <Container maxWidth={'xl'}>
                <Stack
                    alignItems="center"
                    direction="row"
                    justifyContent="space-between"
                    spacing={2}
                    sx={{
                        px: 0,
                        py: 1
                    }}
                >
                    <Stack alignItems="center" direction="row" spacing={2}>
                        {!mdUp && (
                            <IconButton onClick={onMobileNav}>
                                <SvgIcon>
                                    <Menu01Icon />
                                </SvgIcon>
                            </IconButton>
                        )}
                        {mdUp && (
                            <Box
                                component={RouterLink}
                                href={paths.index}
                                sx={{
                                    width: '10vw',
                                    display: 'flex'
                                }}
                            >
                                <Logo />
                            </Box>
                        )}
                        {breadcrumb && <BreadCrumbs items={breadcrumb} />}
                    </Stack>
                    <Stack alignItems="center" direction="row" spacing={1}>
                        <LanguageSwitch />
                        <NotificationsButton />
                        {/* <SettingsButton /> */}
                        <ThemePopover />
                        <AccountButton />
                    </Stack>
                </Stack>
                {mdUp && (
                    <Box
                        sx={{
                            borderTopWidth: 1,
                            borderTopStyle: 'solid',
                            borderTopColor: 'divider'
                        }}
                    >
                        <Scrollbar
                            sx={{
                                '& .simplebar-scrollbar:before': {
                                    background: 'var(--nav-scrollbar-color)'
                                }
                            }}
                        >
                            <Stack
                                alignItems="center"
                                component="nav"
                                direction="row"
                                spacing={1}
                                sx={{
                                    px: 0,
                                    py: 1.5
                                }}
                            >
                                {sections.map((section, index) => (
                                    <TopNavSection
                                        items={section.items}
                                        key={index}
                                        pathname={pathname}
                                        subheader={section.subheader}
                                    />
                                ))}
                            </Stack>
                        </Scrollbar>
                    </Box>
                )}
            </Container>
        </Box>
    )
}
