import styled from '@emotion/styled'
import { Card, CardContent, Skeleton, Typography } from '@mui/material'
import { Box, Stack } from '@mui/system'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { GridComponents, Virtuoso } from 'react-virtuoso'
import { useToast } from 'src/hooks/useToast'
import {
    DetailedVotingResultResponse,
    MinimalCandidateResponse,
    MinimalPartyResponse,
    VotingResultsService
} from 'src/services/api/emos'
import TextFieldCountInput from './components/text-field-count-input'
import { useVirtualItemsKey } from 'src/contexts/virtual-items-key'
import { useVotingResult } from 'src/hooks/use-voting-results'

export const ItemContainer = styled.div`
    box-sizing: border-box;
    padding: 10px;
    width: 100%;
    // background: #f5f5f5;
    display: flex;
    flex: none;
    align-content: stretch;
`

export const ListContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
` as GridComponents['List']

export const ItemWrapper = styled.div`
    flex: 1;
  }
`

export const Candidate = React.memo<{
    party?: MinimalPartyResponse
    votingResult: DetailedVotingResultResponse
    candidate: MinimalCandidateResponse
    virtuoso: React.MutableRefObject<any>
    index: number
}>(
    ({
        party,
        votingResult,
        candidate,
        virtuoso,
        index
    }: {
        party?: MinimalPartyResponse
        votingResult: DetailedVotingResultResponse
        candidate: MinimalCandidateResponse
        virtuoso: React.MutableRefObject<any>
        index: number
    }) => {
        const { t } = useTranslation()
        const { errorsToast } = useToast()
        const queryClient = useQueryClient()
        const { incrementKey } = useVirtualItemsKey()
        const { canEdit } = useVotingResult({ votingResult })

        const candidateInVotingResult = votingResult.candidates.find((c) => c.id === candidate.id)

        const [version, setVersion] = React.useState<number>(candidateInVotingResult?.version ?? 0)
        const [count, setCount] = React.useState<number | undefined>(candidateInVotingResult?.count ?? undefined)

        const { mutate, isPending, isError } = useMutation({
            mutationKey: ['updateCandidateCount'],
            mutationFn: async (dataCount: number) => {
                await VotingResultsService.updateCandidateCount({
                    id: votingResult.id,
                    requestBody: {
                        count: dataCount,
                        id: candidate.id as string,
                        version
                    }
                })
                return dataCount
            },
            onSuccess: (countRes) => {
                setVersion(version + 1)
                setCount(countRes)
                queryClient.setQueryData(['votingResult', votingResult.id], (oldData: DetailedVotingResultResponse) => {
                    const existentParty = oldData.parties.find((p) => p.id === party?.id)

                    const existingCandidate = oldData.candidates?.find((c) => c.id === candidate.id)

                    if (!existentParty) {
                        oldData.parties.push({
                            id: party?.id as string,
                            partyVotes: 0,
                            total: countRes,
                            version: 0,
                            candidateVotes: 0
                        })
                    } else {
                        // existentParty.total += !existingCandidate ? countRes : countRes - existingCandidate.count
                        existentParty.candidateVotes += !existingCandidate
                            ? countRes
                            : countRes - existingCandidate.count
                        existentParty.partyVotes -= !existingCandidate ? countRes : countRes - existingCandidate.count
                    }

                    if (!existingCandidate) {
                        oldData.candidates?.push({
                            count: countRes,
                            version: version + 1,
                            id: candidate.id as string
                        })
                    } else {
                        existingCandidate.count = countRes
                        existingCandidate.version = version + 1
                    }

                    incrementKey()

                    return oldData
                })
            },
            onError: (error) => {
                errorsToast(error)
            }
        })

        return (
            <Card
                key={candidate.id}
                variant="outlined"
                sx={{
                    height: 110
                }}
            >
                <Box sx={{ px: 2, py: 1 }}>
                    <Stack spacing={2}>
                        <Typography
                            variant="subtitle2"
                            sx={{
                                userSelect: 'none'
                            }}
                        >
                            {candidate.number}
                        </Typography>

                        <TextFieldCountInput
                            label={t('model.party.candidateCount')}
                            defaultValue={count}
                            disabled={!canEdit}
                            isPending={isPending}
                            isError={isError}
                            onChange={(count) => {
                                mutate(count)
                            }}
                            onFocus={() => {
                                virtuoso.current.scrollToIndex({
                                    index: index,
                                    align: 'center',
                                    behavior: 'smooth'
                                })
                                return false
                            }}
                        />
                    </Stack>
                </Box>
            </Card>
        )
    }
)
Candidate.displayName = 'Candidate'

export const CandidatesVirtuosoGrid = ({
    parties,
    votingResult,
    candidates,
    value,
    loading
}: {
    parties: MinimalPartyResponse[]
    votingResult: DetailedVotingResultResponse
    candidates: MinimalCandidateResponse[]
    value: string | null | undefined
    loading: boolean
}) => {
    const virtuoso = useRef(null)
    const { t } = useTranslation()

    const party = parties.find((party) => party.id === value)

    const itemContent = React.useCallback(
        (index: number) => (
            <ItemWrapper>
                <Candidate
                    virtuoso={virtuoso}
                    party={party}
                    votingResult={votingResult}
                    candidate={candidates[index]}
                    index={index}
                />
            </ItemWrapper>
        ),
        [party, votingResult, candidates]
    )

    return (
        <Stack spacing={1}>
            {loading && <Skeleton variant="rectangular" height={100} sx={{ mx: 2, borderRadius: 1 }} />}
            {candidates.length ? (
                <Card variant="outlined">
                    <CardContent sx={{ p: 1 }}>
                        <Typography variant="body2" color="textSecondary">
                            {t('view.candidate.header')} ({party?.name ?? value} - {party?.number})
                        </Typography>

                        <Virtuoso
                            ref={virtuoso}
                            style={{ height: 800 }}
                            data={candidates}
                            components={{
                                Item: ItemContainer,
                                List: ListContainer
                            }}
                            context={{ virtuoso }}
                            itemContent={itemContent}
                            overscan={10}
                            increaseViewportBy={10}
                        />
                    </CardContent>
                </Card>
            ) : (
                <Box sx={{ mx: 2 }}></Box>
            )}
        </Stack>
    )
}
