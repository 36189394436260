import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { useSettings } from 'src/store/settings'

type Data = {
    total: number
    name: string
    color: string
    percentage: number
    [key: string]: any
}

const BarChart = ({ title, dataset }: { title?: string; dataset: Data[] }) => {
    const { paletteMode } = useSettings()

    const options: Highcharts.Options = {
        chart: {
            type: 'column',
            backgroundColor: 'transparent'
        },
        accessibility: {
            enabled: false
        },
        title: {
            text: title
        },
        credits: {
            enabled: false
        },
        xAxis: {
            categories: dataset.map((data) => `${data.number}`),
            title: {
                text: null
            },
            labels: {
                style: {
                    color: paletteMode === 'dark' ? 'white' : 'black',
                    fontFamily: 'Kurdish',
                    fontSize: '14px'
                }
            },
            gridLineWidth: 0
        },
        yAxis: {
            min: 0,
            title: {
                text: null,
                align: 'high'
            },
            labels: {
                enabled: false,
                overflow: 'justify'
            },
            gridLineWidth: 0
        },
        tooltip: {
            headerFormat: '<strong>{point.number}</strong><br/>',
            useHTML: true,
            backgroundColor: 'transparent',
            borderColor: 'transparent',
            shadow: false,
            padding: 0,
            shared: true,
            formatter: function () {
                const point = this.point.options as any
                return `<div style="
                    border-radius: 0.5rem;
					background-color: white;
					box-shadow: #CCC 0px 1px 3px 0px;
					padding: 0.5rem;
                    text-align: center;
                    ">
                        <span style="color: grey; font-size: 16px">${point.name} (${point.number})<br/></span>
                       <hr style="border: none; height: 1px; background-color: #ccc; margin: 7px 0;">
                        <span style="color: black; font-size: 17px">${this.point.y}</span>
                        <span style="color: grey; font-size: 17px">(${this.point.percentage})</span>
                    </div>`
            },
            style: {
                fontSize: '15px', // Change the font size
                fontFamily: 'Kurdish',
                boxShadow: 'none'
            }
        },
        legend: {
            layout: 'vertical',
            align: 'right',
            verticalAlign: 'middle',
            floating: false,
            backgroundColor: '#FFFFFF',
            borderColor: '#CCC',
            borderWidth: 1,
            shadow: true,
            enabled: false
        },
        series: [
            {
                name: 'Total votes',
                type: 'column',
                data: dataset.map((data) => ({
                    name: data.name,
                    y: data.count,
                    color: data.color,
                    drilldown: data.name,
                    number: data.number,
                    percentage: data.percentage
                })),
                dataLabels: {
                    enabled: true,
                    format: '{point.y}',
                    style: {
                        color: paletteMode === 'dark' ? 'white' : 'black',
                        fontFamily: 'Kurdish',
                        fontSize: '14px'
                    }
                }
            }
        ]
    }

    return <HighchartsReact highcharts={Highcharts} options={options} />
}

export default BarChart
