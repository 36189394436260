import { alpha } from '@mui/system/colorManipulator'
import type { ColorRange, PaletteColor } from '@mui/material/styles/createPalette'

const withAlphas = (color: PaletteColor): PaletteColor => {
    return {
        ...color,
        alpha4: alpha(color.main, 0.04),
        alpha8: alpha(color.main, 0.08),
        alpha12: alpha(color.main, 0.12),
        alpha30: alpha(color.main, 0.3),
        alpha50: alpha(color.main, 0.5)
    }
}

export const neutral: ColorRange = {
    50: '#F0F3F9',
    100: '#d8dbe0',
    200: '#c0c2c7',
    300: '#a8aaae',
    400: '#9DA4AE',
    500: '#6C737F',
    600: '#4D5761',
    700: '#2F3746',
    800: '#1C2536',
    900: '#0a0f26'
}

export const blue = withAlphas({
    lightest: '#e6e6ff',
    light: '#6667ff',
    main: '#0002FF',
    dark: '#0001b3',
    darkest: '#000033',
    contrastText: '#FFFFFF'
})

export const green = withAlphas({
    lightest: '#F6FEF9',
    light: '#EDFCF2',
    main: '#16B364',
    dark: '#087443',
    darkest: '#084C2E',
    contrastText: '#FFFFFF'
})

export const yellow = withAlphas({
    lightest: '#ffffcc',
    light: '#fffe4d',
    main: '#FFFD00',
    dark: '#e6e400',
    darkest: '#999800',
    contrastText: '#000'
})

export const black = withAlphas({
    lightest: '#e9e9e9',
    light: '#373737',
    main: '#212121',
    dark: '#070707',
    darkest: '#000000',
    contrastText: '#FFFD00'
})

export const indigo = withAlphas({
    lightest: '#F5F7FF',
    light: '#EBEEFE',
    main: '#6366F1',
    dark: '#4338CA',
    darkest: '#312E81',
    contrastText: '#FFFFFF'
})

export const purple = withAlphas({
    lightest: '#F9F5FF',
    light: '#F4EBFF',
    main: '#9E77ED',
    dark: '#6941C6',
    darkest: '#42307D',
    contrastText: '#FFFFFF'
})

export const success = withAlphas({
    lightest: '#F0FDF9',
    light: '#3FC79A',
    main: '#10B981',
    dark: '#0B815A',
    darkest: '#134E48',
    contrastText: '#FFFFFF'
})

export const info = withAlphas({
    lightest: '#ECFDFF',
    light: '#CFF9FE',
    main: '#06AED4',
    dark: '#0E7090',
    darkest: '#164C63',
    contrastText: '#FFFFFF'
})

export const warning = withAlphas({
    lightest: '#fff176',
    light: '#ffee58',
    main: '#F79009',
    dark: '#B54708',
    darkest: '#7A2E0E',
    contrastText: '#FFFFFF'
})

export const error = withAlphas({
    lightest: '#FEF3F2',
    light: '#f2574c',
    main: '#F04438',
    dark: '#B42318',
    darkest: '#7A271A',
    contrastText: '#FFFFFF'
})
