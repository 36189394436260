import { TFunction } from 'i18next'
import { REASONS } from 'src/hooks/use-incidents'
import { StationOperationalStatus } from 'src/services/api/emos'
import * as yup from 'yup'

export const createSchema = ({ t, isStationInitiation }: { t: TFunction; isStationInitiation?: boolean }) => {
    return yup.object().shape({
        reason: yup.lazy((_, p: any) => {
            return yup
                .string()
                .nullable()
                .test('reason', t('view.validations.required'), (value) => {
                    return isStationInitiation
                        ? p?.from[1]?.value?.operationType === StationOperationalStatus.NON_OPERATIONAL
                            ? !!value
                            : true
                        : !!value
                })
        }),

        timeOfBreakage: yup.lazy(() => {
            return yup
                .string()
                .nullable()
                .test('timeOfBreakage', t('view.validations.required'), (value) => {
                    return isStationInitiation ? true : !!value && value !== 'Invalid Date'
                })
                .test('notInFuture', t('view.validations.cannot_be_in_future'), (value) => {
                    if (!value) return true // skip validation if value is not provided

                    // Combine today's date with the provided time
                    const today = new Date()
                    const [hours, minutes, seconds] = value.split(':').map(Number) // Convert to numbers
                    const inputTime = new Date(today.setHours(hours, minutes, seconds))
                    const now = new Date()

                    return inputTime <= now // ensures the time is not in the future
                })
        }),
        stationId: yup.string().when([], {
            is: () => isStationInitiation,
            then: (schema) => schema,
            otherwise: (schema) => schema.required()
        }),

        description: yup.string().when('reason', {
            is: (value) => value === REASONS.OTHER,
            then: (schema) => schema.required(),
            otherwise: (schema) => schema.nullable()
        })
    })
}
