import { CreateCandidateRequest, UpdateCandidateRequest } from 'src/services/api/emos'

type CreateCandidateRequestKeys = Array<keyof CreateCandidateRequest>
export const createCandidateAttributesToKeep: CreateCandidateRequestKeys = [
    'circleId',
    'gender',
    'name',
    'number',
    'partyId',
    'photo'
]

type UpdateCandidateRequestKeys = Array<keyof UpdateCandidateRequest>
export const updateCandidateAttributesToKeep: UpdateCandidateRequestKeys = [
    'circleId',
    'isActive',
    'name',
    'number',
    'partyId',
    'photo'
]
