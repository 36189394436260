import React from 'react'
import { UploadField } from '@ditkrg/react-core'
import { useFieldArray, UseFormReturn } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Typography } from '@mui/material'
import { useToast } from 'src/hooks/useToast'

interface UploadFormProps {
    name: string
    formConfigs: UseFormReturn<any>
    header: string
    errorMessage?: string
    maxFiles?: number
}

const UploadForm = ({ name, header, errorMessage, maxFiles = 10, formConfigs }: UploadFormProps) => {
    const [t] = useTranslation()
    const { errorToast } = useToast()
    const { fields, append, update } = useFieldArray({ name, control: formConfigs.control, keyName: 'indexId' })

    const onUpload = (file: any) => {
        append({ ...file, ...file.response })
    }

    const onRemove = (file: any) => {
        const index = fields.findIndex((f: any) => f.key === file.key)
        update(index, { ...fields[index], secret: '', _destroy: true })
    }

    const onError = (e) => {
        switch (e.type) {
            case 'maxSize':
                return errorToast(t('view.validations.file_too_large'))
            case 'maxFiles':
                return errorToast(t('view.validations.max_files', { maxFiles }))
            case 'invalidFileType':
                return errorToast(t('view.validations.file-invalid-type'))
            default:
                return errorToast(t('view.validations.upload_failed'))
        }
    }

    return (
        <UploadField
            name={name}
            label={
                (
                    <Typography variant="body2" color="textSecondary">
                        {header}
                    </Typography>
                ) as any
            }
            // description={t('view.validations.mb', { size: 10 })}
            onUpload={onUpload}
            onRemove={onRemove}
            files={fields.filter((file: any) => !file._destroy)}
            imageSrc="/assets/upload-icon.svg"
            maxFiles={maxFiles}
            errorMessage={errorMessage ?? (formConfigs.formState.errors[name]?.message as string)}
            accept={['image/jpg', 'image/jpeg', 'image/png', 'application/pdf']}
            onError={onError}
            uploadUrl="/api/v1/uploads"
        />
    )
}

export default UploadForm
