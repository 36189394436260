import { view } from './view'
import { model } from './model'
import { notifications } from './notifications'

export const en = {
    view,
    model,
    notifications
}

export type Translation = typeof en
