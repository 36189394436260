import { useQuery } from '@tanstack/react-query'
import { FieldProps } from 'mui-rhf-library'
import { useMemo } from 'react'
import { UseFormReturn } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import {
    CirclesService,
    ElectionType,
    NodesService,
    NodeType,
    VotingRegistrationCentersService
} from 'src/services/api/emos'
import { useRegion } from '../use-region'

export const NODE_TYPE_VALUES = Object.values(NodeType).filter((v) => v !== NodeType.EMR) as NodeType[]

export const useNodes = ({ formConfigs, isEdit }: { formConfigs?: UseFormReturn<any>; isEdit?: boolean }) => {
    const { t } = useTranslation()

    const getNodeIndexByValue = (value: NodeType): number => {
        return NODE_TYPE_VALUES.indexOf(value)
    }

    const getNodeValueByIndex = (index: number): NodeType => {
        return NODE_TYPE_VALUES[index]
    }

    const { fields: regionFields } = useRegion({
        tableState: {} as any,
        formConfigs: formConfigs
    })

    const nodeTypeWatcher = formConfigs?.watch('nodeType')
    const parentWatcher = formConfigs?.watch('parentId')

    const { data: parentOptions, isLoading } = useQuery({
        queryKey: ['nodeParentOptions', nodeTypeWatcher],
        queryFn: async () => {
            const res = await NodesService.listAllNodes({
                nodeType: NODE_TYPE_VALUES[NODE_TYPE_VALUES.indexOf(nodeTypeWatcher) - 1]
            })
            return res
        },
        placeholderData: (placeholderData) => placeholderData,
        enabled: nodeTypeWatcher !== NodeType.PBS
    })

    const { data: circlesOptions, isLoading: isLoadingCircleOptions } = useQuery({
        queryKey: ['nodeCircleOptions', nodeTypeWatcher],
        queryFn: async () => {
            const res = await CirclesService.listAllCircles()
            return res
        },
        placeholderData: (placeholderData) => placeholderData,
        enabled: nodeTypeWatcher === NodeType.BRANCH
    })

    const { data: vrcOptions, isLoading: isLoadingVrcOptions } = useQuery({
        queryKey: ['nodeVrcOptions', nodeTypeWatcher, parentWatcher],
        queryFn: async () => {
            const res = await VotingRegistrationCentersService.listAllVotingRegistrationCenters()
            return res
        },
        placeholderData: (placeholderData) => placeholderData,
        enabled: nodeTypeWatcher === NodeType.POLLING_CENTER
    })

    const createBranchFields = useMemo(
        (): FieldProps[] =>
            nodeTypeWatcher === NodeType.BRANCH
                ? [
                      {
                          name: 'parentId',
                          label: t('model.node.parent'),
                          fieldType: 'autocomplete',
                          props: {
                              fullWidth: true,
                              options: parentOptions,
                              optionLabel: 'name',
                              optionValue: 'id',
                              loading: isLoading
                          },
                          gridProps: { xs: 12 },
                          textFieldProps: {
                              label: t('model.node.parent'),
                              fullWidth: true
                          }
                      },
                      ...regionFields,
                      {
                          name: 'circleId',
                          label: t('view.circle.name.headline'),
                          fieldType: 'autocomplete',
                          props: {
                              disabled: isEdit,
                              fullWidth: true,
                              options: circlesOptions,
                              optionLabel: 'name',
                              optionValue: 'id',
                              loading: isLoadingCircleOptions
                          },
                          gridProps: { xs: 12 },
                          textFieldProps: {
                              label: t('view.circle.name.headline'),
                              fullWidth: true
                          }
                      }
                  ]
                : [],
        [nodeTypeWatcher, t, parentOptions, isLoading, regionFields, isEdit, circlesOptions, isLoadingCircleOptions]
    )

    const createSubBranchFields = useMemo(
        (): FieldProps[] =>
            nodeTypeWatcher === NodeType.SUB_BRANCH && formConfigs
                ? [
                      {
                          name: 'parentId',
                          label: t('model.node.parent'),
                          fieldType: 'autocomplete',
                          props: {
                              fullWidth: true,
                              options: parentOptions,
                              optionLabel: 'name',
                              optionValue: 'id',
                              loading: isLoading,
                              onChange: (province) => {
                                  formConfigs.setValue('provinceId', province?.provinceId)
                                  formConfigs.setValue('districtId', province?.districtId)
                                  formConfigs.setValue('subDistrictId', province?.subDistrictId)
                              }
                          },
                          gridProps: { xs: 12 },
                          textFieldProps: {
                              label: t('model.node.parent'),
                              fullWidth: true
                          }
                      },
                      ...regionFields
                  ]
                : [],
        [nodeTypeWatcher, formConfigs, t, parentOptions, isLoading, regionFields]
    )

    const createPollingCenterFields = useMemo(
        (): FieldProps[] =>
            nodeTypeWatcher === NodeType.POLLING_CENTER
                ? [
                      {
                          name: 'address',
                          label: t('model.node.address'),
                          fieldType: 'textField',
                          props: { fullWidth: true },
                          gridProps: { xs: 12 }
                      },
                      {
                          name: 'code',
                          label: t('model.common.code'),
                          fieldType: 'textField',
                          props: { fullWidth: true, type: 'number' },
                          gridProps: { xs: 12 }
                      },
                      {
                          name: 'parentId',
                          label: t('model.node.parent'),
                          fieldType: 'autocomplete',
                          props: {
                              fullWidth: true,
                              options: parentOptions,
                              optionLabel: 'name',
                              optionValue: 'id',
                              loading: isLoading
                          },
                          gridProps: { xs: 12 },
                          textFieldProps: {
                              label: t('model.node.parent'),
                              fullWidth: true
                          }
                      },
                      {
                          name: 'electionType',
                          label: t('model.user.electionTypes.label'),
                          fieldType: 'select',
                          props: {
                              fullWidth: true,
                              options: Object.values(ElectionType).map((value) => ({
                                  label: t(`model.user.electionTypes.options.${value}` as any),
                                  value
                              }))
                          },
                          gridProps: { xs: 12 }
                      },
                      {
                          name: 'vrcId',
                          label: t('model.node.vrcId'),
                          fieldType: 'autocomplete',
                          props: {
                              fullWidth: true,
                              options: vrcOptions,
                              optionValue: 'id',
                              customOptionLabel: (item) => `${item?.name} (${item?.id})`,
                              loading: isLoadingVrcOptions
                          },
                          gridProps: { xs: 12 },
                          textFieldProps: {
                              label: t('model.node.vrcId'),
                              fullWidth: true
                          }
                      }
                  ]
                : [],
        [nodeTypeWatcher, parentOptions, isLoading, t, vrcOptions, isLoadingVrcOptions]
    )

    const fields = useMemo(
        (): FieldProps[] =>
            !formConfigs
                ? []
                : [
                      {
                          hidden: !!formConfigs.getValues('id'),
                          name: 'nodeType',
                          label: t('model.node.type.label'),
                          fieldType: 'select',
                          props: {
                              fullWidth: true,
                              options: NODE_TYPE_VALUES.map((value) => ({
                                  label: t(`model.node.type.options.${value}` as any),
                                  value
                              })),
                              onChange: (e) => {
                                  formConfigs.reset({ nodeType: e.target.value, isActive: true })
                              }
                          }
                      },
                      {
                          name: 'name',
                          label: t('model.common.name'),
                          fieldType: 'textField',
                          props: { fullWidth: true },
                          gridProps: { xs: 12 }
                      },
                      ...createBranchFields,
                      ...createSubBranchFields,
                      ...createPollingCenterFields,
                      {
                          name: 'isActive',
                          label: t('model.common.isActive'),
                          fieldType: 'switch',
                          gridProps: { xs: 12 }
                      }
                  ],
        [createBranchFields, createPollingCenterFields, createSubBranchFields, formConfigs, t]
    )

    return {
        fields,
        nodeTypeValues: NODE_TYPE_VALUES,
        getNodeIndexByValue,
        getNodeValueByIndex
    }
}
