import { Column } from '@ditkrg/react-core'
import { TFunction } from 'i18next'
import StationStatusLabel from './status-label'
import dateFormat from 'src/utils/date-format'
import PCNameCode from '../nodes/pc-name-code'

const GenerateColumns = ({ tableState, t }: { tableState: any; t: TFunction }) => {
    const cols: Column[] = [
        {
            id: 'index',
            header: '#',
            cell: (_, rowIndex) => tableState.rowsPerPage * tableState.page + rowIndex + 1
        },
        {
            id: 'number',
            header: t('model.common.number'),
            cell: (row) => `${t('view.station.name.headline')}: ${row.number}`
        },
        {
            id: 'incidents',
            header: t('view.incident.header'),
            accessor: 'incidents'
        },
        {
            id: 'pcId',
            header: t('model.node.type.options.PollingCenter'),
            cell: (row) => <PCNameCode name={row.pc.name} code={row.pc.code} variant="body2" />
        },
        {
            id: 'initiationStatus',
            header: t('model.station.initiationStatus'),
            cell: (row) => <StationStatusLabel status={row.initiationStatus} />
        },
        {
            id: 'currentStatus',
            header: t('model.station.currentStatus'),
            cell: (row) => <StationStatusLabel status={row.currentStatus} />
        },
        {
            id: 'initiatedAt',
            header: t('model.station.initiatedAt'),
            cell: (row) => {
                return (
                    dateFormat({
                        date: row.initiatedAt
                    }) ?? ''
                )
            }
        }
    ]
    return cols
}

export default GenerateColumns
