import type { FC } from 'react'
import IconButton from '@mui/material/IconButton'
import SvgIcon from '@mui/material/SvgIcon'
import Tooltip from '@mui/material/Tooltip'
import SettingsIcon from 'src/assets/icons/gear'

import { usePopover } from 'src/hooks/use-popover'

import { SettingsPopover } from './settings-popover'

export const SettingsButton: FC = () => {
    const popover = usePopover<HTMLButtonElement>()

    return (
        <>
            <Tooltip title="Settings">
                <IconButton ref={popover.anchorRef} onClick={popover.handleOpen}>
                    <SvgIcon fontSize="small">
                        <SettingsIcon />
                    </SvgIcon>
                </IconButton>
            </Tooltip>
            <SettingsPopover anchorEl={popover.anchorRef.current} onClose={popover.handleClose} open={popover.open} />
        </>
    )
}
