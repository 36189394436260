import { type ReactNode } from 'react'
import { paths } from 'src/paths'
import { useTranslation } from 'react-i18next'
import {
    House,
    Users,
    Pyramid,
    CircleDot,
    Settings,
    BadgeAlert,
    Archive,
    Ban,
    Vote,
    PieChart,
    BarChart3
} from 'lucide-react'
import { ACTIONS, RESOURCES, useCheckPermissions } from 'src/hooks/use-check-permissions'
import { useSystemConfigurationCheck } from 'src/hooks/use-system-configuration-check'

export interface Item {
    disabled?: boolean
    external?: boolean
    icon?: ReactNode
    items?: Item[]
    label?: ReactNode
    path?: string
    title: string
    actions?: string[]
    resource?: string
    hidden?: boolean
}

export interface Section {
    items: Item[]
    subheader?: string
}

export const useSections = (): Section[] => {
    const [t] = useTranslation()
    const { can } = useCheckPermissions()
    const { noTimeLimits, isActiveEvening, canBahAll } = useSystemConfigurationCheck()

    const sections: Section[] = [
        {
            subheader: t('view.nav.application'),
            items: [
                {
                    title: t('view.nav.home'),
                    path: paths.index,
                    icon: <House />
                },
                {
                    hidden: !(
                        (can({ resource: RESOURCES.voting_result, actions: [ACTIONS.read_am, ACTIONS.read_f] }) &&
                            noTimeLimits) ||
                        isActiveEvening ||
                        canBahAll
                    ),
                    resource: RESOURCES.voting_result,
                    actions: [ACTIONS.read_am, ACTIONS.read_f],
                    title: t('view.votingResult.header'),
                    path: paths.votingResults.index,
                    icon: <Vote />
                },
                {
                    resource: RESOURCES.station,
                    actions: [ACTIONS.read],
                    title: t('view.station.header'),
                    path: paths.stations.index,
                    icon: <Archive />
                },
                {
                    resource: RESOURCES.incident,
                    actions: [ACTIONS.read],
                    title: t('view.incident.header'),
                    path: paths.incidents.index,
                    icon: <BadgeAlert />
                },
                {
                    resource: RESOURCES.violation,
                    actions: [ACTIONS.read],
                    title: t('view.violation.header'),
                    path: paths.violations.index,
                    icon: <Ban />
                },
                {
                    resource: RESOURCES.node,
                    actions: [ACTIONS.ui],
                    title: t('view.pollingCenters.header'),
                    path: paths.pollingCenters,
                    icon: <Pyramid />
                }
            ]
        },
        {
            subheader: t('view.reports.header'),
            items: [
                {
                    title: t('view.reports.header'),
                    path: paths.allReports,
                    icon: <PieChart />,
                    items: [
                        {
                            actions: [ACTIONS.read],
                            resource: RESOURCES.d_reports,
                            title: t('view.reports.header'),
                            path: paths.reports,
                            icon: <BarChart3 />
                        },
                        {
                            actions: [ACTIONS.read],
                            resource: RESOURCES.d_operational,
                            title: t('view.reports.operation.header'),
                            path: paths.operationReports,
                            icon: <PieChart />
                        }
                    ]
                }
            ]
        },
        {
            subheader: t('view.nav.management'),
            items: [
                {
                    title: t('view.nav.management'),
                    path: paths.management,
                    icon: <Users />,
                    items: [
                        {
                            resource: RESOURCES.party,
                            actions: [ACTIONS.create, ACTIONS.update],
                            title: t('view.party.header'),
                            path: paths.parties,
                            icon: <Users />
                        },
                        {
                            resource: RESOURCES.node,
                            actions: [ACTIONS.ui, ACTIONS.create, ACTIONS.update],
                            title: t('view.node.header'),
                            path: paths.nodes,
                            icon: <Pyramid />
                        },
                        {
                            resource: RESOURCES.candidate,
                            actions: [ACTIONS.create, ACTIONS.update],
                            title: t('view.candidate.header'),
                            path: paths.candidates,
                            icon: <Users />
                        },
                        {
                            resource: RESOURCES.circle,
                            actions: [ACTIONS.create, ACTIONS.update],
                            title: t('view.circle.header'),
                            path: paths.circles,
                            icon: <CircleDot />
                        },
                        {
                            resource: RESOURCES.user,
                            actions: [ACTIONS.read],
                            title: t('view.user.header'),
                            path: paths.users,
                            icon: <Users />
                        },
                        {
                            resource: RESOURCES.sys_config,
                            actions: [ACTIONS.update],
                            title: t('view.systemConfiguration.header'),
                            path: paths.systemConfiguration,
                            icon: <Settings />
                        }
                    ]
                }
            ]
        }
    ]

    const filterItemsRecursively = (items) => {
        return items
            .map((item) => {
                // Recursively filter nested items if they exist
                const nestedItems = item.items ? filterItemsRecursively(item.items) : []

                // Check if the item itself is permitted
                const isPermitted =
                    !item.actions ||
                    !item.resource ||
                    (can({
                        actions: item.actions,
                        resource: item.resource
                    }) &&
                        !item.hidden)

                // If the item is permitted and has nested items or is permitted itself, process it
                if (isPermitted) {
                    // If there are no nested items after filtering, don't include the item
                    if (nestedItems.length === 0 && item.items) {
                        return null
                    }

                    // Otherwise, return the item, removing `items` if it's empty
                    return { ...item, items: nestedItems.length > 0 ? nestedItems : undefined }
                }

                // If the item itself is not permitted, check if it has nested items
                return nestedItems.length > 0 ? { ...item, items: nestedItems } : null
            })
            .filter((item) => item !== null)
    }

    // Filter sections based on permissions
    const filteredSections = sections
        .map((section) => ({
            ...section,
            items: filterItemsRecursively(section.items)
        }))
        .filter((section) => section.items.length > 0)

    return filteredSections
}
