import Divider from '@mui/material/Divider'
import Drawer from '@mui/material/Drawer'
import Skeleton from '@mui/material/Skeleton'
import Stack from '@mui/material/Stack'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import DetailsList, { DetailsListProps } from '../list/details-list'

interface Props {
    open: boolean
    close: () => void
    children?: any
    header?: string
    details?: DetailsListProps
    loading?: boolean
    width?: string
}

const SkeletonLoading = () => {
    return (
        <>
            <Skeleton variant="text" sx={{ width: '50%' }} />
            <Stack direction="row" spacing={2}>
                <Skeleton variant="text" sx={{ width: '50%' }} />
                <Skeleton variant="text" sx={{ width: '50%' }} />
            </Stack>

            <Skeleton variant="rectangular" height={60} />
        </>
    )
}

const MiniDrawer = ({ open, close, header, details, loading, width = '42vh', children }: Props) => {
    return (
        <Drawer
            open={open}
            anchor="right"
            variant="temporary"
            onClose={close}
            ModalProps={{
                keepMounted: true // Better open performance on mobile.
            }}
            PaperProps={{
                sx: {
                    borderRadius: 1,
                    mx: 2,
                    mt: 2,
                    height: '97%'
                }
            }}
        >
            <Box sx={{ width, zIndex: 120, borderRadius: 5, pb: 5 }}>
                <Toolbar
                    sx={{
                        position: 'sticky',
                        justifyContent: 'space-between'
                    }}
                >
                    <Typography variant="h6">{header}</Typography>
                    <IconButton onClick={close} aria-label="close" sx={{ mr: 1 }}>
                        <CloseIcon />
                    </IconButton>
                </Toolbar>
                <Divider />

                <Stack sx={{ px: 3, mt: 3 }} spacing={5}>
                    {loading ? <SkeletonLoading /> : details && <DetailsList details={details} />}
                    {children && !loading && <Box>{children}</Box>}
                </Stack>
            </Box>
        </Drawer>
    )
}

export default MiniDrawer
