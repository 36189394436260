import React from 'react'
import { Slide, type Breakpoint, type ButtonProps, type DialogProps } from '@mui/material'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import IconButton from '@mui/material/IconButton'

import LoadingButton from 'src/components/button/loading-button'
import CloseIcon from '@mui/icons-material/Close'
import { TransitionProps } from '@mui/material/transitions'

type ActionButton = {
    loading: boolean
    text: string
} & ButtonProps

type Props = {
    open: boolean
    handleClose: () => void
    title: string
    actionButtons?: ActionButton[]
    maxWidth?: Breakpoint
    children?: any
    bgColor?: string
    maxHeight?: number
    dense?: boolean
} & DialogProps

export const FullScreenTransition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />
})

const Modal = ({
    open,
    handleClose,
    title,
    actionButtons,
    maxWidth,
    children,
    bgColor,
    maxHeight,
    dense,
    ...rest
}: Props) => {
    return (
        <Dialog
            open={open}
            onClose={(_, reason) => {
                if (reason !== 'backdropClick') {
                    handleClose()
                }
            }}
            maxWidth={maxWidth ?? 'xl'}
            fullWidth={true}
            PaperProps={{ elevation: 0 }}
            {...rest}
            TransitionComponent={rest.fullScreen ? FullScreenTransition : undefined}
        >
            <DialogTitle
                sx={{
                    backgroundColor: 'background.default',
                    p: dense ? 0 : 2
                }}
            >
                <IconButton onClick={handleClose} aria-label="close" sx={{ mr: 1 }}>
                    <CloseIcon sx={{ color: 'text.primary' }} />
                </IconButton>
                {title}
            </DialogTitle>
            <div>
                <DialogContent style={{ backgroundColor: bgColor, maxHeight: maxHeight }} sx={{ py: dense ? 0 : 3 }}>
                    {children}
                </DialogContent>
                {actionButtons && (
                    <DialogActions>
                        {actionButtons.map((button, index) => (
                            // @ts-ignore:next-line
                            <LoadingButton key={index} {...button}>
                                {button.text}
                            </LoadingButton>
                        ))}
                    </DialogActions>
                )}
            </div>
        </Dialog>
    )
}

export default React.memo(Modal)
