/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateIncidentRequest } from '../models/CreateIncidentRequest';
import type { DetailedIncidentResponse } from '../models/DetailedIncidentResponse';
import type { IdResponse } from '../models/IdResponse';
import type { IncidentStatus } from '../models/IncidentStatus';
import type { PagedIncidentResponseListResponse } from '../models/PagedIncidentResponseListResponse';
import type { UpdateIncidentRequest } from '../models/UpdateIncidentRequest';
import type { UpdateIncidentStateRequest } from '../models/UpdateIncidentStateRequest';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class IncidentsService {
    /**
     * @returns PagedIncidentResponseListResponse OK
     * @throws ApiError
     */
    public static listIncidents({
        page,
        rowsPerPage,
        reason,
        stationId,
        ancestorId,
        status,
        sortAscending,
    }: {
        page: number,
        rowsPerPage: number,
        reason?: string,
        stationId?: string,
        ancestorId?: string,
        status?: IncidentStatus,
        sortAscending?: boolean,
    }): CancelablePromise<PagedIncidentResponseListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/incidents',
            query: {
                'Page': page,
                'Reason': reason,
                'StationId': stationId,
                'AncestorId': ancestorId,
                'Status': status,
                'SortAscending': sortAscending,
                'RowsPerPage': rowsPerPage,
            },
        });
    }
    /**
     * @returns IdResponse Created
     * @throws ApiError
     */
    public static createIncident({
        requestBody,
    }: {
        requestBody: CreateIncidentRequest,
    }): CancelablePromise<IdResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/incidents',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @returns void
     * @throws ApiError
     */
    public static updateIncident({
        id,
        requestBody,
    }: {
        id: string,
        requestBody: UpdateIncidentRequest,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/incidents/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @returns DetailedIncidentResponse OK
     * @throws ApiError
     */
    public static getIncidentById({
        id,
    }: {
        id: string,
    }): CancelablePromise<DetailedIncidentResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/incidents/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * @returns void
     * @throws ApiError
     */
    public static updateIncidentState({
        id,
        requestBody,
    }: {
        id: string,
        requestBody: UpdateIncidentStateRequest,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/v1/incidents/{id}/state',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
