import { useQuery } from '@tanstack/react-query'
import { FieldProps } from 'mui-rhf-library'
import { useCallback, useMemo } from 'react'
import { useFieldArray, UseFormReturn } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import CirclesMaxVotersInput from 'src/components/dashboard/stations/circles-input'
import { ListboxComponent } from 'src/components/input/virtualize-autocomplete'
import { allCirclesQuery } from 'src/queries/circles'
import { allNodesQuery } from 'src/queries/nodes'
import { ElectionType, NodeType } from 'src/services/api/emos'

export const filterOptionsWithLabelAndValue = (options, { inputValue }) => {
    return options.filter((option: { name: string; id: string }) => {
        return (
            option.name?.toLowerCase().includes(inputValue?.toLowerCase?.()) ||
            option.id?.toLowerCase().includes(inputValue?.toLowerCase?.())
        )
    })
}

export const useStations = ({ isEdit, formConfigs }: { isEdit?: boolean; formConfigs: UseFormReturn<any> }) => {
    const { t } = useTranslation()

    const { data: pollingCenters = [], isLoading } = useQuery({
        ...allNodesQuery({
            type: NodeType.POLLING_CENTER
        })
    })

    const { data: circles = [], isLoading: isLoadingCircles } = useQuery({
        ...allCirclesQuery()
    })

    const circlesFieldArray = useFieldArray({
        control: formConfigs.control,
        name: 'circles',
        keyName: 'key'
    })
    const { append, remove } = circlesFieldArray

    const selectedCircles = formConfigs.watch('circles', [])

    const handleAddCircle = useCallback(
        (valueArray) => {
            const valueFlatArray = valueArray.map((item) => (typeof item === 'string' ? item : item.id))

            selectedCircles.forEach((selectedCircle) => {
                if (!valueFlatArray.includes(selectedCircle.id)) {
                    remove(selectedCircles.indexOf(selectedCircle))
                }
            })

            valueFlatArray.forEach((value) => {
                if (!selectedCircles.find((selectedCircle) => selectedCircle.id === value)) {
                    append({ id: value })
                }
            })
        },
        [selectedCircles, remove, append]
    )

    const electionTypeWatcher = formConfigs.watch('electionType')
    const isVipWatcher = formConfigs.watch('isVip')

    const fields = useMemo((): FieldProps[] => {
        return [
            {
                name: 'pcId',
                label: t('model.node.type.options.PollingCenter'),
                fieldType: 'autocomplete',
                props: {
                    fullWidth: true,
                    options: pollingCenters,
                    optionValue: 'id',
                    optionLabel: 'name',
                    loading: isLoading,
                    disabled: isEdit,
                    ListboxComponent,
                    disableListWrap: true,
                    filterOptions: filterOptionsWithLabelAndValue,
                    renderOption: (props, option: any) => [props, `${option.name} (${option.code})`],
                    getOptionLabel: (option: any) => {
                        if (typeof option === 'object') {
                            return option.name
                        } else {
                            const pc = pollingCenters.find((availableOption) => availableOption.id === option)
                            return `${pc?.name} (${pc?.code})`
                        }
                    },
                    customOptionLabel: (option) => `${option?.name} (${option?.code})`,
                    onChange: (value) => {
                        formConfigs.setValue('electionType', value.electionType, { shouldDirty: true })
                        formConfigs.setValue('pcId', undefined, { shouldDirty: true })

                        formConfigs.setValue('circles', [])
                        formConfigs.setValue('circlesIds', [])
                    }
                },
                gridProps: {
                    xs: 12,
                    md: 6
                },
                textFieldProps: {
                    fullWidth: true,
                    label: t('model.node.type.options.PollingCenter')
                }
            },
            {
                name: 'number',
                label: t('model.common.number'),
                fieldType: 'textField',
                props: { fullWidth: true, type: 'number' },
                gridProps: { xs: 12, md: 6 }
            },
            {
                name: 'maxVoters',
                label: t('model.station.maxVoters'),
                fieldType: 'textField',
                props: { fullWidth: true, type: 'number' },
                gridProps: { xs: 12, md: 6 }
            },
            {
                hidden: electionTypeWatcher !== ElectionType.PUBLIC,
                name: 'isVip',
                label: t('model.station.isVip'),
                fieldType: 'switch',
                gridProps: { xs: 12, md: 6 },
                props: {
                    onChange: () => {
                        formConfigs.setValue('circlesIds', [])
                        formConfigs.setValue('circles', [])
                    }
                }
            },
            {
                hidden: electionTypeWatcher !== ElectionType.PRIVATE && !isVipWatcher,
                name: 'circlesIds',
                label: t('view.circle.name.headline'),
                fieldType: 'autocomplete',
                props: {
                    multiple: true,
                    fullWidth: true,
                    options: circles,
                    optionLabel: 'name',
                    optionValue: 'id',
                    loading: isLoadingCircles,
                    onChange: (value, e) => {
                        handleAddCircle(value)
                    }
                },
                gridProps: { xs: 12 },
                textFieldProps: {
                    label: t('view.circle.name.headline'),
                    fullWidth: true
                }
            },
            {
                hidden: electionTypeWatcher !== ElectionType.PRIVATE && !isVipWatcher,
                name: 'circlesMaxVoters',
                label: 'circlesMaxVoters',
                fieldType: 'custom',
                CustomComponent: CirclesMaxVotersInput,
                props: { formConfigs, circlesFieldArray }
            },
            {
                name: 'isActive',
                label: t('model.common.isActive'),
                fieldType: 'switch',
                gridProps: { xs: 12, md: 6 }
            }
        ]
    }, [
        t,
        pollingCenters,
        isLoading,
        isEdit,
        electionTypeWatcher,
        isVipWatcher,
        circles,
        isLoadingCircles,
        formConfigs,
        circlesFieldArray,
        handleAddCircle
    ])

    return { fields }
}
