import type { FC, ReactNode } from 'react'
import { createContext, useCallback, useEffect, useReducer } from 'react'
import { AuthService, DetailedUserResponse, LoginRequest, SelfService } from 'src/services/api/emos'

const STORAGE_KEY = 'accessToken'

interface State {
    isInitialized: boolean
    isAuthenticated: boolean
    user: DetailedUserResponse | null | undefined
    errorCode?: number
}

enum ActionType {
    INITIALIZE = 'INITIALIZE',
    SIGN_IN = 'SIGN_IN',
    SIGN_OUT = 'SIGN_OUT',
    VERSION_UP = 'VERSION_UP',
    CHANGE_LANGUAGE = 'CHANGE_LANGUAGE'
}

type InitializeAction = {
    type: ActionType.INITIALIZE
    payload: {
        isAuthenticated: boolean
        user: DetailedUserResponse | null
        errorCode?: number
    }
}

type SignInAction = {
    type: ActionType.SIGN_IN
    payload: {
        user: DetailedUserResponse
    }
}

type SignOutAction = {
    type: ActionType.SIGN_OUT
}

type VersionUpAction = {
    type: ActionType.VERSION_UP
}

type ChangeLanguageAction = {
    type: ActionType.CHANGE_LANGUAGE
    payload: {
        language: string
    }
}

type Action = InitializeAction | SignInAction | SignOutAction | VersionUpAction | ChangeLanguageAction

type Handler = (state: State, action: any) => State

const initialState: State = {
    isAuthenticated: false,
    isInitialized: false,
    user: null,
    errorCode: 0
}

const handlers: Record<ActionType, Handler> = {
    INITIALIZE: (state: State, action: InitializeAction): State => {
        const { isAuthenticated, user, errorCode } = action.payload

        return {
            ...state,
            isAuthenticated,
            isInitialized: true,
            user,
            errorCode
        }
    },
    SIGN_IN: (state: State, action: SignInAction): State => {
        const { user } = action.payload

        return {
            ...state,
            isAuthenticated: true,
            user
        }
    },

    SIGN_OUT: (state: State): State => {
        try {
            window.location.href = '/login'
        } catch (error) {
            console.error(error)
        }
        return {
            ...state,
            isAuthenticated: false,
            user: null
        }
    },
    VERSION_UP: (state: State): State => {
        return {
            ...state,
            user: {
                ...(state.user as DetailedUserResponse),
                version: state.user?.version ? state.user.version + 1 : 0
            }
        }
    },
    CHANGE_LANGUAGE: (state: State, action: ChangeLanguageAction): State => {
        return {
            ...state,
            user: {
                ...(state.user as DetailedUserResponse)
            }
        }
    }
}

const reducer = (state: State, action: Action): State =>
    handlers[action.type] ? handlers[action.type](state, action) : state

export interface AuthContextType extends State {
    signIn: (data: LoginRequest) => Promise<void>
    signOut: () => Promise<void>
    versionUp: () => Promise<void>
    changeLanguage: (language: string) => Promise<void>
}

export const AuthContext = createContext<AuthContextType>({
    ...initialState,
    signIn: (data) => Promise.resolve(),
    signOut: () => Promise.resolve(),
    versionUp: () => Promise.resolve(),
    changeLanguage: () => Promise.resolve()
})

interface AuthProviderProps {
    children: ReactNode
}

export const AuthProvider: FC<AuthProviderProps> = (props) => {
    const { children } = props
    const [state, dispatch] = useReducer(reducer, initialState)

    const initialize = useCallback(async (): Promise<void> => {
        try {
            const user: any = await SelfService.getSelf()

            if (user) {
                dispatch({
                    type: ActionType.INITIALIZE,
                    payload: {
                        isAuthenticated: true,
                        user,
                        errorCode: 0
                    }
                })
            } else {
                dispatch({
                    type: ActionType.INITIALIZE,
                    payload: {
                        isAuthenticated: false,
                        user: null
                    }
                })
            }
        } catch (err) {
            dispatch({
                type: ActionType.INITIALIZE,
                payload: {
                    isAuthenticated: false,
                    user: null,
                    errorCode: err.status
                }
            })
        }
    }, [dispatch])

    useEffect(
        () => {
            initialize()
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    )

    const signIn = useCallback(
        async (data): Promise<void> => {
            await AuthService.login({
                requestBody: data
            })

            const user = await SelfService.getSelf()

            console.log({ user })

            dispatch({
                type: ActionType.SIGN_IN,
                payload: {
                    user
                }
            })
        },
        [dispatch]
    )

    const signOut = useCallback(async (): Promise<void> => {
        await AuthService.logout()
        localStorage.removeItem(STORAGE_KEY)
        dispatch({ type: ActionType.SIGN_OUT })
    }, [dispatch])

    const versionUp = useCallback(async (): Promise<void> => {
        dispatch({ type: ActionType.VERSION_UP })
    }, [dispatch])

    const changeLanguage = useCallback(
        async (language: string): Promise<void> => {
            dispatch({
                type: ActionType.CHANGE_LANGUAGE,
                payload: {
                    language
                }
            })
        },
        [dispatch]
    )

    return (
        <AuthContext.Provider
            value={{
                ...state,
                signIn,
                signOut,
                versionUp,
                changeLanguage
            }}
        >
            {children}
        </AuthContext.Provider>
    )
}

export const AuthConsumer = AuthContext.Consumer
