import { AdvancedTable } from '@ditkrg/react-core'
import { Card } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import LayoutPage from 'src/components/page'
import UseNodesColumns from 'src/hooks/nodes/use-nodes-columns'
import { NodesService, NodeType } from 'src/services/api/emos'
import { useSettings } from 'src/store/settings'
import { usePollingCentersTableState } from 'src/store/table'
import { ROWS_PER_PAGE_OPTIONS } from 'src/utils/constants'

const PollingCenters = () => {
    const { t } = useTranslation()
    const { language } = useSettings()
    const { generateColumns } = UseNodesColumns({
        tableState: usePollingCentersTableState(),
        type: NodeType.POLLING_CENTER
    })

    const tableState = usePollingCentersTableState()
    const { page, rowsPerPage, filters } = tableState

    const {
        data: nodes,
        isLoading,
        isFetching
    } = useQuery({
        queryKey: ['pollingCenters', rowsPerPage, page, filters],
        queryFn: async () => {
            const res = await NodesService.listNodes({
                page: page + 1,
                rowsPerPage,
                ...filters,
                nodeType: NodeType.POLLING_CENTER,
                parentId:
                    filters?.[NodeType.POLLING_CENTER] ||
                    filters?.[NodeType.SUB_BRANCH] ||
                    filters?.[NodeType.BRANCH] ||
                    filters?.[NodeType.PBS]
            })
            return res
        },
        placeholderData: (placeholderData) => placeholderData
    })

    return (
        <LayoutPage
            title="Polling Centers"
            breadcrumbs={[
                {
                    title: t('model.node.type.options.PollingCenter')
                }
            ]}
        >
            <Card variant="outlined">
                <AdvancedTable
                    data={nodes?.data ?? []}
                    columns={generateColumns()}
                    state={tableState}
                    totalItems={nodes?.meta?.pagination?.count ?? 0}
                    loading={isLoading || isFetching}
                    loadingVariant={isFetching ? 'linear' : 'skeleton'}
                    filterLabel={t('view.common.filter.label')}
                    clearLabel={t('view.common.clear')}
                    paginationVariant="advanced"
                    rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
                    locale={language as any}
                />
            </Card>
        </LayoutPage>
    )
}

export default PollingCenters
