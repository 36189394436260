import dayjs from 'dayjs'

const generateTimeOfBreakage = (timeString: string) => {
    // check if timeString is date and not time only
    if (timeString.includes('GMT')) {
        return dayjs(timeString).toISOString()
    }

    // Get the current date
    const today = dayjs()
    // Combine the current date with the time string
    const dateTimeString = `${today.format('YYYY-MM-DD')}T${timeString}`
    // Create a dayjs object from the combined string
    const date = dayjs(dateTimeString)
    // Convert to ISO string
    return date.toISOString()
}

export { generateTimeOfBreakage }
