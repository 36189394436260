import { Model } from '../en/model'

export const model: Model = {
    user: {
        name: 'ناوی تەواو',
        password: 'وشەی نهێنی',
        currentPassword: 'وشەی نهێنیی ئێستا',
        newPassword: 'وشەی نهێنیی نوێ',
        passwordConfirmation: 'دووبارەکردنەوەی وشەی نهێنی',
        username: 'ناوی بەکارهێنەر',
        phoneNumber: 'ژمارەی مۆبایل',
        locale: 'زمان',
        roles: 'ڕۆڵەکان',
        image: 'وێنە',
        electionTypes: {
            label: 'جۆری دەنگدان',
            options: {
                Public: 'گشتی',
                Private: 'تایبەت'
            }
        },
        lastLogin: 'دوایین چوونە ژوورەوە'
    },
    party: {
        number: 'ژمارە',
        color: 'ڕەنگ',
        isSystem: 'سیستەمیە',
        partyType: {
            label: 'جۆری حزب',
            options: {
                Party: 'حزب',
                Coalition: 'هاوپەیمان',
                Independent: 'سەربەخۆ'
            }
        },
        seatType: {
            label: 'جۆری کورسی',
            options: {
                Majority: 'زۆرینە',
                Turkmen: 'تورکمانی',
                Chaldean: 'کلدانی',
                Minority: 'کەمینە'
            }
        },
        partyCandidateCount: 'کۆی دەنگی حزب لەگەڵ پاڵێوراو',
        candidateCount: 'کۆی دەنگی پاڵێوراوی حزب',
        totalVotes: 'کۆی دەنگدان'
    },
    circle: {
        majoritySeats: 'کورسییەکانی زۆرینە',
        minoritySeats: 'کورسییەکانی کەمینە'
    },
    node: {
        type: {
            label: 'جۆر',
            options: {
                HEC: 'کۆمسییۆنی سەربەخۆی باڵای هەڵبژاردن',
                EMR: 'ژووری بەڕێوەبردنی هەڵبژاردن',
                PBS: 'مەکتەب رێکخستن',
                Branch: 'لق',
                SubBranch: 'ناوچە',
                PollingCenter: 'بنکە'
            }
        },
        parent: 'باوان',
        province: 'پارێزگا',
        district: 'قەزا',
        subDistrict: 'ناحیە',
        address: 'ناونیشان',
        maxVoters: 'کۆی گشتی دەنگدەران لە بنکە',
        maxVotersMessage: 'دەنگدەرانی بەشداربوو دەبێت کەمتر یاخود هاوتای کۆی دەنگدەرانی بنکە بن',
        vrcId: 'VRC',
        middayVoters: 'دەنگدەرانی نیوەی ڕۆژ',
        middayVotersRecordedAt: 'ڕێکەوتی تۆمارکردنی دەنگدەرانی نیوەی ڕۆژ',
        pollingCenterCode: 'ژمارەی ناوەندی دەنگدان',
        pollingCenterName: 'ناوی ناوەندی دەنگدان'
    },
    systemConfiguration: {
        restrictAgentLoginToActiveDuration: 'Restrict Agent Login To Active Duration',
        respectTimeLimits: 'ڕەچاوکردنی کاتە دیاریکراوەکان',
        privateElectionDay: 'ڕۆژی هەڵبژاردنی تایبەت',
        publicElectionDay: 'ڕۆژی هەڵبژاردنی گشتی',
        activeDuration: 'ماوەی چالاک',
        morningDuration: 'ماوەی بەیانی',
        afternoonDuration: 'ماوەی دوانیوەڕۆ',
        eveningDuration: 'ماوەی ئێوارە',
        common: {
            min: 'کەمترین',
            max: 'زۆرترین'
        }
    },
    incident: {
        reason: {
            label: 'هۆکار',
            options: {
                hot_ethernet_cable: 'کێبلی گەرم',
                inexperience_of_commission_employees: 'ناشارەزایی کارمەندانی کۆمسیۆن',
                database_error: 'کێشەی داتابەیس و نەخوێندنەوەی کارت',
                other: 'هیتر',
                pcos_device: 'کارنەکردنی ئامێری PCOS',
                rts_device: 'کارنەکردنی ئامێری RTS',
                vvd_device: 'کارنەکردنی ئامێری VVD'
            }
        },
        timeOfBreakage: 'کاتی ڕووداو',
        description: 'وەسف',
        commands: {
            resolve: 'چارەسەرکردن',
            reopen: 'کردنەوە'
        },
        states: {
            Open: 'کراوە',
            Resolved: 'چارەسەرکراو'
        }
    },
    station: {
        maxVoters: 'کۆی گشتی دەنگدەران لە وێستگە',
        initiationStatus: 'دۆخی دەستپێکردن',
        currentStatus: 'دۆخی ئێستا',
        operationType: 'جۆری کارکردن',
        states: {
            Pending: 'چاوەڕوان',
            Operational: 'کارایە',
            NonOperational: 'ناکارایە'
        },
        voters: 'دەنگدەرانی بەشداربوو',
        middayVoters: 'دەنگدەرانی نیوەی ڕۆژ',
        middayVotersRecordedAt: 'ڕێکەوتی تۆمارکردنی دەنگدەرانی نیوەی ڕۆژ',
        initiatedAt: 'ڕێکەوت و کاتی کردنەوەی وێستگە',
        isInitiated: 'دەستبەکارخراوە',
        isNotInitiated: 'دەستبەکارنەخراوە',
        hasIncidents: 'ڕووداوی هەیە',
        number: 'ژمارەی وێستگەی دەنگدان',
        isVip: "VIP'ە",
        invalidPapersDiff: 'جیاوازی دەنگی پووچەڵ',
        validVotesDiff: 'جیاوازی دەنگی دروست',
        Undefined: 'نەناسراو',
        NoDiff: 'جیاوازی نییە',
        LowDiff: 'جیاوازی کەم',
        HighDiff: 'جیاوازی زۆر'
    },
    violation: {
        type: {
            label: 'جۆر',
            options: {
                SystemPartyAgainstOtherParty: 'حزبی سیستەم لەدژی لایەنێکی دیکە',
                OtherPartyAgainstSystemParty: 'حزبی دیکە دژی حزبی سیستەم',
                OtherPartyAgainstOtherParty: 'حزبی دیکە دژی حزبی دیکە'
            }
        },
        complainant: {
            label: 'سکاڵاکار',
            name: 'ناوی سکاڵاکار',
            type: {
                label: 'جۆری سکاڵا',
                options: {
                    Voter: 'دەنگدەر',
                    Agent: 'بریکار',
                    Candidate: 'کاندید'
                }
            },
            partyId: 'حزبی سکاڵاکار'
        },
        allegedViolator: {
            label: 'سکاڵا لێکراو',
            name: 'ناوی سکاڵا لێکراو',
            type: {
                label: 'جۆری سکاڵا لێکراو',
                options: {
                    Unknown: 'نەزانراو',
                    CommissionEmployee: 'کارمەندی کۆمسیۆن',
                    Commission: 'کۆمسیۆن',
                    Candidate: 'پاڵێوراوی حزب',
                    SecurityMember: 'ئەندامی ئاسایش',
                    PoliticalParty: 'لایەنی سیاسی'
                }
            },
            partyId: 'حزبی سکاڵا لێکراو'
        },
        states: {
            open: 'کراوە',
            reported: 'ڕاپۆرت کراوە',
            submitted: 'پێشکەشکراوە',
            dropped: 'داخراوە'
        },
        commands: {
            drop: 'داخستن',
            reopen: 'کردنەوە',
            submit: 'پێشکەشکردن'
        }
    },
    votingResult: {
        invalidPapers: 'کۆی پەڕاوی دەنگدانی پووچەڵ',
        receiptPhoto: 'وێنەی وەسڵ',
        formType: {
            label: 'جۆری فۆرم',
            options: {
                Automatic: 'ئەلیکترۆنی',
                Manual: 'دەستی',
                Final: 'کۆتایی'
            }
        },
        commands: {
            Submit: 'پێشکەشکردن',
            Draft: 'دەستکاریکردن'
        },
        status: {
            Draft: 'دەستکاریکردن',
            Submitted: 'پێشکەشکراوە'
        }
    },
    common: {
        id: 'ID',
        name: 'ناو',
        status: 'دۆخ',
        createdAt: 'ڕێکەوتی دروستکردن',
        code: 'کۆد',
        isActive: 'چالاکە',
        photo: 'وێنە',
        number: 'ژمارە',
        gender: {
            label: 'ڕەگەز',
            options: {
                male: 'نێر',
                female: 'مێ'
            }
        },
        comment: 'سەرنج',
        attachment: 'هاوپێچ',
        count: 'ژماردن',
        updatedAt: 'ڕێکەوتی نوێکردنەوە',
        ancestorId: 'باوباپیران',
        lastComment: 'دوایین سەرنج',
        updatedBy: 'نوێکراوەتەوە لەلایەن',
        createdBy: 'دروستکراوە لەلایەن'
    }
}
