import React from 'react'
import { useTranslation } from 'react-i18next'
import { useToast } from 'src/hooks/useToast'
import { AlertDialog } from 'src/components/dialog/alert-dialog'
import { DetailedVotingResultResponse, VotingResultCommand, VotingResultsService } from 'src/services/api/emos'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useVirtualItemsKey } from 'src/contexts/virtual-items-key'
import { Alert } from '@mui/material'

function UpdateStatusDialog({
    votingResult,
    dialogStatus,
    setDialog,
    command
}: Readonly<{
    votingResult: DetailedVotingResultResponse
    dialogStatus: boolean
    setDialog: (status: boolean) => void
    command: VotingResultCommand
}>) {
    const [t] = useTranslation()
    const { errorsToast, successToast } = useToast()

    const queryClient = useQueryClient()
    const { incrementKey } = useVirtualItemsKey()

    const { mutate, isPending, error, isError } = useMutation({
        mutationKey: ['updateVotingResultStatus'],
        mutationFn: async (command: VotingResultCommand) => {
            return await VotingResultsService.updateVotingResultState({
                id: votingResult.id,
                requestBody: {
                    command
                }
            })
        },
        onSuccess: () => {
            successToast(t(`view.common.actions.status.success`, { name: t('view.votingResult.name.headline') }))
            setDialog(false)
            queryClient.invalidateQueries({
                queryKey: ['votingResult', votingResult.id]
            })
            incrementKey()
        },
        onError: (error) => {
            errorsToast(error)
        }
    })

    // @ts-ignore:next-line
    const errors = error?.body?.errors

    return (
        <AlertDialog
            open={dialogStatus}
            title={t(`view.votingResult.command.${command}.action.title` as any)}
            description={t(`view.votingResult.command.${command}.action.confirmation` as any, {
                name: t(`view.votingResult.name.headline` as any)
            })}
            handleClose={() => setDialog(false)}
            handleSubmit={() => mutate(command)}
            loading={isPending}
            submitText={t(`view.votingResult.command.${command}.action.title` as any)}
        >
            {isError &&
                errors.map((e, index) => (
                    <Alert key={index} severity="error">
                        {t(`view.errors.${e.code}` as any)}
                    </Alert>
                ))}
        </AlertDialog>
    )
}

export default UpdateStatusDialog
