import * as yup from 'yup';

const ar: yup.LocaleObject = {
    mixed: {
        default: 'هذا الحقل غير صحيح',
        required: 'الحقل مطلوب',
        defined: 'الحقل مطلوب',
        notType: function notType(_ref: any) {
            switch (_ref.type) {
                case 'number':
                    return 'يجب أن يكون الحقل رقمًا';
                case 'string':
                    return 'يجب أن يكون الحقل نصًا';
                default:
                    return 'هذا الحقل غير صحيح';
            }
        },
        oneOf: 'خيار غير صالح',
    },

    string: {
        min: 'يجب ألا يقل طول الحقل عن ${min} حرفًا',
        max: 'يجب أن يتألف الحقل من ${max} من الأحرف على الأكثر',
        email: 'يجب أن يكون الحقل بريدًا إلكترونيًا صالحًا',
    },

    number: {
        min: (m: any) => `يجب أن يكون الحقل ${m?.min?.toLocaleString()} على الأقل`,
        max: (m: any) => `يجب ألا يتجاوز الحقل ${m?.max?.toLocaleString()}`,
        lessThan: 'يجب أن يكون الحقل أقل من ${less}',
        moreThan: 'يجب أن يكون الحقل أكبر من ${more}',
        positive: 'يجب أن يكون الحقل رقمًا موجبًا',
        negative: 'يجب أن يكون الحقل رقمًا سالبًا',
        integer: 'يجب أن يكون الحقل رقمًا',
    },

    array: {
        min: 'يجب أن يكون الحقل ${min} على الأقل',
        max: 'يجب ألا يتجاوز الحقل ${max}',
    },
};

export default ar;
