import { createRoot } from 'react-dom/client';
import React, { Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';

import { App } from 'src/app';

const root = createRoot(document.getElementById('root') as HTMLElement);

root.render(
    <React.StrictMode>
        <HelmetProvider>
            <BrowserRouter>
                <Suspense>
                    <App />
                </Suspense>
            </BrowserRouter>
        </HelmetProvider>
    </React.StrictMode>
);
