import { useQuery } from '@tanstack/react-query'
import { FieldProps } from 'mui-rhf-library'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { allCirclesQuery } from 'src/queries/circles'
import { allPartiesQuery } from 'src/queries/parties'
import UploadForm from 'src/components/upload/upload-form'
import { UseFormReturn } from 'react-hook-form'
import { CandidateGender } from 'src/services/api/emos'

export const useCandidates = (formConfigs: UseFormReturn<any>) => {
    const { t } = useTranslation()

    const { data: parties = [], isLoading } = useQuery({
        ...allPartiesQuery({})
    })

    const { data: circles = [], isLoading: isLoadingCircles } = useQuery({
        ...allCirclesQuery()
    })

    const fields = useMemo(
        (): FieldProps[] => [
            {
                name: 'name',
                label: t('model.common.name'),
                fieldType: 'textField',
                props: { fullWidth: true },
                gridProps: { xs: 12 }
            },
            {
                name: 'number',
                label: t('model.common.number'),
                fieldType: 'textField',
                props: { fullWidth: true, type: 'number' },
                gridProps: { xs: 12 }
            },
            {
                name: 'partyId',
                fieldType: 'autocomplete',
                props: {
                    fullWidth: true,
                    optionLabel: 'name',
                    options: parties,
                    loading: isLoading,
                    optionValue: 'id'
                },

                gridProps: { xs: 12 },
                textFieldProps: {
                    fullWidth: true,
                    label: t('view.party.name.headline')
                }
            },
            {
                name: 'circleId',
                fieldType: 'autocomplete',
                props: {
                    fullWidth: true,
                    options: circles,
                    loading: isLoadingCircles,
                    optionLabel: 'name',
                    optionValue: 'id'
                },
                gridProps: { xs: 12 },
                textFieldProps: {
                    fullWidth: true,
                    label: t('view.circle.name.headline')
                }
            },
            {
                name: 'gender',
                label: t('model.common.gender.label'),
                fieldType: 'select',
                props: {
                    fullWidth: true,
                    options: Object.values(CandidateGender).map((value) => ({
                        value,
                        label: t(`model.common.gender.options.${value.toLowerCase()}` as any)
                    }))
                },
                gridProps: { xs: 12 }
            },
            {
                name: 'photo',
                fieldType: 'custom',
                CustomComponent: UploadForm,
                props: {
                    header: t('model.common.photo'),
                    name: 'photo',
                    formConfigs,
                    maxFiles: 1
                }
            },
            {
                name: 'isActive',
                label: t('model.common.isActive'),
                fieldType: 'switch',
                gridProps: { xs: 12 }
            }
        ],
        [t, parties, isLoading, circles, isLoadingCircles, formConfigs]
    )

    return { fields }
}
