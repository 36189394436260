import { Grid } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import HighPieChart from 'src/components/dashboard/reports/highcharts/pie-chart'
import { DashboardsService, ElectionType, NodeType, VotingResultFormType } from 'src/services/api/emos'
import { useReportsFilters } from 'src/store/reports'
import ReportCard from './report-card'
import { REFETCH_INTERVAL } from './constants'

const VoterValidityDistribution = ({ enabledQueries }: { enabledQueries: boolean }) => {
    const { t } = useTranslation()
    const state = useReportsFilters()
    const { filters } = state

    const { data: voterValidityDistribution = [] } = useQuery({
        queryKey: ['voterValidityDistribution', filters],
        queryFn: async () => {
            const res = await DashboardsService.voterValidityDistribution({
                circleId: filters?.circle as string,
                ancestorId:
                    filters?.[NodeType.POLLING_CENTER] ||
                    filters?.[NodeType.SUB_BRANCH] ||
                    filters?.[NodeType.BRANCH] ||
                    filters?.[NodeType.PBS],
                electionType: filters?.electionType as ElectionType,
                formType: filters?.formType as VotingResultFormType
            })
            return [
                {
                    y: res.validVotes,
                    name: t('view.reports.validVotes'),
                    color: '#BCE1A5'
                },
                {
                    y: res.invalidPapers,
                    name: t('view.reports.invalidVotes'),
                    color: '#f87171'
                }
            ]
        },
        enabled: enabledQueries,
        refetchInterval: REFETCH_INTERVAL
    })

    return (
        <Grid item xs={12} sm={6} md={12}>
            <ReportCard title={t('view.reports.voterValidityDistribution')}>
                <HighPieChart dataset={voterValidityDistribution} />
            </ReportCard>
        </Grid>
    )
}

export default VoterValidityDistribution
