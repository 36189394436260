import { Alert } from '@mui/material'
import { useTranslation } from 'react-i18next'

const ReportAlert = () => {
    const { t } = useTranslation()
    return (
        <Alert icon={false} color="warning">
            {t('view.reports.disclaimer')}
        </Alert>
    )
}

export default ReportAlert
