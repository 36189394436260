import type { FC } from 'react'
import Tooltip from '@mui/material/Tooltip'
import { usePopover } from 'src/hooks/use-popover'
import { LanguagePopover } from './language-popover'
import { Globe } from 'lucide-react'
import { IconButton } from '@mui/material'
import { useTranslation } from 'react-i18next'

export const LanguageSwitch: FC = () => {
    const popover = usePopover<HTMLButtonElement>()
    const { t } = useTranslation()

    return (
        <>
            <Tooltip title={t('view.languages.label')}>
                <IconButton
                    onClick={popover.handleOpen}
                    ref={popover.anchorRef}
                    size="small"
                    sx={{ color: 'text.secondary' }}
                >
                    <Globe size={20} />
                </IconButton>
            </Tooltip>
            <LanguagePopover anchorEl={popover.anchorRef.current} onClose={popover.handleClose} open={popover.open} />
        </>
    )
}
