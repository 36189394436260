import { Card, CardContent } from '@mui/material'
import { useTranslation } from 'react-i18next'
import Create from 'src/components/dashboard/violations/create'
import LayoutPage from 'src/components/page'
import { useRouter } from 'src/hooks/use-router'
import { paths } from 'src/paths'

const New = () => {
    const { t } = useTranslation()
    const router = useRouter()
    return (
        <LayoutPage
            title="Violation"
            breadcrumbs={[
                {
                    title: t('view.violation.header'),
                    href: paths.violations.index
                },
                {
                    title: t('view.violation.new.headline')
                }
            ]}
        >
            <Card variant="outlined">
                <CardContent>
                    <Create
                        handleSuccess={() => router.push(paths.violations.index)}
                        handleClose={() => router.push(paths.violations.index)}
                    />
                </CardContent>
            </Card>
        </LayoutPage>
    )
}

export default New
