import { useQuery } from '@tanstack/react-query'
import { FieldProps } from 'mui-rhf-library'
import { useMemo } from 'react'
import { UseFormReturn } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import StationListInput from 'src/components/dashboard/stations/list-input'
import SectionDivider from 'src/components/input/section-divider'
import { allPartiesQuery } from 'src/queries/parties'
import { allStationsQuery } from 'src/queries/stations'
import { AllegedViolatorType, ComplainantType, ViolationType } from 'src/services/api/emos'
import { useFilterOptions } from '../use-filter-options'

export const useViolations = ({ formConfigs, isEdit }: { formConfigs: UseFormReturn<any>; isEdit?: boolean }) => {
    const { t } = useTranslation()

    const { data: stations = [], isLoading: isLoadingStation } = useQuery({
        ...allStationsQuery({})
    })

    const { data: parties = [], isLoading } = useQuery({
        ...allPartiesQuery({})
    })

    const allegedViolatorTypeWatcher = formConfigs.watch('allegedViolator.type')
    const typeWatcher = formConfigs.watch('type')

    const { filterPartiesOptions } = useFilterOptions()

    const fields = useMemo((): FieldProps[] => {
        return [
            {
                name: 'stationId',
                label: t('view.station.header'),
                fieldType: 'custom',
                CustomComponent: StationListInput,
                props: {
                    formConfigs,
                    stations,
                    isEdit,
                    loading: isLoadingStation
                }
            },
            {
                name: 'divider',
                fieldType: 'custom',
                CustomComponent: SectionDivider,
                props: {
                    header: t('view.violation.detail')
                }
            },
            {
                name: 'description',
                label: t('model.incident.description'),
                fieldType: 'textField',
                props: {
                    fullWidth: true
                },
                gridProps: {
                    xs: 12,
                    sm: 6,
                    md: 6
                }
            },
            {
                name: 'type',
                label: t('model.violation.type.label'),
                fieldType: 'select',
                props: {
                    disabled: isEdit,
                    fullWidth: true,
                    options: Object.values(ViolationType).map((type) => ({
                        value: type,
                        label: t(`model.violation.type.options.${type}` as any)
                    })),
                    onChange: (option) => {
                        if (option.target?.value === ViolationType.SYSTEM_PARTY_AGAINST_OTHER_PARTY) {
                            formConfigs.setValue('complainant.partyId', parties.find((party) => party.isSystem)?.id)
                            formConfigs.setValue('allegedViolator.partyId', null)
                        } else if (
                            option.target?.value === ViolationType.OTHER_PARTY_AGAINST_SYSTEM_PARTY &&
                            allegedViolatorTypeWatcher === AllegedViolatorType.POLITICAL_PARTY
                        ) {
                            formConfigs.setValue('allegedViolator.partyId', parties.find((party) => party.isSystem)?.id)
                        } else {
                            formConfigs.setValue('complainant.partyId', null)
                            formConfigs.setValue('allegedViolator.partyId', null)
                        }
                    }
                },
                gridProps: {
                    xs: 12,
                    sm: 6,
                    md: 6
                }
            },
            {
                name: 'divider',
                fieldType: 'custom',
                CustomComponent: SectionDivider,
                props: {
                    header: t('model.violation.complainant.label')
                }
            },
            {
                name: 'complainant.name',
                label: t('model.violation.complainant.name'),
                fieldType: 'textField',
                props: {
                    fullWidth: true
                },
                gridProps: {
                    xs: 12,
                    sm: 6,
                    md: 6
                }
            },
            {
                name: 'complainant.type',
                label: t('model.violation.complainant.type.label'),
                fieldType: 'autocomplete',
                props: {
                    fullWidth: true,
                    options: Object.values(ComplainantType).map((type) => ({
                        value: type,
                        label: t(`model.violation.complainant.type.options.${type}` as any)
                    }))
                },
                gridProps: {
                    xs: 12,
                    sm: 6,
                    md: 6
                },
                textFieldProps: {
                    label: t('model.violation.complainant.type.label'),
                    fullWidth: true
                }
            },
            {
                name: 'complainant.partyId',
                label: t('model.violation.complainant.partyId'),
                fieldType: 'autocomplete',
                props: {
                    optionLabel: 'name',
                    options: parties,
                    loading: isLoading,
                    optionValue: 'id',
                    filterOptions: filterPartiesOptions,
                    disabled: typeWatcher === ViolationType.SYSTEM_PARTY_AGAINST_OTHER_PARTY
                },
                gridProps: {
                    xs: 12,
                    sm: 6,
                    md: 6
                },
                textFieldProps: {
                    label: t('model.violation.complainant.partyId'),
                    fullWidth: true
                }
            },
            {
                name: 'divider',
                fieldType: 'custom',
                CustomComponent: SectionDivider,
                props: {
                    header: t('model.violation.allegedViolator.label')
                }
            },
            {
                name: 'allegedViolator.name',
                label: t('model.violation.allegedViolator.name'),
                fieldType: 'textField',
                props: {
                    fullWidth: true
                },
                gridProps: {
                    xs: 12,
                    sm: 6,
                    md: 6
                }
            },
            {
                name: 'allegedViolator.type',
                label: t('model.violation.allegedViolator.type.label'),
                fieldType: 'select',
                props: {
                    fullWidth: true,
                    options: Object.values(AllegedViolatorType).map((type) => ({
                        value: type,
                        label: t(`model.violation.allegedViolator.type.options.${type}` as any)
                    })),
                    onChange: (option) => {
                        if (
                            option.target?.value === AllegedViolatorType.POLITICAL_PARTY &&
                            typeWatcher === ViolationType.OTHER_PARTY_AGAINST_SYSTEM_PARTY
                        ) {
                            formConfigs.setValue('allegedViolator.partyId', parties.find((party) => party.isSystem)?.id)
                        } else {
                            formConfigs.setValue('allegedViolator.partyId', null)
                        }
                    }
                },
                gridProps: {
                    xs: 12,
                    sm: 6,
                    md: 6
                }
            },
            {
                hidden: allegedViolatorTypeWatcher !== AllegedViolatorType.POLITICAL_PARTY,
                name: 'allegedViolator.partyId',
                label: t('model.violation.allegedViolator.partyId'),
                fieldType: 'autocomplete',
                props: {
                    disabled:
                        allegedViolatorTypeWatcher === AllegedViolatorType.POLITICAL_PARTY &&
                        typeWatcher === ViolationType.OTHER_PARTY_AGAINST_SYSTEM_PARTY,
                    optionLabel: 'name',
                    options: parties,
                    loading: isLoading,
                    optionValue: 'id',
                    filterOptions: filterPartiesOptions
                },
                gridProps: {
                    xs: 12,
                    sm: 6,
                    md: 6
                },
                textFieldProps: {
                    label: t('model.violation.allegedViolator.partyId'),
                    fullWidth: true
                }
            }
        ]
    }, [
        allegedViolatorTypeWatcher,
        filterPartiesOptions,
        formConfigs,
        isEdit,
        isLoading,
        isLoadingStation,
        parties,
        stations,
        t,
        typeWatcher
    ])

    return { fields }
}
