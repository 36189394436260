import React, { FC, useEffect, type JSX } from 'react'
import { Seo } from './seo'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import { useSettings } from 'src/store/settings'
import { Button, ButtonProps, Grid, SvgIcon } from '@mui/material'
import { Breadcrumb, useBreadcrumb } from 'src/contexts/breadcrumb-context'

type Action = ButtonProps & {
    hidden?: boolean
}
interface PageProps {
    title?: string
    children: JSX.Element | JSX.Element[]
    breadcrumbs?: Breadcrumb[]
    prefixActions?: Action[] | any[]
    actions?: Action[] | any[]
    actionsContainerProps?: any
    maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl'
}

const LayoutPage: FC<PageProps> = ({
    children,
    title,
    breadcrumbs,
    prefixActions,
    actions,
    actionsContainerProps,
    maxWidth = 'xl'
}) => {
    const settings = useSettings()

    const { setBreadcrumb } = useBreadcrumb()

    useEffect(() => {
        setBreadcrumb(breadcrumbs ?? [])
        return () => {
            setBreadcrumb([])
        }
    }, [breadcrumbs, setBreadcrumb])

    const content = (
        <Container maxWidth={settings.stretch ? false : maxWidth}>
            <Seo title={title} />
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    py: 2
                }}
            >
                <Grid
                    container
                    gap={1}
                    mb={1}
                    justifyContent={prefixActions?.length ? 'space-between' : 'flex-end'}
                    alignItems="center"
                    id="actions"
                    {...actionsContainerProps}
                >
                    <Grid item xs={3} sm={4} container spacing={1}>
                        {prefixActions
                            ?.filter((a) => a)
                            ?.filter((a) => !a.hidden)
                            .map((action, index) => {
                                return (
                                    <Grid item key={index}>
                                        {action.component ? (
                                            <React.Fragment>{action.component}</React.Fragment>
                                        ) : (
                                            <Button
                                                variant={action.variant || 'contained'}
                                                onClick={action.onClick}
                                                disabled={action.disabled}
                                                color={action.color || 'primary'}
                                                startIcon={action.startIcon}
                                                size={action.size || 'medium'}
                                            >
                                                {action.label}
                                                {action.icon ? <SvgIcon fontSize="small">{action.icon}</SvgIcon> : null}
                                            </Button>
                                        )}
                                    </Grid>
                                )
                            })}
                    </Grid>

                    <Grid item xs={8} sm={7} container justifyContent="flex-end" gap={1}>
                        {actions
                            ?.filter((a) => a)
                            ?.filter((a) => !a.hidden)
                            .map((action, index) => {
                                return (
                                    <Grid item key={index}>
                                        {action.component ? (
                                            <React.Fragment>{action.component}</React.Fragment>
                                        ) : (
                                            <Button
                                                variant={action.variant || 'contained'}
                                                onClick={action.onClick}
                                                disabled={action.disabled}
                                                color={action.color || 'primary'}
                                                startIcon={action.startIcon}
                                                size={action.size || 'medium'}
                                            >
                                                {action.label}
                                                {action.icon ? <SvgIcon fontSize="small">{action.icon}</SvgIcon> : null}
                                            </Button>
                                        )}
                                    </Grid>
                                )
                            })}
                    </Grid>
                </Grid>
                {children}
            </Box>
        </Container>
    )

    return content
}

export default LayoutPage
