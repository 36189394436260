import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useToast } from 'src/hooks/useToast'
import {
    DetailedViolationResponse,
    ViolationCommandViolationStatusViolationMetaTransitionResponse,
    ViolationsService
} from 'src/services/api/emos'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import FormDialog from 'src/components/dialog/form-dialog'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { transitionSchema } from './schema'
import { FieldProps, FormFields } from 'mui-rhf-library'
import { Grid } from '@mui/material'
import UploadForm from 'src/components/upload/upload-form'
import { filterObject } from 'src/utils/filter-object'
import { updateViolationStateAttributesToKeep } from './constants'

function UpdateStatusDialog({
    violation,
    dialogStatus,
    setDialog,
    transition
}: Readonly<{
    violation: DetailedViolationResponse
    dialogStatus: boolean
    setDialog: (status: boolean) => void
    transition: ViolationCommandViolationStatusViolationMetaTransitionResponse
}>) {
    const [t] = useTranslation()
    const { errorsToast, successToast } = useToast()

    const queryClient = useQueryClient()

    const form = useForm<any>({
        resolver: yupResolver(transitionSchema({ t, transition })),
        defaultValues: {
            attachment: []
        }
    })
    const { handleSubmit, control } = form

    const { mutate, isPending } = useMutation({
        mutationKey: ['updateViolationStatus'],
        mutationFn: async (data: any) => {
            return await ViolationsService.updateViolationState({
                id: violation.id,
                requestBody: {
                    command: transition.command,
                    ...data,
                    attachment: transition.meta?.requiresAttachment ? data.attachment[0].secret : undefined
                }
            })
        },
        onSuccess: () => {
            successToast(t(`view.common.actions.status.success`, { name: t('view.violation.name.headline') }))
            setDialog(false)
            queryClient.invalidateQueries({ queryKey: ['violation'] })
        },
        onError: (error) => {
            errorsToast(error)
        }
    })

    const handleSubmitTransition = (data) => {
        const filteredData = filterObject({
            obj: data,
            keys: updateViolationStateAttributesToKeep
        }) as any

        mutate(filteredData)
    }

    const fields: FieldProps[] = useMemo(() => {
        return [
            {
                hidden: !transition.meta?.requiresAttachment,
                name: 'attachment',
                fieldType: 'custom',
                CustomComponent: UploadForm,
                props: {
                    header: t('model.common.attachment'),
                    name: 'attachment',
                    formConfigs: form,
                    maxFiles: 1
                }
            },
            {
                name: 'comment',
                fieldType: 'textField',
                label: t('model.common.comment'),
                gridProps: { xs: 12 },
                props: {
                    fullWidth: true,
                    autoComplete: 'off'
                }
            }
        ]
    }, [form, t, transition.meta?.requiresAttachment])

    return (
        <FormDialog
            formDialogStatus={dialogStatus}
            title={t(`view.violation.command.${transition.command.toLocaleLowerCase()}.action.title` as any)}
            contentText={t(
                `view.violation.command.${transition.command.toLocaleLowerCase()}.action.confirmation` as any,
                {
                    name: t(`view.violation.name.headline` as any)
                }
            )}
            handleClose={() => setDialog(false)}
            handleSubmit={handleSubmit}
            handleFormSubmit={handleSubmitTransition}
            loading={isPending}
            maxWidth="sm"
        >
            <Grid container spacing={2}>
                <FormFields fields={fields} control={control} />
            </Grid>
        </FormDialog>
    )
}

export default UpdateStatusDialog
