import { yupResolver } from '@hookform/resolvers/yup'
import { useState } from 'react'
import { createSchema } from './schema'
import { DetailedPartyResponse, PartiesService } from 'src/services/api/emos'
import { SubmitHandler, useForm } from 'react-hook-form'
import { Grid } from '@mui/material'
import { FormFields } from 'mui-rhf-library'
import { useParties } from 'src/hooks/use-parties'
import FormActions from 'src/components/drawer/form-actions'
import { useToast } from 'src/hooks/useToast'
import { filterObject } from 'src/utils/filter-object'
import { updatePartyAttributesToKeep } from './constants'

const Edit = ({
    party,
    handleSuccess,
    handleClose
}: {
    party: DetailedPartyResponse
    handleSuccess: () => void
    handleClose: () => void
}) => {
    const [btnLoading, setBtnLoading] = useState(false)
    const { errorsToast } = useToast()

    const formConfigs = useForm({
        resolver: yupResolver(createSchema()),
        defaultValues: {
            ...party,
            photo: party.photo ? [{ url: party.photo?.url }] : []
        }
    })
    const {
        handleSubmit,
        control,
        formState: { isDirty }
    } = formConfigs

    const { fields } = useParties(formConfigs)

    const handleFormSubmit: SubmitHandler<any> = async (formData) => {
        setBtnLoading(true)

        const filteredData = filterObject({
            obj: formData,
            keys: updatePartyAttributesToKeep
        }) as any

        try {
            await PartiesService.updateParty({
                id: party.id,
                requestBody: { ...filteredData, photo: filteredData.photo[0]?.secret || '', version: party.version }
            })
            handleSuccess()
        } catch (err) {
            errorsToast(err)
        } finally {
            setBtnLoading(false)
        }
    }

    return (
        <form onSubmit={handleSubmit(handleFormSubmit)}>
            <Grid container spacing={2}>
                <FormFields fields={fields} control={control} />
            </Grid>
            <FormActions
                disabled={!isDirty}
                handleClose={handleClose}
                handleSubmit={handleSubmit(handleFormSubmit)}
                loading={btnLoading}
            />
        </form>
    )
}

export default Edit
