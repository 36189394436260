/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SystemConfig } from '../models/SystemConfig';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class SystemConfigService {
    /**
     * @returns SystemConfig OK
     * @throws ApiError
     */
    public static getSystemConfig(): CancelablePromise<SystemConfig> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/system-config',
        });
    }
    /**
     * @returns void
     * @throws ApiError
     */
    public static updateSystemConfig({
        requestBody,
    }: {
        requestBody: SystemConfig,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/v1/system-config',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
