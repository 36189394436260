/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AuditResponse } from '../models/AuditResponse';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class AuditsService {
    /**
     * @returns AuditResponse OK
     * @throws ApiError
     */
    public static listAuditsForResource({
        type,
        id,
    }: {
        type: string,
        id: string,
    }): CancelablePromise<Array<AuditResponse>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/{type}/{id}/audits',
            path: {
                'type': type,
                'id': id,
            },
        });
    }
}
