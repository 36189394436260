import { Card, CardContent } from '@mui/material'
import { useTranslation } from 'react-i18next'
import LayoutPage from 'src/components/page'
import { paths } from 'src/paths'
import { useParams } from 'react-router'
import InitiateStation from 'src/components/dashboard/stations/initiate'
import { useRouter } from 'src/hooks/use-router'

const Initiate = () => {
    const router = useRouter()
    const { t } = useTranslation()
    const { id } = useParams<{ id: string }>()

    return (
        <LayoutPage
            title="station"
            breadcrumbs={[
                {
                    title: t('view.station.header'),
                    href: paths.stations.index
                },
                {
                    title: id as string,
                    href: paths.stations.view(id)
                },
                {
                    title: t('view.station.initiate.headline')
                }
            ]}
        >
            <Card variant="outlined">
                <CardContent>
                    {id && <InitiateStation id={id} handleClose={() => router.push(paths.stations.view(id))} />}
                </CardContent>
            </Card>
        </LayoutPage>
    )
}

export default Initiate
