import { Grid, Typography } from '@mui/material'
import { Box, Stack } from '@mui/system'
import React from 'react'
import CopyToClipboard from '../copy-to-clipboard'

interface Field {
    label: string
    labelProps?: any
    value?: string | null | number
    Template?: React.FC<any>
    templateProps?: any
    hidden?: boolean
}

export type DetailsListProps = {
    title?: string
    fields: Field[]
}

const DetailsList = ({
    details,
    hover,
    copyValue
}: {
    details: DetailsListProps
    hover?: boolean
    copyValue?: boolean
}) => {
    return (
        <Stack spacing={0}>
            <Typography variant="subtitle2" sx={{ color: 'text.primary', mb: 1 }}>
                {details?.title}
            </Typography>

            {details?.fields
                ?.filter((f: Field) => !f.hidden)
                .map((field: Field, i: number) => {
                    const FieldTemplate = field.Template
                    return (
                        <Grid
                            container
                            key={i}
                            sx={{
                                '&:hover': {
                                    cursor: hover ? 'pointer' : 'default',
                                    backgroundColor: hover ? 'background.default' : 'inherit'
                                },
                                '&:hover .copyColumn': {
                                    display: 'block'
                                },
                                borderRadius: 1,
                                p: 1
                            }}
                        >
                            <Grid item xs={4}>
                                <Typography variant="body2" color="textSecondary" {...field.labelProps}>
                                    {field.label}
                                </Typography>
                            </Grid>
                            <Grid item xs={copyValue ? 7 : 8}>
                                <Box
                                    sx={{
                                        textAlign: 'end',
                                        justifyContent: 'flex-end'
                                    }}
                                >
                                    {FieldTemplate ? (
                                        <Box
                                            sx={{
                                                justifyContent: 'flex-end',
                                                display: 'flex'
                                            }}
                                        >
                                            <FieldTemplate {...field.templateProps} />
                                        </Box>
                                    ) : (
                                        <Typography variant="body2">{field.value ?? '-'}</Typography>
                                    )}
                                </Box>
                            </Grid>
                            {copyValue && (
                                <Grid
                                    item
                                    xs={1}
                                    textAlign="end"
                                    sx={{
                                        height: 10
                                    }}
                                >
                                    <Box
                                        className="copyColumn"
                                        sx={{
                                            display: 'none'
                                        }}
                                    >
                                        {copyValue && !!field.value && <CopyToClipboard text={field.value as string} />}
                                    </Box>
                                </Grid>
                            )}
                        </Grid>
                    )
                })}
        </Stack>
    )
}

export default DetailsList
