import { useQuery } from '@tanstack/react-query'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import MasonryCards from 'src/components/card/masonry-cards'
import AuditsDialog from 'src/components/dashboard/audits/audits-dialog'
import IncidentStatusLabel from 'src/components/dashboard/incidents/status-label'
import UpdateStatusDialog from 'src/components/dashboard/incidents/update-status-dialog'
import { formatStationNumber } from 'src/components/dashboard/stations/utils'
import LayoutPage from 'src/components/page'
import { PermissionsGuard } from 'src/guards/permissions-guard'
import { ACTIONS, RESOURCES, useCheckPermissions } from 'src/hooks/use-check-permissions'
import { useRouter } from 'src/hooks/use-router'
import { paths } from 'src/paths'
import { IncidentCommand, IncidentsService } from 'src/services/api/emos'
import { Card } from 'src/types/card'
import dateFormat from 'src/utils/date-format'

const Profile = () => {
    const { t } = useTranslation()
    const router = useRouter()
    const { id } = useParams<{ id: string }>()
    const [updateStatusDialog, setUpdateStatusDialog] = useState<boolean>(false)
    const [updateStatusTo, setUpdateStatusTo] = useState<IncidentCommand | undefined>(undefined)
    const { can } = useCheckPermissions()
    const [auditsDialogOpen, setAuditsDialogOpen] = useState(false)

    const { data: incident } = useQuery({
        queryKey: ['incident', id],
        queryFn: async () => {
            const res = await IncidentsService.getIncidentById({
                id: id as string
            })
            return res
        },
        enabled: !!id
    })

    const cards = useMemo(
        (): Card[] =>
            incident
                ? [
                      {
                          fields: [
                              {
                                  label: t('view.station.name.headline'),
                                  value: formatStationNumber(incident.station.number)
                              },
                              {
                                  label: t('model.node.type.options.PollingCenter'),
                                  value: `${incident.station.pc?.name} (${incident.station.pc?.code})`
                              },
                              {
                                  label: t('model.user.electionTypes.label'),
                                  value: t(`model.user.electionTypes.options.${incident.station.electionType}`)
                              },
                              {
                                  label: t('model.incident.reason.label'),
                                  value: t(`model.incident.reason.options.${incident.reason}` as any)
                              },
                              {
                                  label: t('model.incident.description'),
                                  value: incident.description
                              },
                              {
                                  label: t('model.incident.timeOfBreakage'),
                                  value: dateFormat({
                                      date: incident.timeOfBreakage,
                                      format: 'hh:mm:ss A - MM/DD'
                                  })
                              },

                              {
                                  label: t('model.common.status'),
                                  Template: IncidentStatusLabel,
                                  templateProps: {
                                      status: incident.status
                                  }
                              },
                              {
                                  label: t('model.common.createdAt'),
                                  value: dateFormat({
                                      date: incident.createdAt,
                                      format: 'hh:mm:ss A - MM/DD'
                                  })
                              }
                          ]
                      },
                      {
                          title: t('model.common.createdBy'),
                          hidden: !incident.createdBy,
                          fields: [
                              {
                                  label: t('model.common.name'),
                                  value: incident.createdBy?.name
                              },
                              {
                                  label: t('model.user.username'),
                                  value: incident.createdBy?.username
                              },
                              {
                                  label: t('model.user.phoneNumber'),
                                  value: incident.createdBy?.phoneNumber
                              }
                          ]
                      },
                      {
                          title: t('model.common.updatedBy'),
                          hidden: !incident.updatedBy,
                          fields: [
                              {
                                  label: t('model.common.name'),
                                  value: incident.updatedBy?.name
                              },
                              {
                                  label: t('model.user.username'),
                                  value: incident.updatedBy?.username
                              },
                              {
                                  label: t('model.user.phoneNumber'),
                                  value: incident.updatedBy?.phoneNumber
                              }
                          ]
                      }
                  ]
                : [],
        [incident, t]
    )

    return (
        <PermissionsGuard resource="incident" actions={[ACTIONS.read]}>
            <LayoutPage
                title="incident"
                breadcrumbs={[
                    {
                        href: paths.incidents.index,
                        title: t('view.incident.header')
                    },
                    {
                        title: t('view.incident.name.headline')
                    }
                ]}
                actions={[
                    {
                        label: t('view.common.dialog.back'),
                        onClick: () => router.push(paths.incidents.index),
                        variant: 'outlined'
                    },
                    {
                        hidden: !can({
                            resource: RESOURCES.incident,
                            actions: [ACTIONS.update]
                        }),
                        label: t('view.common.dialog.edit'),
                        onClick: () => router.push(paths.incidents.edit(id))
                    },
                    ...(incident?.transitions?.map((transition) => ({
                        label: t(`model.incident.commands.${transition.command?.toLowerCase()}` as any),
                        color: 'secondary',
                        onClick: () => {
                            setUpdateStatusDialog(true)
                            setUpdateStatusTo(transition.command)
                        }
                    })) ?? []),
                    {
                        hidden: !can({
                            resource: RESOURCES.audit,
                            actions: [ACTIONS.read]
                        }),
                        label: t('view.common.audit'),
                        onClick: () => setAuditsDialogOpen(true),
                        color: 'info'
                    }
                ]}
            >
                <MasonryCards
                    cards={cards}
                    columns={{
                        xs: 1,
                        sm: 2,
                        md: 2,
                        lg: 3
                    }}
                    spacing={2}
                />
                <>
                    {incident && (
                        <AuditsDialog
                            version={incident?.version ?? 0}
                            open={auditsDialogOpen}
                            onClose={() => setAuditsDialogOpen(false)}
                            id={incident?.id ?? ''}
                            type="incidents"
                        />
                    )}
                </>
            </LayoutPage>
            {updateStatusDialog && incident && updateStatusTo && (
                <UpdateStatusDialog
                    incident={incident}
                    dialogStatus={updateStatusDialog}
                    setDialog={setUpdateStatusDialog}
                    command={updateStatusTo}
                />
            )}
        </PermissionsGuard>
    )
}

export default Profile
