import { Typography } from '@mui/material'
import { useCallback, useEffect, useRef, useState } from 'react'
import { HexColorPicker } from 'react-colorful'
import { useTranslation } from 'react-i18next'
import { useSettings } from 'src/store/settings'
import { useDebouncedCallback } from 'use-debounce'

const useClickOutside = (refs, handler) => {
    useEffect(() => {
        const listener = (event) => {
            // Do nothing if clicking inside the referenced elements
            if (refs.some((ref) => ref.current?.contains(event.target))) return
            handler(event)
        }

        document.addEventListener('mousedown', listener)
        document.addEventListener('touchstart', listener)

        return () => {
            document.removeEventListener('mousedown', listener)
            document.removeEventListener('touchstart', listener)
        }
    }, [refs, handler])
}

const ColorPicker = ({
    color,
    onChange,
    error
}: {
    color: string
    onChange: (color: string) => void
    error?: string
}) => {
    const { t } = useTranslation()
    const popover = useRef(null)
    const swatchButton = useRef(null)
    const [isOpen, setIsOpen] = useState(false)
    const close = useCallback(() => setIsOpen(false), [])
    const { language } = useSettings()

    useClickOutside([popover, swatchButton], close)

    const debouncedOnChange = useDebouncedCallback((value) => {
        onChange(value)
    }, 500)

    return (
        <div className={`picker ${language === 'en' ? 'ltr' : 'rtl'}`} style={{ width: 'fit-content' }}>
            <Typography variant="body2">{t('model.party.color')}</Typography>
            <button
                className="swatch"
                ref={swatchButton}
                style={{ backgroundColor: color, width: 50, height: 50 }}
                onClick={(e) => {
                    e.preventDefault()
                    setIsOpen(true)
                }}
            />

            <Typography variant="caption" color="error" component="p">
                {error}
            </Typography>

            {isOpen && (
                <div className="popover" ref={popover}>
                    <HexColorPicker color={color} onChange={(v) => debouncedOnChange(v)} />
                </div>
            )}
        </div>
    )
}

export default ColorPicker
