import { Avatar, Divider, Grid, Tab, Tabs, Typography } from '@mui/material'
import { Box, Stack } from '@mui/system'
import { useQuery } from '@tanstack/react-query'
import { i18n, TFunction } from 'i18next'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Modal from 'src/components/dialog/modal'
import AppTable from 'src/components/table/AppTable'
import { AuditsService } from 'src/services/api/emos'
import dateFormat from 'src/utils/date-format'
import JsonView from '@uiw/react-json-view'
import { vscodeTheme } from '@uiw/react-json-view/vscode'

type Type = 'stations' | 'users' | 'incidents' | 'violations' | 'votingResults' | 'nodes' | 'parties' | 'candidates'

interface TabPanelProps {
    children?: React.ReactNode
    index: number
    value: number
    dataVersion: number
}

const dateFields = ['createdAt', 'updatedAt', 'initiatedAt']

const modelTypeMappers: Record<Type, string> = {
    stations: 'station',
    users: 'user',
    incidents: 'incident',
    violations: 'violation',
    votingResults: 'votingResult',
    nodes: 'node',
    parties: 'party',
    candidates: 'candidate'
}

function TabPanel(props: Readonly<TabPanelProps>) {
    const { children, value, index, dataVersion, ...other } = props
    return (
        <Box
            role="tabpanel"
            hidden={value !== dataVersion}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            <Box sx={{ p: 3 }}>{children}</Box>
        </Box>
    )
}

const KeyColumnTemplate = ({ type, key, t, i18next }: { type: Type; key: string; t: TFunction; i18next: i18n }) => {
    if (key === 'allegedViolator' || key === 'complainant' || key === 'reason') {
        return <Typography variant="subtitle2">{t(`model.${modelTypeMappers[type]}.${key}.label` as any)}</Typography>
    }
    const modelKey = `model.${modelTypeMappers[type]}.${key}`
    const commonKey = `model.common.${key}`

    const translatedKey = i18next.exists(modelKey)
        ? t(modelKey as any)
        : i18next.exists(commonKey)
          ? t(commonKey as any)
          : key

    return <Typography variant="subtitle2">{translatedKey}</Typography>
}

const AllegedViolatorChange = ({
    value
}: {
    value: { name: string; partyId: string; phoneNumber: string; type: string }
}) => {
    const { t } = useTranslation()

    const values = useMemo(() => {
        return [
            { name: 'name', label: t('model.violation.allegedViolator.name'), value: value?.name },
            { name: 'partId', label: t('model.violation.allegedViolator.partyId'), value: value?.partyId },
            { name: 'type', label: t('model.violation.type.label'), value: value?.type }
        ]
    }, [t, value])

    return (
        <Stack spacing={2}>
            {values.map((item, index) => (
                <>
                    <Stack key={item.name} direction="row" alignItems="center" spacing={1}>
                        <Typography variant="caption" color="textSecondary" fontWeight={400}>
                            {item.label}:
                        </Typography>
                        <Typography variant="caption" fontWeight={400}>
                            {item.value}
                        </Typography>
                    </Stack>
                    {index !== values.length - 1 && <Divider />}
                </>
            ))}
        </Stack>
    )
}

const AttachmentChange = ({ value }: { value: { url: string } }) => {
    return <Avatar variant="square" src={value?.url || ''} />
}

const ChangeColumnTemplate = ({ id, key, t, ...rest }: { id: string; key: string; t: TFunction; rest: any }) => {
    if (dateFields.includes(key)) {
        return <>{dateFormat({ date: rest[id] })}</>
    }
    if (key === 'allegedViolator' || key === 'complainant') {
        return <AllegedViolatorChange value={rest[id]} />
    } else if (key === 'attachment') {
        return <AttachmentChange value={rest[id]} />
    } else if (key === 'isActive') {
        return t(`view.common.yes/no.${rest[id]}` as any)
    } else if (key === 'initiationStatus') {
        return t(`model.station.states.${rest[id]}` as any)
    } else if (key === 'reason') {
        return t(`model.incident.reason.options.${rest[id]}` as any)
    }

    if (typeof rest[id] === 'object') {
        return <JsonView value={rest[id]} style={vscodeTheme} />
    }
    return <>{rest[id]}</>
}

const AuditsDialog = ({
    version,
    open,
    onClose,
    type,
    id
}: {
    version: number
    open: boolean
    onClose: () => void
    type: Type
    id: string
}) => {
    const { t } = useTranslation()
    const [value, setValue] = useState<number | undefined>(version)

    const { data } = useQuery({
        queryKey: ['audits', id, type],
        queryFn: async () => {
            const res = await AuditsService.listAuditsForResource({
                type: type as string,
                id
            })
            return res
        },
        enabled: !!id && open
    })

    const columns = useMemo(
        () => [
            {
                id: 'key',
                label: '',
                template: KeyColumnTemplate,
                width: 100,
                type
            },
            {
                id: 'from',
                label: t('view.common.changes.from'),
                template: ChangeColumnTemplate,
                width: 100
            },
            {
                id: 'to',
                label: t('view.common.changes.to'),
                template: ChangeColumnTemplate,
                width: 100
            }
        ],
        [t, type]
    )

    const handleChange = (_, newValue: number) => {
        setValue(newValue)
    }

    const selectedData = data?.find((item) => item.version === value)

    return (
        <Modal title={t('view.common.audit')} open={open} handleClose={onClose}>
            {data && data.length > 0 ? (
                <Grid container>
                    <Grid item xs={12} sm={4} md={3}>
                        <Tabs
                            orientation="vertical"
                            variant="scrollable"
                            value={value}
                            onChange={handleChange}
                            sx={{ borderRight: 1, borderColor: 'divider', maxHeight: '100%' }}
                        >
                            {data.map((item, index) => (
                                <Tab
                                    label={`${t('view.version')}: ${item?.version}`}
                                    value={item.version}
                                    wrapped
                                    key={index}
                                    sx={{
                                        marginLeft: 0,
                                        '&.MuiTab-root': {
                                            marginLeft: 0
                                        },
                                        '&.Mui-selected': {
                                            backgroundColor: 'background.default'
                                        }
                                    }}
                                />
                            ))}
                        </Tabs>
                    </Grid>

                    <Grid item xs={12} sm={8} md={9}>
                        {data.map((dataVersion, index) => {
                            return (
                                <TabPanel
                                    key={index}
                                    value={value ?? 0}
                                    index={index}
                                    dataVersion={dataVersion?.version ?? 0}
                                >
                                    <Grid container spacing={4}>
                                        <Grid item xs={12}>
                                            <Stack
                                                sx={{
                                                    backgroundColor: 'background.default',
                                                    p: 2
                                                }}
                                                alignItems="flex-start"
                                            >
                                                <Stack direction="row" alignItems="center" spacing={1}>
                                                    <Typography variant="h6">{dataVersion?.version ?? 0}</Typography>
                                                </Stack>
                                                <Typography variant="subtitle1" color="textSecondary">
                                                    {dateFormat({
                                                        date: dataVersion.createdAt
                                                    })}
                                                </Typography>
                                            </Stack>
                                        </Grid>
                                        <Grid item xs={12}>
                                            {selectedData?.operation !== 'insert' ? (
                                                <AppTable
                                                    columns={columns}
                                                    loading={false}
                                                    rows={
                                                        selectedData?.updated?.map((change, index) => ({
                                                            id: index,
                                                            key: change.field,
                                                            from: change.from,
                                                            to: change.to
                                                        })) ?? []
                                                    }
                                                    hidePagination
                                                />
                                            ) : (
                                                <Typography color="textSecondary">
                                                    {t('view.notifications.common.create', {
                                                        name: t(`view.${modelTypeMappers[type]}.name.headline` as any)
                                                    })}
                                                </Typography>
                                            )}
                                        </Grid>
                                    </Grid>
                                </TabPanel>
                            )
                        })}
                    </Grid>
                </Grid>
            ) : (
                <div>{t('view.common.no_data')}</div>
            )}
        </Modal>
    )
}

export default AuditsDialog
