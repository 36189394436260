import { Grid } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import HighPieChart from 'src/components/dashboard/reports/highcharts/pie-chart'
import { DashboardsService, ElectionType, NodeType, StationOperationalStatus } from 'src/services/api/emos'
import { useOperationReportsFilters } from 'src/store/reports'
import ReportCard from './report-card'
import { REFETCH_INTERVAL } from './constants'

const STATION_INITIATION_COLOR_MAPPER = {
    [StationOperationalStatus.PENDING]: '#9e9e9e',
    [StationOperationalStatus.NON_OPERATIONAL]: '#e74c3c',
    [StationOperationalStatus.OPERATIONAL]: '#2ecc71'
}

const StationInitiationReport = ({ enabledQueries }: { enabledQueries: boolean }) => {
    const { t } = useTranslation()
    const state = useOperationReportsFilters()
    const { filters } = state

    const { data: stationInitiationReport = [] } = useQuery({
        queryKey: ['stationInitiationReport', filters],
        queryFn: async () => {
            const res = await DashboardsService.stationInitiationReport({
                circleId: filters?.circle as string,
                ancestorId:
                    filters?.[NodeType.POLLING_CENTER] ||
                    filters?.[NodeType.SUB_BRANCH] ||
                    filters?.[NodeType.BRANCH] ||
                    filters?.[NodeType.PBS],
                electionType: filters?.electionType as ElectionType
            })

            return res.map((r) => ({
                y: r.count || 0,
                name: t(`model.station.states.${r.id}` as any),
                color: STATION_INITIATION_COLOR_MAPPER[r.id as any]
            }))
        },
        enabled: enabledQueries && !!filters?.formType,
        refetchInterval: REFETCH_INTERVAL
    })

    return (
        <Grid item xs={12} sm={6} md={4}>
            <ReportCard title={t('view.reports.operation.stationInitiationReport')}>
                <HighPieChart dataset={stationInitiationReport} />
            </ReportCard>
        </Grid>
    )
}

export default StationInitiationReport
