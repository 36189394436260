import { useQuery } from '@tanstack/react-query'
import { allProvincesQuery } from 'src/queries/provinces'

export const useProvinces = () => {
    const { data = [] } = useQuery({
        ...allProvincesQuery()
    })

    const transformedData = data.reduce((acc, province) => {
        acc[province.id as string] = province.name
        return acc
    }, {})

    return { provinces: transformedData, provincesArray: data }
}
