import { Divider, Typography } from '@mui/material'
import { Stack } from '@mui/system'

const SectionDivider = ({ header }: { header: string }) => {
    return (
        <Stack spacing={2}>
            <Divider />
            <Typography variant="body2" fontWeight="bold">
                {header}
            </Typography>
        </Stack>
    )
}

export default SectionDivider
