import { Card, CardContent, Grid } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import { CandidatesVirtuosoGrid } from 'src/components/dashboard/voting-results/candidate-list-virtualize'
import LayoutPage from 'src/components/page'
import { paths } from 'src/paths'
import { allCandidatesQuery } from 'src/queries/candidates'
import { allPartiesQuery } from 'src/queries/parties'
import { MinimalPartyResponse, PartyType, VotingResultCommand, VotingResultsService } from 'src/services/api/emos'
import StationDetails from '../../components/dashboard/voting-results/components/station-details'
import PartiesVotingCount from '../../components/dashboard/voting-results/components/parties-voting-count'
import { ErrorProvider } from 'src/contexts/count-errors'
import AuditsDialog from 'src/components/dashboard/audits/audits-dialog'
import { ACTIONS, RESOURCES, useCheckPermissions } from 'src/hooks/use-check-permissions'
import UpdateStatusDialog from 'src/components/dashboard/voting-results/components/update-status-dialog'
import VotingResultStatusLabel from 'src/components/dashboard/voting-results/components/status-label'
import { useRouter } from 'src/hooks/use-router'
import { VirtualItemsKeyProvider } from 'src/contexts/virtual-items-key'
import { useSettings } from 'src/store/settings'
import { Stack } from '@mui/system'
import VotingResultFormTypeStatusLabel from 'src/components/dashboard/voting-results/components/form-type-status-label'
import { useVersion, VersionProvider } from 'src/contexts/version-context'
import { formatStationNumber } from 'src/components/dashboard/stations/utils'

const NewVotingResult = () => {
    const { t } = useTranslation()
    const router = useRouter()
    const { id } = useParams<{ id: string }>()
    const { can } = useCheckPermissions()
    const settings = useSettings()
    const [selectedParty, setSelectedParty] = useState<MinimalPartyResponse | undefined>(undefined)
    const [updateStatusTo, setUpdateStatusTo] = useState<VotingResultCommand | undefined>(undefined)
    const [updateStatusDialog, setUpdateStatusDialog] = useState<boolean>(false)

    const [auditsDialogOpen, setAuditsDialogOpen] = useState(false)

    const { setVersionValue } = useVersion()

    const { data: votingResult } = useQuery({
        queryKey: ['votingResult', id],
        queryFn: async () => {
            const res = await VotingResultsService.getVotingResultById({
                id: id as string
            })
            setVersionValue(res.version)
            return res
        },
        enabled: !!id,
        gcTime: 0
    })

    const { data: allParties = [], isPending } = useQuery({
        ...allPartiesQuery({
            isActive: true,
            circleId: votingResult?.circleId
        }),
        enabled: !!votingResult?.circleId
    })

    const { data: allCandidates = [], isLoading: loadingCandidates } = useQuery({
        ...allCandidatesQuery({
            partyId: selectedParty?.id,
            isActive: true,
            circleId: votingResult?.circleId
        }),
        enabled: !!selectedParty && selectedParty.partyType !== PartyType.INDEPENDENT
    })

    const alertUser = useCallback((e: BeforeUnloadEvent) => {
        e.preventDefault()
    }, [])

    useEffect(() => {
        window.addEventListener('beforeunload', alertUser)
        return () => {
            window.removeEventListener('beforeunload', alertUser)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <LayoutPage
            title="Voting Result"
            breadcrumbs={[
                {
                    title: t('view.votingResult.header'),
                    href: paths.votingResults.index
                },
                {
                    title: votingResult?.stationNumber ? formatStationNumber(votingResult?.stationNumber) : ''
                }
            ]}
            prefixActions={[
                {
                    component: <VotingResultStatusLabel status={votingResult?.status} />
                },
                {
                    component: votingResult?.formType ? (
                        <VotingResultFormTypeStatusLabel status={votingResult?.formType} />
                    ) : (
                        <></>
                    )
                }
            ]}
            actions={[
                {
                    label: t('view.common.dialog.back'),
                    onClick: () => router.push(paths.votingResults.index),
                    variant: 'outlined',
                    size: 'small'
                },

                {
                    hidden: !can({
                        resource: RESOURCES.audit,
                        actions: [ACTIONS.read]
                    }),
                    label: t('view.common.audit'),
                    onClick: () => setAuditsDialogOpen(true),
                    color: 'info',
                    size: 'small'
                },
                ...(votingResult?.transitions?.map((transition) => ({
                    label: t(`model.votingResult.commands.${transition.command}` as any),
                    color: transition.command === VotingResultCommand.DRAFT ? 'error' : 'success',
                    size: 'small',
                    onClick: () => {
                        setUpdateStatusDialog(true)
                        setUpdateStatusTo(transition.command)
                    }
                })) ?? [])
            ]}
            actionsContainerProps={{
                sx: {
                    position: 'sticky',
                    zIndex: 1000,
                    top: 0,
                    background: settings.paletteMode === 'dark' ? '#0B0F19' : '#fff',
                    p: 1,
                    boxShadow: '0 0 0 1px #e6e6ff',
                    borderRadius: 1
                }
            }}
        >
            {votingResult ? (
                <VirtualItemsKeyProvider>
                    <Stack gap={1}>
                        <Grid container>
                            <Grid item xs={12} container>
                                <StationDetails votingResult={votingResult} />
                            </Grid>
                        </Grid>

                        <Grid container spacing={1} px={3}>
                            {!isPending && (
                                <Grid item xs={12} sm={6}>
                                    <Card variant="outlined">
                                        <CardContent sx={{ p: 0 }}>
                                            <ErrorProvider>
                                                <PartiesVotingCount
                                                    votingResult={votingResult}
                                                    allParties={allParties}
                                                    selectedParty={selectedParty}
                                                    setSelectedParty={setSelectedParty}
                                                />
                                            </ErrorProvider>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            )}
                            <Grid item xs={12} sm={6}>
                                <CandidatesVirtuosoGrid
                                    parties={allParties}
                                    votingResult={votingResult}
                                    candidates={allCandidates}
                                    value={selectedParty?.id}
                                    loading={loadingCandidates}
                                />
                            </Grid>
                            {votingResult && (
                                <AuditsDialog
                                    version={votingResult?.version ?? 0}
                                    open={auditsDialogOpen}
                                    onClose={() => setAuditsDialogOpen(false)}
                                    id={votingResult?.id ?? ''}
                                    type="votingResults"
                                />
                            )}

                            {updateStatusDialog && votingResult && updateStatusTo && (
                                <UpdateStatusDialog
                                    votingResult={votingResult}
                                    dialogStatus={updateStatusDialog}
                                    setDialog={setUpdateStatusDialog}
                                    command={updateStatusTo}
                                />
                            )}
                        </Grid>
                    </Stack>
                </VirtualItemsKeyProvider>
            ) : (
                <></>
            )}
        </LayoutPage>
    )
}

const New = () => {
    return (
        <VersionProvider>
            <NewVotingResult />
        </VersionProvider>
    )
}

export default New
