import { yupResolver } from '@hookform/resolvers/yup'
import { useState } from 'react'
import { createSchema } from './schema'
import { SystemConfig, SystemConfigService } from 'src/services/api/emos'
import { SubmitHandler, useForm } from 'react-hook-form'
import { Grid, Paper, Typography } from '@mui/material'
import { FormFields } from 'mui-rhf-library'
import FormActions from 'src/components/drawer/form-actions'
import { useToast } from 'src/hooks/useToast'
import { useSystemConfiguration } from 'src/hooks/use-system-configuration'
import { Stack } from '@mui/system'
import { t } from 'i18next'
import { TimePickerController } from 'src/components/input/time-picker-controller'

export const TimePickerControllerWrapper = ({
    name,
    control,
    label,
    parser
}: {
    name: string
    control: any
    label: string
    parser: (value: string) => any
}) => {
    return (
        <TimePickerController
            // ampm={false}
            name={name}
            control={control}
            format="hh:mm A"
            label={label}
            views={['hours', 'minutes']}
            parser={parser}
        />
    )
}

const Edit = ({
    systemConfiguration,
    handleSuccess,
    handleClose
}: {
    systemConfiguration: SystemConfig
    handleSuccess: () => void
    handleClose: () => void
}) => {
    const [btnLoading, setBtnLoading] = useState(false)
    const { errorsToast } = useToast()

    const formConfigs = useForm({
        resolver: yupResolver(createSchema() as any),
        defaultValues: systemConfiguration
    })

    const {
        handleSubmit,
        control,
        formState: { isDirty }
    } = formConfigs

    const { configFields, generateElectionDayFields } = useSystemConfiguration({
        formConfigs
    })

    const handleFormSubmit: SubmitHandler<any> = async (formData) => {
        setBtnLoading(true)
        try {
            await SystemConfigService.updateSystemConfig({
                requestBody: { ...formData }
            })
            handleSuccess()
        } catch (err) {
            errorsToast(err)
        } finally {
            setBtnLoading(false)
        }
    }

    return (
        <Grid container>
            <Grid item xs={12} container>
                <form onSubmit={handleSubmit(handleFormSubmit)}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Paper sx={{ p: 2 }}>
                                <Grid container spacing={2}>
                                    <FormFields fields={configFields} control={control} />
                                </Grid>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Paper sx={{ p: 2 }}>
                                <Stack gap={2}>
                                    <Typography variant="body2">
                                        {t('model.systemConfiguration.privateElectionDay')}
                                    </Typography>
                                    <Grid container spacing={2}>
                                        <FormFields
                                            fields={generateElectionDayFields('privateElectionDay')}
                                            control={control}
                                        />
                                    </Grid>
                                </Stack>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Paper sx={{ p: 2 }}>
                                <Stack gap={2}>
                                    <Typography variant="body2">
                                        {t('model.systemConfiguration.publicElectionDay')}
                                    </Typography>
                                    <Grid container spacing={2}>
                                        <FormFields
                                            fields={generateElectionDayFields('publicElectionDay')}
                                            control={control}
                                        />
                                    </Grid>
                                </Stack>
                            </Paper>
                        </Grid>
                    </Grid>
                    <FormActions
                        disabled={!isDirty}
                        handleClose={handleClose}
                        handleSubmit={handleSubmit(handleFormSubmit)}
                        loading={btnLoading}
                    />
                </form>
            </Grid>
        </Grid>
    )
}

export default Edit
