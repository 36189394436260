import Button from '@mui/material/Button'
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import MiniDrawer from 'src/components/drawer/mini-drawer'
import { DetailsListProps } from 'src/components/list/details-list'
import { Stack } from '@mui/system'
import { CirclesService } from 'src/services/api/emos'
import Edit from './edit'
import { useToast } from 'src/hooks/useToast'
import { ACTIONS, RESOURCES, useCheckPermissions } from 'src/hooks/use-check-permissions'

interface Props {
    open: boolean
    close: () => void
    data: any
}

const ProfileDrawer = ({ open, close, data }: Props) => {
    const [t] = useTranslation()
    const [edit, setEdit] = useState<boolean>(false)
    const queryClient = useQueryClient()
    const { successToast } = useToast()
    const { can } = useCheckPermissions()

    const closeDrawer = useCallback(() => {
        close()
    }, [close])

    const {
        data: circle,
        isLoading,
        isRefetching,
        refetch
    } = useQuery({
        queryKey: ['circle', data],
        queryFn: async () => {
            const res = await CirclesService.getCircleById({
                id: data?.id
            })
            return res
        },
        enabled: !!data
    })

    const details: DetailsListProps = useMemo(
        () => ({
            title: t('view.common.details.label'),
            fields: [
                { label: t('model.common.id'), value: circle?.id },
                { label: t('model.common.name'), value: circle?.name },
                { label: t('model.circle.majoritySeats'), value: circle?.majoritySeats },
                { label: t('model.circle.minoritySeats'), value: circle?.minoritySeats }
            ]
        }),
        [circle, t]
    )

    return (
        <MiniDrawer
            open={open}
            close={closeDrawer}
            header={t('view.circle.name.headline')}
            details={edit ? undefined : details}
            loading={isLoading}
        >
            {!edit && (
                <Stack direction="row" justifyContent="space-between">
                    <Button onClick={closeDrawer} variant="outlined" size="small">
                        {t('view.common.dialog.close')}
                    </Button>
                    {can({
                        resource: RESOURCES.circle,
                        actions: [ACTIONS.update]
                    }) && (
                        <Stack direction="row" spacing={2} justifyContent="flex-end">
                            <Button
                                variant="contained"
                                size="small"
                                onClick={() => setEdit(true)}
                                disabled={isRefetching}
                            >
                                {t('view.actions.common.edit')}
                            </Button>
                        </Stack>
                    )}
                </Stack>
            )}
            {edit && circle && (
                <Edit
                    circle={circle}
                    handleSuccess={() => {
                        successToast(
                            t(`view.notifications.common.update`, {
                                name: t(`view.circle.name.headline`)
                            })
                        )
                        refetch()
                        queryClient.invalidateQueries({ queryKey: ['users'] })
                        setEdit(false)
                    }}
                    handleClose={() => {
                        setEdit(false)
                    }}
                />
            )}
        </MiniDrawer>
    )
}

export default ProfileDrawer
