import { Column } from '@ditkrg/react-core'
import { Avatar, Typography } from '@mui/material'
import { Stack } from '@mui/system'
import { useQuery } from '@tanstack/react-query'
import { TFunction } from 'i18next'
import StatusLabel from 'src/components/label/status-label'
import { allCirclesQuery } from 'src/queries/circles'
import { allPartiesQuery } from 'src/queries/parties'
import dateFormat from 'src/utils/date-format'

const GenerateColumns = ({ tableState, t }: { tableState: any; t: TFunction }) => {
    const { data: allParties = [], isLoading } = useQuery({
        ...allPartiesQuery({})
    })

    const { data: allCircles = [] } = useQuery({
        ...allCirclesQuery()
    })

    const filterOptionsWithLabelAndValue = (options, { inputValue }) => {
        return options.filter((option: { name: string; number: string }) => {
            return (
                option.name?.toLowerCase().includes(inputValue?.toLowerCase?.()) ||
                (option.number + '').includes(inputValue?.toLowerCase?.())
            )
        })
    }

    const cols: Column[] = [
        {
            id: 'index',
            header: '#',
            cell: (_, rowIndex) => tableState.rowsPerPage * tableState.page + rowIndex + 1
        },
        {
            id: 'name',
            header: t('model.common.name'),
            cell: (row) => {
                return (
                    <Stack direction="row" spacing={1} alignItems="center">
                        <Avatar src={row?.photo?.url || ''} />
                        <Typography variant="body2">{row?.name}</Typography>
                    </Stack>
                )
            },
            filter: {
                type: 'text'
            }
        },
        {
            id: 'number',
            header: t('model.common.number'),
            accessor: 'number',
            filter: {
                type: 'text',
                props: {
                    type: 'number',
                    className: 'numeric'
                }
            }
        },
        {
            id: 'partyId',
            header: t('view.party.name.headline'),
            hidden: true,
            filter: {
                type: 'autocomplete',
                props: {
                    virtualized: true,
                    options: allParties,
                    optionLabel: 'name',
                    optionValue: 'id',
                    loading: isLoading,
                    filterOptions: filterOptionsWithLabelAndValue
                }
            }
        },
        {
            id: 'circleId',
            header: t('view.circle.name.headline'),
            cell: (row) => allCircles.find((c) => c.id === row.circleId)?.name || '',
            filter: {
                type: 'autocomplete',
                props: {
                    options: allCircles,
                    optionLabel: 'name',
                    optionValue: 'id'
                }
            }
        },
        {
            id: 'createdAt',
            header: t('model.common.createdAt'),
            cell: (row) => {
                return (
                    dateFormat({
                        date: row.createdAt
                    }) ?? ''
                )
            }
        },
        {
            id: 'status',
            header: t('model.common.status'),
            cell: (row) => <StatusLabel isActive={row.isActive} />
        }
    ]
    return cols
}

export default GenerateColumns
